export const translations = {
  "en": {
    "_home": "Home",
    "_navbar_media_center": "Media Center",
    "_navbar_logout": "LOGOUT",
    "_login": "Login",
    "_login_nav": "Login",
    "_navbar_get_started": "Get started",
    "_title_share_page": "Share this page",
    "_are_you": "Are you?",
    "_are_you_1": "Are you",
    "_registration": "Register",
    "_register": "Register",
    "_forgot_password": "Forgot password",
    "_student": "Student",
    "_students": "Students",
    "_school": "School",
    "_schools": "Schools",
    "_parent": "Guardian",
    "_parents": "Guardians",
    "_parents_1": "Parents",
    "_volunteer": "Volunteer",
    "_required_field": "Required fields are followed by",
    "_for_student": "For Student",
    "_for_student_form": "For Student form",
    "_student_name": "Student Name",
    "_student_name_placeholder": "Type your name",
    "_qid": "QID",
    "_qid_placeholder": "Type your QID",
    "_gender": "Gender",
    "_male": "Male",
    "_female": "Female",
    "_select_gender": "Select gender",
    "_dob": "Date of Birth",
    "_dob_placeholder": "DD/MM/YYYY",
    "_email": "Email Address",
    "_email_placeholder": "Type your email",
    "_country_code": "Country Code",
    "_mobile_number": "Mobile Number",
    "_mobile_number_placeholder": "Type your mobile number",
    "_patent_qid": "Guardian QID",
    "_patent_qid_placeholder": "Type guardian QID",
    "_parent_mobile": "Guardian Mobile 1",
    "_parent_mobile_2": "Guardian Mobile 2",
    "_parent_mobile_placeholder": "Type guardian mobile number",
    "_parent_mobile_placeholder_2": "Type guardian mobile number 2",
    "_parent_email": "Guardian Email Address",
    "_parent_email_placeholder": "Type guardian email address",
    "_channel_of_communication": "Preferred Channel of Communication",
    "_channel_of_communication_required": "Preferred channel of communication is required",
    "_phone_call": "Phone call",
    "_sms": "SMS",
    "_email": "Email",
    "_nationality": "Nationality",
    "_select_your_nationality": "Select your nationality",
    "_grade": "Grade",
    "_select_your_grade": "Select your grade",
    "_select_grade": "Select grade",
    "_for_school_representative": "For School representative",
    "_for_school_representative_form": "For School representative form",
    "_school_name": "School Name",
    "_school_phone_number": "School Phone Number",
    "_school_phone_number_placeholder": "Type your school school number",
    "_select_school_name": "Select school name",
    "_not_in_list": "Other",
    "_school_email_address": "School Email Address",
    "_school_email_address_placeholder": "Type your school email address",
    "_school_representative": "School representative",
    "_name": "Name",
    "_name_placeholder": "Type your name",
    "_name_placeholder_1": "Type name",
    "_position": "Position",
    "_position_placeholder": "Type your position",
    "_for_parent": "For Guardian",
    "_for_parent_form": "For Guardian form",
    "_parent_name": "Guardian Name",
    "_parent_name_placeholder": "Type your guardian name",
    "_parent_id": "Guardian QID",
    "_parent_id_placeholder": "Type your ID",
    "_parent_email_address": "Guardian Email Address",
    "_parent_email_address_placeholder": "Type your email",
    "_parent_mobile_number": "Guardian Mobile",
    "_error_title": "There is a problem with the form, please check and correct the following:",

    "_success_title_student_1": "Thanks for Registration.",
    "_success_title_student_2": "Registration is Successful",
    "_success_title_student_3": "Please check your Email Inbox for the Verification Process",
    "_success_title_parent": "Guardian registration completed successfully",
    "_success_title_school": "School registration completed successfully",
    "_success_title_volunteer": "Volunteer registration completed successfully",
    "_forgot_password_success": "Reset password link has send on your registered email address",
    "_reset_password_success": "Password reset successfully",
    "_password": "Password",
    "_password_placeholder": "Type your password",
    "_reset_password": "Reset password",
    "_confirm_password": "Confirm password",
    "_footer_all_rights": "All Rights Reserved to The Ministry of Information and Communication Technology (Studio 5)-",
    "_scroll_to_top": "Top",
    "_student_name_required": "Student name is required",
    "_invalid_student_name": "Invalid student name",
    "_student_name_contains_atleast_two_word": "Student name contain at least two words",
    "_qid_required": "QID is required",
    "_invalid_qid": "Invalid QID",
    "_date_of_birth_required": "Date of birth is required",
    "_nationality_required": "Nationality is required",
    "_grade_required": "Grade is required",
    "_email_required": "Email is required",
    "_parent_email_required": "Guardian email is required",
    "_invalid_email": "Invalid email address",
    "_mobile_number_required": "Mobile number is required",
    "_parent_mobile_number_1_required": "Guardian mobile number 1 is required",
    "_parent_mobile_number_2_required": "Guardian mobile number 2 is required",
    "_invalid_mobile_number": "Invalid mobile number",
    "_parent_qid_required": "Guardian QID is required",
    "_invalid_parent_qid": "Invalid guardian QID",
    "_parent_name_required": "Guardian name is required",
    "_invalid_parent_name": "Invalid guardian name",
    "_parent_name_contains_atleast_two_word": "Guardian name contain at least two words",
    "_relationship_required": "Relationship with student is required",
    "_invalid_parent_mobile": "Invalid guardian mobile number 1",
    "_invalid_parent_mobile_2": "Invalid guardian mobile number 2",
    "_invalid_parent_email": "Invalid guardian email address",
    "_parent_student_email_cannot_be_same": "Guardian and student email address cannot be same",
    "_parent_student_qid_cannot_be_same": "Guardian and student qid cannot be same",
    "_school_name_required": "School name is required",
    "_school_phone_required": "School phone number is required",
    "_invalid_school_phone": "Invalid school phone number",
    "_school_email_required": "School email is required",
    "_invalid_school_email": "Invalid school email",
    "_rep_name_required": "Representative name is required",
    "_invalid_rep_name": "Invalid representative name",
    "_rep_name_contains_atleast_two_word": "Representative name contain at least two words",
    "_rep_position_required": "Representative position is required",
    "_rep_mobile_required": "Representative mobile is required",
    "_invalid_rep_mobile": "Invalid representative mobile number",
    "_rep_email_required": "Representative email is required",
    "_invalid_rep_email": "Invalid representative email address",
    "_for_volunteer": "For Volunteer",
    "_for_volunteer_form": "For Volunteer form",
    "_volunteer_name_required": "Volunteer name is required",
    "_invalid_volunteer_name": "Invalid volunteer name",
    "_volunteer_name_contains_atleast_two_word": "Volunteer name contain at least two words",
    "_password_is_required": "Password is required",
    "_old_password_is_required": "Old password is required",
    "_invalid_credentials": "Invalid credentials",
    "_confirm_password_required": "Confirm password is required",
    "_password_mismatch": "Password mismatch",
    "_challenges": "Challenges",
    "_competitions": "Competitions",
    "_sessions": "Workshops",
    "_events": "Events",
    "_projects": "Projects",
    "_current_activities": "My Activities",
    "_manage_connections": "My Relatives",
    "_profile_settings": "Profile Settings",
    "_profile": "Profile",
    "_search": "Search",
    "_phone_number": "Phone Number",
    "_relation_to_student": "Relation to a student",
    "_status": "Status",
    "_view_profile": "View profile",
    "_remove": "Remove",
    "_edit": "Edit",
    "_add_guardian": "Add Guardian",
    "_choose_school": "Choose School",
    "_add_school": "Add School",
    "_age": "Age",
    "_add_student": "Add Student",
    "_student_qid": "Student QID",
    "_cancel": "Cancel",
    "_add": "Add",
    "_type_child_qid": "Type child QID",
    "_save_changes": "Save Changes",
    "_activities": "Activities",
    "_code": "Code",
    "_settings": "Settings",
    "_upload_new_photo": "Upload new photo",
    "_student_profile_updated_successfully": "Student profile updated successfully",
    "_activity_type": "Activity type",
    "_select_activity_type": "Select activity type",
    "_select_activity": "Select Activity",
    "_all": "All",
    "_search_result": "Search Result",
    "_no_result_found": "No Data Available",
    "_start_date": "Start date",
    "_end_date": "End date",
    "_clear": "Clear",
    "_enrollments": "Enrollments",
    "_zone": "Zones",
    "_zone_description": "Studio 5 has three main zones to provide the kids extraordinary learning experiences.",
    "_notification": "Notifications",
    "_logout": "Logout",
    "_subscribe": "Subscribe",
    "_subscribe_heading_1": "Subscribe now to get the latest update",
    "_subscribe_heading_2": "Please fill out the form below to receive our update",
    "_describe_your_self": "How you best describe yourself?",
    "_student718": "Student (7-18 years old)",
    "_i_agree": "I agree to the Studio 5",
    "_privacy_policy": "Privacy Policy",
    "_filter_media": "Filter Media",
    "_latest_insight": "Our latest insights, delivered straight to your inbox",
    "_news": "News",
    "_media": "Media",
    "_updates": "UPDATES",
    "_view_more": "View More",
    "_studio5": "Studio 5",
    "_innovate": "Innovate",
    "_imagination": "Imagine",
    "_and": "and",
    "_creativity": "Create",
    "_banner_heading_1": "aims at nurturing youth as digital learners by sharpening their 21",
    "_banner_heading_2": "st",
    "_banner_heading_3": " century learning skills, as they develop in an all-pervasive digital environment.",
    "_main_menu": "Main Menu",
    "_contact_us": "Contact us",
    "_equipment": "Equipment",
    "_about_us": "About us",
    "_machines": "Machines",
    "_faq": "FAQ",
    "_home_page": "Homepage",
    "_registration_menu": "Registration",
    "_,": ",",
    "_and_symbol": "&",
    "_back": "Back",
    "_activity_history": "Activity History",
    "_photo": "Photo",
    "_login_required": "Login is required for enrollment",
    "_enroll_success": "Enrolment completed successfully",
    "_success": "Success",
    "_failed": "Failed",
    "_warning": "Warning",
    "_ok": "Ok",
    "_request_send": "Your request has been sent, will contact you soon",
    "_already_enrolled": "This activity is already enrolled",
    "_volunteer_not_needed": "This activity doesn't want volunteer",
    "_volunteer_not_registered": "You are not registered. Please register as volunteer",
    "_title": "Title",
    "_date": "Date",
    "_volunteer_now": "Volunteer Now",
    "_enrolled": "Enrolled",
    "_enroll": "Enroll",
    "_pending": "Pending",
    "_previous": "Previous",
    "_next": "Next",
    "_sign_up_now": "Sign Up now",
    "_error": "Error",
    "_successfully_cancelled": "Successfully cancelled the activity",
    "_failed_to_cancel": "Failed to cancel the activity",
    "_explore_activity": "You have not enrolled yet to any activity",
    "_explore_activity_1": "Explore our Activities and Enroll Now",
    "_details": "Details",
    "_confirm": "Confirm",
    "_cancel_activity_confirm": "Are you sure want to cancel activity",
    "_continue": "Continue",
    "_upcoming_activity": "Upcoming Activity",
    "_calendar": "Calendar",
    "_forget_password": "Forget Password?",
    "_dont_have": "Don’t have an account?",
    "_sign_up": "Sign up",
    "_guardian_information": "Guardian Information",
    "_children": "Children",
    "_add_children": "Add a Child",
    "_gender_required": "Gender is required",
    "_of": "of",
    "_event_details": "Event Details",
    "_session_details": "Workshop Details",
    "_venue": "Venue",
    "_venue_unavailable": "Venue unavailable",
    "_zone": "Zone",
    "_zone_unavailable": "Zone unavailable",
    "_class_no": "Class No",
    "_class_no_unavailable": "Class no unavailable",
    "_trainer_name": "Trainer Name",
    "_trainer_name_unavailable": "Trainer name unavailable",
    "_gender_unavailable": "Gender unavailable",
    "_age_group": "Age group",
    "_age_group_unavailable": "Age group unavailable",
    "_no_of_attendee": "No of Attendee",
    "_no_of_attendee_unavailable": "No of attendee unavailable",
    "_location": "Location",
    "_location_view": "View Location In Google Map",
    "_learning_objectives": "Learning Objectives",
    "_category": "Category",
    "_category_unavailable": "Category unavailable",
    "_start_date_unavailable": "Start date unavailable",
    "_end_date_unavailable": "End date unavailable",
    "_zone_address": "Address",
    "_address_unavailable": "Address unavailable",
    "_class_room": "Class Room",
    "_class_room_unavailable": "Class room unavailable",
    "_materials": "Materials",
    "_password_set_successfully": "Password has been set successfully, you can Login to the portal now.",
    "_invalid_token": "Invalid token",
    "_go_forgot_password": "Go forgot password again",
    "_invalid_file_type": "Invalid file type. Choose an image file!",
    "_invalid": "Invalid",
    "_crop_your_image": "Crop your profile image",
    "_upload": "Upload",
    "_parent_limit_exceeds": "Parent limit exceeds",
    "_student_unlink_failed": "Student unlinking failed",
    "_select_relation": "Select relation",
    "_select_relation_1": "Select relation",
    "_father": "Father",
    "_mother": "Mother",
    "_relation": "Relation",
    "_remove_student_confirm": "Are you sure want to remove student",
    "_profile_updated_successfully": "Profile updated successfully",
    "_profile_image_updated_successfully": "Profile image updated successfully",
    "_profile_image_removed_successfully": "Profile image removed successfully",
    "_change_password": "Change Password",
    "_student_activation_failed": "Student activation failed",
    "_waiting_list": "Waiting list",
    "_approve": "Approve",
    "_notified_to_admin": "Notified to Studio 5 team",
    "_school_unlinking_failed": "School unlinking failed",
    "_school_unlinking_success": "Successfully unlinked school",
    "_edit_school_confirmation": "Are you sure want to edit school",
    "_no": "No",
    "_yes": "Yes",
    "_password_condition": "Password must be min 8 character long, should contains an upper case, lower case and special character",
    "_image_caption": "Image caption",
    "_video_caption": "Video caption",
    "_guardian_exist": "Guardian already exist",
    "_find_student": "Find Student",
    "_sister": "Sister",
    "_brother": "Brother",
    "_grand_mother": "Grandmother",
    "_grand_father": "Grandfather",
    "_year_old": "Years Old",
    "_parent_name_english": "Guardian name in English",
    "_parent_name_english_placeholder": "Type guardian name in English",
    "_parent_name_arabic": "Guardian name in Arabic",
    "_parent_name_arabic_placeholder": "Type guardian name in Arabic",
    "_student_name_english": "Student name in English",
    "_student_name_english_placeholder": "Type student name in English",
    "_student_name_arabic": "Student name in Arabic",
    "_student_name_arabic_placeholder": "Type student name in Arabic",
    "_name_english": "Name in English",
    "_name_english_placeholder": "Type name in English",
    "_name_arabic": "Name in Arabic",
    "_name_arabic_placeholder": "Type name in Arabic",
    "_boys": "Boys",
    "_girls": "Girls",
    "_mixed": "Mixed",
    "_is_required_for_enrolment": " is required for enrollment",
    "_login_link": "Login",
    "_subscribe_with_activities": "Tick the name of the participant you wish to enroll",
    "_subscribe_with_activities_school": "Select your Students to enroll them",
    "_no_student_to_list": "No student to list",
    "_go_back": "Go back",
    "_no_relative_yet": "You do not have any participant added",
    "_click_add_children": `Click "Add a Participant" to add participant`,
    "_no_relatives": "You have not added any relative add them now",
    "_add_relatives": "Add a Child",
    "_enrolled_successfully": "Enrolled Successfully",
    "_password_changed_success": "Password changed successfully",
    "_old_password": "Old password",
    "_admin_contact_you": "Studio 5 team will contact you",
    "_retry": "Retry",
    "_thank_you": "Thank you",
    "_we_appreciate": "We appreciate that you've taken the time to write us",
    "_get_back_soon": "We'll get back to you very soon",
    "_unauthorized": "You are not authorized to access this page!",
    "_make_an_enquiry": "Contact us",
    "_thankyou_for_interest": "Thank you for your interest in Studio 5",
    "_please_fill_out": "Please fill out the form below to ask a question or report a technical problem.",
    "_type_of_inquiry": "Type of Inquiry:",
    "_general_inquiries": "General Inquiries",
    "_feel_free_to_reach_out": "Feel free to reach out to us for any general inquiries you may have about Studio5/6",
    "_name_required": "Name is required",
    "_invalid_name": "Invalid name",
    "_name_contain_atleast_two_words": "Name contain at least two words",
    "_agree_required": "Agree check is required",
    "_message": "Message",
    "_message_placeholder": "Type your message",
    "_message_required": "Message is required",
    "_contact_request_success": "Request sent successfully",
    "_contact_request_fail": "Failed to send the request",
    "_student_already_exist_with_this_qid": "User already exist with this qid",
    "_jan": "Jan",
    "_feb": "Feb",
    "_mar": "Mar",
    "_apr": "Apr",
    "_may": "May",
    "_jun": "Jun",
    "_jul": "Jul",
    "_aug": "Aug",
    "_sep": "Sep",
    "_oct": "Oct",
    "_nov": "Nov",
    "_dec": "Dec",
    "_january": "January",
    "_february": "February",
    "_march": "March",
    "_april": "April",
    "_may": "May",
    "_june": "June",
    "_july": "July",
    "_august": "August",
    "_september": "September",
    "_october": "October",
    "_november": "November",
    "_december": "December",
    "_page_not_found": "Page Not found",
    "_unauthorized_1": "Unauthorized",
    "_enroll_now": "Enroll Now",
    "_am": "am",
    "_pm": "pm",
    "_home_activity_caption": "Explore our latest activities and enroll in them",
    "_entry": "In",
    "_exit": "Out",
    "_entry_date": "Entry date",
    "_entry_time": "Entry time",
    "_exit_date": "Exit date",
    "_exit_time": "Exit time",
    "_attendance": "Attendance",
    "_mark": "Mark attendance",
    "_submit": "Submit",
    "_delete_attendance_confirmation": "Are you sure want to remove this attendance",
    "_in_time_required": "in time is required",
    "_out_time_required": "out time is required",
    "_enrolment_listing": "Enrolment listing",
    "_send_invitation": "Send invitation",
    "_student_already_enrolled": "Student already enrolled",
    "_attended": "Attended",
    "_ar_country_code": "+974",
    "_closed": "Closed",
    "_enrolment_closed": "Activity enrollment closed",
    "_select_date_range": "Select a date range to filter your dashboard:",
    "_from": "From",
    "_to": "To",
    "_apply": "Apply",
    "_reset": "Reset",
    "_export_student_details": "Export Student Details",
    "_export_activity_details": "Export Activity Details",
    "_students_enrolled": "Students enrolled",
    "_students_waiting_list": "Students waiting list",
    "_students_attended": "Students attended",
    "_activity": "Activity",
    "_total_activities": "Total activities",
    "_current_activities_1": "Current activities",
    "_completed_activities": "Completed activities",
    "_upcoming_activities": "Upcoming activities",
    "_type": "Type",
    "_creative_zone": "Creativity",
    "_technology_zone": "Technology",
    "_software_zone": "Software",
    "_student_details": "Student details",
    "_activity_details": "Activity details",
    "_select_nationality": "Select nationality",
    "_student_name_1": "Student name",
    "_waiting": "Waiting",
    "_activity_name": "Activity name",
    "_select_type": "Select type",
    "_select_zone": "Select Zone",
    "_online": "Online",
    "_onsite": "Onsite",
    "_select": "Select",
    "_session_hours": "Session hours",
    "_activity_gender": "Activity gender",
    "_dashboard": "Dashboard",
    "_enrolled_by": "Enrolled by",
    "_no_of_volunteers": "No. of Volunteers",
    "_trainer": "Trainer",
    "_activity_date": "Activity Date",
    "_absents": "Absents",
    "_assistant": "Assistant",
    "_applied_filters": "Applied filters",
    "_select_activity_time": "Select activity date",
    "_not_attended": "Not attended",
    "_remove_attendance": "Remove attendance",
    "ministry_logo_alt": "Ministry Of Transport and Communication, homepage",
    "studio_logo_alt": "Studio 5, homepage",
    "switch_language_arabic": "Switch language to arabic",
    "switch_language_english": "Switch language to english",
    "sign_up_button_label": "Login button",
    "view_more_activity_label": "Button for view more activity",
    "already_waiting_list": "You are already in the waiting list",
    "footer_logo_alt": "Footer logo of studio5/6",
    "volunteer_now_for": "volunteer now for",
    "_delete": "Delete",
    "_participants": "Participants",
    "_terms_and_condition_agree": "Agree terms and conditions",
    "_terms_and_condition": " Terms and conditions",
    "_agree": "Agree",
    "_objective": "Learning Objective",
    "_import_students": "Import students",
    "_choose_file": "Choose excel file",
    "_present": "Present",
    "_absent": "Absent",
    "_select_age_group": "Select age group",
    "_public_activity_error": "You can not enroll in a public activity",
    "_school_activity_error": "You can not enroll in a school activity",
    "_view_more_news_and_media": "Button for view more news and media",
    "_button": "Button",
    "_pause": "Pause",
    "_play": "Play",
    "_pause_video": "Pause the video",
    "_play_video": "Play the video",
    "_reg_title": "Registration title",
    "_coma": ",",
    "_show_password": "show password",
    "_hide_password": "hide password",
    "_phone_aria_label": "Mobile number starting with country code +974",
    "_close": "Close",
    "_public": "Public",
    "_with": "With",
    "_items": "items",
    "_enroll_remove_message": "Are you sure want to remove enrollment",
    "_view_all": "View All",
    "_transcript": "Transcript",
    "_public_activity": "Public activity",
    "_school_activity": "School activity",
    "_sun": "Sun",
    "_mon": "Mon",
    "_tue": "Tue",
    "_wed": "Wed",
    "_thu": "Thu",
    "_fri": "Fri",
    "_sat": "Sat",
    "_other_description_required": "Other description is required",
    "_i_agree_1": "i agree",
    "_pre_gallery_image": "Previous gallery image",
    "_next_gallery_image": "Next gallery image",
    "_challenge_count": "Challenges count",
    "_competition_count": "Competitions count",
    "_session_count": "Sessions count",
    "_event_count": "Events count",
    "_project_count": "Projects count",
    "_page": "page",
    "_change_profile_image": "profile image edit",
    "_remove_profile_image": "profile image remove",
    "_feature": "Features",
    "_materials": "Materials",
    "_safety_procedure": "Safety procedure",
    "_software_required": "Software Required",
    "_updated_model": "Updated Model",
    "_equipments_used": "Equipment Used",
    "_machines_used": "Machines Used",
    "_no_attendance": "There's no attendance",
    "_no_notification": "No Notifications found",
    "_read_privacy_policy": "Read privacy policy",
    "_read_terms_condition": "Read terms and condition",
    "_selected_date": "selected date",
    "_select_month": "Select month",
    "_select_year": "select year",
    "_select_date_button": "choose date button",
    "_select_start_date_button": "choose start date button",
    "_select_end_date_button": "choose end date button",
    "_select_dob_button": "choose date of birth button",
    "_section": "section",
    "_section {number}": "contains {number} items",
    "_select_all_for_remove_connection": "select all for remove connection",
    "_add_guardian_for": "add guardian for",
    "_link_crop_image": "this is a link to crop your profile image",
    "_selected_for_remove_connection": "select for remove connection",
    "_selected_for_remove_enrollment": "select for remove enrollment",
    "_select_all_for_enrollment": "select all to enrollment",
    "_no_activities_found": "No activities found",
    "_select_for_enrollment": "select for enrollment",
    "_select_all": "select all",
    "_loading": "page loading",
    "_about_us_page": "About us page",
    "_contact_us_page": "Contact us page",
    "_view": "View",
    "_is_required_for_volunteer": " is required for volunteer",
    "_percentage_of_gender": "% of Gender",
    "_qatar_citizens": "Qatar Citizens",
    "_all_students": "All Students",
    "_export_all_student_details": "Export All Student Details",
    "_studio_logo": "Studio 5, logo",
    "_maintenance": "Maintenance",
    "_maintenance_mode": "Maintenance mode",
    "_maintenance_mode_caption1": "Sorry for the inconvenience.",
    "_maintenance_mode_caption2": "Our website is currently undergoing scheduled maintenance.",
    "_maintenance_mode_caption3": "Thank you for your understanding.",
    "_new_registered_students": "New registered students",
    "_add_participants": "Add participants",
    "_add_a_participants": "Add a participants",
    "_participant_name": "Participant name",
    "_participant_qid": "Participant QID",
    "_participant_qid_placeholder": "Type participant QID",
    "_relatives": "Relatives",
    "_student_level": "Student Level",
    "_student_points": "Student Points",
    "_reward_system": "Reward System",
    "_total_points": "Total Points",
    "_points": "Points",
    "_pts": "pts",
    "_skills": "Skills",
    "_skills_used": "Skills used",
    "_software_used": "Software used",
    "_challenge_details": "Challenge Details",
    "_project_details": "Project Details",
    "_software": "Software",
    "_no_content_title_skills": "You don't have any Skills yet, go ahead and start enrolling in the",
    "_no_content_title_software": "You don't have any Software yet, go ahead and start enrolling in the",
    "_no_content_title_machines": "You don't have any Machines yet, go ahead and start enrolling in the",
    "_no_content_title_equipment": "You don't have any Equipment yet, go ahead and start enrolling in the",
    "_hours": "Hours",
    "_minutes": "Minutes",
    "_home_no_activity": "There are no latest activities at the moment, check out activities page to see what's new",
    "_go_to_activities": "Go to activities"
  },
  "ar": {
    "_home": "الرئيسية",
    "_navbar_media_center": "المركز الإعلامي",
    "_navbar_logout": "تسجيل خروج",
    "_login": "التسجيل",
    "_login_nav": "تسجيل الدخول ",
    "_navbar_get_started": "لنبدأ",
    "_title_share_page": "شارك الصفحة",
    "_are_you": "هل أنت؟",
    "_are_you_1": "هل أنت",
    "_registration": "التسجيل",
    "_register": "التسجيل",
    "_forgot_password": "هل نسيت كلمة المرور؟",
    "_student": "طالب",
    "_students": "طالب",
    "_school": "مدرسة",
    "_schools": "مدرسة ",
    "_parent": "ولي أمر",
    "_parents": "الأوصياء",
    "_parents_1": "ولي أمر",
    "_volunteer": "متطوع",
    "_required_field": "الحقول الإجبارية موسومة بالرمز *",
    "_for_student": "للطلبة",
    "_for_student_form": "لاستمارة الطالب",
    "_student_name": "اسم الطالب",
    "_student_name_placeholder": "اكتب اسمك",
    "_qid": "البطاقة الشخصية القطرية",
    "_qid_placeholder": "اكتب رقم البطاقة الشخصية القطرية",
    "_gender": "النوع",
    "_male": "ذكر",
    "_female": "أنثى",
    "_select_gender": "اختر نوع",
    "_dob": "تاريخ الميلاد",
    "_dob_placeholder": "يوم/شهر/سنة",
    "_email": "البريد الالكتروني",
    "_email_placeholder": "اكتب البريد الالكتروني",
    "_country_code": "الرمز الدولي",
    "_mobile_number": "رقم الهاتف",
    "_mobile_number_placeholder": "اكتب رقم جوالك",
    "_patent_qid": "الرقم القطري الشخصي لولي الأمر",
    "_patent_qid_placeholder": "اكتب الرقم الشخصي لولي الأمر",
    "_parent_mobile": "رقم الهاتف لولي الأمر",
    "_parent_mobile_2": "رقم جوال ولي الآمر 2 مطلوب",
    "_parent_mobile_placeholder": "اكتب رقم هاتف ولي الأمر",
    "_parent_mobile_placeholder_2": "جوال ولي الآمر جوال ولي الأمر ٢",
    "_parent_email": "البريد الالكتروني لولي الأمر",
    "_parent_email_placeholder": "اكتب البريد الالكتروني لولي الأمر",
    "_channel_of_communication": "وسيلة الاتصال المفضلة",
    "_channel_of_communication_required": "يجب تحديد وسيلة التواصل المفضلة",
    "_phone_call": "مكالمة هاتفية",
    "_sms": "رسائل نصية",
    "_email": "البريد الالكتروني",
    "_nationality": "الجنسية",
    "_select_your_nationality": "أختر",
    "_grade": "المستوى الدراسي",
    "_select_your_grade": "اختر المستوى الدراسي",
    "_select_grade": "اختر صف",
    "_for_school_representative": "ممثل المدرسة",
    "_for_school_representative_form": "لاستمارة المدرسة",
    "_school_name": "اسم المدرسة",
    "_school_phone_number": "رقم هاتف المدرسة",
    "_school_phone_number_placeholder": "اكتب رقم المدرسة",
    "_select_school_name": "اختر اسم المدرسة",
    "_not_in_list": "آخر",
    "_school_email_address": "البريد الالكتروني للمدرسة",
    "_school_email_address_placeholder": "اكتب البريد الالكتروني لولي للمدرسة",
    "_school_representative": "ممثل المدرسة",
    "_name": "الاسم",
    "_name_placeholder": "اكتب اسمك",
    "_name_placeholder_1": "اكتب اسم",
    "_position": "المسمى الوظيفي",
    "_position_placeholder": "اكتب المسمى الوظيفي",
    "_for_parent": "لولي الأمر",
    "_for_parent_form": "لاستمارة ولي الأمر ",
    "_parent_name": "اسم ولي الأمر",
    "_parent_name_placeholder": "اكتب اسم ولي الأمر",
    "_parent_id": "الرقم القطري الشخصي لولي الأمر",
    "_parent_id_placeholder": "اكتب الرقم الشخصي",
    "_parent_email_address": "البريد الالكتروني لولي الأمر",
    "_parent_email_address_placeholder": "اكتب البريد الالكتروني",
    "_parent_mobile_number": "رقم الهاتف لولي الأمر",
    "_error_title": "هناك خطأ ما في بيانات التسجيل، نرجو مراجعة البيانات وتصحيح التالي",

    "_success_title_student_1": "شكراً لتسجيلك معنا.",
    "_success_title_student_2": "تمت عملية التسجيل بنجاح",
    "_success_title_student_3": "يرجى زيارة صندوق البريد الوارد لإتمام عملية التحقق من بريدك الإلكتروني ",
    "_success_title_parent": "تمت عملية التسجيل بنجاح",
    "_success_title_school": "تمت عملية التسجيل بنجاح",
    "_success_title_volunteer": "تمت عملية التسجيل بنجاح",
    "_forgot_password_success": "لقد تم ارسال رابط تعين كلمة المرور الى بريدك الإلكتروني",
    "_reset_password_success": "تم إعادة تعين كلمة المرور بنجاح",
    "_password": "كلمة المرور",
    "_password_placeholder": "اكتب كلمة المرور",
    "_reset_password": "إعادة تعين كلمة مرور",
    "_confirm_password": "إعادة تعين كلمة مرور",
    "_footer_all_rights": "جميع الحقوق محفوظة وزارة المواصلات و الاتصالات (ستوديو 5/6)-",
    "_scroll_to_top": "لأعلى",
    "_student_name_required": "اسم الطالب مطلوب",
    "_invalid_student_name": "اسم الطالب غير صحيح",
    "_student_name_contains_atleast_two_word": "يجب أن يتكون اسم الطالب من كلمتين على الأقل",
    "_qid_required": "يجب ادخال رقم البطاقة الشخصية القطرية",
    "_invalid_qid": "رقم البطاقة الشخصية القطرية غير صحيح",
    "_date_of_birth_required": "يجب ادخال تاريخ الميلاد",
    "_nationality_required": "يجب ادخال الجنسية",
    "_grade_required": "يجب تحديد الصف الدراسي",
    "_email_required": "يجب ادخال البريد الإلكتروني",
    "_parent_email_required": "البريد الإلكتروني لولي الأمر مطلوب",
    "_invalid_email": "بريد الكتروني غير صحيح",
    "_mobile_number_required": "يجب ادخال رقم الجوال",
    "_parent_mobile_number_1_required": "يجب ادخال رقم هاتف ولي الأمر 1",
    "_parent_mobile_number_2_required": "يجب ادخال رقم هاتف ولي الأمر 2",
    "_invalid_mobile_number": "رقم الهاتف غير صحيح",
    "_parent_qid_required": "يجب ادخال رقم البطاقة الشخصية القطرية لولي الأمر",
    "_invalid_parent_qid": "رقم البطاقة الشخصية القطرية لولي الأمر غير صحيح",
    "_parent_name_required": "يجب ادخال اسم ولي الأمر",
    "_invalid_parent_name": "اسم ولي الأمر غير صحيح",
    "_parent_name_contains_atleast_two_word": "يجب أن يتكون اسم ولي الأمر من كلمتين على الأقل",
    "_relationship_required": "يجب تحديد صلة قرابة ولي الأمر بالطالب",
    "_invalid_parent_mobile": "رقم ولي الأمر 1 غير صحيح",
    "_invalid_parent_mobile_2": "رقم ولي الأمر 2 غير صحيح",
    "_invalid_parent_email": "البريد الالكتروني لولي الأمر غير صحيح",
    "_parent_student_email_cannot_be_same": "البريد الالكتروني لولي الأمر و الطالب يجب ألا يتطابقان",
    "_parent_student_qid_cannot_be_same": "الرقم الشخصي القطري لولي الأمر و الطالب يجب ألا يتطابقان",
    "_school_name_required": "اسم المدرسو مطلوب ",
    "_school_phone_required": "رقم هاتف المدرسة مطلوب",
    "_invalid_school_phone": "رقم هاتف المدرسة غير صحيح",
    "_school_email_required": "اسم المدرسة مطلوب",
    "_invalid_school_email": "البريد الالكتروني للمدرسة غير صحيح",
    "_rep_name_required": "اسم ممثل المدرسة مطلوب ",
    "_invalid_rep_name": "اسم ممثل المدرسة غير صحيح",
    "_rep_name_contains_atleast_two_word": "يجب أن يتكون اسم ممثل المدرسة من كلمتين على الأقل",
    "_rep_position_required": "المسمى الوظيفي لممثل المدرسة مطلوب",
    "_rep_mobile_required": "رقم جوال ممثل المدرسة مطلوب",
    "_invalid_rep_mobile": "رقم جوال ممثل المدرسة غير  صحيح",
    "_rep_email_required": "البريد الالكتروني لممثل المدرسة مطلوب",
    "_invalid_rep_email": "البريد الالكتروني لممثل المدرسة غير صحيح",
    "_for_volunteer": "للمتطوعين",
    "_for_volunteer_form": "لاستمارة التطوع ",
    "_volunteer_name_required": " اسم المتطوع مطلوب",
    "_invalid_volunteer_name": "اسم المتطوع غير صحيح",
    "_volunteer_name_contains_atleast_two_word": "يجب أن يتكون اسم المتطوع من كلمتين على الأقل",
    "_password_is_required": "كلمة المرور مطلوبة",
    "_old_password_is_required": "كلمة المرور القديمة مطلوبة",
    "_invalid_credentials": "معلومات الدخول غير صحيحة",
    "_confirm_password_required": "الرجاء تأكيد كلمة المرور",
    "_password_mismatch": "كلمة المرور غير متطابقة",
    "_challenges": "التحديات",
    "_competitions": "المسابقات",
    "_sessions": "الدورات",
    "_events": "الفعاليات",
    "_projects": "المشاريع",
    "_current_activities": "فعالياتي",
    "_manage_connections": "أقربائي",
    "_profile_settings": "الإعدادات",
    "_profile": "الملف الشخصي",
    "_search": "بحث",
    "_phone_number": "رقم الهاتف",
    "_relation_to_student": "العلاقة مع الطالب",
    "_status": "الحالة",
    "_view_profile": "الملف الشخصي",
    "_remove": "إلغاء",
    "_edit": "تعديل",
    "_add_guardian": "إضافة ولي آمر",
    "_choose_school": "اختر مدرسة",
    "_add_school": "اختر مدرسة",
    "_age": "العمر ",
    "_add_student": "إضافة طالب",
    "_student_qid": "الرقم الشخصي القطري للطالب",
    "_cancel": "إلغاء",
    "_add": "إضافة",
    "_type_child_qid": "اكتب الرقم الشخصي القطري لطفلك",
    "_save_changes": "حفظ",
    "_activities": "الأنشطة",
    "_code": "رمز",
    "_settings": "الإعدادات",
    "_upload_new_photo": "تحمسل صورة جديدة",
    "_student_profile_updated_successfully": "تم تحديث حساب الطالب بنجاح",
    "_activity_type": "نوع الفعالية",
    "_select_activity_type": "اختر نوع فعالية",
    "_select_activity": "اختر فعالية",
    "_all": "الكل",
    "_search_result": "نتيجة البحث",
    "_no_result_found": "لا يوجد شئ بع",
    "_start_date": "تاريخ البدء",
    "_end_date": "تاريخ الانتهاء",
    "_clear": "مسح",
    "_enrollments": "المسجلون",
    "_zone": "مناطق",
    "_zone_description": "يحتوي ستوديو 5/6 على ثلاث مناطق رئيسية لتزويد الأطفال بخبرات تعليمية غير اعتيادية",
    "_notification": "التنبيهات ",
    "_logout": "خروج",
    "_subscribe": "الإشتراك",
    "_subscribe_heading_1": "اشترك الآن لمتابعة تحديثاتنا ",
    "_subscribe_heading_2": "الرجاء ملء النموذج أدناه لتصلك آخر الأخبار",
    "_describe_your_self": "كيف تصف نفسك؟",
    "_student718": "طالب (7-18 سنة)",
    "_i_agree": "أوافق على سياسة الخصوصية لستوديو 5/6",
    "_privacy_policy": "سياسة الخصوصية",
    "_filter_media": "تصنيف",
    "_latest_insight": "لتصلك آخر أخبارنا على بريدك الإلكتروني",
    "_news": "الأخبار",
    "_media": "الإعلام",
    "_updates": "التحديثات",
    "_view_more": "عرض المزيد",
    "_studio5": "ستوديو 5/6",
    "_innovate": "أبدِع",
    "_imagination": "تخيّل",
    "_and": "و",
    "_creativity": "ابتكر",
    "_banner_heading_1": "يهدف ",
    "_banner_heading_2": "",
    "_banner_heading_3": " إلى الإرتقاء بقدرات الشباب عبر تطوير مهاراتهم العلمية في بيئة العصر الرقمي.",
    "_main_menu": "القائمة",
    "_contact_us": "تواصل معنا",
    "_equipment": "المعدات",
    "_about_us": "من نحن",
    "_machines": "الآلات",
    "_faq": "الأسئلة الشائعة",
    "_home_page": "الرئيسية",
    "_registration_menu": "التسجيل",
    "_,": "،",
    "_and_symbol": "و",
    "_back": "عودة",
    "_activity_history": "الفعاليات السابقة",
    "_photo": "صورة فوتوغرافية",
    "_login_required": "تسجيل الدخول مطلوب للتسجيل في الفعالية",
    "_enroll_success": "تم التسجيل بنجاح",
    "_success": "نجاح",
    "_failed": "فشل",
    "_warning": "تحذير",
    "_ok": "موافق",
    "_request_send": "تم إرسال الطلب، سيتم التواصل معك لاحقاً",
    "_already_enrolled": "هذه الفعالية مسجلة بالفعل",
    "_volunteer_not_needed": "هذه الفعالية لا تحتاج متطوعين ",
    "_volunteer_not_registered": "أنت غير مسجل، نرجو منك التسجيل كمتطوع.",
    "_title": "عنوان",
    "_date": "تاريخ",
    "_volunteer_now": "تطوع الآن",
    "_enrolled": "تم التسجيل",
    "_enroll": "سجل",
    "_pending": "قيد الانتظار",
    "_previous": "السابق",
    "_next": "التالى",
    "_sign_up_now": "أفتح حساب الأن",
    "_error": "خطأ",
    "_successfully_cancelled": "تم إلغاء الفعالية بنجاح",
    "_failed_to_cancel": "تمت عملية الإلغاء بالفشل ",
    "_explore_activity": "أنت غير مسجل بأي فعالية.",
    "_explore_activity_1": "اكتشف فعالياتنا  وسجل فيها الآن.",
    "_details": "تفاصيل",
    "_confirm": "تأكيد",
    "_cancel_activity_confirm": "هل أنت حقا تريد إلغاء الفعالية؟",
    "_continue": "متابعة",
    "_upcoming_activity": "الأنشطة القادمة",
    "_calendar": "التقويم",
    "_forget_password": "نسيت كلمة المرور؟",
    "_dont_have": "غير مسجل ولا تملك حساب؟",
    "_sign_up": "سجّل الآن",
    "_guardian_information": "بيانات ولي الآمر",
    "_children": "الأطفال",
    "_add_children": "إضافة طفل",
    "_gender_required": "يجب تحديد النوع",
    "_of": "من",
    "_event_details": "تفاصيل الحدث",
    "_session_details": "تفاصيل الجلسة",
    "_venue": "المكان",
    "_venue_unavailable": "المكان غير متوفر",
    "_zone": "المناطق",
    "_zone_unavailable": "المنطقة غير متوفرة ",
    "_class_no": "رقم الصف",
    "_class_no_unavailable": "الصف غير متوفر",
    "_trainer_name": "اسم المدرب",
    "_trainer_name_unavailable": "اسم المدرب غير متوفر",
    "_gender_unavailable": "النوع غير متوفر",
    "_age_group": "الفئة العمرية",
    "_age_group_unavailable": "الفئة العمرية غير متوفرة",
    "_no_of_attendee": "عدد الحضور",
    "_no_of_attendee_unavailable": "عدد الحضور غير متوفر",
    "_location": "الموقع",
    "_location_view": "عرض الموقع في خريطة جوجل ",
    "_learning_objectives": "الأهداف",
    "_category": "الفئة ",
    "_category_unavailable": "الفئة غير متوفرة",
    "_start_date_unavailable": "تاريخ البدء",
    "_end_date_unavailable": "تاريخ الإنتهاء",
    "_zone_address": "العنوان",
    "_address_unavailable": "العنوان غير متوفر",
    "_class_room": "القاعة",
    "_class_room_unavailable": "القاعة غير متوفرة",
    "_materials": "المواد ",
    "_password_set_successfully": "تم تعين كلمة المرور بنجاح ، يمكنك الآن الدخول.",
    "_invalid_token": "رابط غير صالح",
    "_go_forgot_password": "اضغط على نسيت كلمة المرور",
    "_invalid_file_type": "اختر صورة آخرى، نوع الملف غير صحيح",
    "_invalid": "غير صحيح",
    "_crop_your_image": "قص الصورة",
    "_upload": "تحميل",
    "_parent_limit_exceeds": "تم الوصول للحد الآقصى لإضافة ولي آمر",
    "_student_unlink_failed": "لم يكتمل الطلب",
    "_select_relation": "اختر صلة القرابة",
    "_select_relation_1": "اختر صلة القرابة",
    "_father": "أب",
    "_mother": "أم",
    "_relation": "صلة القرابة",
    "_remove_student_confirm": "هل ترغب بحذف الطالب",
    "_profile_updated_successfully": "تمت عملية تحديث الملف الشخصي بنجاح ",
    "_profile_image_updated_successfully": "تم عملية تحديث الصورة الشخصية بنجاح",
    "_profile_image_removed_successfully": "تمت عملية إزالة صورة الملف الشخصي بنجاح",
    "_change_password": "تغير كلمة المرور",
    "_student_activation_failed": "Student activation failed",
    "_waiting_list": "قائمة الانتظار",
    "_approve": "الموافقة",
    "_notified_to_admin": "سيتم تبليغ فريق ستوديو 5/6",
    "_school_unlinking_failed": "تمت العملية بالفشل",
    "_school_unlinking_success": "تمت العملية بنجاح",
    "_edit_school_confirmation": "هل ترغب بتعديل المدرسة؟",
    "_no": "لا",
    "_yes": "نعم",
    "_password_condition": "يجب أن تتكون كلمة المرور من 8 أحرف متنوعة.",
    "_image_caption": "نص الصورة",
    "_video_caption": "نص الفيديو",
    "_guardian_exist": "ولي الآمر موجود بالفعل",
    "_find_student": "ابحث عن طالب",
    "_sister": "أخت",
    "_brother": "أخ",
    "_grand_mother": "جدة",
    "_grand_father": "جد",
    "_year_old": "سنوات",
    "_parent_name_english": "اسم ولي الآمر باللغة الإنجليزية",
    "_parent_name_english_placeholder": "اسم ولي الآمر باللغة الإنجليزية",
    "_parent_name_arabic": "اسم ولي الآمر باللغة العربية",
    "_parent_name_arabic_placeholder": "اسم ولي الآمر باللغة الإنجليزية",
    "_student_name_english": "اسم الطالب باللغة الإنجليزية",
    "_student_name_english_placeholder": "اكتب اسمك باللغة الإنجليزية",
    "_student_name_arabic": "اسم الطالب باللغة العربية",
    "_student_name_arabic_placeholder": "اسم الطالب باللغة العربية",
    "_name_english": "الاسم باللغة الإنجليزية",
    "_name_english_placeholder": "الاسم باللغة الإنجليزية",
    "_name_arabic": "الاسم باللغة العربية",
    "_name_arabic_placeholder": "اسم الطالب باللغة العربية",
    "_boys": "أولاد",
    "_girls": "فتيات",
    "_mixed": "مختلطة",
    "_is_required_for_enrolment": " مطلوب للتسجيل في النشاط ",
    "_login_link": "تسجيل الدخول",
    "_subscribe_with_activities": "اختر أحد أطفالك لتسجيلهم",
    "_subscribe_with_activities_school": "اختر طلابك لتسجيلهم",
    "_no_student_to_list": "لا يوجد طلاب ",
    "_go_back": "حنث بوعده",
    "_no_relative_yet": "لم تقم بإضافة طفل بعد",
    "_click_add_children": `اضغط على "إضافة طفل" للإضافة`,
    "_no_relatives": "لم تقم بإضافة طفل بعد",
    "_add_relatives": "إضافة طفل",
    "_enrolled_successfully": "تمت عملية التسجيل",
    "_password_changed_success": "تمت عملية تغير كلمة المرور بنجاح",
    "_old_password": "كلمة المرور القديمة",
    "_admin_contact_you": "سيتم التواصل معك من قبل فريق ستوديو 5/6",
    "_retry": "إعادة",
    "_thank_you": "شكرا",
    "_we_appreciate": "نحن نقدر الوقت الذي منحتة للكتابة لنا",
    "_get_back_soon": "سنقوم بالرد عليك قريبا",
    "_unauthorized": "لا يمكنك الدخول لهذه الصفحة",
    "_make_an_enquiry": "تواصل معنا ",
    "_thankyou_for_interest": "شكرا لاهتمامك بستوديو 5/6",
    "_please_fill_out": "اكتب لنا في حال لديك أي استفسار او مشكلة تقنية.",
    "_type_of_inquiry": "Type of Inquiry:",
    "_general_inquiries": "استفسارات عامة",
    "_feel_free_to_reach_out": " لا تتردد في التواصل معنا  للاستفسارات العامة التي قد تكون لديك حول ستوديو 5/6",
    "_name_required": "الاسم مطلوب",
    "_invalid_name": "اسم غير صحيح",
    "_name_contain_atleast_two_words": "يجب أن يتكون الاسم من كلمتين على الأقل",
    "_agree_required": "الموافقة على سياسات الخصوصية مطلوبة",
    "_message": "الرسالة",
    "_message_placeholder": "الرسالة",
    "_message_required": "الرسالة مطلوبة",
    "_contact_request_success": "تم إرسال الطلب بنجاح",
    "_contact_request_fail": "فشلت عملية إرسال الطلب",
    "_student_already_exist_with_this_qid": "رقم البطاقة الشخصية موجود بالفعل",
    "_jan": "يناير",
    "_feb": "فبراير",
    "_mar": "مارس",
    "_apr": "أبريل",
    "_may": "مايو",
    "_jun": "يونيو",
    "_jul": "يوليو",
    "_aug": "أغسطس",
    "_sep": "سبتمبر",
    "_oct": " أكتوبر",
    "_nov": "نوفمبر",
    "_dec": " ديسمبر",
    "_january": "يناير",
    "_february": "فبراير",
    "_march": "مارس",
    "_april": "أبريل",
    "_may": "مايو",
    "_june": "يونيو",
    "_july": "يوليو",
    "_august": "أغسطس",
    "_september": "سبتمبر",
    "_october": " أكتوبر",
    "_november": "نوفمبر",
    "_december": " ديسمبر",
    "_page_not_found": "الصفحة غير موجودة",
    "_unauthorized_1": "غير مصرح",
    "_enroll_now": "سجّل الآن",
    "_am": "ص",
    "_pm": "م",
    "_home_activity_caption": "استكشف آخر الأنشطة وقم بالتسجيل بها",
    "_entry": "الدخول",
    "_exit": "الخروج",
    "_entry_date": "In date",
    "_entry_time": "وقت الدخول",
    "_exit_date": "Out date",
    "_exit_time": "وقت الخروج",
    "_attendance": "الحضور",
    "_mark": "الحضور",
    "_submit": "تقديم ",
    "_delete_attendance_confirmation": "هل أنت متأكد من مسح حضور الطالب ",
    "_in_time_required": "وقت الدخول إجباري",
    "_out_time_required": "وقت الخروج إجباري",
    "_enrolment_listing": "قائمة المسجلين",
    "_send_invitation": "إرسال دعوة",
    "_student_already_enrolled": "الطالب مسجل بالفعل",
    "_attended": "الحضور",
    "_ar_country_code": "+٩٧٤",
    "_closed": "مغلق",
    "_enrolment_closed": "التسجيل مغلق",
    "_select_date_range": "اختر تاريخ:",
    "_from": "من",
    "_to": "إلى",
    "_apply": "تطبيق",
    "_reset": "إعادة تعيين",
    "_export_student_details": "تحميل بيانات الطالب",
    "_export_activity_details": "تحميل بيانات الفعالية",
    "_students_enrolled": "الطلاب المسجلين",
    "_students_waiting_list": "طلاب قائمة الانتظار",
    "_students_attended": "الحضور",
    "_activity": "الأنشطة",
    "_total_activities": "عدد الفعاليات",
    "_current_activities_1": "الأنشطة الحالية",
    "_completed_activities": "الأنشطة السابقة",
    "_upcoming_activities": "الأنشطة القادمة",
    "_type": "النوع",
    "_creative_zone": "منطقة الابداع",
    "_technology_zone": "منطقة التكنولوجيا",
    "_software_zone": "منطقة البرمجة",
    "_student_details": "بيانات الطالب",
    "_activity_details": "بيانات النشاط",
    "_select_nationality": "اختر الجنسية",
    "_student_name_1": "اسم الطالب",
    "_waiting": "قائمة الانتظار",
    "_activity_name": "اسم النشاط",
    "_select_type": "اختر النوع",
    "_select_zone": "اختر المنطقة",
    "_online": "أونلاين",
    "_onsite": "موقع",
    "_select": "اختر",
    "_session_hours": "عدد الساعات",
    "_activity_gender": "النوع",
    "_dashboard": "لوحة المعلومات",
    "_enrolled_by": "سجل من قبل",
    "_no_of_volunteers": "عدد المتطوعين",
    "_trainer": "المدرب",
    "_activity_date": "التاريخ",
    "_absents": "الغياب",
    "_assistant": "المدرب المساعد",
    "_applied_filters": "المطبق",
    "_select_activity_time": "اختر التاريخ",
    "_not_attended": "لم يحضر",
    "_remove_attendance": "الغاء الحضور",
    "ministry_logo_alt": "وزارة المواصلات والاتصالات ، الصفحة الرئيسية",
    "studio_logo_alt": "ستوديو5/6، الصفحة الرئيسية",
    "switch_language_arabic": "تغيير اللغة إلى العربية",
    "switch_language_english": "تغيير اللغة إلى الإنجليزية",
    "sign_up_button_label": "زر تسجيل الدخول",
    "view_more_activity_label": "زر لعرض المزيد من الأنشطة",
    "already_waiting_list": "أنت بالفعل في قائمة الانتظار",
    "footer_logo_alt": "Footer logo of studio5/6",
    "volunteer_now_for": "volunteer now for",
    "_delete": "حذف",
    "_participants": "مشاركين",
    "_terms_and_condition_agree": "أوافق على الشروط و الأحكام ",
    "_terms_and_condition": " الشروط و الأحكام",
    "_agree": "أوافق",
    "_objective": "الأهداف التعليمية",
    "_import_students": "تحميل طلاب",
    "_choose_file": "اختر ملف أكسل ",
    "_present": "حاضر",
    "_absent": "غائب",
    "_select_age_group": "اختر فئة عمرية ",
    "_public_activity_error": "لا يمكنك التسجيل في الفعاليات العامة ",
    "_school_activity_error": "لا يمكنك التسجيل في فعاليات المدارس",
    "_view_more_news_and_media": "عرض المزيد من الأخبار و الإعلام ",
    "_button": "زر ",
    "_pause": "توقف",
    "_play": "تشغيل",
    "_pause_video": "وقف الفيديو",
    "_play_video": "تشغيل الفيديو",
    "_reg_title": "التسجيل بصفة",
    "_coma": "،",
    "_show_password": "إظهار كلمة المرور",
    "_hide_password": "إخفاء كلمة المرور",
    "_phone_aria_label": "+974 رقم الهاتف الجوال يبدأ برمز الدولة",
    "_close": "إغلاق",
    "_public": "عام",
    "_with": "مع",
    "_items": "العناصر",
    "_enroll_remove_message": "هل أنت متأكد من أنك تريد إزالة التسجيل",
    "_view_all": "عرض الكل",
    "_transcript": "كشف البيانات",
    "_public_activity": "نشاط عام",
    "_school_activity": "نشاط مدرسة",
    "_sun": "الأحد",
    "_mon": "الإثنين",
    "_tue": "الثلاثاء",
    "_wed": "الأربعاء",
    "_thu": "الخميس",
    "_fri": "الجمعة",
    "_sat": "السبت",
    "_other_description_required": "مطلوب وصف آخر ",
    "_i_agree_1": "انا اوافق",
    "_pre_gallery_image": "صورة المعرض السابقة",
    "_next_gallery_image": "صورة المعرض التالية",
    "_challenge_count": "عدد التحديات",
    "_competition_count": "عدد المسابقات",
    "_session_count": "عدد الدورات",
    "_event_count": "عدد الفعاليات",
    "_project_count": "عدد المشاريع",
    "_page": "الصفحة",
    "_change_profile_image": "تعديل صورة الملف الشخصي",
    "_remove_profile_image": "إزاله صورة الملف الشخصي",
    "_feature": "المميزات",
    "_materials": "المواد",
    "_safety_procedure": "إجراءات السلامة",
    "_software_required": "مطلوب البرنامج",
    "_updated_model": "نموذج محدث",
    "_equipments_used": "المعدات المستخدمة",
    "_machines_used": "الآلات المستخدمة",
    "_no_attendance": "لا يوجد حضور",
    "_no_notification": "لم يتم العثور على إشعارات",
    "_read_privacy_policy": "اقرأ سياسة الخصوصية",
    "_read_terms_condition": "اقرأ الشروط والأحكام",
    "_selected_date": "التاريخ المحدد",
    "_select_month": "اختر الشهر",
    "_select_year": "اختر السنة",
    "_select_date_button": "زر اختيار التاريخ",
    "_select_start_date_button": "زر اختيار تاريخ البدء",
    "_select_end_date_button": "زر اختيار تاريخ الانتهاء",
    "_select_dob_button": "زر اختيار تاريخ الميلاد",
    "_section": "قسم",
    "_section {number}": "يحتوي على {number} عنصرًا",
    "_select_all_for_remove_connection": "اختر الكل",
    "_add_guardian_for": "إضافة ولي أمر ل",
    "_link_crop_image": "هذا رابط لاقتصاص صورة ملفك الشخصي",
    "_selected_for_remove_connection": "حدد لحذف الارتباط",
    "_selected_for_remove_enrollment": "حدد لحذف التسجيل",
    "_select_all_for_enrollment": "حدد الكل لحذف التسجيل",
    "_no_activities_found": "لم يتم العثور على انشطة",
    "_select_for_enrollment": "اختر للتسجيل",
    "_select_all": "تحديد الكل",
    "_loading": "تحميل الصفحة",
    "_about_us_page": "About us page",
    "_contact_us_page": "Contact us page",
    "_view": "عرض",
    "_is_required_for_volunteer": " مطلوب للتطوع في النشاط",
    "_percentage_of_gender": "% النوع ",
    "_qatar_citizens": "القطريين",
    "_all_students": "جميع الطلاب",
    "_export_all_student_details": "تحميل بيانات جميع الطلاب",
    "_studio_logo": "Studio 5, logo",
    "_maintenance": "صيانة",
    "_maintenance_mode": "تحت الصيانة ",
    "_maintenance_mode_caption1": "نأسف للإزعاج.",
    "_maintenance_mode_caption2": "موقعنا يخضع حاليا للصيانة .",
    "_maintenance_mode_caption3": "شكرا لتفهمكم",
    "_new_registered_students": "الطلاب الجدد المسجلين",
    "_add_participants": "أضف مشاركين",
    "_add_a_participants": "أضف مشترك",
    "_participant_name": "اسم المشترك",
    "_participant_qid": "البطاقة الشخصية للمشارك",
    "_participant_qid_placeholder": "اكتب البطاقة الشخصية للمشارك",
    "_relatives": "الأقارب",
    "_student_level": "مستوى الطالب",
    "_student_points": "نقاط الطالب",
    "_reward_system": "نظام المكافأة",
    "_total_points": "مجموع النقاط",
    "_points": "النقاط",
    "_pts": "نقطة",
    "_skills": "المهارات",
    "_skills_used": "المهارات المستخدمة",
    "_software_used": "البرمجيات المستخدمة",
    "_challenge_details": "تفاصيل التحدي",
    "_project_details": "تفاصيل المشروع",
    "_software": "البرمجة",
    "_no_content_title_skills": "لا يوجد لديك مهارات ، ولكن يمكنك الان تعلم المزيد عن طريق التسجيل في",
    "_no_content_title_software": "لا يوجد لديك مهارة في البرمجة ، ولكن يمكنك الان تعلم المزيد عن طريق التسجيل في",
    "_no_content_title_machines": "لا يوجد لديك مهارة في الإبداع ، ولكن يمكنك الان تعلم المزيد عن طريق التسجيل في",
    "_no_content_title_equipment": "لا يوجد لديك مهارة في التكنولوجيا ، ولكن يمكنك الان تعلم المزيد عن طريق التسجيل في",
    "_hours": "ساعات",
    "_minutes": "دقائق",
    "_home_no_activity": "لا يوجد أنشطة تم اضافتها مؤخرا، انظر صفحة الأنشطة للإطلاع على كل ما هو جديد",
    "_go_to_activities": "صفحة الأنشطة"
  }
}