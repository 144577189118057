import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash"
import Main from "../../main";
import PageTitle from "../../includes/page_title.js";
import SlideToTop from "../../includes/slide_to_top.js";
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { dynamicStyles } from "./../../../constants/dynamicStyles"
import { urlEndPoints } from "./../../../httpAPI/apiConfig"
import { PopupAction, LoadingAction } from "./../../../actions"
import topLineImage from "../../../assets/images/top-line.png"

class WaitingList extends Main {
    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            studentList: [],
            config: this.config(),
            intervalId: 0,
            scrollStepInPx: 50,
            delayInMs: 16.66,
            isParentExist: true,
        };
        this.props.showLoader();
    }
    async componentDidMount() {
        document.title = `${this.context.t("_studio5")} | ${this.context.t("_waiting_list")}`
        await this.initState();
        await this.getWaitingList();
    }
    componentDidUpdate() {
        document.title = `${this.context.t("_studio5")} | ${this.context.t("_waiting_list")}`
    }
    initState = () => {
        this.setState({
            formData: {
                name: { value: "", error: "", class: "" },
                grade: { value: "", error: "", class: "" },
                gender: { value: "", error: "", class: "" }
            }
        })
    }
    handleChange = (field, value) => {
        const { formData } = this.state;
        formData[field]["class"] = value ? "" : "invalid";
        formData[field]["value"] = value;
        this.setState({ formData });
    };

    getWaitingList = async () => {
        // const authResponse = await this.auth();
        const { formData } = this.state;
        // if (authResponse) {
        const { config } = this.state
        let url = `${config.api_url}${urlEndPoints.getWaitingList}`

        if (!_.isEmpty(formData.name.value))
            url = `${url}&combine=${formData.name.value}`

        // const headers = {
        //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
        // }
        try {
            const data = await this.networkRequest(url)
            this.props.hideLoader();
            if (!data.data.message) {
                this.setState({ studentList: data.data.results })
            }
            setTimeout(() => {
                document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
            }, 500);
        } catch (error) {
            this.props.hideLoader()
            console.warn("error: ", error)
        }
        // }
    }
    calculateAge = (dateString) => {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    onSubmit = (event) => {
        event.preventDefault();
        this.props.showLoader()
        this.getWaitingList()
    }

    handleApprove = async id => {
        this.props.showLoader()
        const { config } = this.state;
        // const authResponse = await this.auth();
        // if (authResponse) {
        let obj = {
            "nid": id
        }
        const url = `${config.api_url}${urlEndPoints.studentApprove}`
        // const headers = {
        //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
        // }
        try {
            const data = await this.networkRequest(url, "POST", obj)
            if (data.data.error) {
                this.props.hideLoader()
                this.props.showPopup({
                    type: "error",
                    title: this.context.t("_failed"),
                    message: this.context.t("_student_activation_failed"),
                    isOpen: true,
                    buttonText: this.context.t("_ok")
                })
            }
            else {
                this.getWaitingList()
            }
        } catch (error) {
            this.props.hideLoader()
            console.warn("error: ", error)
        }
        // } else {
        //     this.props.hideLoader()
        // }
    }
    clearSearch = async () => {
        this.props.showLoader()
        const { formData } = this.state;
        formData.name.value = ""
        await this.setState({ formData })
        this.getWaitingList()
    }
    render() {
        const { formData, studentList } = this.state;
        const { lang } = this.props;
        return (

            <div id="main-container">
                <SlideToTop />
                <PageTitle title={this.context.t("_waiting_list")} />
                <div id="skipContent" className="container">
                    <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                        <ul>
                            <li className="breadcrumb-item">
                                <Link to="/profile">{this.context.t("_profile")}</Link> {" "}

                            </li>
                            <li className="breadcrumb-item">
                                <Link tabIndex="0" to aria-current={this.context.t("_page")}>
                                    <span>
                                        {this.context.t("_waiting_list")}
                                    </span>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div>
                    <div className="container">
                        <h2>{this.context.t("_students")}</h2>
                        {
                            _.isEmpty(formData) ?
                                <div /> :
                                <div className="row topFormHolder">
                                    <form onSubmit={this.onSubmit} className="row full-width">
                                        <div className="col s12 m8 l9">
                                            <div className="input-field item">
                                                <label className="active" style={dynamicStyles.position(lang)}>{this.context.t("_search")} <span className="asterisk">*</span></label>
                                                <input
                                                    onChange={evt =>
                                                        this.handleChange("name", evt.target.value)
                                                    }
                                                    onFocus={evt =>
                                                        this.handleChange("name", evt.target.value)
                                                    }
                                                    ref={(input) => { this.parentName = input; }}
                                                    value={formData.name.value}
                                                    placeholder={this.context.t("_search")}
                                                    type="text"
                                                />
                                                <span className="helper-text"></span>
                                            </div>
                                        </div>

                                        <div className="col s12 m4 l3 valign-wrapper center-align search-btn-holder">
                                            <button className="btn profileBtn yellow waves-effect waves-light">
                                                {this.context.t("_search")} <i className={`tiny material-icons ${lang}`} >{`${lang == "en" ? "arrow_forward" : "arrow_backward"}`}</i>
                                            </button>
                                            <button type="button" onClick={() => this.clearSearch()} className="btn noWidth  blue-btn waves-effect waves-light">
                                                {this.context.t("_clear")}
                                            </button>
                                            <br /><br />
                                        </div>
                                    </form>
                                </div>
                        }

                        <div className="tableHolder">
                            {
                                _.isEmpty(studentList) ?
                                    <div className="row"><p aria-label={this.context.t("_no_result_found")} className="col s12 no-result">{this.context.t("_no_result_found")}</p></div>
                                    :
                                    <table className="responsive-table highlight">
                                        <thead className="blue lighten-5">
                                            <tr>
                                                <th><strong>{this.context.t("_name")}</strong></th>
                                                <th><strong>{this.context.t("_qid")}</strong></th>
                                                <th><strong>{this.context.t("_phone_number")}</strong></th>
                                                <th><strong>{this.context.t("_age")}</strong></th>
                                                <th><strong>{this.context.t("_status")}</strong></th>
                                                <th><strong>{this.context.t("_parents")}</strong></th>
                                                <th width="140"><strong></strong></th>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            {
                                                studentList.map((item, index) => {
                                                    return (
                                                        <tr key={item.nid}>
                                                            <td>{item.field_student_name}</td>
                                                            <td>{item.field_qid}</td>
                                                            <td>{item.field_student_mobile}</td>
                                                            <td>{this.calculateAge(item.field_student_dob)}</td>
                                                            <td>{item.status}</td>
                                                            <td>{item.field_parent_name}</td>
                                                            <td><button onClick={() => { this.handleApprove(item.nid) }} className="btn profileBtn">{this.context.t("_approve")}</button></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                            }
                        </div>
                    </div>
                </div>
                <div className="top-line ng-scope">
                    <img src={topLineImage} alt="" />
                </div>
            </div>
        )
    }

}

const mapStateToProps = ({ i18nState }) => {
    return {
        lang: i18nState.lang
    }
}
const mapDispatchToProps = {
    showPopup: (data) => PopupAction.showPopup(data),
    hidePopup: (data) => PopupAction.hidePopup(data),
    showLoader: LoadingAction.showLoader,
    hideLoader: LoadingAction.hideLoader,
}
WaitingList.contextTypes = {
    t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(WaitingList);
