import Main from "../../components/main"
import _ from "lodash"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { GET_STUDENTS_ENROLLED, GET_STUDENTS_WAITING_LIST, GET_STUDENTS_ATTENDED } from "./../../constants/constants"

const MainConst = new Main();
const configConst = MainConst.config();
const getStudentsEnrolled = ({ startDate = "", endDate = "" }) => {
    return async dispatch => {
        let url = `${configConst.origin}${urlEndPoints.getDashboardStudentEnrolled}?`
        if (!_.isEmpty(startDate))
            url = `${url}&field_start_and_end_time_value=${startDate} 00:00:00`
        if (!_.isEmpty(endDate))
            url = `${url}&field_start_and_end_time_end_value_1=${endDate} 00:00:00`
        MainConst.networkRequest(url).then(data => {
            if (data.data.error) {
                dispatch({
                    type: GET_STUDENTS_ENROLLED,
                    payload: 0,
                })
            } else {
                dispatch({
                    type: GET_STUDENTS_ENROLLED,
                    payload: data.data.length
                })
            }
        })
    }
}
const getStudentsWaitingList = ({ startDate = "", endDate = "" }) => {
    return async dispatch => {
        let url = `${configConst.origin}${urlEndPoints.getDashboardStudentWaitingList}?`
        if (!_.isEmpty(startDate))
            url = `${url}&field_start_and_end_time_value=${startDate} 00:00:00`
        if (!_.isEmpty(endDate))
            url = `${url}&field_start_and_end_time_end_value_1=${endDate} 00:00:00`
        MainConst.networkRequest(url).then(data => {
            if (data.data.error) {
                dispatch({
                    type: GET_STUDENTS_WAITING_LIST,
                    payload: 0,
                })
            } else {
                dispatch({
                    type: GET_STUDENTS_WAITING_LIST,
                    payload: data.data.length
                })
            }
        })
    }
}
const getStudentsAttended = ({ startDate = "", endDate = "" }) => {
    return async dispatch => {
        let url = `${configConst.origin}${urlEndPoints.getDashboardStudentAttended}?`
        if (!_.isEmpty(startDate))
            url = `${url}&field_start_and_end_time_value=${startDate} 00:00:00`
        if (!_.isEmpty(endDate))
            url = `${url}&field_start_and_end_time_end_value_1=${endDate} 00:00:00`
        MainConst.networkRequest(url).then(data => {
            if (data.data.error) {
                dispatch({
                    type: GET_STUDENTS_ATTENDED,
                    payload: 0,
                })
            } else {
                dispatch({
                    type: GET_STUDENTS_ATTENDED,
                    payload: data.data.length
                })
            }
        })
    }
}

export default {
    getStudentsEnrolled,
    getStudentsWaitingList,
    getStudentsAttended
}