import React from "react"
import Main from "../main"
import { Link } from "react-router-dom"
import Banner from "./banner"
import About from "./about"
import Zone from "./zone"
import News from "./news"
import Activity from "./activity"
import Makers from "./makers"
import PropTypes from "prop-types"
import { connect } from "react-redux";

// const axios = require("axios");
class Home extends Main {
	constructor(props) {
		super(props);
		this.checkLogin();
		this.state = {
			isLoaded: false,
			config: this.config(),
			error: "",
		}
	}
	componentDidMount(){
		document.title = `${this.context.t("_studio5")} | ${this.context.t("_home")}`
	}
	componentDidUpdate(){
		document.title = `${this.context.t("_studio5")} | ${this.context.t("_home")}`
	}
	// images/mail.svg
	render() {
		const topLineImage = require("../../assets/images/top-line.png");
		const mail_icon = require("../../assets/images/mail.svg");
		const top_images = [
			require("../../assets/images/top-1.png"),
			require("../../assets/images/top-2.png"),
			require("../../assets/images/top-3.png"),
			require("../../assets/images/top-4.png"),
		];
		const { lang } = this.props;
		const { error, isLoaded } = this.state;
		return (
			<div id="main-content">
				<Banner />
				{/* <div className="top-line">
					<img src={topLineImage} alt="" />
				</div> */}
				<Makers />
				<About />
				<div className="top-images ng-scope">
					{top_images.map((row, index) => (
						<div className="item" key={index} >
							<img src={row} alt="" />
						</div>
					))}
				</div>
				<Zone />
				<Activity />
				<News />
				<div id="register-wrap" className={`ng-scope ${lang}`}>
					<div className={`container home-subscribe ${lang}`}>
						<span className="multi-square-orange"><b><i></i></b></span>
						<div><img src={mail_icon} className="mail-icon" alt="" /></div>
						<p>{this.context.t("_latest_insight")}</p>
						<p><Link to="/subscribe" className="red-btn btn btn-signup" aria-label="subscribe button for newsletter">{this.context.t("_subscribe")} <i className={`material-icons ${lang}`}>{`${lang == "en" ? "" : ""}`}</i></Link></p>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = ({ i18nState }) => {
	return {
		lang: i18nState.lang
	}
}
Home.contextTypes = {
	t: PropTypes.func
}
export default connect(mapStateToProps)(Home);
