import React from "react"
import axios from "axios";
import querystring from "querystring"
import { env, envConfig, client_secret, client_id } from "./../components/main"
import { urlEndPoints } from "./apiConfig"
import { Redirect } from "react-router-dom";

let apiRequestID = 1
async function getCsrf() {
    const xcsrfURL = envConfig[env].url + urlEndPoints.xcsrf
    const xcsrfToken = await axios(xcsrfURL)
    if (xcsrfToken.status == 200) {
        return xcsrfToken.data
    }
    else
        return null
}
async function networkRequest(url, method = "GET", param = {}, header = {}, isRegistration = false, apiID = apiRequestID++) {
    const requestBodyParam = param

    const headerParam = {
        // ...header,
        // "X-CSRF-Token": xcsrfToken,
        Authorization: `${localStorage.getItem("tokenType")} ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
    }
    if (isRegistration) {
        const xcsrfToken = await getCsrf();
        headerParam["X-CSRF-Token"] = xcsrfToken;
    }
    const promise = new Promise(async function (resolve, reject) {
        try {
            const response = await axios({
                method: method.toUpperCase(),
                url,
                headers: headerParam,
                data: requestBodyParam
            });
            if (response && response.status === 200) {
                resolve(response)
            } else {
                if (response && response.message) {
                    reject(response)
                } else if (response) {
                    reject(response)
                }
            }
        } catch (error) {
            console.warn(`APIRequestID : ${apiID} CONNECTION FAILED`)
            console.warn(`error : ${error})
        } `)
            reject(error)
        }
    });

    return promise
}
axios.interceptors.response.use(
    response => {
        const promise = new Promise((resolve, reject) => {
            if (response.data && response.data.logged_out) {
                resolve(refreshApiCall(response))
            }
            resolve(response);
        });
        return promise;
    },
    async (error) => {
        const apiError = error;
        if (apiError.response && (apiError.response.status === 401 || apiError.response.status === 403) && apiError.config && !(apiError.config.url.includes(urlEndPoints.auth))) {
            return refreshApiCall(apiError)
        }
    })
const refreshApiCall = async (apiError) => {
    try {
        // refresh access token
        const refreshToken = await localStorage.getItem("refreshToken");
        const obj = {
            "client_secret": client_secret,
            "grant_type": "refresh_token",
            "client_id": client_id,
            "refresh_token": refreshToken
        }

        const authUrl = `${envConfig[env].url}${urlEndPoints.auth}`
        const response = await fetch(authUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: querystring.stringify(obj)
        })
        if (response.status == 200) {
            const authResponse = await response.json();
            localStorage.setItem("accessToken", authResponse.access_token);
            localStorage.setItem("refreshToken", authResponse.refresh_token);
            localStorage.setItem("tokenType", authResponse.token_type);

            apiError.config.headers["Authorization"] = `${authResponse.token_type} ${authResponse.access_token}`
            apiError.config.baseURL = undefined;
            const newResponse = axios.request(apiError.config);
            return newResponse;

        } else {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("tokenType");
            localStorage.removeItem("role");
            window.location = `/${localStorage.getItem("lang")}/login`
        }
    } catch (error) {
        console.warn("ERROR : ", error)
        return error.response
    }
}
async function authRequest(url, method = "GET", param = {}, header = {}, apiID = apiRequestID++, self) {
    const requestBodyParam = querystring.stringify(param)
    const headerParam = {
        "Content-Type": "application/x-www-form-urlencoded"
    }
    const promise = new Promise(async function (resolve, reject) {
        try {
            const response = await axios({
                method: method.toUpperCase(),
                url,
                headers: headerParam,
                data: requestBodyParam
            });
            if (response.status === 200) {
                resolve(response)
            } else {

                if (response && response.message) {
                    reject(response)
                } else if (response) {
                    reject(response)
                }
            }

        } catch (error) {
            console.warn(`APIRequestID : ${apiID} CONNECTION FAILED`)
            console.warn(`error : ${error})
        } `)
            reject(error)
            console.warn(self)
            if (self) {
                // localStorage.removeItem("accessToken");
                localStorage.removeItem("refreshToken");
                localStorage.removeItem("tokenType");
                localStorage.removeItem("role");
                self.props.history.push("/login");
            }
        }
    });

    return promise
}

export { networkRequest, authRequest };
