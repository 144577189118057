import React, { Component } from "react"
import Unauthorized from "./../components/unauthorized"

const Authorization = (allowedRoles) =>
    (WrappedComponent) => {
        return class WithAuthorization extends Component {
            constructor(props) {
                super(props)
            }
            render() {
                const role = localStorage.getItem("role")
                if (allowedRoles.includes(role))
                    return <WrappedComponent {...this.props} />
                else
                    return <Unauthorized />

            }
        }
    }

export default Authorization;