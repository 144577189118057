import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash"
import Main from "../../../main";
import { connect } from "react-redux"
import PropTypes from "prop-types"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { dynamicStyles, customModalStyles } from "./../../../../constants/dynamicStyles"
import { urlEndPoints } from "./../../../../httpAPI/apiConfig"
import {
    qidValidate, nameValidate, emailValidate, phoneValidate, nameWordLengthCheck, calenderI18n, CustomDateInput
    , calenderLocal
} from "./../../../../constants/common"
import M from "materialize-css";
import { PopupAction, LoadingAction } from "./../../../../actions"
import Modal from "react-modal";
import dateformat from "dateformat"
import topLineImage from "../../../../assets/images/top-line.png"

class ParentConnections extends Main {
    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            studentFormData: {},
            studentList: [],
            config: this.config(),
            intervalId: 0,
            scrollStepInPx: 50,
            delayInMs: 16.66,
            removeStudentConfirm: false,
            schoolList: []
        };
        this.props.showLoader();
    }
    async componentDidMount() {
        document.title = `${this.context.t("_studio5")} | ${this.context.t("_manage_connections")}`
        await this.initState();
        this.getChildren();
        this.getSchoolList();
        M.AutoInit();
        var options = {
            autoClose: true,
            dismissible: true
        };
        const dobOptions = {
            autoClose: true,
            onSelect: ((date) => {
                console.warn(date)
                this.handleChangeAddForm("dob", dateformat(date, "dd/mm/yyyy"))
            }),
            defaultDate: new Date("01/01/2001"),
            setDefaultDate: new Date("01/01/2001"),
            format: "dd/mm/yyyy",
            container: null,
            maxDate: new Date("12/31/2014"),
            minDate: new Date("01/01/2001"),
            yearRange: 20,
            minYear: 2001,
            maxYear: 2014
        };
        setTimeout(() => {
            var elems = document.querySelectorAll("#dob");
            dobOptions.i18n = calenderI18n(this.context);
            // M.Datepicker.init(elems, dobOptions);
            var elems = document.querySelectorAll(".modal");
            M.Modal.init(elems, options);
            const selectelem = document.querySelectorAll("select");
            M.FormSelect.init(selectelem, options);

        }, 200);
        // setTimeout(() => {
        //     this.refs.participantsHeading.focus()
        // }, 1000);
        window.addEventListener('load', this.handleTabIndex());
    }
    componentDidUpdate() {
        document.title = `${this.context.t("_studio5")} | ${this.context.t("_manage_connections")}`
    }
    handleTabIndex = () => {
        setTimeout(() => {
            let elem = document.querySelectorAll('select');
            elem.forEach(element => {
                element.setAttribute("tabindex", "0");
            });
        }, 1000);
    }
    getSchoolList = async () => {
        const { config } = this.state
        const { lang } = this.props;
        const url = `${config.api_url}${urlEndPoints.schoolList(lang)}`
        try {
            const data = await this.networkRequest(url)
            this.setState({ schoolList: data.data })
            // this.schoolName.focus()
            this.initSelect()
        } catch (error) {
            this.props.hideLoader()
            console.warn("error: ", error)
        }
    }
    initSelect = () => {
        const { formData } = this.state;
        if (formData.schoolName) {
            var options = {
                autoClose: true,
                classes: this.state.formData.schoolName.class

            };
            // var elems = document.querySelectorAll("select");
            // M.FormSelect.init(elems, options);
        }

    }
    initState = () => {
        this.setState({
            formData: {
                name: { value: "", error: "", class: "" },
                grade: { value: "", error: "", class: "" },
                gender: { value: "", error: "", class: "" }
            },
            studentFormData: {
                qid: { value: "", error: "", class: "" },
                schoolName: { value: "", error: "", class: "" },
                schoolNewName: { value: "", error: "", class: "" },
                name: { value: "", error: "", class: "" },
                email: { value: "", error: "", class: "" },
                mobile: { value: "", error: "", class: "" },
                gender: { value: "Male", error: "", class: "" },
                relation: { value: "", error: "", class: "" },
                dob: { value: "", error: "", class: "" },
            }
        })
    }
    handleChange = (field, value) => {
        const { formData } = this.state;
        formData[field]["class"] = value ? "" : "invalid";
        formData[field]["value"] = value;
        this.setState({ formData });
    };
    handleChangeAddForm = (field, value) => {
        if (field == "qid" || field == "mobile")
            if (isNaN(value))
                return false
        const { studentFormData } = this.state;
        studentFormData[field]["class"] = value ? "" : "invalid";
        studentFormData[field]["value"] = value;
        this.setState({ studentFormData });
    };

    getChildren = async () => {
        // const authResponse = await this.auth();
        const { formData } = this.state;
        // if (authResponse) {
        const { config } = this.state
        let url = `${config.api_url}${urlEndPoints.myChildren}`

        if (!_.isEmpty(formData.name.value))
            url = `${url}&combine=${formData.name.value}`

        // const headers = {
        //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
        // }
        try {
            const data = await this.networkRequest(url)
            this.props.hideLoader();
            if (!data.data.message) {
                this.setState({ studentList: data.data.results })
                await this.handleGetParent();
            }
        } catch (error) {
            this.props.hideLoader()
            console.warn("error: ", error)
        }
        // }
    }
    handleGetParent = async id => {
        const { config, studentList } = this.state
        studentList.map(async (item, index) => {
            let url = `${config.api_url}${urlEndPoints.getParentName(item.uid)}`
            try {
                const data = await this.networkRequest(url)
                if (_.has(data, "data.results") && data.data.results.length > 0)
                    studentList[index].parents = data.data.results
                else
                    studentList[index].parents = []
                this.setState({ studentList })
                this.refs.participantsHeading.focus()
            } catch (error) {
                this.props.hideLoader()
                console.warn("error: ", error)
            }
        });
    }
    calculateAge = (dateString) => {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
    handleCloseModal = () => {
        this.initState();
        let elems = document.querySelector("#checkQid");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        let instance = M.Modal.getInstance(elems);
        instance.close();
        elems = document.querySelector("#addStudent");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        instance = M.Modal.getInstance(elems);
        instance.close();
        this.checkQidForm.reset();
        this.studentAddForm.reset();
        document.getElementById("addChildBtn") && document.getElementById("addChildBtn").focus()
    }
    validate = (type) => {
        const { studentFormData } = this.state;
        for (var key in studentFormData) {
            studentFormData[key].value = key == "dob" ? studentFormData[key].value : studentFormData[key].value.trim();
            studentFormData[key].error = "";
        }
        if (type == "qid") {
            if (studentFormData.qid.value == "")
                studentFormData.qid.error = this.context.t("_qid_required");

            if (studentFormData.qid.value != "" && !qidValidate.test(studentFormData.qid.value))
                studentFormData.qid.error = this.context.t("_invalid_qid");

            if (studentFormData.relation.value == "")
                studentFormData.relation.error = this.context.t("_relationship_required");
        } else {
            if (studentFormData.name.value == "")
                studentFormData.name.error = this.context.t("_student_name_required");

            if (studentFormData.name.value != "" && !nameValidate.test(studentFormData.name.value))
                studentFormData.name.error = this.context.t("_invalid_student_name");

            if (studentFormData.name.value != "" && nameValidate.test(studentFormData.name.value) && !nameWordLengthCheck(studentFormData.name.value))
                studentFormData.name.error = this.context.t("_student_name_contains_atleast_two_word");

            if (studentFormData.dob.value == "")
                studentFormData.dob.error = this.context.t("_date_of_birth_required");

            if (studentFormData.email.value != "" && !emailValidate.test(studentFormData.email.value))
                studentFormData.email.error = this.context.t("_invalid_email");

            if (studentFormData.mobile.value == "")
                studentFormData.mobile.error = this.context.t("_mobile_number_required");

            if (studentFormData.mobile.value != "" && !phoneValidate.test(studentFormData.mobile.value.replace(/ /g, "")))
                studentFormData.mobile.error = this.context.t("_invalid_mobile_number");

            if (studentFormData.mobile.value != "")
                if (studentFormData.mobile.value.toString().startsWith("3") || studentFormData.mobile.value.toString().startsWith("5") || studentFormData.mobile.value.toString().startsWith("6") || studentFormData.mobile.value.toString().startsWith("7")) {

                } else {
                    studentFormData.mobile.error = this.context.t("_invalid_mobile_number");
                }

            if (studentFormData.relation.value == "")
                studentFormData.relation.error = this.context.t("_relationship_required");

            if (studentFormData.schoolName.value == "")
                studentFormData.schoolName.error = this.context.t("_school_name_required");

            if (studentFormData.schoolName.value == "0" && studentFormData.schoolNewName.value == "")
                studentFormData.schoolNewName.error = this.context.t("_school_name_required");
        }

        for (var key in studentFormData) {
            if (studentFormData[key].error != "") {
                this.setState({ studentFormData, submissionStatus: false })
                // this.scrollToTop();
                setTimeout(() => {
                    // this.refs.errorListRef.focus()
                    this[key + "error"].focus()
                }, 1000);
                return false;

            }
        }
        return true;
    }

    displayError = error => {
        const { studentFormData } = this.state;
        const { lang } = this.props;
        if (error.qid)
            studentFormData.qid.error = error.qid[lang]

        if (error.name)
            studentFormData.name.error = error.name[lang]

        if (error.mail)
            studentFormData.email.error = error.mail[lang]

        if (error.DOB)
            studentFormData.dob.error = error.DOB[lang]

        for (var key in studentFormData) {
            if (studentFormData[key].error != "") {
                this.setState({ studentFormData })
                // this.scrollToTop();
                setTimeout(() => {
                    // this.refs.errorListRef.focus()
                    this[key + "error"].focus()
                }, 1000);
                return false;

            }
        }

    }
    handleOpenAddStudentModal = () => {
        const { studentFormData } = this.state;
        for (var key in studentFormData) {
            studentFormData[key].error = "";
        }
        this.setState({ studentFormData })
        let elems = document.querySelector("#checkQid");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        let instance = M.Modal.getInstance(elems);
        instance.close();
        elems = document.querySelector("#addStudent");
        elems.setAttribute("aria-hidden", "false")
        elems.setAttribute("tabindex", "-1")
        instance = M.Modal.getInstance(elems);
        instance.open();
        elems = document.querySelectorAll("select");
        M.FormSelect.init(elems)
    }
    handleOpenQidCheck = () => {
        const { studentFormData } = this.state;
        for (var key in studentFormData) {
            studentFormData[key].error = "";
        }
        this.setState({ studentFormData })
        let options = {
            inDuration: 1000,
            opacity: 1
        }
        let elems = document.querySelector("#addStudent");
        elems.setAttribute("aria-hidden", "false")
        elems.setAttribute("tabindex", "-1")
        let instance = M.Modal.getInstance(elems, options);
        instance.open();
    }
    handleCheckQid = async (event) => {
        // event.preventDefault();
        const { studentFormData, config } = this.state;

        if (this.validate("qid")) {
            this.props.showLoader()
            // const authResponse = await this.auth();
            // if (authResponse) {
            const obj = {
                field_qid: studentFormData.qid.value,
                field_relation_to_the_student: studentFormData.relation.value,
            }
            // const headers = {
            //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
            // }
            try {
                let url = `${config.api_url}${urlEndPoints.getParentCount(studentFormData.qid.value)}`
                let data = await this.networkRequest(url)
                if (_.has(data, "data.results") && data.data.results.length < 2) {
                    url = `${config.api_url}${urlEndPoints.checkQid}`
                    data = await this.networkRequest(url, "POST", obj)
                    if (data.status == 200) {
                        if (data.data.error) {
                            this.props.hideLoader()
                            this.displayError(data.data.error)
                        }
                        else {
                            if (!data.data.user) {
                                return true;
                                // this.props.hideLoader()
                                // this.handleOpenAddStudentModal();
                            }
                            else {
                                this.props.showPopup({
                                    type: "error",
                                    // title: this.context.t("_warning"),
                                    message: this.context.t("_student_already_exist_with_this_qid"),
                                    isOpen: true,
                                    buttonText: this.context.t("_ok")
                                })
                                this.handleCloseModal()
                                this.getChildren();
                                return false;
                                // this.getChildren();
                                // this.handleCloseModal()
                            }
                        }
                    }
                } else {
                    this.props.hideLoader()
                    this.handleCloseModal()
                    this.props.showPopup({
                        type: "error",
                        title: this.context.t("_failed"),
                        message: this.context.t("_parent_limit_exceeds"),
                        isOpen: true,
                        buttonText: this.context.t("_ok")
                    })
                }
            } catch (error) {
                this.props.hideLoader()
                console.warn("error: ", error)
            }
            // }
        }
    }
    onSubmit = (event) => {
        event.preventDefault();
        this.props.showLoader()
        this.getChildren()
    }
    handleAddNewStudent = async (event) => {
        event.preventDefault();
        const { studentFormData, config, schoolList } = this.state;
        if (this.validate()) {
            this.props.showLoader()
            const qidStatus = await this.handleCheckQid();
            if (qidStatus) {


                let schoolName = schoolList.filter(item => item.id == studentFormData.schoolName.value)
                if (studentFormData.schoolName.value == "0")
                    schoolName = studentFormData.schoolNewName.value
                else
                    schoolName = _.isEmpty(schoolName) ? null : schoolName[0].sname
                // const authResponse = await this.auth();
                // if (authResponse) {
                let obj = {
                    // "mail": studentFormData.email.value || null,
                    "name": studentFormData.email.value != "" ? studentFormData.email.value : studentFormData.name.value,
                    "school_id": {
                        "target_id": studentFormData.schoolName.value,
                        "target_type": "taxonomy_term"
                    },
                    "school_name": schoolName,
                    "field_qid": studentFormData.qid.value,
                    "field_student_mobile": `+974${studentFormData.mobile.value.replace(/ /g, "")}`,
                    "roles": {
                        "target_id": "student"
                    },
                    "field_relation_to_the_student": studentFormData.relation.value,
                    "field_gender": studentFormData.gender.value,
                    "field_student_name": studentFormData.name.value,
                    "field_student_dob": dateformat(studentFormData.dob.value, "dd/mm/yyyy"),
                    "field_reference": {
                        "target_id": ""
                    }
                }
                if (studentFormData.email.value != "") {
                    obj.mail = studentFormData.email.value
                    obj.field_student_email = studentFormData.email.value
                }
                const url = `${config.api_url}${urlEndPoints.parentAddStudent}`
                // const headers = {
                //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
                // }
                try {
                    const data = await this.networkRequest(url, "POST", obj)
                    if (data.data.error) {
                        this.displayError(data.data.error)
                        this.props.hideLoader()
                    }
                    else {
                        this.setState({ submissionStatus: true })
                        this.handleCloseModal()
                        this.getChildren();
                    }
                } catch (error) {
                    this.props.hideLoader()
                    console.warn("error: ", error)
                }
            }
            // }
        }

    }
    renderInput = ({ name, label, placeholder, type = "text", isMandatory = false, max }) => {
        const { studentFormData } = this.state;
        const { lang } = this.props;
        return (type == "phone_number" ?
            <div className="row mobile">
                <div className="col s4">
                    <div className="input-field item">
                        <input name={`${name}_country_code`}
                            placeholder="Country Code"
                            value={this.context.t("_ar_country_code")}
                            style={dynamicStyles.textAlign(lang)}
                            id={`${name}_country_code`}
                            type="text"
                            minLength="3"
                            maxLength="3"
                            disabled
                        // className={"validate " + registration_form.mobile.class}
                        // required
                        />
                        <label htmlFor={`${name}_country_code`} style={dynamicStyles.position(lang)}>{this.context.t("_code")}</label>
                        <span className="helper-text" data-error=""></span>
                    </div>
                </div>
                <div className="col s8">
                    <div className="input-field item">
                        <input
                            name={name}
                            placeholder={placeholder}
                            ref={(input) => { this[name] = input; }}
                            id={name}
                            aria-label={this.context.t("_phone_aria_label")}
                            maxLength={max}
                            type="text"
                            style={dynamicStyles.textAlign(lang)}
                            // readOnly={type === "date" ? "readonly" : false}
                            autoComplete="no"
                            onChange={evt =>
                                this.handleChangeAddForm(name, evt.target.value)
                            }
                            onFocus={evt =>
                                this.handleChangeAddForm(name, evt.target.value)
                            }
                            value={studentFormData[name].value}
                        // className={"validate " + formData[name].class}
                        // required={isMandatory}
                        />
                        <label htmlFor={name} style={dynamicStyles.position(lang)}>
                            {`${label} `}
                            {isMandatory ? <span className="asterisk">*</span> : null}
                        </label>
                        <span
                            style={dynamicStyles.textAlign(lang)}
                            className="helper-text"
                            data-error={studentFormData[name].error ? studentFormData[name].error : "Required field."}
                        ></span>
                    </div>
                </div>
            </div>
            : type == "date" ? <div className="input-field item">
                <DatePicker
                    name={name}
                    placeholderText={placeholder}
                    ref={(input) => { this[name] = input; }}
                    id={name}
                    autoComplete="no"
                    // className={styleClass}
                    maxDate={new Date("12/31/2014")}
                    minDate={new Date("01/01/2001")}
                    dateFormat="yyyy-MM-dd"
                    onChange={date =>
                        this.handleChangeAddForm(name, date)
                    }
                    style={dynamicStyles.textAlign(lang)}
                    value={studentFormData[name].value}
                    selected={studentFormData[name].value}
                    locale={calenderLocal(this.context)}
                    customInput={<CustomDateInput ariaLabel={this.context.t("_select_dob_button")} onFieldChange={date => this.handleChangeAddForm(name, new Date(date))} />}
                    onCalendarOpen={e => {
                        let elems = document.querySelector(".react-datepicker");
                        elems.setAttribute("tabindex", "0")
                        elems.focus()
                        elems = document.querySelector(".react-datepicker__month-select");
                        elems.setAttribute("aria-label", this.context.t("_select_month"))
                        elems = document.querySelector(".react-datepicker__year-select");
                        elems.setAttribute("aria-label", this.context.t("_select_year"))

                    }}
                    onCalendarClose={e => {
                        var elems = document.querySelector(".hiddenDob");
                        elems.setAttribute("aria-label", `${this.context.t("_selected_date")} ${dateformat(studentFormData[name].value, "dd-mm-yyyy")}`)
                        elems.setAttribute("value", dateformat(studentFormData[name].value, "yyyy-mm-dd"))
                        setTimeout(() => {
                            elems.focus()
                        }, 200);
                    }}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
                <label htmlFor={name} style={dynamicStyles.position(lang)}>
                    {`${label} `}
                    {isMandatory ? <span className="asterisk">*</span> : null}
                </label>
                <span
                    style={dynamicStyles.textAlign(lang)}
                    className="helper-text"
                    data-error={studentFormData[name].error ? studentFormData[name].error : "Required field."}
                ></span>
            </div> :
                <div className="input-field item">
                    <input
                        name={name}
                        placeholder={placeholder}
                        ref={(input) => { this[name] = input; }}
                        id={name}
                        maxLength={max}
                        type={type === "date" ? "text" : type}
                        style={dynamicStyles.textAlign(lang)}
                        // readOnly={type === "date" ? "readonly" : false}
                        autoComplete="no"
                        onChange={evt =>
                            this.handleChangeAddForm(name, evt.target.value)
                        }
                        onFocus={evt =>
                            this.handleChangeAddForm(name, evt.target.value)
                        }
                        value={studentFormData[name].value}
                    // className={"validate " + formData[name].class}
                    // required={isMandatory}
                    />
                    <label htmlFor={name} style={dynamicStyles.position(lang)}>
                        {`${label} `}
                        {isMandatory ? <span className="asterisk">*</span> : null}
                    </label>
                    <span
                        style={dynamicStyles.textAlign(lang)}
                        className="helper-text"
                        data-error={studentFormData[name].error ? studentFormData[name].error : "Required field."}
                    ></span>
                </div>)
    }

    renderSelect = ({ name, label, placeholder, isMandatory = false, data = [], type = "select", disabled = false }) => {
        const { studentFormData } = this.state;
        const { lang } = this.props;
        return (
            <div className="input-field mobileSelect">
                <label htmlFor={name} style={dynamicStyles.position(lang)}>
                    {`${label} `}
                    {isMandatory ? <span className="asterisk">*</span> : null}
                </label>
                <select
                    disabled={disabled}
                    className="browser-default"
                    name={name}
                    id={name}
                    style={dynamicStyles.textAlign(lang)}
                    ref={(input) => { this[name] = input; }}
                    onChange={evt =>
                        this.handleChangeAddForm(name, evt.target.value)
                    }

                    onFocus={evt =>
                        this.handleChangeAddForm(name, evt.target.value)
                    }
                    value={studentFormData[name].value}
                // className={"validate" + studentFormData[name].class}
                >
                    <option value="" disabled> {placeholder} </option>
                    {
                        data.map((item, index) => {
                            return type == "country" ?
                                <option key={index} value={item.country_id}>{item.country[lang]}</option>
                                :
                                type == "school" ? <option key={index} value={item.id}>{item.sname}</option> :
                                    <option key={index} value={item.key}>{item.label}</option>
                        })
                    }
                    {
                        type == "school" && <option value="0">{this.context.t("_not_in_list")}</option>
                    }
                </select>
                <span
                    style={dynamicStyles.textAlign(lang)}
                    className="helper-text"
                    data-error={studentFormData[name].error ? studentFormData[name].error : "Required field."}
                ></span>
            </div>
        )
    }
    renderRadioButton = ({ data = [], name, label, defaultChecked, isMandatory = false }) => {
        const { studentFormData } = this.state;
        const { lang } = this.props;
        return (
            <div className="input-field item" style={{ width: 220 }}>
                <div className="input-radio valign-wrapper" role="radiogroup" aria-labelledby={name}>
                    {
                        data.map((item, index) => {
                            return (
                                <div key={index} className={item.value}>
                                    <input
                                        aria-checked={studentFormData[name].value === item.value}
                                        name={name}
                                        id={item.value}
                                        aria-labelledby={`${name} ${item.label}`}
                                        type="radio"
                                        readOnly
                                        className="radio-btn"
                                        onClick={evt =>
                                            this.handleChangeAddForm(name, item.value)
                                        }
                                        checked={studentFormData[name].value === item.value}
                                    />
                                    <label id={`_${item.label}`} htmlFor={item.value} className="custom-radio-style">
                                        <span className="custom-radio-style">{item.label}</span>
                                    </label>
                                </div>
                            )
                        })
                    }

                </div>
                <label htmlFor={name} id={name} style={dynamicStyles.position(lang)}>
                    {`${label} `}
                    {isMandatory ? <span className="asterisk">*</span> : null}
                </label>
                <span
                    className="helper-text"
                    data-error="Required field."
                ></span>
            </div>
        )
    }
    handleRemove = async id => {
        this.props.showLoader()
        const { config } = this.state;
        // const authResponse = await this.auth();
        // if (authResponse) {
        let obj = {
            "linked_user": id
        }
        const url = `${config.api_url}${urlEndPoints.removeLinking}`
        // const headers = {
        //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
        // }
        try {
            const data = await this.networkRequest(url, "POST", obj)
            if (data.data.error) {
                this.props.hideLoader()
                this.props.showPopup({
                    type: "error",
                    title: this.context.t("_failed"),
                    message: this.context.t("_student_unlink_failed"),
                    isOpen: true,
                    buttonText: this.context.t("_ok")
                })
            }
            else {
                this.getChildren()
            }
        } catch (error) {
            this.props.hideLoader()
            console.warn("error: ", error)
        }
        // }
    }
    clearSearch = async () => {
        this.props.showLoader()
        const { formData } = this.state;
        formData.name.value = ""
        await this.setState({ formData })
        this.getChildren()
    }
    handleViewProfile = id => {
        this.props.history.push(`/student-profile/${id}`);
    }
    handleCloseConfirmModal = (state) => {
        this.setState({ [state]: false, removeStudentId: null })
    }
    handleOpenRemoveStudentConfirm = id => {
        this.setState({ removeStudentConfirm: true, removeStudentId: id })
    }
    handleConfirmRemoveSchool = () => {
        const { removeStudentId } = this.state;
        if (!_.isEmpty(removeStudentId))
            this.handleRemove(removeStudentId);
        this.handleCloseConfirmModal("removeStudentConfirm")
    }
    render() {
        const { formData, studentList, removeStudentConfirm, studentFormData, schoolList } = this.state;
        const { lang } = this.props;
        let hasError = false;
        Object.keys(studentFormData).map((item, index) => {
            if (studentFormData[item].error != "")
                hasError = true
        })
        return (
            <>
                <div className="attendance-holder">
                    <div className="attendance-heading">
                        <h2 id="participants-title" tabIndex="0" ref="participantsHeading" className="line">{this.context.t("_participants")}</h2>
                    </div>

                    <div className="tableHolder">
                        {
                            _.isEmpty(studentList) ?
                                <div className="row">
                                    <div className="col s12 no-result" style={{ textAlign: "center" }}>
                                        <p>{this.context.t("_no_relative_yet")}</p>
                                        <p>{this.context.t("_click_add_children")}</p>
                                    </div>
                                </div>
                                :
                                <table className="responsive-table highlight">
                                    <thead className="blue lighten-5">
                                        <tr>
                                            <th><strong>{this.context.t("_name")}</strong></th>
                                            <th><strong>{this.context.t("_qid")}</strong></th>
                                            <th><strong>{this.context.t("_phone_number")}</strong></th>
                                            <th><strong>{this.context.t("_age")}</strong></th>
                                            {/* <th><strong>{this.context.t("_status")}</strong></th> */}
                                            <th><strong>{this.context.t("_parents")}</strong></th>
                                            <th width="80"><strong>{this.context.t("_profile")}</strong></th>
                                            <th width="80"><strong>{this.context.t("_delete")}</strong></th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        {
                                            studentList.map(item => {
                                                return (
                                                    <tr key={item.uid}>
                                                        <td>{item.field_student_name}</td>
                                                        <td>{item.field_qid}</td>
                                                        <td>{item.field_student_mobile}</td>
                                                        <td>{this.calculateAge(item.field_student_dob)}</td>
                                                        {/* <td>{item.status == "On" ? "Approved" : "Blocked"}</td> */}
                                                        <td className="gaurdianText">
                                                            {item.parents ?
                                                                item.parents.length > 0 ? item.parents.map(itemParent => {
                                                                    return <span key={itemParent.uid}>{itemParent.field_parent_name}<br /></span>
                                                                }) :
                                                                    null
                                                                : null
                                                            }
                                                        </td>
                                                        <td>
                                                            <Link aria-label={`${this.context.t("_participants")} ${item.field_student_name} ${this.context.t("_view_profile")}`} to={`/student-profile/${item.uid}`}><i className="material-icons attendanceClose tooltipped" data-tooltip={this.context.t("_view_profile")}>remove_red_eye</i>
                                                            </Link>

                                                        </td>
                                                        <td>
                                                            <button aria-label={`${this.context.t("_participants")} ${item.field_student_name} ${this.context.t("_remove")}`} style={{ background: "transparent", border: 0 }} onClick={() => this.handleOpenRemoveStudentConfirm(item.uid)}>
                                                                <i className="material-icons attendanceClose tooltipped" data-tooltip={this.context.t("_remove")}>delete_forever</i>
                                                            </button>

                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                        }

                    </div>
                    <button id="addChildBtn" className="waves-effect waves-light btn profileBtn" onClick={() => { this.handleOpenQidCheck() }}>{this.context.t("_add_participants")}</button>

                    <div id="checkQid" className="modal sm" role="dialog">
                        <div className="modal-content modal-no-close">
                            {
                                _.isEmpty(studentFormData) ?
                                    <div /> :
                                    <div>
                                        <span className="badge modalClose"><button className="close" aria-label="close" onClick={() => this.handleCloseModal()}><i className="material-icons">close</i></button></span>
                                        <h2 className="line">{this.context.t("_add_children")}</h2>
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit.</p> */}
                                        {hasError ? <div className="error-container fail">
                                            <h5>{this.context.t("_error_title")}</h5>
                                            <ul>
                                                {studentFormData.qid.error != "" ? <li ><label className="error" htmlFor="qid">{studentFormData.qid.error}</label></li> : null}
                                                {studentFormData.relation.error != "" ? <li ><label className="error" htmlFor="relation">{studentFormData.relation.error}</label></li> : null}
                                            </ul>
                                        </div> : null
                                        }
                                        <form onSubmit={this.handleCheckQid} ref={(el) => this.checkQidForm = el} className="row full-width">
                                            <div className="col s12">
                                                <div className="input-field item">
                                                    <input
                                                        placeholder={this.context.t("_type_child_qid")}
                                                        type="text"
                                                        name="qid"
                                                        id="qid"
                                                        onChange={(evt) => this.handleChangeAddForm("qid", evt.target.value)}
                                                        onFocus={(evt) => this.handleChangeAddForm("qid", evt.target.value)}
                                                        value={studentFormData.qid.value}
                                                        maxLength={11}
                                                    />
                                                    <label style={dynamicStyles.position(lang)}>{this.context.t("_student_qid")} <span className="asterisk">*</span></label>
                                                </div>

                                                <div className="input-field mobileSelect">
                                                    <label style={dynamicStyles.position(lang)}>{this.context.t("_select_relation_1")}<span className="asterisk">*</span>
                                                    </label>
                                                    <select
                                                        className="browser-default"
                                                        name="relation"
                                                        onChange={(evt) => this.handleChangeAddForm("relation", evt.target.value)}
                                                        value={studentFormData.relation.value}
                                                    >
                                                        <option value="" disabled> {this.context.t("_select_relation")} </option>
                                                        <option value="Father">{this.context.t("_father")}</option>
                                                        <option value="Mother">{this.context.t("_mother")}</option>
                                                        <option value="Sister">{this.context.t("_sister")}</option>
                                                        <option value="Brother">{this.context.t("_brother")}</option>
                                                        <option value="Grandmother">{this.context.t("_grand_mother")}</option>
                                                        <option value="Grandfather">{this.context.t("_grand_father")}</option>
                                                        <option value="Guardian">{this.context.t("_parent")}</option>
                                                    </select>
                                                    <span
                                                        style={dynamicStyles.textAlign(lang)}
                                                        className="helper-text"
                                                        data-error="Required field."
                                                    ></span>
                                                </div>
                                            </div>

                                            <div className="col s12">
                                                <button type="button" onClick={this.handleCloseModal} className="btn profileBtn none waves-effect waves-dark">{this.context.t("_cancel")}</button>
                                                <button className="btn profileBtn blue noWidth waves-effect waves-light">{this.context.t("_add")}</button>
                                            </div>
                                        </form>
                                    </div>
                            }
                        </div>
                    </div>
                    <div id="addStudent" className="modal user-add-modal overflow-scroll" role="dialog">
                        <div className="modal-content modal-no-close">
                            {
                                _.isEmpty(studentFormData) ?
                                    <div /> :
                                    <form onSubmit={this.handleAddNewStudent} ref={(el) => this.studentAddForm = el} className="col full-width">
                                        <span className="badge modalClose"><button className="close" aria-label="close" onClick={() => this.handleCloseModal()}><i className="material-icons">close</i></button></span>
                                        <h2 className="line">{this.context.t("_add_a_participants")}</h2>

                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit, tellus arcu maximus ipsum, ut pulvinar erat lacus at libero.</p> */}
                                        {hasError ? <div className="error-container fail">
                                            <h5>{this.context.t("_error_title")}</h5>
                                            <ul aria-label="error" >
                                                {
                                                    Object.keys(studentFormData).map((item, index) => {
                                                        return studentFormData[item].error != "" ? <li key={index} ><a ref={(refItem) => { this[item + "error"] = refItem; }} tabIndex="0" href={`#${item}`} className="error" htmlFor={item}>{studentFormData[item].error}</a></li>
                                                            :
                                                            null
                                                    })}
                                            </ul>
                                        </div> : null
                                        }
                                        <p>{this.context.t("_required_field")}{" "} <span className="asterisk">*</span></p>

                                        <div className="row">
                                            <div className="col s12 m6">
                                                {this.renderInput({ name: "name", label: this.context.t("_participant_name"), placeholder: this.context.t("_student_name_placeholder"), isMandatory: true })}
                                                {this.renderInput({ name: "qid", label: this.context.t("_participant_qid"), placeholder: this.context.t("_participant_qid_placeholder"), isMandatory: true, max: 11 })}
                                                {this.renderInput({ name: "email", label: this.context.t("_email"), placeholder: this.context.t("_email_placeholder"), type: "email" })}

                                                <div className="result-type">
                                                    {
                                                        this.renderSelect({
                                                            name: "relation", label: this.context.t("_relation"), placeholder: this.context.t("_select_relation"), isMandatory: true, data: [
                                                                { label: this.context.t("_father"), value: "Father" },
                                                                { label: this.context.t("_mother"), value: "Mother" },
                                                                { label: this.context.t("_sister"), value: "Sister" },
                                                                { label: this.context.t("_brother"), value: "Brother" },
                                                                { label: this.context.t("_grand_mother"), value: "Grandmother" },
                                                                { label: this.context.t("_grand_father"), value: "Grandfather" },
                                                                { label: this.context.t("_parent"), value: "Guardian" }
                                                            ]
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <div className="col s12 m6">
                                                <div className="date-popup">
                                                    {this.renderInput({ name: "dob", label: this.context.t("_dob"), placeholder: this.context.t("_dob_placeholder"), isMandatory: true, type: "date" })}
                                                </div>

                                                {this.renderInput({ name: "mobile", label: this.context.t("_mobile_number"), placeholder: this.context.t("_mobile_number_placeholder"), isMandatory: true, type: "phone_number", max: 8 })}

                                                {/* {
                                                        studentFormData.schoolName.value == "0" && this.renderInput({ name: "schoolNewName", label: this.context.t("_school_name"), placeholder: this.context.t("_select_school_name") })
                                                    } */}
                                                {
                                                    this.renderRadioButton({ label: this.context.t("_gender"), isMandatory: true, name: "gender", defaultChecked: "male", data: [{ label: this.context.t("_male"), value: "Male" }, { label: this.context.t("_female"), value: "Female" }] })
                                                }
                                                <div className="result-type" style={{ paddingTop: 14 }}>
                                                    {
                                                        this.renderSelect({
                                                            name: "schoolName", label: this.context.t("_school_name"), placeholder: this.context.t("_select_school_name"), data: schoolList, type: "school", isMandatory: true
                                                        })
                                                    }
                                                </div>
                                                {
                                                    studentFormData.schoolName.value == "0" && this.renderInput({ name: "schoolNewName", label: this.context.t("_school_name"), placeholder: this.context.t("_select_school_name"), isMandatory: true })
                                                }
                                            </div>
                                            <div className="col s12">
                                                <button className="btn profileBtn blue waves-effect waves-light">{this.context.t("_save_changes")}</button>
                                                <button type="button" onClick={() => this.handleCloseModal()} className="waves-effect waves-light btn profileBtn">{this.context.t("_close")}</button>
                                            </div>
                                            <div className="clearfix" />
                                        </div>
                                    </form>
                            }
                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={removeStudentConfirm}
                    style={customModalStyles}
                    contentLabel="unlink parent confirm modal"
                    ariaHideApp={false}
                >
                    <div className="popupAlert warning">
                        <br />
                        <h3>{this.context.t("_confirm")}</h3>
                        <p>{this.context.t("_remove_student_confirm")}</p>
                        <div className="row">
                            <button className="col btn blue" onClick={() => this.handleCloseConfirmModal("removeStudentConfirm")}>{this.context.t("_no")}</button>
                            <button className="col btn profileBtn yellow" onClick={() => this.handleConfirmRemoveSchool()}>{this.context.t("_yes")}</button>
                        </div>
                    </div>
                </Modal>
            </>
        )
    }

}

const mapStateToProps = ({ i18nState }) => {
    return {
        lang: i18nState.lang
    }
}
const mapDispatchToProps = {
    showPopup: (data) => PopupAction.showPopup(data),
    hidePopup: (data) => PopupAction.hidePopup(data),
    showLoader: LoadingAction.showLoader,
    hideLoader: LoadingAction.hideLoader,
}
ParentConnections.contextTypes = {
    t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(ParentConnections);
