import React from "react"
import Main from "../main"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom";
import _ from "lodash"
import { PopupAction, LoadingAction } from "./../../actions"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { dynamicStyles } from "../../constants/dynamicStyles"
import newActive1 from "../../assets/images/newActive.jpg"
class ActivityHome extends Main {
	constructor(props) {
		super(props);
		this.state = {
			config: this.config(),
			activityList: [],
			userActivityList: [],
			studentList: [],
			isLoaded: false
		}
	}
	async componentDidMount() {
		if (localStorage.getItem("role") == "parent")
			await this.getChildren();
		if (localStorage.getItem("role") == "student")
			await this.checkStudentEnrolled();
		if (localStorage.getItem("role") == "volenteer")
			await this.checkVolunteerEnrolled();
		this.getActivity()
	}
	getChildren = async () => {
		const { config } = this.state
		let url = `${config.api_url}${urlEndPoints.myChildren}`
		try {
			const data = await this.networkRequest(url)
			if (!data.data.message) {
				this.setState({ studentList: data.data.results })
			}
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
		// }
	}
	checkStudentEnrolled = async () => {
		const { config } = this.state
		let url = `${config.api_url}${urlEndPoints.getUserActivityById}`
		try {
			const data = await this.networkRequest(url)
			this.setState({ userActivityList: data.data })
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
	}
	checkVolunteerEnrolled = async () => {
		const { config } = this.state
		let url = `${config.api_url}${urlEndPoints.get_volunteer_activities}`
		try {
			const data = await this.networkRequest(url)
			this.setState({ userActivityList: data.data })
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
	}
	getActivity = async () => {
		const { config } = this.state;
		const { lang } = this.props;
		let url = "";
		if (lang == "ar")
			url = `${config.origin}${lang}/api/${urlEndPoints.homeActivities}`
		else
			url = `${config.origin}api/${urlEndPoints.homeActivities}`
		try {
			const data = await this.networkRequest(url)
			if (data.status == 200) {
				const activityList = data.data.results;
				activityList.forEach(item => {
					item.field_start_and_end_time_2 = item.field_start_and_end_time_2.split("*")
					item.field_start_and_end_time_2 = item.field_start_and_end_time_2.map(date => new Date(date))
					item.field_start_and_end_time_2 = new Date(Math.min.apply(null, item.field_start_and_end_time_2));
					item.field_start_and_end_time_3 = item.field_start_and_end_time_3.split("*")
					item.field_start_and_end_time_4 = item.field_start_and_end_time_4.split("*")
					item.field_start_and_end_time = item.field_start_and_end_time.split("*")
					item.field_start_and_end_time_1 = item.field_start_and_end_time_1.split("*")
				});
				this.setState({
					// activityList,
					pager: data.data.pager,
					isLoaded: true
				})
				this.setEnrolCount(activityList)
			}
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
	}
	setEnrolCount = async (activities) => {
		const { config } = this.state
		for (const activity of activities) {
			let url = `${config.api_url}${urlEndPoints.getEnrolmentCount(activity.nid)}`
			try {
				const data = await this.networkRequest(url)
				activity.enrolmentCount = !_.isEmpty(data.data) ? data.data.length : 0
			} catch (error) {
				this.props.hideLoader()
				console.warn("error: ", error)
			}
		}
		this.setState({ activityList: activities })
		this.props.hideLoader()
	}
	getGender = gender => {
		gender = gender.toLowerCase()
		switch (gender) {
			case "male":
				return this.context.t("_boys")
			case "female":
				return this.context.t("_girls")
			case "mixed":
				return this.context.t("_mixed")

		}
	}
	getDateInFormat = date => {
		if (date.includes("Jan"))
			date = date.replace("Jan", this.context.t("_jan"))

		if (date.includes("Feb"))
			date = date.replace("Feb", this.context.t("_feb"))

		if (date.includes("Mar"))
			date = date.replace("Mar", this.context.t("_mar"))

		if (date.includes("Apr"))
			date = date.replace("Apr", this.context.t("_apr"))

		if (date.includes("May"))
			date = date.replace("May", this.context.t("_may"))

		if (date.includes("Jun"))
			date = date.replace("Jun", this.context.t("_jun"))

		if (date.includes("Jul"))
			date = date.replace("Jul", this.context.t("_jul"))

		if (date.includes("Aug"))
			date = date.replace("Aug", this.context.t("_aug"))

		if (date.includes("Sep"))
			date = date.replace("Sep", this.context.t("_sep"))

		if (date.includes("Oct"))
			date = date.replace("Oct", this.context.t("_oct"))

		if (date.includes("Nov"))
			date = date.replace("Nov", this.context.t("_nov"))

		if (date.includes("Dec"))
			date = date.replace("Dec", this.context.t("_dec"))

		return date
	}
	getTimeInFormat = time => {
		if (time.includes("am"))
			time = time.replace("am", this.context.t("_am"))

		if (time.includes("pm"))
			time = time.replace("pm", this.context.t("_pm"))

		return time;
	}
	handleEnrolledClick = () => {
		this.props.showPopup({
			type: "error",
			title: this.context.t("_warning"),
			message: this.context.t("_already_enrolled"),
			isOpen: true,
			buttonText: this.context.t("_ok")
		})
	}
	handleEnroll = async (id) => {
		// if (_.isEmpty(localStorage.getItem("role"))) {
		// 	return this.props.history.push("/registration")
		// }
		const role = await localStorage.getItem("role");
		switch (role) {
			case "student":
				this.props.showLoader();
				this.handleEnrollStudent(id)
				break;
			case "volenteer":
				this.props.showLoader();
				this.handleEnrollVolunteer(id)
				break;
			case "school":
			case "parent":
				this.props.showLoader();
				this.handleEnrollParent(id)
				break;

			default:
				this.props.showPopup({
					type: "error",
					title: "",
					// message: this.context.t("_login_required"),
					message: <>
						<Link onClick={() => { this.props.hidePopup({}) }} to="/login"><b>{this.context.t("_login_link")}</b></Link> {this.context.t("_is_required_for_enrolment")}
					</>,
					isOpen: true,
					buttonText: this.context.t("_ok")
				})
				break;
		}
	}
	handleRegisterVolunteerRegister = () => {
		this.props.showPopup({
			type: "error",
			title: "",
			// message: this.context.t("_login_required"),
			message: <>
				<Link aria-label={this.context.t("_login_link")} onClick={() => { this.props.hidePopup({}) }} to="/login"><b>{this.context.t("_login_link")}</b></Link> {this.context.t("_is_required_for_volunteer")}
			</>,
			isOpen: true,
			buttonText: this.context.t("_ok")
		})
		// this.props.showPopup({
		// 	type: "error",
		// 	title: this.context.t("_warning"),
		// 	message: this.context.t("_volunteer_not_registered"),
		// 	isOpen: true,
		// 	buttonText: this.context.t("_ok")
		// })
		// this.props.history.push("/registration/volunteer")
	}
	handleVolunteeredClick = () => {
		this.props.showPopup({
			type: "error",
			title: this.context.t("_warning"),
			message: this.context.t("_volunteer_not_needed"),
			isOpen: true,
			buttonText: this.context.t("_ok")
		})
	}
	handleEnrollStudent = async (id) => {
		const { config } = this.state
		const { lang } = this.props
		const url = `${config.api_url}${urlEndPoints.activityEnrolment}`
		const obj = {
			"activity_id": id
		}
		try {
			const data = await this.networkRequest(url, "POST", obj)
			this.props.hideLoader();
			if (data.data.error) {
				this.props.showPopup({
					type: "error",
					title: this.context.t("_error"),
					message: `${data.data.error.name[lang]}`,
					isOpen: true,
					buttonText: this.context.t("_ok")
				})
			} else {
				await this.checkStudentEnrolled();
				this.getActivity()
				this.props.showPopup({
					type: "success",
					title: this.context.t("_success"),
					message: this.context.t("_enroll_success"),
					isOpen: true,
					buttonText: this.context.t("_ok")
				})
			}
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}

	}
	handleEnrollVolunteer = async (id) => {
		const { config } = this.state
		const { lang } = this.props
		const url = `${config.api_url}${urlEndPoints.volunteerEnrolment}`
		const obj = {
			"activity_id": id
		}
		try {
			const data = await this.networkRequest(url, "POST", obj)
			this.props.hideLoader();
			if (data.data.error) {
				this.props.showPopup({
					type: "error",
					title: this.context.t("_error"),
					message: `${data.data.error.name[lang]}`,
					isOpen: true,
					buttonText: this.context.t("_ok")
				})
			} else {
				await this.checkVolunteerEnrolled();
				this.getActivity()
				this.props.showPopup({
					type: "success",
					title: this.context.t("_success"),
					message: this.context.t("_request_send"),
					isOpen: true,
					buttonText: this.context.t("_ok")
				})
			}
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}

	}
	handleEnrollParent = (id) => {
		const { studentList } = this.state;
		if (localStorage.getItem("role") == "parent" && studentList.length == 1) {
			this.handleInitialEnroll(id);
		} else {
			this.props.history.push(`/subscribe-activities/${id}`);
		}

	}
	handleEnrolButton = item => {
		if (Number(item.field_number_of_attendees) > Number(item.enrolmentCount))
			return <button aria-label={`${item.title} Enroll`} onClick={() => { this.handleEnroll(item.nid) }} className="btn blueColor ">
				{this.context.t("_enroll")}
			</button>
		else if (Number(item.enrolmentCount) < (Number(item.field_number_of_attendees) + Number(item.field_waiting_list_count)))
			return <button aria-label={`${item.title} Waiting`} onClick={() => { this.handleEnroll(item.nid) }} className="btn blueColor ">
				{this.context.t("_waiting_list")}
			</button>
		else
			return <button aria-label={`${item.title} Closed`} onClick={() => { this.handleEnrollClosed() }} className="btn blueColor btn-disabled">
				{this.context.t("_closed")}
			</button>
	}
	handleEnrollClosed = () => {
		this.props.showPopup({
			type: "error",
			title: this.context.t("_warning"),
			message: this.context.t("_enrolment_closed"),
			isOpen: true,
			buttonText: this.context.t("_ok")
		})
	}
	render() {
		const { isLoaded, activityList, userActivityList } = this.state;
		const { lang } = this.props;
		return (
			isLoaded ?
				<div className="activityHome">
					<div className="container">
						<div className={`zone-text en ${activityList.length > 0 ? "" : "activity-block"}`}>
							<h3 id="activity">{this.context.t("_activities")}</h3>
							<div>
								{this.context.t("_home_activity_caption")}
								{activityList.length > 0 && <Link className="btnNewYellow btn" aria-labelledby="activity" aria-label={this.context.t("view_more_activity_label")} to="/activities">{this.context.t("_view_more")} <i className={`material-icons ${lang}`}>{`${lang === "en" ? "arrow_forward" : "arrow_backward"}`}</i></Link>}
							</div>
						</div>
						<div className="row noFlex">
							<ul aria-label="Activities">
								{
									activityList.length > 0 ? activityList.map(item => {
										return (
											<li className="col s12 m4 l4" key={item.nid}>
												<div className="newactivities">
													<div className={`textHolderNA ${item.field_activity_zone}`}>
														<div className={`topTextHolderNA`}>
															<Link to={`/activity/${item.nid}`} aria-label={`${item.title} ${this.getGender(item.field_gender)} ${item.field_age_group} ${this.context.t("_year_old")} ${this.context.t("_start_date")} ${this.getDateInFormat(item.field_start_and_end_time_3[0])} ${this.getTimeInFormat(item.field_start_and_end_time[0])} ${this.context.t("_end_date")} ${this.getDateInFormat(item.field_start_and_end_time_4[item.field_start_and_end_time_4.length - 1])} ${this.getTimeInFormat(item.field_start_and_end_time_1[item.field_start_and_end_time_1.length - 1])}`}>
																<h4><i className="topHeadNA"></i>{item.title}</h4>
															</Link>
															<div className="topTextInsideNA">
																<span className="blackText"><i className="topBoyIconNA"></i> {this.getGender(item.field_gender)}</span>
																<span>{`${item.field_age_group} ${this.context.t("_year_old")}`}</span>
																{/* <span className="schoolSessionNA">School session</span> */}
															</div>
														</div>
														<div className="bottomTextHolderNA">
															{/* <div className="bottomTextLeftNA" dangerouslySetInnerHTML={{ __html: item.body }} /> */}
															<div className="bottomTextRightNA">
																<span className="calendarNA">
																	<i className="calendar-icons"></i> {this.context.t("_start_date")}  <span className="date">{this.getDateInFormat(item.field_start_and_end_time_3[0])} <br /><small>{this.getTimeInFormat(item.field_start_and_end_time[0])}</small></span>
																</span>
																<span className="calendarNA">
																	<i className="calendar-icons"></i> {this.context.t("_end_date")}
																	<span className="date">{this.getDateInFormat(item.field_start_and_end_time_4[item.field_start_and_end_time_4.length - 1])}<br /><small>{this.getTimeInFormat(item.field_start_and_end_time_1[item.field_start_and_end_time_1.length - 1])}</small></span>
																</span>
															</div>
														</div>
													</div>
													<div className="btnHolderNA">
														{
															(
																item.field_need_to_show_enroll_button == "1" &&
																!_.isEmpty(item.field_start_and_end_time_3) &&
																!_.isEmpty(item.field_start_and_end_time_4) &&
																(new Date(item.field_start_and_end_time_2) >= new Date())) ?
																(localStorage.getItem("role") != "volenteer" ||
																	_.isEmpty(localStorage.getItem("role"))) &&
																(!_.isEmpty(_.find(userActivityList, ["field_activity_id", item.nid])) ?
																	(_.find(userActivityList, ["field_activity_id", item.nid]).field_published_status == "Unpublished" ||
																		_.find(userActivityList, ["field_activity_id", item.nid]).field_published_status == "Published") ?
																		<button onClick={() => { this.handleEnrolledClick() }} className="btn blueColor btn-disabled" >
																			{_.find(userActivityList, ["field_activity_id", item.nid]).field_published_status == "Published" ? this.context.t("_enrolled") : this.context.t("_pending")}
																		</button> :
																		this.handleEnrolButton(item)
																	// <button onClick={() => { this.handleEnroll(item.nid) }} className="btn blueColor ">{this.context.t("_enroll_now")}</button> 
																	:
																	this.handleEnrolButton(item)
																	// <button onClick={() => { this.handleEnroll(item.nid) }} className="btn blueColor">{this.context.t("_enroll_now")}</button>
																)
																:
																null
														}
														{
															(_.isEmpty(localStorage.getItem("role")) && item.field_need_volenteer != "0") ?
																<button className="btn" aria-label={`${this.context.t("volunteer_now_for")} ${item.title}`} onClick={() => { this.handleRegisterVolunteerRegister() }}>{this.context.t("_volunteer_now")}</button> : null
														}
														{
															localStorage.getItem("role") == "volenteer" ?
																item.field_need_volenteer == "0" ?
																	<button className="btn btn-disabled" onClick={() => { this.handleVolunteeredClick() }} >{this.context.t("_volunteer_now")}</button>
																	:
																	<button className="btn" onClick={() => { this.handleEnroll(item.nid) }} >{this.context.t("_volunteer_now")}</button>
																: null
														}
														{/* <button className="btn">Volunteer</button>
													<button className="btn blueColor">Enroll Now</button> */}
													</div>
												</div>
											</li>
										)
									}) :
										<div class="activities-inner">
											<p>{this.context.t("_home_no_activity")}</p>
											<a class="btnNewYellow btn" aria-labelledby="activity" aria-label="Button for view more activity" href="/activities">
												{this.context.t("_go_to_activities")}<i className={`material-icons ${lang}`}>{`${lang === "en" ? "arrow_forward" : "arrow_backward"}`}</i></a>
										</div>
								}
								{/* 
							<div className="col s12 m4 l4">
								<div className="newactivities Software-Zone">
									<div className="textHolderNA">
										<div className="topTextHolderNA redIcon">
											<h4><i className="topHeadNA"></i>Robotics competition</h4>
											<div className="topTextInsideNA">
												<span className="blackText"><i className="topBoyIconNA"></i> Boys</span>
												<span>12 - 18 Years Old</span>
												<span className="schoolSessionNA">School session</span>
											</div>
										</div>
										<div className="bottomTextHolderNA">
											<div className="bottomTextLeftNA">Cmpetitions are a fun way for both adults and kids to learn </div>
											<div className="bottomTextRightNA">
												<span className="calendarNA">
													<i className="calendar-icons"></i> Start date <span className="date">13 Apr,2020</span>
												</span>
												<span className="calendarNA">
													<i className="calendar-icons"></i> End date <span className="date">13 Apr,2020</span>
												</span>
											</div>
										</div>
									</div>

									<div className="btnHolderNA">
										<button className="btn">Volunteer</button>
										<button className="btn blueColor">Enroll Now</button>
									</div>
								</div>
							</div>

							<div className="col s12 m4 l4">
								<div className="newactivities Technology-Zone">

									<div className="textHolderNA">
										<div className="topTextHolderNA blueIcon">
											<h4><i className="topHeadNA"></i>Robotics competition</h4>
											<div className="topTextInsideNA">
												<span className="blackText"><i className="topBoyIconNA"></i> Boys</span>
												<span>12 - 18 Years Old</span>
												<span className="schoolSessionNA">School session</span>
											</div>
										</div>
										<div className="bottomTextHolderNA">
											<div className="bottomTextLeftNA">Cmpetitions are a fun way for both adults and kids to learn </div>
											<div className="bottomTextRightNA">
												<span className="calendarNA">
													<i className="calendar-icons"></i> Start date <span className="date">13 Apr,2020</span>
												</span>
												<span className="calendarNA">
													<i className="calendar-icons"></i> End date <span className="date">13 Apr,2020</span>
												</span>
											</div>
										</div>
									</div>

									<div className="btnHolderNA">
										<button className="btn">Volunteer</button>
										<button className="btn blueColor">Enroll Now</button>
									</div>

								</div>
							</div> */}
							</ul>
						</div>



					</div>

				</div>
				:
				<div />
		)
	}
}

const mapStateToProps = ({ i18nState }) => {
	return {
		lang: i18nState.lang
	}
}
const mapDispatchToProps = {
	showPopup: (data) => PopupAction.showPopup(data),
	hidePopup: (data) => PopupAction.hidePopup(data),
	showLoader: LoadingAction.showLoader,
	hideLoader: LoadingAction.hideLoader,
}
ActivityHome.contextTypes = {
	t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ActivityHome));
