import React from "react"
import Main from "../main"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { dynamicStyles } from "../../constants/dynamicStyles"
import { LoadingAction } from "./../../actions"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import _ from "lodash"

class Banner extends Main {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			config: this.config(),
			contentData: {}
		}
	}
	componentDidMount() {
		this.getMakerData()
	}
	getMakerData = async () => {
		const { config } = this.state
		const { lang } = this.props;
		let url = "";
		if (lang == "ar")
			url = `${config.origin}${lang}/api/${urlEndPoints.markersAtHome}`
		else
			url = `${config.origin}api/${urlEndPoints.markersAtHome}`
		try {
			const data = await this.networkRequest(url)
			if (data.data.error) {

			} else {
				this.setState({
					contentData: data.data[0]
				})

			}
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
	}
	render() {
		const { contentData } = this.state;
		const { lang } = this.props;
		return (


			<div className="makeHome" id="skipContent">
				{
					(!_.isEmpty(contentData) && contentData.status == "1") && <> <div className="registration-color-container">
						<span className="grey-square-rotate red-sq one"></span>
						<span className="grey-square-rotate red-sq two"></span>
						<span className="orange-circle one"></span>
						<span className="multi-square redcolor three"><b><i></i></b></span>
						<span className="multi-square yellowcolor one"><b><i></i></b></span>
						<span className="multi-square yellowcolor two"><b><i></i></b></span>
					</div>


						<div className="container">
							<div dangerouslySetInnerHTML={{ __html: contentData.body }}></div>

							<span className="orange-circle"></span>
						</div></>
				}

			</div>
		)
	}
}

const mapStateToProps = ({ i18nState }) => {
	return {
		lang: i18nState.lang
	}
}
const mapDispatchToProps = {
	showLoader: LoadingAction.showLoader,
	hideLoader: LoadingAction.hideLoader,
}
Banner.contextTypes = {
	t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(Banner);
