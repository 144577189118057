const urlEndPoints = {
    auth: "oauth/token",
    xcsrf: "rest/session/token",
    login: "login?_format=json",
    forgotPassword: "forgotpassword?_format=json",
    resetPassword: "resetpassword?_format=json",
    getPrivacyPolicy: "get_privacy?_format=json",

    registerStudent: "studentpost?_format=hal+json",
    registerSchoolRep: "schoolrepresentativepost?_format=hal+json",
    registerParent: "parentpost?_format=hal+json",
    registerVolunteer: "volenteerpost?_format=hal+json",
    schoolList: (lang) => `snames?_format=json&lang=${lang}`,
    schoolListWithRep: "school_names?_format=json",
    userDetails: "user-detail?_format=json",
    userSettings: "user-profile-settigs?_format=json",
    updateUserPic: "updateprofilepicpost?_format=json",
    removeUserPic: "removeprofilepicpost?_format=json",
    getActivitiesList: "activicties?_format=json",
    getVolunteerActivitiesList: "volenteer_activities?_format=json",
    getSchoolActivitiesList: "school_activities?_format=json",
    getPublicActivitiesList: "public_activites?_format=json",
    activityEnrolment: "activityenrollmentpost?_format=json",
    myChildren: "my_children?_format=json",
    studentsUnderMe: "students_under_me?_format=json",
    myParents: "my_parents?_format=json",
    mySchools: "my_schools?_format=json",
    currentActivity: "current_activities?_format=json",
    parentCurrentActivity: (urlParam) => `parent_current_activity/${urlParam}?_format=json`,
    schoolCurrentActivity: "school_current_activities?_format=json",
    volunteerCurrentActivity: "volenteer_current_activities?_format=json",
    volunteerActivityHistory: "volunteer_activity_history?_format=json",
    volunteerEnrolment: "volenteerenrollmentpost?_format=json",
    checkQid: "checkqidpost?_format=json",
    parentAddStudent: "parent/addstudent?_format=json",
    studentAddParent: "student/addparent?_format=json",
    schoolAddStudent: "school_representative/addstudent?_format=json",
    studentAddSchool: "studentaddschoolpost?_format=json",
    cancelActivity: "cancelactivitypost?_format=json",
    removeLinking: "removelikingpost?_format=json",
    viewUserProfileSettings: (id) => `view-user-profile-settigs/${id}?_format=json`,
    getProfile: (id) => `getProfile/${id}?_format=json`,
    viewStudentProfileUpdate: "studentprofileupdatepost?_format=json",
    viewStudentParent: (id) => `selected_user_parents/${id}?_format=json`,
    viewStudentSchool: (id) => `selected_user_schools/${id}?_format=json`,
    viewStudentActivity: (id) => `selected_user_current_activities/${id}?_format=json`,
    viewProfileLinking: "removeprofilelikingpost?_format=json",
    viewRemoveProfilePic: "removelinkeduserprofilepicpost?_format=json",
    viewUpdateProfilePic: "updateselecteduserprofilepicpost?_format=json",
    removeSchoolLinking: "removeschoollikingpost?_format=json",
    getWaitingList: "waiting_list?_format=json",
    studentApprove: "approvestudentpost?_format=json",
    sendNotification: "sendnotification?_format=json",
    enrolledUsers: (id) => `enrolled_user/${id}?_format=json`,
    getParentCount: (uid) => `getParentsCount/${uid}?_format=json`,
    viewAddSchool: "studentaddschoolforSelectedstudentpost?_format=json",
    viewRemoveSchool: "removeschoollikingforselecteduserpost?_format=json",
    viewCheckQid: "checkqidafterPrfileview?_format=json",
    viewCancelStudentActivity: "cancelactivityforselectedstudentpost?_format=json",
    getParentName: (id) => `getparentName/${id}?_format=json`,
    checkQidAfterProfileView: "checkqidafterPrfileview?_format=json",
    updatePassword: "updatepassword?_format=json",
    linkValidity: "linkvalidity?_format=json",
    mediaCenter: "media-centre?_format=json",
    filter: (lang) => `filter/${lang}?_format=json`,
    filterMedia: (id) => `filtermedia-centre/${id}?_format=json`,
    mediaDetail: (id) => `media-details/${id}?_format=json`,
    mediaDetailGallery: (id) => `media-detail-gallery/${id}?_format=json`,
    mySchoolRep: "my_school_representative",
    viewMySchoolRep: (id) => `my_school_representative/${id}`,
    notificationForStudent: "notification_for_student",
    notificationForParent: "notifications",
    getMachinesList: "get_machines",
    getFabLabs: "get_fab_page",
    getAnnouncement: "get_announcements",
    getFaqs: "faqs",
    getEquipmentUsed: "get_equipment_used",
    getVirtualTours: "get_virtual_tours",
    webFormSubmit: "webform_rest/submit",
    parentCurrentActivity: "parent-current-activity-list",
    getActivityById: (id) => `get_activity_ids/${id}`,
    getUserActivityById: "get_logged_user_activity_ids",
    about_us: "about_page",
    get_volunteer_activities: "get_volunteer_activities",
    markersAtHome: "markers_at_home",
    volunteerRemoveActivity: "removeactivitypost",
    upcomingActivities: "upcoming_activities",
    trainerActivityHistory: "activity_history",
    trainerCalendar: "trainer_asistant_callendar_activity",
    activityDetails: (id) => `activity-details/${id}`,
    activityDetailsGallery: (id) => `activity-detail-gallery/${id}`,
    userExistCheck: "userexistpost",
    parentExistCheck: "parentuserexistpost",
    getContactUs: "contact_us_page",
    homeActivities: "featured_activities",
    getEnrolledStudents: (activityId) => `get_enrolled_students/${activityId}`,
    getActivityEnrollments: (activityId) => `get_activity_enrollments/${activityId}`,
    markAttendance: "markattendancepost",
    ourVision: "our_vision",
    ourMission: "our_mission",
    aboutPage: "about_page",
    cancelAttendance: "cancelattendancepost",
    managerCalendar: "manager_calendar_activities",
    managerActivityHistory: "activity_history_manager",
    managerUpcomingActivity: "upcoming_activity_manager",
    checkStudentQid: (id) => `check_student_qid/${id}`,
    sendInvitation: "sendinvitation",
    checkEnrolment: (activityId, userId) => `check_enrollment/${activityId}/${userId}`,
    makersPath: "getRedirectUrl",
    getEnrolmentCount: (id) => `get_eroll_count/${id}`,
    getDashboardStudentEnrolled: "enrollement_count",
    getDashboardStudentWaitingList: "waitinglist_count",
    getDashboardStudentAttended: "attendence_count",
    getDashboardTotalActivities: "activity_list",
    getDashboardCurrentActivities: "current_running_activity",
    getDashboardCompletedActivities: "completed_activity",
    getDashboardUpcomingActivities: "upcomming_activities",
    getDashboardStudentList: "dashboard-user-list",
    getDashboardActivityList: "dashboard-activity-list",
    getDashboardTypesEvents: "event_activities",
    getDashboardTypesSessions: "session_activiies",
    getDashboardTypesCompetitions: "competition_activities",
    getDashboardZoneCreative: "creativity_zone_activity",
    getDashboardZoneSoftware: "software_zone_activity",
    getDashboardZoneTechnology: "technology_zone_activities",
    getDashboardStudentFullList: "dashboard-user-full-list",
    getDashboardActivityFullList: "dashboard-activity-full-list",
    getDashboardStudentActivities: (id) => `dashboard_student_activities/${id}`,
    getDashboardActivityStudents: (id) => `dasboard_activity_students/${id}`,
    getDashboardStudentProfile: (id) => `dashboard-student-profile/${id}`,
    getDashboardStudentCompleteActivity: (id) => `dashboard_complete_student_activities/${id}`,
    getDashboardActivityCompleteStudent: (id) => `dasboard_complete_students/${id}`,
    getFullAttendance: (id) => `get_activity_attendence/${id}`,
    getDashboardCount: "dashboard-count",
    studentAttendance: "student-attendence",
    termsAndCondition: "get_terms",
    getActivityDate: "activityDates",
    getMachineDetails: (id) => `machine_details/${id}`,
    getActivityEquipments: (id) => `activity/getequipmentsused/${id}`,
    getActivityMachines: (id) => `activity/machines/${id}`,
    dashboardStudentList: "dashboard-student-list",
    getDashboardNewStudentFullList: "dashboard-student-full-list",
    getMaintananceMode: "get-maintance-mode",
    getMySchool: "my_school",
    getStudentLevel: "point-level",
    getStudentReward: "student/reward",
    getStudentPointRange: "point-range",
    getActivityAccessories: (id, language) => `activity_accessories?id=${id}&language=${language}`,
}

export {
    urlEndPoints
}