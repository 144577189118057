import React from "react"
import { Route, BrowserRouter as Router, Switch, } from "react-router-dom"
import { setLanguage } from "redux-i18n"
import { connect } from "react-redux"
import _ from "lodash"
import IdleTimer from "react-idle-timer";
import Authorization from "./authorization"
import { Loading, PopupModal } from "./../components/common"

import Navigation from "./../components/includes/navigation"
import Footer from "./../components/includes/footer"
import Home from "./../components/home/home"
import MediaCenter from "./../components/media-center/media_center"
import FilterMedia from "./../components/media-center/filter_media"
import Subscribe from "./../components/subscribe/subscribe"
import ThankYou from "./../components/subscribe/thank_you"
import News from "./../components/news/news"
import Notfound from "./../components/notfound"
import Login from "./../components/auth/login"
import ChangePassword from "./../components/auth/changePassword"
// import Register from "./../components/auth/register"
import ResetPassword from "./../components/auth/resetPassword"
import ForgotPassword from "./../components/auth/forgotPassword"
// import Dashboard from "./../components/dashboard"
import ActivitiesList from "./../components/activities/activities_list"
import ActivityDetails from "./../components/activities/activity_detail"
import Registration from "./../components/registration/registration"
import SchoolRegistration from "./../components/registration/schoolRegistration"
import ParentRegistration from "./../components/registration/parentRegistration"
import VolunteerRegistration from "./../components/registration/volunteerRegistration"
import StudentRegistration from "./../components/registration/studentRegistration"
import Profile from "./../components/profile/profile"
import StudentSettings from "./../components/profile/student/studentSettings"
import StudentConnections from "./../components/profile/student/studentConnections"
import ViewStudentProfile from "./../components/profile/student/viewProfile"
import ViewStudentSettings from "./../components/profile/student/viewSettings"
import ViewStudentConnection from "./../components/profile/student/viewConnections"
import ViewStudentActivity from "./../components/profile/student/viewActivities"
import ParentSettings from "./../components/profile/parent/parentSettings"
import ParentConnections from "./../components/profile/parent/parentConnections"
import SchoolSettings from "./../components/profile/school/schoolSettings"
import VolunteerSettings from "./../components/profile/volunteer/volunteerSettings"
import ActivityHistory from "./../components/profile/volunteer/activityHistory"
import SchoolConnections from "./../components/profile/school/schoolConnections"
import CurrentActivities from "./../components/profile/currentActivities"
// import Workshop from "./../components/workshop/workshop"
import SubscribeActivities from "./../components/subscribe/subscribeWithActivities"
import Machines from "./../components/machines/machines";
import FabLabs from "./../components/fab-labs/fabLabs";
import Announcements from "./../components/announcements/announcements";
import Faq from "./../components/faq/faq";
import VirtualTour from "./../components/virtual-tour/virtualTour";
// import panorama from "./../components/panorama";
// import panorama1 from "./../components/panorama/index1";
import AboutUs from "./../components/about-us/about_us";
import WaitingList from "./../components/profile/school/waitingList"
import EquipmentUsed from "./../components/equipment-used/equipmentUsed"
import Notification from "./../components/notification/notification"
import AwardCompetition from "./../components/awards-and-competition/awardCompetition"
import ContactUs from "./../components/contact/contact"
import Publications from "./../components/publications/publications"
import Calendar from "./../components/calender/calender"
import PrivacyPolices from "./../components/privacy-polices/privacyPolices"
import Makers from "./../components/makers/makers"
import AdminProfile from "./../components/profile/admin/profile"
import AdminUpcomingActivity from "./../components/profile/admin/upcomingActivity"
import AdminActivityHistory from "./../components/profile/admin/activityHistory"
import AdminCalendar from "./../components/profile/admin/calender"
import MarkAttendance from "./../components/profile/admin/markAttendance"
import EnrolmentListing from "./../components/profile/admin/enrolmentListing"
import EnrolmentStudentListing from "./../components/profile/admin/enrolmentStudentList"
import Summer from "./../components/summer/summer"
import ContactThankYou from "./../components/contact/thank_you"
import Dashboard from "./../components/dashboard/dashboard"
import DashboardActivityDetails from "./../components/dashboard/activity-details"
import DashboardStudentDetails from "./../components/dashboard/student-details"
import UiScreen from "./../components/ui-screens"
import StudentAttendance from "./../components/profile/student/studentAttendance"
import ParentProfile from "./../components/profile/parent/parentProfile"
import StudentTranscript from "./../components/profile/student/studentTranscript"
import MachineDetail from "./../components/machines/machineDetail"
import Maintenance from "./../components/maintenance/maintenance"
import Reward from "../components/profile/student/reward"

import PrivateRoute from "./../components/auth/privateRoute";
import { dynamicStyles } from "./../constants/dynamicStyles"

import { urlEndPoints } from "./../httpAPI/apiConfig"
import Main from "./../components/main"

// import ActivityDetails from "./act-details/act-details"

import "../assets/css/materialize.min.css"
import "../assets/css/slick.css"
import "../assets/css/style.css"
import "../assets/css/font-awesome.css"

const Student = Authorization(["student"]);
const Parent = Authorization(["parent"]);
const StudentParent = Authorization(["student", "parent"]);
const School = Authorization(["school"]);
const Volunteer = Authorization(["volenteer"]);
const Admin = Authorization(["trainer", "manager", "asistant", "edu_specialist", "administrator"]);
const Administrator = Authorization(["manager", "administrator", "edu_specialist"]);
const Common = Authorization(["student", "parent", "school", "volenteer", "trainer", "manager", "asistant", "edu_specialist", "administrator"]);

class Routes extends Main {
  constructor(props) {
    super(props)
    this.state = {
      timeout: 300000 * 3,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
      loading: false,
      isMaintenance: false,
      config: this.config(),

    }

    this.idleTimer = null
    // this.onAction = this._onAction.bind(this)
    // this.onActive = this._onActive.bind(this)
    // this.onIdle = this._onIdle.bind(this)
  }
  async componentDidMount() {

    try {
      const lang = await localStorage.getItem("lang");
      let pathName = window.location.pathname.toString();
      const urlLang = pathName.split("/")
      if (urlLang[1] == "ar" || urlLang[1] == "en") {
        if (urlLang[1] != lang) {
          await localStorage.setItem("lang", lang == "en" ? "ar" : "en");
          window.location.reload();
        }
      }

      if (lang !== null) {
        this.props.dispatch(setLanguage(lang || "en"))
      } else {
        localStorage.setItem("lang", "en");
        this.props.dispatch(setLanguage("en"))
      }
      this.getMaintenance()
    } catch (error) {
      // console.log("ERROR", error);
      // Error retrieving data
    }
  }
  getMaintenance = async () => {
    const { config } = this.state;
    let url = `${config.origin}${urlEndPoints.getMaintananceMode}`

    try {
      const data = await this.networkRequest(url, "POST")
      if (_.has(data, "data.maintanace_mode") && data.data.maintanace_mode == 1) {
        this.setState({
          loading: true,
          isMaintenance: true
        }, () => {
          this.forceUpdate()
          this.props.dispatch({
            type: "HIDE_LOADER", payload: false
          })
        })
      }
    } catch (error) {
      console.warn("error: ", error)
    }
    // setTimeout(() => {
    // 
    // }, 3000);
  }
  componentDidUpdate() {
    let elem = document.querySelectorAll('select');
    elem.forEach(element => {
      element.setAttribute("tabindex", "0");
    });
  }
  shouldComponentUpdate(nextProps, nextState) {
    // return !(nextState.isTimedOut == this.state.nextState)
    return false;
  }
  onAction = (e) => {
    this.setState({ isTimedOut: false })
  }

  onActive = (e) => {
    this.setState({ isTimedOut: false })
  }

  onIdle = (e) => {
    const isTimedOut = this.state.isTimedOut
    const { lang } = this.props;
    if (isTimedOut) {
      if (
        !_.isEmpty(localStorage.getItem("accessToken")) ||
        !_.isEmpty(localStorage.getItem("refreshToken")) ||
        !_.isEmpty(localStorage.getItem("tokenType")) ||
        !_.isEmpty(localStorage.getItem("role"))
      ) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("tokenType");
        localStorage.removeItem("role");
        window.location = `/${lang}/login`
      }
    } else {
      this.setState({ showModal: true })
      this.idleTimer.reset();
      this.setState({ isTimedOut: true })
    }

  }

  render() {
    const { lang } = this.props;
    const { isMaintenance, loading } = this.state
    return (
      <>
        {
          loading && isMaintenance ? <div style={Object.assign(dynamicStyles.direction(lang), { overflow: "hidden" })} className={lang == "ar" ? "ab" : ""}>
            <Router basename={`/${lang}/`}>
              <Loading />
              <div className="clearfix" />
              <Route component={Maintenance} />
            </Router>
          </div>
            :
            <div style={Object.assign(dynamicStyles.direction(lang), { overflow: "hidden" })} className={lang == "ar" ? "ab" : ""}>
              <a href="#skipContent" className="skipLink">
                {lang == "ar" ? "تخطي إلى المحتوى الرئيسي" : "Skip to main content"}
              </a>
              <IdleTimer
                ref={ref => { this.idleTimer = ref }}
                element={document}
                onActive={(e) => this.onActive(e)}
                onIdle={(e) => this.onIdle(e)}
                onAction={(e) => this.onAction(e)}
                // debounce={50}
                timeout={this.state.timeout}
              />
              <Router basename={`/${lang}/`}>
                <Navigation />
                <Loading />
                <PopupModal />
                <div className="clearfix" />
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/media-center" component={MediaCenter} />
                  <Route exact path="/filter-media/:id" component={FilterMedia} />
                  <Route exact path="/subscribe" component={Subscribe} />
                  <Route exact path="/thankyou" component={ThankYou} />
                  <Route exact path="/login" component={Login} />
                  {/* <Route exact path="/register" component={Register} /> */}
                  <Route exact path="/news/:id" component={News} />
                  <Route exact path="/activities" component={ActivitiesList} />
                  <Route exact path="/activity/:id" component={ActivityDetails} />
                  <Route exact path="/registration" component={Registration} />
                  <Route exact path="/registration/school" component={SchoolRegistration} />
                  <Route exact path="/registration/parent" component={ParentRegistration} />
                  <Route exact path="/registration/volunteer" component={VolunteerRegistration} />
                  <Route exact path="/registration/student" component={StudentRegistration} />
                  <PrivateRoute extend path="/profile" component={Common(Profile)} />
                  <PrivateRoute exact extend path="/student-settings" component={Student(StudentSettings)} />
                  <PrivateRoute exact extend path="/parent-settings" component={Parent(ParentSettings)} />
                  <PrivateRoute exact extend path="/school-settings" component={School(SchoolSettings)} />
                  <PrivateRoute exact extend path="/volenteer-settings" component={Volunteer(VolunteerSettings)} />
                  <PrivateRoute exact extend path="/volenteer-history" component={Volunteer(ActivityHistory)} />
                  <Route extend path="/reset-password/:token" component={ResetPassword} />
                  <Route extend path="/forgot-password" component={ForgotPassword} />
                  {/* <Route extend path="/workshop" component={Workshop} /> */}
                  <PrivateRoute exact extend path="/student-connections" component={Student(StudentConnections)} />
                  <PrivateRoute exact extend path="/parent-connections" component={Parent(ParentConnections)} />
                  <PrivateRoute exact extend path="/school-connections" component={School(SchoolConnections)} />
                  <PrivateRoute exact extend path="/subscribe-activities/:id" component={SubscribeActivities} />
                  <PrivateRoute exact extend path="/current-activities" component={Common(CurrentActivities)} />
                  <PrivateRoute exact extend path="/student-profile/:id" component={Common(ViewStudentProfile)} />
                  <PrivateRoute exact extend path="/student-settings/:id" component={Common(ViewStudentSettings)} />
                  <PrivateRoute exact extend path="/student-connection/:id" component={Common(ViewStudentConnection)} />
                  <PrivateRoute exact extend path="/student-activity/:id" component={Common(ViewStudentActivity)} />
                  <PrivateRoute exact extend path="/change-password" component={Common(ChangePassword)} />
                  <PrivateRoute exact extend path="/calendar" component={Common(Calendar)} />
                  <Route exact path="/machines" component={Machines} />
                  <Route exact path="/fab-labs" component={FabLabs} />
                  <Route exact path="/announcements" component={Announcements} />
                  <Route exact path="/faq" component={Faq} />
                  <Route exact path="/virtual-tour" component={VirtualTour} />
                  {/* <Route exact path="/panorama" component={panorama} />
            <Route exact path="/panorama1" component={panorama1} /> */}
                  <Route exact path="/about" component={AboutUs} />
                  <PrivateRoute exact path="/waiting-list" component={School(WaitingList)} />
                  <Route exact path="/equipment-used" component={EquipmentUsed} />
                  <PrivateRoute exact path="/notification" component={Common(Notification)} />
                  <Route exact path="/awards-competition" component={AwardCompetition} />
                  <Route exact path="/contact" component={ContactUs} />
                  <Route exact path="/contact-success" component={ContactThankYou} />
                  <Route exact path="/publications" component={Publications} />
                  <Route exact path="/privacy-policies" component={PrivacyPolices} />
                  <Route exact path="/makers" component={Makers} />
                  <PrivateRoute exact path="/admin-profile" component={Admin(AdminProfile)} />
                  <PrivateRoute exact path="/upcoming-activity" component={Admin(AdminUpcomingActivity)} />
                  <PrivateRoute exact path="/admin-history" component={Admin(AdminActivityHistory)} />
                  <PrivateRoute exact path="/admin-calendar" component={Admin(AdminCalendar)} />
                  <PrivateRoute exact path="/attendance/:activityId" component={Admin(MarkAttendance)} />
                  <PrivateRoute exact path="/admin-enrolment" component={Admin(EnrolmentListing)} />
                  <PrivateRoute exact path="/admin-enrolment/:activityId" component={Admin(EnrolmentStudentListing)} />
                  <Route exact path="/summer" component={Summer} />
                  <PrivateRoute exact path="/dashboard" component={Administrator(Dashboard)} />
                  <PrivateRoute exact path="/dashboard/:type" component={Administrator(Dashboard)} />
                  <PrivateRoute exact path="/activity-details/:id" component={Administrator(DashboardActivityDetails)} />
                  <PrivateRoute exact path="/student-details/:id" component={Administrator(DashboardStudentDetails)} />
                  <Route exact path="/ui-screen" component={UiScreen} />
                  {/* <Route exact path="/current-activity/new" component={CurrentActivity} /> */}
                  <PrivateRoute exact path="/student-attendance" component={Student(StudentAttendance)} />
                  <PrivateRoute exact path="/student-attendance/:id" component={Parent(StudentAttendance)} />
                  <PrivateRoute exact path="/parent-profile" component={Parent(ParentProfile)} />
                  <PrivateRoute exact path="/parent-profile/:menuType" component={Parent(ParentProfile)} />
                  <PrivateRoute exact path="/student-transcript/:id" component={StudentParent(StudentTranscript)} />
                  <Route exact path="/machine-detail/:id" component={MachineDetail} />
                  <PrivateRoute exact path="/reward/:id" component={Common(Reward)} />
                  <Route component={Notfound} />
                </Switch>
                <Footer />
              </Router>
            </div>
        }
      </>
    )
  }
}
const mapStateToProps = ({ i18nState }) => {
  return {
    lang: i18nState.lang
  }
}
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Routes)