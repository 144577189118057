import React from "react"
import Main from "../main"
import Slider from "react-slick"
import { Map, Marker, GoogleApiWrapper } from "google-maps-react"
import _ from "lodash"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { PopupAction, LoadingAction } from "./../../actions"
import { connect } from "react-redux"
import dateformat from "dateformat"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom";
const axios = require("axios");
class EventDetails extends Main {
	constructor(props) {
		super(props);
		this.checkLogin();
		this.state = {
			isLoaded: false,
			config: this.config(),
			gallery: []
		}
	}
	componentDidMount() {
		const { id, lang } = this.props;
		var self = this;
		const { config } = this.state;
		let url = ""
		if (lang == "ar")
			url = `${config.origin}${lang}/api/${urlEndPoints.activityDetailsGallery(id)}`
		else
			url = `${config.origin}api/${urlEndPoints.activityDetailsGallery(id)}`

		axios.get(url, {
			params: {
				_format: "json"
			}
		})
			.then(function (response) {
				self.props.hideLoader()
				self.setState({ gallery: response.data["media-gallery"] });
			}).catch(err => {
				self.props.hideLoader()
				console.warn("Error", err)
			});

	}
	componentDidUpdate() {
		setTimeout(() => {
			let elem = document.querySelectorAll('.machines-scroll-item');
			if (elem)
				elem.forEach(element => {
					element.setAttribute("tabindex", "0");
				})
			elem = document.querySelectorAll('.equipments-scroll-item');
			if (elem)
				elem.forEach(element => {
					element.setAttribute("tabindex", "0");
				});
		}, 500);
	}
	handleEnroll = async (id) => {
		// if (_.isEmpty(localStorage.getItem("role"))) {
		// 	return this.props.history.push("/registration")
		// }
		const role = await localStorage.getItem("role");
		switch (role) {
			case "student":
				this.props.showLoader();
				this.handleEnrollStudent(id)
				break;
			case "school":
			case "parent":
				this.props.showLoader();
				this.handleEnrollParent(id)
				break;

			default:
				this.props.showPopup({
					type: "error",
					title: "",
					// message: this.context.t("_login_required"),
					message: <>
						<Link onClick={() => { this.props.hidePopup({}) }} to="/login"><b>{this.context.t("_login_link")}</b></Link> {this.context.t("_is_required_for_enrolment")}
					</>,
					isOpen: true,
					buttonText: this.context.t("_ok")
				})
				break;
		}
	}
	displayError = error => {
		const { lang } = this.props;
		if (error.name) {
			this.props.showPopup({
				type: "error",
				title: this.context.t("_failed"),
				message: error.name[lang],
				isOpen: true,
				buttonText: this.context.t("_ok")
			})
		}
	}
	handleEnrollStudent = async (id) => {
		// const authResponse = await this.auth();
		// if (authResponse) {
		const { config } = this.state
		const url = `${config.api_url}${urlEndPoints.activityEnrolment}`
		// const headers = {
		// 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
		// }
		const obj = {
			"activity_id": id
		}
		try {
			const data = await this.networkRequest(url, "POST", obj)
			this.props.hideLoader();
			if (data.data.error) {
				this.displayError(data.data.error)
			} else {
				this.props.showPopup({
					type: "success",
					title: this.context.t("_success"),
					message: this.context.t("_enroll_success"),
					isOpen: true,
					buttonText: this.context.t("_ok")
				})
			}
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
		// }

	}
	handleEnrollParent = (id) => {
		this.props.history.push(`/subscribe-activities/${id}`);
	}
	handleOpenMap = (mapUrl) => {
		window.open(mapUrl);
	}
	handleEnrolledClick = () => {
		this.props.showPopup({
			type: "error",
			title: this.context.t("_warning"),
			message: this.context.t("_already_enrolled"),
			isOpen: true,
			buttonText: this.context.t("_ok")
		})
	}
	getMapEmbeddedUrl = url => {
		var coords = /\@([0-9\.\,\-a-zA-Z]*)/.exec(url);
		if (coords != null) {
			var coordsArray = coords[1].split(",");
			return "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d20000!2d" + coordsArray[1] + "!3d" + coordsArray[0] + "!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2suk!4v1486486434098";
		}
	}
	getGender = gender => {
		gender = gender.toLowerCase()
		switch (gender) {
			case "male":
				return this.context.t("_boys")
			case "female":
				return this.context.t("_girls")
			case "mixed":
				return this.context.t("_mixed")

		}
	}
	getDateInFormat = date => {
		if (date.includes("Jan"))
			date = date.replace("Jan", this.context.t("_jan"))

		if (date.includes("Feb"))
			date = date.replace("Feb", this.context.t("_feb"))

		if (date.includes("Mar"))
			date = date.replace("Mar", this.context.t("_mar"))

		if (date.includes("Apr"))
			date = date.replace("Apr", this.context.t("_apr"))

		if (date.includes("May"))
			date = date.replace("May", this.context.t("_may"))

		if (date.includes("Jun"))
			date = date.replace("Jun", this.context.t("_jun"))

		if (date.includes("Jul"))
			date = date.replace("Jul", this.context.t("_jul"))

		if (date.includes("Aug"))
			date = date.replace("Aug", this.context.t("_aug"))

		if (date.includes("Sep"))
			date = date.replace("Sep", this.context.t("_sep"))

		if (date.includes("Oct"))
			date = date.replace("Oct", this.context.t("_oct"))

		if (date.includes("Nov"))
			date = date.replace("Nov", this.context.t("_nov"))

		if (date.includes("Dec"))
			date = date.replace("Dec", this.context.t("_dec"))

		return date
	}
	getTimeInFormat = time => {
		if (time.includes("am"))
			time = time.replace(/am/g, this.context.t("_am"))

		if (time.includes("pm"))
			time = time.replace(/pm/g, this.context.t("_pm"))

		return time;
	}
	handleEnrolButton = (item, id) => {
		if (Number(item.field_number_of_attendees) > Number(item.enrolmentCount))
			return <button onClick={() => { this.handleEnroll(id) }} className="btn blue-btn ">{this.context.t("_enroll")}</button>
		else if (Number(item.enrolmentCount) < (Number(item.field_number_of_attendees) + Number(item.field_waiting_list_count)))
			return <button onClick={() => { this.handleEnroll(id) }} className="btn blue-btn ">{this.context.t("_waiting_list")}</button>
		else
			return <button onClick={() => { this.handleEnrollClosed() }} className="btn blueColor btn-disabled">
				{this.context.t("_closed")}
			</button>
	}
	render() {
		const { result, id, isEnrolled } = this.props;
		const { gallery, config } = this.state;
		const slick_settings = {
			className: "slider news-slider",
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1
		};
		const slick_equipments = {
			speed: 500,
			slidesToShow: 2.65,
			infinite: false,
			slidesToScroll: 1,
			afterChange: this.afterChangeHandler,
			arrows: true,
			responsive: [
				{
					breakpoint: 1770,
					settings: {
						slidesToShow: 2.4,
					}
				},
				{
					breakpoint: 1600,
					settings: {
						slidesToShow: 2.2,
					}
				},
				{
					breakpoint: 1470,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 1330,
					settings: {
						slidesToShow: 1,
						arrows: true
					}
				}
			]
		};
		const slick_machines = {
			speed: 500,
			slidesToShow: 4.5,
			infinite: false,
			slidesToScroll: 1,
			afterChange: this.afterChangeHandler,
			arrows: true,
			responsive: [
				{
					breakpoint: 1700,
					settings: {
						slidesToShow: 4,
					}
				},
				{
					breakpoint: 1500,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
						arrows: true
					}
				}
			]
		};
		return (
			<div className="event-details event-detail">
				<div className="container">
					<div className="flex-row">
						<h2>{result.eventname}</h2>
						{
							(
								localStorage.getItem("role") == "trainer" ||
								localStorage.getItem("role") == "manager" ||
								localStorage.getItem("role") == "asistant" ||
								localStorage.getItem("role") == "edu_specialist" ||
								localStorage.getItem("role") == "administrator"
							) ? null :
								result.field_need_to_show_enroll_button == "1" && (!_.isEmpty(result.field_start_and_end_time_3) && !_.isEmpty(result.field_start_and_end_time_4)) ? (new Date(result.field_start_and_end_time_2) >= new Date()) ?
									isEnrolled ? <button onClick={() => { this.handleEnrolledClick() }} className="btn blue-btn btn-disabled btn-enrolled">{this.context.t("_enrolled")}</button> : this.handleEnrolButton(result, id)
									: <button onClick={() => { this.handleEnrollClosed() }} className="btn blueColor btn-disabled">
										{this.context.t("_closed")}
									</button>
									: null
						}
					</div>
				</div>
				<div className="">
					{
						!_.isEmpty(gallery) &&

						<div className="article-news">
							<Slider {...slick_settings}>
								{gallery.map((slider, slide_index) =>
									<div key={slide_index} className="item" style={{ padding: 0 }}>
										<div className="sliderHeightNew1">
											<img src={slider} alt="" />
										</div>
										{/* <p><strong>Image caption </strong>{slider.caption}</p> */}
									</div>
								)}

							</Slider>
							{/* <div dangerouslySetInnerHTML={{ __html: row.media }} className="img-wrap"></div> */}
						</div>
						// <Slider {...slick_settings}>
						// 	{gallery.map((row, index) => (
						// 		<div className="item" key={index}>
						// 			<img src={row} alt="" />
						// 		</div>
						// 		<div className="sliderHeight" style={{ backgroundImage: `url(${slider.image})` }}>
						// 		<img src={slider.image} alt="" />
						// 	</div>
						// 	))}
						// </Slider>
					}
					<div className="container">
						<div className="description" dangerouslySetInnerHTML={{ __html: result.details }}></div>
						{
							!_.isEmpty(result.field_activity_objective) && <>
								<br />
								<h3 style={{ color: "#4f59a8", fontSize: 16 }}>{this.context.t("_objective")}</h3>
								<div className="description" dangerouslySetInnerHTML={{ __html: result.field_activity_objective }}></div>
							</>
						}
					</div>
					{/* <div className="col s6 gallery-wrap">
						{
							!_.isEmpty(gallery) &&
							<Slider {...slick_settings}>
								{gallery.map((row, index) => (
									<div className="item" key={index}>
										<img src={row} alt="" />
									</div>
								))}
							</Slider>
						}
					</div> */}
				</div>
				<div className="container">
					{
						result.machines && result.machines.length > 0 && <>
							<h2 className="header-two">{this.context.t("_machines_used")}</h2>
							<div className="machines-scroll-item-holder">
								<Slider {...slick_machines}>
									{
										result.machines.map(item => {
											return (
												<Link tabIndex="0" aria-label={`machine ${item.title}`} to={`/machine-detail/${item.id}`} className="machines-scroll-item">
													<figure className="machines-scroll-item-figure">
														<img src={item.image} alt="" />
													</figure>
													<h4>{item.title}</h4>
													{
														!_.isEmpty(item.field_sub_title) && <p>{item.field_sub_title}</p>
													}

												</Link>
											)
										})
									}



								</Slider>
							</div>
						</>
					}
					{
						result.equipment && result.equipment.length > 0 && <>
							<h2 className="header-two">{this.context.t("_equipments_used")}</h2>

							<div className="equipments-scroll-item-holder">
								<Slider {...slick_equipments}>
									{
										result.equipment.map(item => {
											return (
												item.field_is_there_an_update_model_ == 1 ? <a tabIndex="0" aria-label={`equipment ${item.title}`} target="_black" href={item.field_updated_model_website} className="equipments-scroll-item" key={item.id}>
													<div className="equipments-scroll-item-inner">
														<figure className="equipments-scroll-item-figure">
															<img src={item.image} alt="" />
														</figure>
														<div className="equipments-scroll-item-content">
															<p>{item.field_sub_title} <span>{item.field_equipment_type}</span></p>
															<h4>{item.title}</h4>
														</div>
													</div>
												</a> : <div key={item.id} className="equipments-scroll-item" tabIndex="0" aria-label={`equipment ${item.title}`}				>
													<div className="equipments-scroll-item-inner">
														<figure className="equipments-scroll-item-figure">
															<img src={item.image} alt="" />
														</figure>
														<div className="equipments-scroll-item-content">
															<p>{item.field_sub_title} <span>{item.field_equipment_type}</span></p>
															<h4>{item.title}</h4>
														</div>
													</div>
												</div>
											)
										})
									}
								</Slider>
							</div>
						</>
					}

					{
						result.software && result.software.length > 0 && <>
							<h2 className="header-two">{this.context.t("_software_used")}</h2>

							<div className="equipments-scroll-item-holder">
								<Slider {...slick_equipments}>
									{
										result.software.map(item => {
											return (<div key={item.id} className="equipments-scroll-item" tabIndex="0" aria-label={`equipment ${item.title}`}				>
												<div className="equipments-scroll-item-inner">
													<figure className="equipments-scroll-item-figure">
														<img src={item.image} alt="" />
													</figure>
													<div className="equipments-scroll-item-content">
														<p>{item.field_sub_title} <span>{item.field_equipment_type}</span></p>
														<h4>{item.title}</h4>
													</div>
												</div>
											</div>
											)
										})
									}
								</Slider>
							</div>
						</>
					}
					{
						result.skills && result.skills.length > 0 && <>
							<h2 className="header-two">{this.context.t("_skills_used")}</h2>

							<div className="equipments-scroll-item-holder">
								<Slider {...slick_equipments}>
									{
										result.skills.map(item => {
											return (<div key={item.id} className="equipments-scroll-item" tabIndex="0" aria-label={`equipment ${item.title}`}				>
												<div className="equipments-scroll-item-inner">
													<figure className="equipments-scroll-item-figure">
														<img src={item.image} alt="" />
													</figure>
													<div className="equipments-scroll-item-content">
														<p>{item.field_sub_title} <span>{item.field_equipment_type}</span></p>
														<h4>{item.title}</h4>
													</div>
												</div>
											</div>
											)
										})
									}
								</Slider>
							</div>
						</>
					}

					<div className="row  details-wrap noFlex">
						<h3>{this.context.t("_details")}</h3>
						<div className="col s6 main-details">
							<table>
								<tbody>
									<tr>
										<th>{this.context.t("_date")}</th>
										<td className="activityDetailDateRow p0">
											<table>
												<tbody>
													<tr>
														<th>{this.context.t("_start_date")}</th>
														<th>{this.context.t("_end_date")}</th>
													</tr>
													{
														result.field_start_and_end_time_3?.map((item, index) => {
															return (
																<tr key={index}>
																	<td>{`${this.getDateInFormat(item)} - ${this.getTimeInFormat(result.field_start_and_end_time[index])}`}</td>
																	<td>{`${this.getDateInFormat(result.field_start_and_end_time_4[index])} - ${this.getTimeInFormat(result.field_start_and_end_time_1[index])}`}</td>
																</tr>
															)
														})
													}
												</tbody>
											</table>
										</td>
									</tr>
									{/* <tr>
									<th>{this.context.t("_start_date")}</th>
									<td>{_.isEmpty(result.field_start_and_end_time_3) ? this.context.t("_start_date_unavailable") : `${this.getDateInFormat(result.field_start_and_end_time_3)} - ${this.getTimeInFormat(result.field_start_and_end_time)}`}</td>
								</tr>
								<tr>
									<th>{this.context.t("_end_date")}</th>
									<td>{_.isEmpty(result.field_start_and_end_time_4) ? this.context.t("_end_date_unavailable") : `${this.getDateInFormat(result.field_start_and_end_time_4)} - ${this.getTimeInFormat(result.field_start_and_end_time_1)}`}</td>
								</tr> */}
									<tr>
										<th>{this.context.t("_zone_address")}</th>
										<td dangerouslySetInnerHTML={{ __html: `${_.isEmpty(result.field_activity_address) ? this.context.t("_address_unavailable") : result.field_activity_address}` }}></td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className="col s6 main-details">
							<table>
								<tbody>
									<tr>
										<th>{this.context.t("_age_group")}</th>
										<td>{_.isEmpty(result.age_group) ? this.context.t("_age_group_unavailable") : result.age_group}</td>
									</tr>
									<tr>
										<th>{this.context.t("_gender")}</th>
										<td>{_.isEmpty(result.gender) ? this.context.t("_gender_unavailable") : this.getGender(result.gender)}</td>
									</tr>
									<tr>
										<th>{this.context.t("_no_of_attendee")}</th>
										<td>{_.isEmpty(result.attendees) ? this.context.t("_no_of_attendee_unavailable") : result.attendees}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className="col s12 main-details">
							{
								!_.isEmpty(result.field_google_map) &&
								<>
									<br />
									<h3>{this.context.t("_location")}</h3>
									<div className="google-map">
										<iframe title="map" className={"map"} src={this.getMapEmbeddedUrl(result.field_google_map)} frameborder="0" allowfullscreen=""  ></iframe>

										<br />
										<button onClick={() => this.handleOpenMap(result.field_google_map)} className="btn profileBtn blue-border">{this.context.t("_location_view")}</button>
									</div>
								</>
							}
						</div>
					</div>
				</div>
			</div>
		)
	}
}
var main_obj = new Main();


const mapStateToProps = ({ i18nState }) => {
	return {
		lang: i18nState.lang
	}
}
const mapDispatchToProps = {
	showPopup: (data) => PopupAction.showPopup(data),
	hidePopup: (data) => PopupAction.hidePopup(data),
	showLoader: LoadingAction.showLoader,
	hideLoader: LoadingAction.hideLoader,
}
EventDetails.contextTypes = {
	t: PropTypes.func
}

export default GoogleApiWrapper({
	apiKey: main_obj.config().map_api_key
})(connect(mapStateToProps, mapDispatchToProps)(withRouter(EventDetails)));