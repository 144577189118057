import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle } from "react"
import Main from "../../main"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import _ from "lodash"
// import { PopupAction, LoadingAction, StudentCountAction } from "./../../../actions"
import ProgressBar from "@ramonak/react-progress-bar";

const Citizen = (props, context) => {
    const [activeType, setActiveType] = useState("full")
    const { enrollment_count, waitinglist_count, attendance_count, full = {}, type, zone, fieldType, resetFlag } = props;

    useEffect(() => {
        if (activeType == "full" && (!_.isEmpty(type) || !_.isEmpty(zone) || !_.isEmpty(fieldType))) {
            setActiveType("enrolled")
        }
    }, [type, zone, fieldType])

    useEffect(() => {
        setActiveType("full")
    }, [resetFlag])

    return (
        <div className="dashboardBox">
            <div className="dashboardBox-header">
                <h2>{context.t("_qatar_citizens")}</h2>
                <div className="input-field mobileSelect dashboardBox-dropdown">
                    <select className="browser-default" value={activeType} onChange={(value => setActiveType(value.target.value))}>
                        {
                            _.isEmpty(type) && _.isEmpty(zone) && _.isEmpty(fieldType) && <option value="full" >{context.t("_all_students")}</option>
                        }

                        <option value="enrolled" >{context.t("_students_enrolled")}</option>
                        <option value="attendence" >{context.t("_students_attended")}</option>
                        <option value="waitinglist" >{context.t("_students_waiting_list")}</option>
                    </select>
                </div>
            </div>
            <div className="dashbox">
                <div className="box-val">
                    <h5>{`${props[activeType].qatari_percentage.toFixed(2)} %`}</h5>
                    <h6>{`${props[activeType].qatari_count}/${props[activeType].total_students}`}</h6>
                </div>
                <div className="progressBar">
                    <ProgressBar
                        completed={props[activeType].qatari_percentage}
                        bgcolor="#EE3645"
                        height="7px"
                        baseBgColor="#E6E6E6"
                        isLabelVisible={false}
                        transitionDuration="1s"
                        transitionTimingFunction="linear"
                    />
                </div>

            </div>

        </div>
    )
}

Citizen.defaultProps = {
    enrollment_count: 0,
    waitinglist_count: 0,
    attendance_count: 0,
    full: {
        female_count: 0,
        female_percentage: 0,
        male_count: 0,
        male_percentage: 0,
        qatari_count: 0,
        qatari_percentage: 0,
        total_students: 0
    },
    enrolled: {
        female_count: 0,
        female_percentage: 0,
        male_count: 0,
        male_percentage: 0,
        qatari_count: 0,
        qatari_percentage: 0,
        total_students: 0
    }
}
const mapStateToProps = ({ i18nState, studentCountReducer }) => {
    return {
        lang: i18nState.lang,
        // studentsEnrolledCount: studentCountReducer.studentsEnrolledCount,
        // studentsWaitingListCount: studentCountReducer.studentsWaitingListCount,
        // studentsAttendedCount: studentCountReducer.studentsAttendedCount,
    }
}
const mapDispatchToProps = {
    // showPopup: (data) => PopupAction.showPopup(data),
    // hidePopup: (data) => PopupAction.hidePopup(data),
    // getStudentsEnrolled: StudentCountAction.getStudentsEnrolled,
    // getStudentsWaitingList: StudentCountAction.getStudentsWaitingList,
    // getStudentsAttended: StudentCountAction.getStudentsAttended,
    // showLoader: LoadingAction.showLoader,
    // hideLoader: LoadingAction.hideLoader,
}
Citizen.contextTypes = {
    t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(Citizen);