import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types"
import { connect } from "react-redux"
import M from "materialize-css"
import _ from "lodash"
import Modal from "react-modal";

import Main from "./../../../main"
import { urlEndPoints } from "./../../../../httpAPI/apiConfig"
import { LoadingAction, PopupAction } from "./../../../../actions"

import { getGender, handleGradeName } from "./../../../dashboard/pages/utilFunctions"
import { customModalStyles } from "./../../../../constants/dynamicStyles"


class ParentCurrentActivity extends Main {
    constructor(props) {
        super(props)
        this.state = {
            config: this.config(),
            search: {},
            currentActivityList: [],
            studentList: [],
            selectedActivityId: null,
            checkedList: {},
            cancelEnrolConfirm: false
        }
    }
    componentDidMount() {
        M.AutoInit()
        this.props.showLoader()
        this.getActivity()
        setTimeout(() => {
            let elems = document.querySelectorAll(".modal");
            M.Modal.init(elems, {
                autoClose: true,
                dismissible: true
            });

        }, 200);

    }
    getActivity = async (isModal) => {
        const { config, search } = this.state;
        const { lang } = this.props;
        const { match: { params: { menuType } } } = this.props;

        // const authResponse = await this.auth();
        // if (authResponse) {
        let url = `${config.origin}${urlEndPoints.parentCurrentActivity}`

        const body = {
            "paged": 1,
            "lang": lang,
            "is_home": 1
        }

        try {
            const data = await this.networkRequest(url, "POST", body)
            if (data.status == 200) {
                this.props.hideLoader()
                const currentActivityList = data.data.results;
                currentActivityList.forEach(async item => {
                    item.field_start_and_end_time_3 = item.field_start_and_end_time_3.split("*")
                    item.field_start_and_end_time_4 = item.field_start_and_end_time_4.split("*")
                    item.field_start_and_end_time = item.field_start_and_end_time.split("*")
                    item.field_start_and_end_time_1 = item.field_start_and_end_time_1.split("*")
                })

                this.setState({
                    currentActivityList
                })
                if (menuType && !isModal)
                    this.refs.currentActivityHeading.focus()
            }
        } catch (error) {
            this.setState({ isLoaded: true })
            this.props.hideLoader()
            console.warn("error: ", error)
        }
        // }
    }

    getDateInFormat = date => {
        // if (!_.isEmpty(date)) {
        if (date.includes("Jan"))
            date = date.replace("Jan", this.context.t("_jan"))

        if (date.includes("Feb"))
            date = date.replace("Feb", this.context.t("_feb"))

        if (date.includes("Mar"))
            date = date.replace("Mar", this.context.t("_mar"))

        if (date.includes("Apr"))
            date = date.replace("Apr", this.context.t("_apr"))

        if (date.includes("May"))
            date = date.replace("May", this.context.t("_may"))

        if (date.includes("Jun"))
            date = date.replace("Jun", this.context.t("_jun"))

        if (date.includes("Jul"))
            date = date.replace("Jul", this.context.t("_jul"))

        if (date.includes("Aug"))
            date = date.replace("Aug", this.context.t("_aug"))

        if (date.includes("Sep"))
            date = date.replace("Sep", this.context.t("_sep"))

        if (date.includes("Oct"))
            date = date.replace("Oct", this.context.t("_oct"))

        if (date.includes("Nov"))
            date = date.replace("Nov", this.context.t("_nov"))

        if (date.includes("Dec"))
            date = date.replace("Dec", this.context.t("_dec"))
        // }
        return date
    }
    handleGetStudentList = async id => {
        this.props.showLoader()
        const { config } = this.state
        const url = `${config.api_url}${urlEndPoints.enrolledUsers(id)}`

        try {
            const data = await this.networkRequest(url)
            this.props.hideLoader();
            if (!data.data.message) {
                const studentList = _.orderBy(data.data.results, ["field_student_name"], ["asc"])
                this.setState({ studentList, selectedActivityId: id, checkedList: {} })

                let elems = document.querySelector("#enrollments");
                elems.setAttribute("aria-hidden", "false")
                elems.setAttribute("tabindex", "-1")
                let instance = M.Modal.getInstance(elems);
                instance.open();
            }
        } catch (error) {
            this.props.hideLoader()
            console.warn("error: ", error)
        }
    }
    handleCloseModal = () => {
        let elems = document.querySelector("#enrollments");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        let instance = M.Modal.getInstance(elems);
        instance.close();
    }
    calculateAge = (dateString) => {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
    showStatus = status => {
        switch (status) {
            case "Unpublished":
                return this.context.t("_pending");
            case "Waiting List":
                return this.context.t("_waiting_list");

            default:
                return status;
        }
    }
    showCategory = category => {
        switch (category) {
            case "Public Activities":
                return this.context.t("_public_activity");
            case "School Activities":
                return this.context.t("_school_activity");

            default:
                return category;
        }
    }

    handleCheck = (event, id, index) => {
        const { checkedList } = this.state
        if (event.target.checked)
            checkedList[index] = id
        else
            delete checkedList[index]
        this.setState({ checkedList })
    }
    handleCancelMultipleActivityConfirm = () => {
        this.setState({ cancelEnrolConfirm: true })
        this.handleCloseModal()
    }
    handleCancelMultipleActivity = async () => {
        this.props.showLoader();
        const { config, checkedList, selectedActivityId } = this.state;
        const { lang } = this.props;
        if (!_.isEmpty(checkedList)) {
            // const authResponse = await this.auth();
            // if (authResponse) {
            const url = `${config.api_url}${urlEndPoints.cancelActivity}`
            const obj = {
                "activity_id": selectedActivityId,
                user_ids: checkedList
            }
            // const headers = {
            // 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
            // }
            try {
                const data = await this.networkRequest(url, "POST", obj);
                if (data.status == 200) {
                    if (!_.isEmpty(data.data.error)) {
                        this.props.showPopup({
                            type: "error",
                            title: this.context.t("_error"),
                            message: data.data.error.name[lang],
                            isOpen: true,
                            buttonText: this.context.t("_ok")
                        })
                    } else {
                        this.props.showPopup({
                            type: "success",
                            // title: this.context.t("_success"),
                            message: this.context.t("_successfully_cancelled"),
                            isOpen: true,
                            buttonText: this.context.t("_ok")
                        })
                    }
                    this.handleCloseModal();
                    this.getActivity(true)
                }
            } catch (error) {
                this.props.showPopup({
                    type: "error",
                    title: this.context.t("_failed"),
                    message: this.context.t("_failed_to_cancel"),
                    isOpen: true,
                    buttonText: this.context.t("_ok")
                })
                this.props.hideLoader()
                console.warn("error: ", error)
            }
            // }
        } else {
            this.handleCloseModal();
            this.getActivity()
        }

    }
    render() {
        const { currentActivityList, config, studentList, checkedList, cancelEnrolConfirm } = this.state
        const { lang } = this.props
        return (

            <div className="attendance-holder">

                <div className="attendance-heading">
                    <h2 ref="currentActivityHeading" tabIndex="0" className="line">{this.context.t("_current_activities")}</h2>
                    {
                        currentActivityList.length > 0 && <aside>
                            <Link to="/current-activities" type="submit" className="btn profileBtn yellow" aria-label={`${this.context.t("_view_all")} ${this.context.t("_current_activities")}`}>{this.context.t("_view_all")} <i className={`material-icons ${lang}`}>{`${lang === "en" ? "arrow_forward" : "arrow_backward"}`}</i></Link>
                        </aside>
                    }

                </div>
                {
                    currentActivityList.map((row, index) => {
                        return (
                            <div className="attendance-item attendance-item__two" key={row.nid}>
                                <figure>
                                    <img src={`${config.origin}${row.field_activity_thumbnail}`} alt={`${row.title_1} image`} />
                                </figure>

                                <aside>
                                    <h4 tabindex="0" aria-label={`${row.title_1} in ${row.field_activity_category} ${this.context.t("_start_date")} ${this.getDateInFormat(row.field_start_and_end_time_3[0])} ${this.context.t("_end_date")} ${this.getDateInFormat(row.field_start_and_end_time_4[row.field_start_and_end_time_4.length - 1])}`} className={row.field_activity_category == "Public Activities" ? "pinkalert" : ""}>
                                        <i className="topHeadNA"></i>{row.title_1}
                                        <span>{this.showCategory(row.field_activity_category)}</span>
                                    </h4>
                                    <div className="details"><span><i className="calendar-icons"></i> {this.context.t("_start_date")} <span className="date">{this.getDateInFormat(row.field_start_and_end_time_3[0])}</span></span><span><i className="calendar-icons"></i> {this.context.t("_end_date")} <span className="date">{this.getDateInFormat(row.field_start_and_end_time_4[row.field_start_and_end_time_4.length - 1])}</span></span></div>
                                </aside>

                                <div className="attendance-button-holder">



                                    <button onClick={() => this.handleGetStudentList(row.nid)} type="submit" className="btn  blue" aria-label={`${row.title_1} ${this.context.t("_enrollments")}`}>{this.context.t("_enrollments")}</button>
                                </div>
                            </div>
                        )
                    })
                }
                {
                    currentActivityList.length == 0 && <div style={{ textAlign: "center" }}>

                        <p >{this.context.t("_explore_activity")}</p>
                        <Link className="btn profileBtn" to="/activities">{this.context.t("_explore_activity_1")}</Link>
                    </div>
                }

                <div id="enrollments" className="modal user-add-modal" role="dialog">
                    <div className="modal-content modal-no-close">
                        {/* <form onSubmit={this.handleAddNewStudent} className="col full-width"> */}
                        <span className="badge modalClose"><button className="close" aria-label="close" onClick={() => this.handleCloseModal()}><i className="material-icons">close</i></button></span>
                        <h2 className="line">{this.context.t("_enrollments")}</h2>
                        <div className="tableHolder">

                            <table className="responsive-table highlight">
                                <thead className="blue lighten-5">
                                    <tr>
                                        <th><strong>{this.context.t("_name")}</strong></th>
                                        <th><strong>{this.context.t("_qid")}</strong></th>
                                        <th><strong>{this.context.t("_phone_number")}</strong></th>
                                        <th><strong>{this.context.t("_grade")}</strong></th>
                                        <th><strong>{this.context.t("_gender")}</strong></th>
                                        <th><strong>{this.context.t("_age")}</strong></th>
                                        <th width="50"><strong>{this.context.t("_enroll")}</strong></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        studentList.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.field_student_name}</td>
                                                    <td>{item.field_qid}</td>
                                                    <td>{item.field_student_mobile}</td>
                                                    <td>{handleGradeName(item.field_grade, this.props.lang)}</td>
                                                    <td>{getGender(item.field_gender, this.context)}</td>
                                                    <td>{!_.isEmpty(item.field_student_dob) ? this.calculateAge(item.field_student_dob) : null}</td>
                                                    <td>
                                                        {
                                                            item.field_published_status == "Published" ? <p className="checkboxHolder checkboxNew"><input onChange={(e) => this.handleCheck(e, item.uid, index)} aria-label={`${item.field_student_name} ${this.context.t("_selected_for_remove_enrollment")}`} checked={checkedList[index] ? true : false} type="checkbox" /><label><span></span></label></p> : this.showStatus(item.field_published_status)
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <button disabled={_.isEmpty(checkedList)} onClick={() => { this.handleCancelMultipleActivityConfirm(); }} className="btn blue-border">{this.context.t("_delete")}</button>
                            <button onClick={() => { this.handleCloseModal(); }} className="btn noWidth blue-btn waves-effect waves-light">{this.context.t("_close")}</button>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={cancelEnrolConfirm}
                    style={customModalStyles}
                    contentLabel="unlink parent confirm modal"
                    ariaHideApp={false}
                >
                    <div className="popupAlert warning">
                        <br />
                        <h3>{this.context.t("_confirm")}</h3>
                        <p>{this.context.t("_enroll_remove_message")}</p>
                        <div className="row">
                            <button className="col btn blue" onClick={() => this.setState({ cancelEnrolConfirm: false })}>{this.context.t("_cancel")}</button>
                            <button className="col btn profileBtn yellow" onClick={() => { this.handleCancelMultipleActivity(); this.setState({ cancelEnrolConfirm: false }); }}>{this.context.t("_confirm")}</button>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}


const mapStateToProps = ({ i18nState }) => {
    return {
        lang: i18nState.lang
    }
}
const mapDispatchToProps = {
    showLoader: LoadingAction.showLoader,
    hideLoader: LoadingAction.hideLoader,
    showPopup: (data) => PopupAction.showPopup(data),
    hidePopup: (data) => PopupAction.hidePopup(data),
}
ParentCurrentActivity.contextTypes = {
    t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(ParentCurrentActivity);
