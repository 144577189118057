import React from "react"
import Main from "../main"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { dynamicStyles } from "./../../constants/dynamicStyles"
const axios = require("axios");
class Banner extends Main {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			config: this.config(),
			banner: [],
			activeClass: "",
			playing: true
		}
	}
	componentDidMount() {
		this.get_banner();
		window.addEventListener("scroll", () => {
			let activeClass = "stickySocial";
			if (window.scrollY === 0) {
				activeClass = "";
			}
			this.setState({ activeClass })
		});
	}
	get_banner() {
		const { config } = this.state;
		const { lang } = this.props;
		let url = "";
		if (lang == "ar")
			url = `${config.origin}${lang}/api/banner`
		else
			url = `${config.origin}api/banner`

		var self = this;
		axios.get(url, {
			params: {
				_format: "json"
			}
		})
			.then(function (response) {
				self.setState({
					banner: response.data
				});
				setTimeout(() => {
					const bannerButton = document.querySelector(".banner-contact");
					if (bannerButton && bannerButton.addEventListener) {
						bannerButton.addEventListener('focusin', () => {
							const bannerElement = document.querySelectorAll(".banner-contact");
							bannerElement[0].classList.add('hover');
						});
						bannerButton.addEventListener('focusout', () => {
							const bannerElement = document.querySelectorAll(".banner-contact");
							bannerElement[0].classList.remove('hover');
						});
					}
				}, 1000);
			})
			.catch(function (error) {
				console.warn(error);
			});
	}
	handlePlayClick = () => {
		this.setState({ playing: !this.state.playing }, () => {
			!this.state.playing ? this.refs.vidRef.pause() : this.refs.vidRef.play()
		})

	}
	render() {
		const { banner, config, activeClass, playing } = this.state;
		const { lang } = this.props;
		return (
			<div className={`app-slider ${activeClass}`}>
				<div className="video-box">
					<div className="video-btn">
						<button onClick={() => this.handlePlayClick()} className={playing ? "btn-toggle play" : "btn-toggle pause"} aria-label={playing ? this.context.t("_pause_video") : this.context.t("_play_video")}>{playing ? this.context.t("_pause") : this.context.t("_play")}</button>
					</div>
					{
						banner.length > 0 && <video ref="vidRef" autoPlay playsInline muted loop style={{ backgroundImage: `url(${config.origin + banner[0].field_media})` }}>
							{banner.map((row, index) => {
								return (<source key={index} src={config.origin + row.video.replace("Video file<br />", "").trim()} />)
							}
							)}

						</video>
					}

				</div>
				<div className="banner">
					{banner.map((row, index) => (
						<div key={index}>
							<div className={`desc ${lang}`} dangerouslySetInnerHTML={{ __html: row.body }}></div>
						</div>
					))}
				</div>
				{/* <div className="banner-text-wrapper">
					<div className={`banner-text ${lang}`}>
						<h1>{this.context.t("_studio5")}</h1>
						<h3><span className="red-text">{this.context.t("_imagination")}</span>{this.context.t("_,")} <span className="blue-text">{this.context.t("_innovate")}{this.context.t("_,")} <b>{this.context.t("_and")}</b> <span className="orange-text">{this.context.t("_creativity")}</span></span></h3>
						{
							lang == "ar" ?
								<p>{this.context.t("_banner_heading_1")}<b>{this.context.t("_studio5")} </b>{this.context.t("_banner_heading_3")}</p>
								:
								<p><b>{this.context.t("_studio5")} </b>{this.context.t("_banner_heading_1")}<sup>{this.context.t("_banner_heading_2")}</sup>{this.context.t("_banner_heading_3")}</p>
						}
						<p><b>{this.context.t("_studio5")} </b>{this.context.t("_banner_heading_1")}<sup>{this.context.t("_banner_heading_2")}</sup>{this.context.t("_banner_heading_3")}</p>
					</div>
				</div> */}
			</div>
		)
	}
}

const mapStateToProps = ({ i18nState }) => {
	return {
		lang: i18nState.lang
	}
}
const mapDispatchToProps = dispatch => {
	return {
		dispatch
	}
}
Banner.contextTypes = {
	t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(Banner);
