import React, { Component } from "react"

export default class Summer extends Component {
    componentDidMount(){
        this.props.history.push("/news/3137")
    }
    render() {
        return (
            <div>
                
            </div>
        )
    }
}
