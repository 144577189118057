import React, { Fragment } from "react";
import Pagination from "react-js-pagination";
import Main from "./../main"
import PageTitle from "../includes/page_title";
import SlideToTop from "../includes/slide_to_top";
import M from "materialize-css"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import _ from "lodash"

import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { LoadingAction } from "./../../actions"

class Machines extends Main {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            config: this.config(),
            pager: { count: 0, pages: 0, items_per_page: 0, current_page: 1 },
            machineList: []
        }
        this.props.showLoader();
    }
    async componentDidMount() {
        document.title = `${this.context.t("_studio5")} | ${this.context.t("_machines")}`
        M.AutoInit();
        await this.getMachineList();
        setTimeout(() => {
            var elems = document.querySelectorAll(".machineModal");
            M.Modal.init(elems)
            setTimeout(() => {
                document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
            }, 500);
        }, 1000);
    }
    componentDidUpdate() {
        document.title = `${this.context.t("_studio5")} | ${this.context.t("_machines")}`
    }
    getMachineList = async () => {
        const { config, pager } = this.state
        const url = `${config.api_url}${urlEndPoints.getMachinesList}?page=${pager.pages}`
        try {
            const data = await this.networkRequest(url)
            if (data.data.error) {

            } else {
                this.setState({
                    machineList: data.data.results,
                    pager: data.data.pager,
                    isLoaded: true
                })

            }
            this.props.hideLoader()
        } catch (error) {
            this.setState({ isLoaded: true })
            this.props.hideLoader()
            console.warn("error: ", error)
        }
    }
    handlePageChange = (pageNumber) => {
        this.props.showLoader()
        const { pager } = this.state;
        pager.pages = pageNumber - 1;
        this.setState({ pager }, () => this.getMachineList());
    }
    render() {
        const { pager, machineList, config, isLoaded } = this.state;
        return (
            <div id="main-container">
                <SlideToTop />
                <PageTitle title={this.context.t("_machines")} isDashboardItem={true} />
                <div className="container">
                    <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                        <ul>
                            <li className="breadcrumb-item">
                                <Link to="/">{this.context.t("_home")}</Link> {" "}
                            </li>
                            <li className="breadcrumb-item">
                                <Link tabIndex="0" to aria-current={this.context.t("_page")}>
                                    <span>{this.context.t("_machines")}</span>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div id="skipContent" className="container">

                    <h2 className="line">{this.context.t("_machines")}</h2>
                    <div className="machine-item-holder">

                        {isLoaded && machineList.length == 0 ? <div className="row"><p aria-label={this.context.t("_no_result_found")} tabIndex="0" className="col s12 no-result">{this.context.t("_no_result_found")}</p></div> :
                            machineList.map(item => {
                                return (
                                    <div className="machine-item" key={item.nid}>
                                        <figure className="machine-item-figure">
                                            <img src={`${config.origin}${item.field_machines_image_1}`} alt="" />
                                        </figure>
                                        <aside className="machine-item-content">
                                            <Link to={`machine-detail/${item.nid}`}>
                                                <h4>{item.title}</h4>
                                            </Link>
                                            {!_.isEmpty(item.field_machine_brand) && <h5>{item.field_machine_brand}</h5>}

                                            <div dangerouslySetInnerHTML={{ __html: item.field_machines_brief }}></div>

                                            <a href="#" aria-label={`view more ${item.title}`} className="machine-btn">{this.context.t("_view_more")}</a>
                                        </aside>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <nav className="pagination-wrapper" aria-label="pagination">
                        <Pagination
                            prevPageText={`< ${this.context.t("_previous")}`}
                            nextPageText={`${this.context.t("_next")} >`}
                            hideFirstLastPages={true}
                            hideDisabled={true}
                            linkClassPrev="linkClassPrev"
                            linkClassNext="linkClassNext"
                            activePage={pager.current_page + 1}
                            itemsCountPerPage={pager.items_per_page}
                            totalItemsCount={pager.count}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange}
                        />
                    </nav>
                </div>
            </div >
        );
    }
}

const mapStateToProps = ({ i18nState }) => {
    return {
        lang: i18nState.lang
    }
}
const mapDispatchToProps = {
    showLoader: LoadingAction.showLoader,
    hideLoader: LoadingAction.hideLoader,
}
Machines.contextTypes = {
    t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(Machines);
