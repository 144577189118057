import { GET_STUDENTS_ENROLLED, GET_STUDENTS_WAITING_LIST, GET_STUDENTS_ATTENDED } from "./../../constants/constants";

const initialState = {
    studentsEnrolledCount: 0,
    studentsWaitingListCount: 0,
    studentsAttendedCount: 0,
};

const studentCountReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_STUDENTS_ENROLLED:
            return {
                ...state,
                studentsEnrolledCount: action.payload
            };
        case GET_STUDENTS_WAITING_LIST:
            return {
                ...state,
                studentsWaitingListCount: action.payload
            };
        case GET_STUDENTS_ATTENDED:
            return {
                ...state,
                studentsAttendedCount: action.payload
            };

        default:
            return { ...state };
    }
};

export default studentCountReducer;
