import React from "react"
import Main from "../main"
import { connect } from "react-redux"
import PropTypes from "prop-types"
const axios = require("axios");
class Zone extends Main {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			config: this.config(),
			zones: [],
		}
	}
	componentDidMount() {
		this.get_zone();
	}
	get_zone() {
		const { config } = this.state;
		const { lang } = this.props;
		let url = "";
		if (lang == "ar")
			url = `${config.origin}${lang}/api/zones`
		else
			url = `${config.origin}api/zones`

		var self = this;
		axios.get(url, {
			params: {
				_format: "json"
			}
		})
			.then(function (response) {
				self.setState({
					zones: response.data
				});
			})
			.catch(function (error) {
				console.warn(error);
			})
			.then(function () {
				// always executed
			});
	}
	render() {
		const { lang } = this.props;
		const { zones } = this.state;
		return (
			<div className="zone-wrap">
				<div className="container">
					<div className={`zone-text ${lang}`}>
						<h3>{this.context.t("_zone")}</h3>
						<div>{this.context.t("_zone_description")}</div>
					</div>
					<div className={`service-zone ${lang}`}>
						<div className="flex-row col-3 ng-scope">
							{zones.map((row, index) => (
								<div className="item" key={index} >
									<div className="icon"></div>
									<h3 dangerouslySetInnerHTML={{ __html: row.zonenames }}></h3>
									<div className="desc" dangerouslySetInnerHTML={{ __html: row.zonesdescription }}></div>
								</div>
							))}
						</div>
						<span className="grey-square"></span>
					</div>
				</div>
			</div>
		)
	}
}
const mapStateToProps = ({ i18nState }) => {
	return {
		lang: i18nState.lang
	}
}
const mapDispatchToProps = {
}
Zone.contextTypes = {
	t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(Zone);
