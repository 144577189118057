import React from "react"
import Main from "../main"
import SlideToTop from "../includes/slide_to_top.js"
import PageTitle from "../includes/page_title.js"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { dynamicStyles } from "./../../constants/dynamicStyles"
import { Link } from "react-router-dom"
import { emailValidate } from "./../../constants/common"
import _ from "lodash"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { LoadingAction } from "./../../actions"
class ForgotPassword extends Main {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			config: this.config(),
			formData: {},
			error: "",
			intervalId: 0,
			scrollStepInPx: 50,
			delayInMs: 16.66,
			submissionStatus: false
		}
		this.handleChange = this.handleChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}
	componentDidMount() {
		document.title = `${this.context.t("_studio5")} | ${this.context.t("_forgot_password")}`
		this.isLoggedInUsers();
		this.initState();
		setTimeout(() => {
			document.getElementById("pageHeading") && document.getElementById("pageHeading").focus()
		}, 500);
	}
	componentDidUpdate() {
		document.title = `${this.context.t("_studio5")} | ${this.context.t("_forgot_password")}`
	}
	initState = () => {
		this.setState({
			formData: {
				username: { value: "", error: "", class: "" },
			}
		})
	}
	handleChange = (field, value) => {
		const { formData } = this.state;
		formData[field]["class"] = value ? "" : "invalid";
		formData[field]["value"] = value;
		// contact_form.submit = submit;
		this.setState({ formData, submissionStatus: false });
	}
	scrollStep() {
		var scrollStepInPx = this.state.scrollStepInPx;
		if (window.pageYOffset <= 400) {
			clearInterval(this.state.intervalId);
		}
		window.scroll(0, window.pageYOffset - scrollStepInPx);
	}
	scrollToTop() {
		window.scroll({ top: 0, left: 0, behavior: "smooth" })
		// var delayInMs = this.props.delayInMs ? this.props.delayInMs : this.state.delayInMs;
		// let intervalId = setInterval(this.scrollStep.bind(this), delayInMs);
		// this.setState({ intervalId: intervalId });
	}
	validate = () => {
		const { formData } = this.state;
		for (var key in formData) {
			formData[key].value = formData[key].value.trim();
			formData[key].error = "";
		}

		if (formData.username.value == "")
			formData.username.error = this.context.t("_email_required");

		if (formData.username.value != "" && !emailValidate.test(formData.username.value))
			formData.username.error = this.context.t("_invalid_email");

		for (var key in formData) {
			if (formData[key].error != "") {
				this.setState({ formData, submissionStatus: false })
				setTimeout(() => {
					// this.refs.errorListRef.focus()
					this[key + "error"].focus()
				}, 1000);
				return false;

			}
		}
		return true;
	}
	displayError = error => {
		const { formData } = this.state;
		const { lang } = this.props;
		if (error)
			formData.username.error = error[lang]


		for (var key in formData) {
			if (formData[key].error != "") {
				this.setState({ formData })
				setTimeout(() => {
					// this.refs.errorListRef.focus()
					this[key + "error"].focus()
				}, 1000);
				return false;

			}
		}
	}
	onSubmit = async (event) => {
		event.preventDefault();
		const { formData, config } = this.state;
		if (this.validate()) {
			this.props.showLoader()
			const obj = {
				"email": formData.username.value
			}
			const url = `${config.api_url}${urlEndPoints.forgotPassword}`
			try {
				const data = await this.networkRequest(url, "POST", obj, null, true)
				if (data.data.error) {
					this.displayError(data.data.error)
					this.props.hideLoader();
				}
				else {
					this.setState({ submissionStatus: true })
					this.initState();
					this.scrollToTop();
					this.props.hideLoader();
					this.refs.successMessageRef && this.refs.successMessageRef.focus()
				}
			} catch (error) {
				this.props.hideLoader();
				console.warn("error: ", error)
			}
		}
	}
	render() {
		const { formData, error, submissionStatus } = this.state;
		const { lang } = this.props;
		let hasError = false;
		Object.keys(formData).map((item, index) => {
			if (formData[item].error != "")
				hasError = true
		})
		return (
			<div id="main-content">
				<SlideToTop />
				<PageTitle title={this.context.t("_forgot_password")} />
				<div className="container">
					<nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
						<ul>
							<li className="breadcrumb-item">
								<Link to="/">{this.context.t("_home")}</Link>{" "}
							</li>
							<li className="breadcrumb-item">
								<Link tabIndex="0" to aria-current={this.context.t("_page")}>
									<span>{this.context.t("_forgot_password")}</span>
								</Link>
							</li>
						</ul>
					</nav>
				</div>
				<div id="skipContent">
					<div id="main-container">
						<div className="container">
							<div className="row col-8">
								{
									_.isEmpty(formData) ?
										<div /> :
										<form name="login" onSubmit={this.onSubmit} className="login-form">
											{error &&
												<div className="error-container fail">
													<ul>
														<li><label className="error" >{error}</label></li>
													</ul>
												</div>
											}
											{
												hasError ? <div className="error-container fail">
													<h5>{this.context.t("_error_title")}</h5>
													<ul>
														{
															Object.keys(formData).map((item, index) => {
																return formData[item].error != "" ? <li key={index} ><a href={`#${item}`} tabIndex="0" ref={(refItem) => { this[item + "error"] = refItem; }} className="error" htmlFor={item}>{formData[item].error}</a></li>
																	:
																	null
															})}
													</ul>
												</div> : null

											}
											{
												submissionStatus ? <div className="error-container success" tabIndex="0" ref="successMessageRef">
													<h5>{this.context.t("_forgot_password_success")}</h5>
												</div> : null
											}
											<div className="input-field item">
												<input name="username"
													placeholder={this.context.t("_email_placeholder")}
													onChange={evt => this.handleChange("username", evt.target.value)}
													onFocus={evt => this.handleChange("username", evt.target.value)}
													value={formData.username.value}
													className="fontEnglish"
													id="username"
													type="text"
												/>
												<label htmlFor="username" style={dynamicStyles.position(lang)}>{this.context.t("_email")}</label>
												<span className="helper-text" data-error="Required field."></span>
											</div>

											<div className="btn-wrap reset-button-container">
												<button className="btn blue login" >
													{this.context.t("_reset_password")} <i className={`material-icons ${lang}`}>{`${lang == "en" ? "arrow_forward" : "arrow_backward"}`}</i>
												</button>
											</div>
										</form>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
const mapStateToProps = ({ i18nState }) => {
	return {
		lang: i18nState.lang
	}
}
const mapDispatchToProps = {
	showLoader: LoadingAction.showLoader,
	hideLoader: LoadingAction.hideLoader,

}
ForgotPassword.contextTypes = {
	t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
