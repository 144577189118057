import React from "react";
import Pagination from "react-js-pagination";
import Main from "./../main"
import PageTitle from "../includes/page_title";
import SlideToTop from "../includes/slide_to_top";

var pager = { count: 0, pages: 0, items_per_page: 0, current_page: 1 };
class AwardCompetition extends Main {
    constructor(props) {
        super(props);
        this.state = {
            pager
        }
    }
    async componentDidMount() {
        // M.AutoInit();
        // await this.getMachineList();
        // setTimeout(() => {
        //     var elems = document.querySelectorAll('.awardModal');
        //     M.Modal.init(elems)
        // }, 1000);
    }
    render() {
        const { pager } = this.state;
        return (
            <div id="main-container">
                <SlideToTop />
                <PageTitle title="Local Fab Lab Awards & Competition" />
                <div className="container">

                    <div className="row noFlex announcementBox">
                        <div className="col s12">
                            <p className="textBlue"><strong>Competition Name</strong></p>
                            <span className="dateHolder"><i className="fa fa-calendar-o"></i> 4 April, 2019 </span>
                            <span className="dateHolder"><i className="fa fa-map-marker"></i> 3384 Blue Spruce Lane, Baltimore, Maryland, 25610 </span>
                            <div className="textHolder">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sed dui id mauris pretium tincidunt sit amet eget nisi. Nullam scelerisque tellus Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sed dui id mauris pretium tincidunt sit amet eget nisi. Nullam scelerisque tellus </p>
                            </div>
                            <p></p>

                            <div className="row noFlex grey lighten-4">
                                <div className="col s12 m12 l12 xl4"><strong>Awards</strong></div>
                                <div className="col s12 m6 l6 xl2">Item 1</div>
                                <div className="col s12 m6 l6 xl2">Item 2</div>
                                <div className="col s12 m6 l6 xl2">Item 3</div>
                                <div className="col s12 m6 l6 xl2">Item 4</div>
                            </div>
                            <a href="#awardModal" className="btn profileBtn blue waves-effect waves-light modal-trigger">More information </a>
                            <div id="awardModal" className="modal awardModal">
                                <div className="modal-content  modal-no-close">
                                    <h4>Modal Header</h4>
                                    <p>A bunch of text</p>
                                </div>
                                <div className="modal-footer">
                                    <a href="#!" className="modal-close waves-effect waves-green btn-flat">Agree</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row noFlex announcementBox">
                        <div className="col s12">
                            <p className="textBlue"><strong>Competition Name</strong></p>
                            <span className="dateHolder"><i className="fa fa-calendar-o"></i> 4 April, 2019 </span>
                            <span className="dateHolder"><i className="fa fa-map-marker"></i> 3384 Blue Spruce Lane, Baltimore, Maryland, 25610 </span>
                            <div className="textHolder">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sed dui id mauris pretium tincidunt sit amet eget nisi. Nullam scelerisque tellus Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sed dui id mauris pretium tincidunt sit amet eget nisi. Nullam scelerisque tellus </p>
                            </div>
                            <p></p>

                            <div className="row noFlex grey lighten-4">
                                <div className="col s12 m12 l12 xl4"><strong>Awards</strong></div>
                                <div className="col s12 m6 l6 xl2">Item 1</div>
                                <div className="col s12 m6 l6 xl2">Item 2</div>
                                <div className="col s12 m6 l6 xl2">Item 3</div>
                                <div className="col s12 m6 l6 xl2">Item 4</div>
                            </div>
                            <a href="" className="btn profileBtn blue waves-effect waves-light">More information </a>

                        </div>
                    </div>

                    <div className="row noFlex announcementBox">
                        <div className="col s12">
                            <p className="textBlue"><strong>Competition Name</strong></p>
                            <span className="dateHolder"><i className="fa fa-calendar-o"></i> 4 April, 2019 </span>
                            <span className="dateHolder"><i className="fa fa-map-marker"></i> 3384 Blue Spruce Lane, Baltimore, Maryland, 25610 </span>
                            <div className="textHolder">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sed dui id mauris pretium tincidunt sit amet eget nisi. Nullam scelerisque tellus Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sed dui id mauris pretium tincidunt sit amet eget nisi. Nullam scelerisque tellus </p>
                            </div>
                            <p></p>

                            <div className="row noFlex grey lighten-4">
                                <div className="col s12 m12 l12 xl4"><strong>Awards</strong></div>
                                <div className="col s12 m6 l6 xl2">Item 1</div>
                                <div className="col s12 m6 l6 xl2">Item 2</div>
                                <div className="col s12 m6 l6 xl2">Item 3</div>
                                <div className="col s12 m6 l6 xl2">Item 4</div>
                            </div>
                            <a href="" className="btn profileBtn blue waves-effect waves-light">More information </a>

                        </div>
                    </div>

                </div>
                <Pagination
                    prevPageText="< Previous"
                    nextPageText="Next >"
                    hideFirstLastPages={true}
                    linkClassPrev="linkClassPrev"
                    linkClassNext="linkClassNext"
                    activePage={pager.current_page + 1}
                    itemsCountPerPage={pager.items_per_page}
                    totalItemsCount={pager.count}
                    pageRangeDisplayed={5}
                // onChange={this.handlePageChange}
                />
            </div>
        );
    }
}

export default AwardCompetition;