import Main from "../../components/main"
import _ from "lodash";
import dateformat from "dateformat"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { GET_STUDENT_LIST, GET_NEW_STUDENT_LIST, GET_STUDENT_FULL_LIST, HIDE_LOADER, GET_STUDENT_LEVEL, GET_STUDENT_POINT_RANGE } from "./../../constants/constants"

const MainConst = new Main();
const configConst = MainConst.config();
const getStudentsList = ({ title = "", age = "", nationality = "", gender = "", pages = 0, startDate = "", endDate = "", type = "", zone = "", fieldType = "", studentLevel = "", studentPoint = "" }) => {
    return async dispatch => {
        let url = `${configConst.api_url}${urlEndPoints.getDashboardStudentList}?`
        const body = {
            page: pages
        }
        if (!_.isEmpty(title)) {
            body.field_student_name_value = title
        }
        if (!_.isEmpty(age)) {
            var dob = new Date();
            dob.setFullYear(dob.getFullYear() - Number(age));
            body.field_student_dob_value = `${dateformat(dob, "yyyy-mm-dd")}`
        }
        if (!_.isEmpty(nationality)) {
            body.field_nationality_value = nationality
        }
        if (!_.isEmpty(gender)) {
            body.field_gender_value = gender
        }
        if (!_.isEmpty(startDate)) {
            body.field_start_and_end_time_value = dateformat(startDate.value, "yyyy-mm-dd")
        }
        if (!_.isEmpty(endDate)) {
            body.field_start_and_end_time_end_value_1 = dateformat(endDate.value, "yyyy-mm-dd")
        }
        if (!_.isEmpty(type)) {
            body.type_1 = type
        }
        if (!_.isEmpty(zone)) {
            if (zone == "1") {
                body.field_activity_zone_target_id = "Creativity Zone"
            }
            if (zone == "3") {
                body.field_activity_zone_target_id = "Technology Zone"
            }
            if (zone == "2") {
                body.field_activity_zone_target_id = "Software Zone"
            }
        }
        if (!_.isEmpty(fieldType)) {
            body.field_type_of_activity_value = fieldType
        }
        if (!_.isEmpty(studentPoint)) {
            const [point_max, point_min] = studentPoint.split("*")
            body.point_min = point_min
            body.point_max = point_max
        }
        if (!_.isEmpty(studentLevel)) {
            body.point_level = studentLevel
        }

        try {
            const data = await MainConst.networkRequest(url, "POST", body);
            // MainConst.networkRequest(url).then(data => {
            if (data.data.error) {
                dispatch({
                    type: GET_STUDENT_LIST,
                    payload: 0,
                })
            } else {
                dispatch({
                    type: GET_STUDENT_LIST,
                    payload: data.data
                })
            }
            dispatch({
                type: HIDE_LOADER, payload: false
            })
            // })
        } catch (error) {
            dispatch({
                type: HIDE_LOADER, payload: false
            })
        }

    }
}


const getNewStudentsList = ({ title = "", age = "", nationality = "", gender = "", pages = 0, startDate = "", endDate = "", studentLevel = "", studentPoint = "" }) => {
    return async dispatch => {
        let url = `${configConst.api_url}${urlEndPoints.dashboardStudentList}?`
        const body = {
            page: pages
        }
        if (!_.isEmpty(title)) {
            body.field_student_name_value = title
        }
        if (!_.isEmpty(age)) {
            var dob = new Date();
            dob.setFullYear(dob.getFullYear() - Number(age));
            body.field_student_dob_value = `${dateformat(dob, "yyyy-mm-dd")}`
        }
        if (!_.isEmpty(nationality)) {
            body.field_nationality_value = nationality
        }
        if (!_.isEmpty(gender)) {
            body.field_gender_value = gender
        }
        if (!_.isEmpty(startDate)) {
            body.field_start_and_end_time_value = dateformat(startDate.value, "yyyy-mm-dd")
        }
        if (!_.isEmpty(endDate)) {
            body.field_start_and_end_time_end_value_1 = dateformat(endDate.value, "yyyy-mm-dd")
        }
        if (!_.isEmpty(studentPoint)) {
            const [point_max, point_min] = studentPoint.split("*")
            body.point_min = point_min
            body.point_max = point_max
        }
        if (!_.isEmpty(studentLevel)) {
            body.point_level = studentLevel
        }

        try {
            const data = await MainConst.networkRequest(url, "POST", body);
            // MainConst.networkRequest(url).then(data => {
            if (data.data.error) {
                dispatch({
                    type: GET_NEW_STUDENT_LIST,
                    payload: 0,
                })
            } else {
                dispatch({
                    type: GET_NEW_STUDENT_LIST,
                    payload: data.data
                })
            }
            dispatch({
                type: HIDE_LOADER, payload: false
            })
            // })
        } catch (error) {
            dispatch({
                type: HIDE_LOADER, payload: false
            })
        }

    }
}
const getStudentLevel = (lang) => {
    return async dispatch => {
        let url = ""
        if (lang == "ar")
            url = `${configConst.api_url}${lang}/api/${urlEndPoints.getStudentLevel}`
        else
            url = `${configConst.api_url}${urlEndPoints.getStudentLevel}`

        try {
            const data = await MainConst.networkRequest(url);
            if (data.status == 200) {
                dispatch({
                    type: GET_STUDENT_LEVEL,
                    payload: data.data,
                })
            }
        } catch (error) {
            dispatch({
                type: HIDE_LOADER, payload: false
            })
        }

    }
}
const getStudentPointRange = (lang) => {
    return async dispatch => {
        let url = `${configConst.api_url}${urlEndPoints.getStudentPointRange}`

        try {
            const data = await MainConst.networkRequest(url);
            if (data.status == 200) {
                dispatch({
                    type: GET_STUDENT_POINT_RANGE,
                    payload: data.data,
                })
            }
        } catch (error) {
            dispatch({
                type: HIDE_LOADER, payload: false
            })
        }

    }
}
export default {
    getStudentsList,
    getNewStudentsList,
    getStudentLevel,
    getStudentPointRange
}