import React from "react";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import Main from "./../main"
import PageTitle from "../includes/page_title";
import SlideToTop from "../includes/slide_to_top";
import M from "materialize-css";
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import _ from "lodash"
import { LoadingAction } from "./../../actions"

class Faq extends Main {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            config: this.config(),
            pager: { count: 0, pages: 0, items_per_page: 0, current_page: 1 },
            faqList: []
        }
        this.props.showLoader()
    }
    componentDidMount() {
        M.AutoInit();
        this.getFaqList();
    }
    getFaqList = async () => {
        const { config } = this.state
        const url = `${config.api_url}${urlEndPoints.getFaqs}`
        try {
            const data = await this.networkRequest(url)
            if (data.data.error) {

            } else {
                this.setState({
                    faqList: data.data.results,
                    isLoaded: true
                })

            }
            this.props.hideLoader()
        } catch (error) {
            this.setState({ isLoaded: true })
            this.props.hideLoader()
            console.warn("error: ", error)
        }
    }
    render() {
        const { pager, faqList, isLoaded } = this.state;
        return (
            <div id="main-container">
                <SlideToTop />
                <PageTitle title={"Frequently Asked Questions (FAQ)"} />
                <div className="container">
                    <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                        <ul>
                            <li className="breadcrumb-item">
                                <Link to="/">{`Home`}</Link> {" "}
                            </li>
                            <li className="breadcrumb-item">
                                <span>
                                    FAQ
                                </span>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="container">

                    <h2>We’re here to help you</h2>

                    <div className="accodianHolder">
                        {isLoaded && faqList.length == 0 ? <div className="row"><p aria-label={this.context.t("_no_result_found")} tabIndex="0" className="col s12 no-result">{this.context.t("_no_result_found")}</p></div> :
                            <ul className="collapsible">
                                {
                                    faqList.map(item => {
                                        return (<li key={item.nid}>
                                            <div className="collapsible-header">
                                                <p><strong>{item.field_faq_question}</strong></p>
                                            </div>
                                            <div className="collapsible-body" dangerouslySetInnerHTML={{ __html: item.field_faq_answer }}>
                                            </div>
                                        </li>)
                                    })
                                }
                            </ul>}
                    </div>

                </div>
                <nav className="pagination-wrapper" aria-label="pagination">
                    <Pagination
                        prevPageText="< Previous"
                        nextPageText="Next >"
                        hideFirstLastPages={true}
                        hideDisabled={true}
                        linkClassPrev="linkClassPrev"
                        linkClassNext="linkClassNext"
                        activePage={pager.current_page + 1}
                        itemsCountPerPage={pager.items_per_page}
                        totalItemsCount={pager.count}
                        pageRangeDisplayed={5}
                    // onChange={this.handlePageChange}
                    />
                </nav>
            </div>
        );
    }
}

const mapStateToProps = ({ i18nState }) => {
    return {
        lang: i18nState.lang
    }
}
const mapDispatchToProps = {
    showLoader: LoadingAction.showLoader,
    hideLoader: LoadingAction.hideLoader,
}
Faq.contextTypes = {
    t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(Faq);
