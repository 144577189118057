import { HIDE_LOADER, SHOW_LOADER } from "./../constants/constants"

const showLoader = () => {
    return async dispatch => {
        dispatch({
            type: SHOW_LOADER, payload: true
        })
    }

}
const hideLoader = () => {
    return async dispatch => {
        dispatch({
            type: HIDE_LOADER, payload: false
        })
    }
}

export default {
    showLoader,
    hideLoader
}