import React from "react"
import { connect } from "react-redux";
import PropTypes from "prop-types"
import Main from "../main"
import { Link, Route, NavLink } from "react-router-dom"
import PageTitle from "../includes/page_title.js"
import Pagination from "react-js-pagination";
import { LoadingAction } from "../../actions"
import { urlEndPoints } from "../../httpAPI/apiConfig"
class Filter extends Main {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			search: { page: 0 },
			pager: { count: 0, pages: 0, items_per_page: 0, current_page: 1 },
			config: this.config(),
			media: [],
			id: ""
		}
		this.handlePageChange = this.handlePageChange.bind(this);
	}
	componentDidMount() {
	}
	componentDidUpdate() {
		const { match: { params } } = this.props;
		if (this.state.id !== params.id) {
			this.getMedia();
			window.scroll(0, 0);
		}
	}
	getMedia = async () => {
		this.props.showLoader()
		const { match: { params } } = this.props;
		const { config, search } = this.state

		const { lang } = this.props;
		let url = "";
		if (lang == "ar")
			url = `${config.origin}${lang}/api/${urlEndPoints.filterMedia(params.id)}`
		else
			url = `${config.origin}api/${urlEndPoints.filterMedia(params.id)}`

		if (search.page)
			url = `${url}&page=${search.page}`
		try {
			const data = await this.networkRequest(url)
			this.setState({
				media: data.data.results,
				pager: data.data.pager,
				id: params.id,
				isLoaded: true
			})
			this.props.hideLoader();
			this.refs.mediaCenterRef0 && this.refs.mediaCenterRef0.focus()
		} catch (error) {
			this.setState({ isLoaded: true })
			this.props.hideLoader()
			console.warn("error: ", error)
		}
	}
	handlePageChange(pageNumber) {
		this.props.showLoader()
		var { search } = this.state;
		search.page = pageNumber - 1;
		this.setState({ search: search });
		this.getMedia();
	}
	render() {
		const { media, config, pager, isLoaded } = this.state;
		const title = media.length > 0 ? media[0].category : ""
		return (
			<>
				{isLoaded && media.length == 0 ? <div className="row"><p aria-label={this.context.t("_no_result_found")} tabIndex="0" className="col s12 no-result">{this.context.t("_no_result_found")}</p></div> : <ul aria-label={`${this.context.t("_navbar_media_center")} ${title} ${this.context.t("_with")} ${media.length} ${this.context.t("_items")}`}>
					{media.map((row, index) => (
						<li className="media-item" key={index}>
							<div dangerouslySetInnerHTML={{ __html: row.category }} className={"category-wrap " + row.field_key.toLowerCase()}></div>
							<h3><Link tabIndex="0" ref={`mediaCenterRef${index}`} aria-label={`${row.title} ${row.date}`} to={"/news/" + row.nid} >{row.title}</Link></h3>
							<div className="mediaList">
								<div className="medialistImg" >
									{row.field_key === "Videos" &&
										<div className="videowrapper">
											<object data={row.video}>
												<param name="movie" value={row.video}></param>
												<param name="allowFullScreen" mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen" value="true"></param>
												<span>video</span>
											</object>
										</div>
									}
									{(row.field_key === "Gallery" || row.field_key === "News") &&
										<div
											// dangerouslySetInnerHTML={{ __html: row.media }}
											className="img-wrap imageFixedSize"
										>
											<img alt="" src={config.origin + row.field_newsimage} />
										</div>
									}
								</div>
								<div className="medialistText">
									<div
										className="desc"
										dangerouslySetInnerHTML={{ __html: row.description }}
									></div>
									<div
										className="date-wrap"
										dangerouslySetInnerHTML={{ __html: row.date }}
									></div>
								</div>

							</div>
						</li>
					))}
				</ul>
				}
				<nav className="pagination-wrapper" aria-label="pagination">
					{(media.length) ? (
						<Pagination
							prevPageText={`< ${this.context.t("_previous")}`}
							nextPageText={`${this.context.t("_next")} >`}
							hideFirstLastPages={true}
							hideDisabled={true}
							linkClassPrev="linkClassPrev"
							linkClassNext="linkClassNext"
							activePage={pager.current_page + 1}
							itemsCountPerPage={pager.items_per_page}
							totalItemsCount={pager.count}
							pageRangeDisplayed={5}
							onChange={this.handlePageChange}
						/>) : ""
					}
				</nav>
			</>
		)
	}
}

const mapStateToProps = ({ i18nState }) => {
	return {
		lang: i18nState.lang
	}
}
const mapDispatchToProps = {
	showLoader: LoadingAction.showLoader,
	hideLoader: LoadingAction.hideLoader,
}
Filter.contextTypes = {
	t: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
