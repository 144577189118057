import React, { useEffect, useState } from "react"
import Main from "../../main"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import _ from "lodash"
// import { PopupAction, LoadingAction, StudentCountAction } from "./../../../actions"
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { easeQuadInOut } from "d3-ease";
import AnimatedProgressProvider from "./Animate/AnimatedProgressProvider"


const Gender = (props, context) => {
    const [activeType, setActiveType] = useState("full")
    const { enrollment_count, waitinglist_count, attendance_count, full = {}, type, zone, fieldType, resetFlag } = props;
    useEffect(() => {
        if (activeType == "full" && (!_.isEmpty(type) || !_.isEmpty(zone) || !_.isEmpty(fieldType))) {
            setActiveType("enrolled")
        }
    }, [type, zone, fieldType])
    useEffect(() => {
        setActiveType("full")
    }, [resetFlag])
    return (
        <div className="dashboardBox">
            <div className="dashboardBox-header">
                <h2>{context.t("_percentage_of_gender")}</h2>
                <div className="input-field mobileSelect dashboardBox-dropdown">
                    <select className="browser-default" value={activeType} onChange={(value => setActiveType(value.target.value))}>
                        {
                            _.isEmpty(type) && _.isEmpty(zone) && _.isEmpty(fieldType) && <option value="full" >{context.t("_all_students")}</option>
                        }

                        <option value="enrolled" >{context.t("_students_enrolled")}</option>
                        <option value="attendence" >{context.t("_students_attended")}</option>
                        <option value="waitinglist" >{context.t("_students_waiting_list")}</option>
                    </select>
                </div>
            </div>
            <div className="dash-itemInner">
                <div className="dash-item">
                    <div style={{ width: 68 }}>
                        <AnimatedProgressProvider
                            valueStart={0}
                            valueEnd={props[activeType].female_percentage}
                            duration={1.4}
                            easingFunction={easeQuadInOut}
                        >
                            {value => {
                                return (
                                    <CircularProgressbar
                                        value={value}
                                        text={`${value.toFixed(1)}%`}
                                        /* This is important to include, because if you're fully managing the
                                  animation yourself, you'll want to disable the CSS animation. */
                                        styles={buildStyles({ pathTransition: "none", pathColor: "#EE3645", trailColor: "#E6E6E6", textColor: "#000000" })}
                                        counterClockwise
                                        strokeWidth={10}
                                    />
                                );
                            }}
                        </AnimatedProgressProvider>
                    </div>
                    <div className="gender-details">
                        <p>{`${props[activeType].female_count}/${props[activeType].total_students}`}</p>
                        <span>{context.t("_female")}</span>
                    </div>
                </div>
                <div className="dash-item">
                    <div style={{ width: 68 }}>
                        <AnimatedProgressProvider
                            valueStart={0}
                            valueEnd={props[activeType].male_percentage}
                            duration={1.4}
                            easingFunction={easeQuadInOut}
                        >
                            {value => {
                                return (
                                    <CircularProgressbar
                                        value={value}
                                        text={`${value.toFixed(1)}%`}
                                        /* This is important to include, because if you're fully managing the
                                  animation yourself, you'll want to disable the CSS animation. */
                                        styles={buildStyles({ pathTransition: "none", pathColor: "#4F5BA8", trailColor: "#E6E6E6", textColor: "#000000" })}
                                        strokeWidth={10}
                                    />
                                );
                            }}
                        </AnimatedProgressProvider>
                    </div>
                    <div className="gender-details">
                        <p>{`${props[activeType].male_count}/${props[activeType].total_students}`}</p>
                        <span>{context.t("_male")}</span>
                    </div>
                </div>
            </div>

        </div>
    )

}
Gender.defaultProps = {
    enrollment_count: 0,
    waitinglist_count: 0,
    attendance_count: 0,
    full: {
        female_count: 0,
        female_percentage: 0,
        male_count: 0,
        male_percentage: 0,
        qatari_count: 0,
        qatari_percentage: 0,
        total_students: 0
    },
    enrolled: {
        female_count: 0,
        female_percentage: 0,
        male_count: 0,
        male_percentage: 0,
        qatari_count: 0,
        qatari_percentage: 0,
        total_students: 0
    }
}
const mapStateToProps = ({ i18nState, studentCountReducer }) => {
    return {
        lang: i18nState.lang,
    }
}
const mapDispatchToProps = {
}
Gender.contextTypes = {
    t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(Gender);