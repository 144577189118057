import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import Main from "./../main"
import { LoadingAction } from "./../../actions"
import { urlEndPoints } from "./../../httpAPI/apiConfig"

class Makers extends Main {
    constructor(props){
        super(props);
        this.state = {
            config: this.config(),
        }
        this.props.showLoader()
    }
    componentDidMount(){
        this.getRedirectPath()
        // this.props.history.push("/news/3137")
    }
    getRedirectPath = async () => {
        const { config } = this.state
        const url = `${config.api_url}${urlEndPoints.makersPath}`
        try {
            const data = await this.networkRequest(url)
            if (data.data.error) {

            } else {
                this.props.history.push(`/news/${data.data[0].field_redirect_node}`)
            }
            this.props.hideLoader()
        } catch (error) {
            this.props.hideLoader()
            console.warn("error: ", error)
        }
    }
    render() {
        return (
            <div  id="main-content"/>
        )
    }
}
const mapStateToProps = ({ i18nState }) => {
    return {
        lang: i18nState.lang
    }
}
const mapDispatchToProps = {
    showLoader: LoadingAction.showLoader,
    hideLoader: LoadingAction.hideLoader,
}
Makers.contextTypes = {
    t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(Makers);
