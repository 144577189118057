import React from "react";
import Pagination from "react-js-pagination";
import Main from "./../main"
import PageTitle from "../includes/page_title";
import SlideToTop from "../includes/slide_to_top";
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { LoadingAction } from "./../../actions"

class Announcements extends Main {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            config: this.config(),
            pager: { count: 0, pages: 0, items_per_page: 0, current_page: 1 },
            announcementList: []
        }
        this.props.showLoader();
    }
    componentDidMount() {
        this.getAnnouncements();
    }
    getAnnouncements = async () => {
        const { config } = this.state
        const url = `${config.api_url}${urlEndPoints.getAnnouncement}`
        try {
            const data = await this.networkRequest(url)
            if (data.data.error) {

            } else {
                this.setState({
                    announcementList: data.data.results,
                    pager: data.data.pager,
                    isLoaded: true
                })

            }
            this.props.hideLoader()
        } catch (error) {
            this.setState({ isLoaded: true })
            this.props.hideLoader()
            console.warn("error: ", error)
        }
    }
    render() {
        const { pager, announcementList, config, isLoaded } = this.state;
        return (
            <div id="main-container">
                <SlideToTop />
                <PageTitle title={`Announcements`} />
                <div className="container">

                    <h2>Title</h2>

                    {isLoaded && announcementList.length == 0 ? <div className="row"><p aria-label={this.context.t("_no_result_found")} tabIndex="0" className="col s12 no-result">{this.context.t("_no_result_found")}</p></div> :
                        announcementList.map(item => {
                            return (
                                <div className="row noFlex announcementBox" key={item.nid}>
                                    <div className="col s12 m12 l12 push-xl6 xl6 "><img src={`${config.origin}${item.field_announcement_image}`} alt="" /></div>
                                    <div className="col s12 m12 l12 pull-xl6 xl6 ">
                                        <h3>{item.title}</h3>
                                        <span className="dateHolder"><i className="fa fa-calendar-o"></i><strong>Start Date</strong> {item.field_announcement_start_date} </span>
                                        <span className="dateHolder"><i className="fa fa-calendar-o"></i><strong>End Date</strong> {item.field_announcement_end_date} </span>
                                        <div className="textHolder" dangerouslySetInnerHTML={{ __html: item.body }}>
                                        </div>
                                        <button className="btn profileBtn none waves-effect waves-yellow"><strong>Click here</strong></button>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <nav className="pagination-wrapper" aria-label="announcement">
                    <Pagination
                        prevPageText="< Previous"
                        nextPageText="Next >"
                        hideFirstLastPages={true}
                        linkClassPrev="linkClassPrev"
                        linkClassNext="linkClassNext"
                        activePage={pager.current_page + 1}
                        itemsCountPerPage={pager.items_per_page}
                        totalItemsCount={pager.count}
                        pageRangeDisplayed={5}
                    // onChange={this.handlePageChange}
                    />
                </nav>
            </div>
        );
    }
}


const mapStateToProps = ({ i18nState }) => {
    return {
        lang: i18nState.lang
    }
}
const mapDispatchToProps = {
    showLoader: LoadingAction.showLoader,
    hideLoader: LoadingAction.hideLoader,
}
Announcements.contextTypes = {
    t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(Announcements);
