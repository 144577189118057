import React from "react";
import { Link } from "react-router-dom";
import Main from "../../main";
import PageTitle from "../../includes/page_title.js";
import SlideToTop from "../../includes/slide_to_top.js";
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { urlEndPoints } from "../../../httpAPI/apiConfig"
import _ from "lodash"
import { LoadingAction, LoginAction } from "../../../actions"
import M from "materialize-css";
import ReactCrop from "react-image-crop";
import topLineImage from "../../../assets/images/top-line.png"
import blankImage from "../../../assets/images/blank.png"
import activityImage from "../../../assets/images/ico_activities.png"
import connectionImage from "../../../assets/images/ico_connections.png"
import settingsImage from "../../../assets/images/ico_settings.png"
import calendarImage from "../../../assets/images/ico_calendar.png"
import enrolmentImage from "../../../assets/images/enrollment-listing.png"
import dashboardImage from "../../../assets/images/dashboard.png"

class TrainerProfile extends Main {
    constructor(props) {
        super(props);
        this.state = {
            config: this.config(),
            profileData: {
                picture: "",
                name: ""
            },
            selectedImage: "",
            croppedImageUrl: "",
            submissionStatus: false,
            submissionStatus_1: false,
            successMessage: null,
            file: "",
            crop: {
                unit: "px",
                width: 200,
                height: 201,
                aspect: 2 / 2.1,
            },
            profileUpdateMessage: null
        }
        this.props.showLoader()
    }
    componentDidMount() {
        document.title = `${this.context.t("_studio5")} | ${this.context.t("_profile")}`
        M.AutoInit();
        this.getProfileData()
    }
    componentDidUpdate() {
        document.title = `${this.context.t("_studio5")} | ${this.context.t("_profile")}`
    }
    getProfileData = async () => {
        // const authResponse = await this.auth();
        // if (authResponse) {
        const { config } = this.state
        const url = `${config.api_url}${urlEndPoints.userDetails}`
        // const headers = {
        //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
        // }
        try {
            const data = await this.networkRequest(url)
            localStorage.setItem("profileImage", `${config.origin}${data.data.picture}`)
            this.props.userLoggedIn(`${config.origin}${data.data.picture}`)
            this.props.hideLoader()
            this.setState({ profileData: data.data })
            setTimeout(() => {
                document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
            }, 500);
        } catch (error) {
            this.props.hideLoader()
            console.warn("error: ", error)
        }
        // }
    }
    onChangeFile(event) {
        event.stopPropagation();
        event.preventDefault();
        const that = this;
        var file = event.target.files[0];
        if (file) {
            if (file.type.includes("image")) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function (e) {
                    var img = new Image;
                    img.onload = function () {
                        // if (img.width < 250) {
                        //     return that.props.showPopup({
                        //         type: "error",
                        //         title: "Invalid",
                        //         message: "Image size should be greater than 250px!",
                        //         isOpen: true,
                        //         buttonText: "Ok"
                        //     })
                        // } else if (img.width >= img.height) {
                        //     return that.props.showPopup({
                        //         type: "error",
                        //         title: "Invalid",
                        //         message: "Image height should be greater than width!",
                        //         isOpen: true,
                        //         buttonText: "Ok"
                        //     })
                        // } else {
                        //     that.saveUserImage(file, reader.result)
                        // }

                        that.setState({ selectedImage: reader.result, file, submissionStatus: false, submissionStatus_1: false })
                        let elems = document.querySelector("#photoCrop");
                        elems.setAttribute("aria-hidden", "false")
                        elems.setAttribute("tabindex", "-1")
                        let instance = M.Modal.getInstance(elems);
                        instance.open();
                        elems = document.querySelector(".ReactCrop");
                        elems.setAttribute("aria-label", that.context.t("_link_crop_image"))
                    };
                    img.src = reader.result;
                };
                // reader.onloadend = () => {
                //     this.saveUserImage(file, reader.result)
                // };
            } else {
                this.props.showPopup({
                    type: "error",
                    title: this.context.t("_invalid"),
                    message: this.context.t("_invalid_file_type"),
                    isOpen: true,
                    buttonText: this.context.t("_ok")
                })
            }
        }

    }

    handleCloseModal = () => {
        this.setState({
            selectedImage: "",
            croppedImageUrl: "",
            file: "",
        })
        this.upload.value = "";
        let elems = document.querySelector("#photoCrop");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        let instance = M.Modal.getInstance(elems);
        instance.close();
        this.refs.changeProfileImageBtn && this.refs.changeProfileImageBtn.focus()
    }

    onImageLoaded = image => {
        this.imageRef = image;
    };
    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
            this.setState({ croppedImageUrl });
        }
    }
    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            resolve(canvas.toDataURL());
        });
    }
    saveUserImage = async () => {
        // let authResponse = await this.auth();
        // if (authResponse) {
        const { config, croppedImageUrl, file } = this.state;
        if (!_.isEmpty(croppedImageUrl)) {
            this.handleCloseModal();
            this.props.showLoader()
            let url = `${config.api_url}${urlEndPoints.updateUserPic}`
            // let headers = {
            //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
            // }
            let obj = {
                name: file.name,
                image: croppedImageUrl
            }
            try {
                const data = await this.networkRequest(url, "POST", obj);
                if (data.status == 200) {
                    // this.setState({ submissionStatus: true, submissionStatus_1: true, successMessage: "Profile image updated successfully" })
                    this.getProfileData()
                    this.setState({ profileUpdateMessage: this.context.t("_profile_image_updated_successfully") })
                    this.refs.profileUpdateMessageRef && this.refs.profileUpdateMessageRef.focus()
                    setTimeout(() => {
                        this.setState({ profileUpdateMessage: null })
                    }, 2000);
                }
            } catch (error) {
                this.props.hideLoader()
                console.warn("error: ", error)
            }
        }
        // }
    }
    removeProfilePic = async () => {
        // let authResponse = await this.auth();
        // if (authResponse) {
        this.props.showLoader()
        const { config } = this.state
        let url = `${config.api_url}${urlEndPoints.removeUserPic}`
        // let headers = {
        //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
        // }
        let obj = {}
        try {
            const data = await this.networkRequest(url, "POST", obj);
            if (data.status === 200) {
                // this.setState({ submissionStatus: true, submissionStatus_1: true, successMessage: "Profile image removed successfully" })
                this.getProfileData()
                this.setState({ profileUpdateMessage: this.context.t("_profile_image_removed_successfully") })
                this.refs.profileUpdateMessageRef && this.refs.profileUpdateMessageRef.focus()
                setTimeout(() => {
                    this.setState({ profileUpdateMessage: null })
                }, 2000);
            }
        } catch (error) {
            this.props.hideLoader()
            console.warn("error: ", error)
        }
        // }
    }
    render() {
        const { profileData, config, selectedImage, crop, profileUpdateMessage } = this.state;
        return (
            <>
                <div id="main-container" className="stripBottom">
                    <SlideToTop />
                    <PageTitle title={this.context.t("_profile")} />
                    <div id="skipContent" className="stripCenterDiv">
                        <div className="container profile-container">
                            <div className="row">
                                <div className="col s3 text-center">
                                    <figure className="profilePic">
                                        {
                                            _.isEmpty(profileData.picture) ?
                                                <img src={blankImage} alt="" />
                                                :
                                                <>
                                                    <img src={blankImage} alt="" />
                                                    <span>
                                                        <img src={`${config.origin}${profileData.picture}`} alt="" />
                                                    </span>
                                                </>
                                        }
                                        <button aria-label={this.context.t("_change_profile_image")}
                                            type="button" ref="changeProfileImageBtn" onClick={() => { this.upload.click() }} className="btn changePic">
                                            <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                        </button>
                                        <input id="myInput"
                                            type="file"
                                            ref={(ref) => this.upload = ref}
                                            style={{ display: "none" }}
                                            onChange={this.onChangeFile.bind(this)}
                                        />
                                        <label style={{ display: "none" }} htmlFor="myInput" aria-label="select profile image">select profile image</label>
                                        <button aria-label={this.context.t("_remove_profile_image")} onClick={() => this.removeProfilePic()} type="button" className="btn removePic">
                                            <i className="fa fa-trash" aria-hidden="true"></i>
                                        </button>

                                    </figure>
                                    <h2 className="profileheading">{`${profileData.name}`}</h2>
                                    {
                                        !_.isEmpty(profileUpdateMessage) && <p tabIndex="0" ref="profileUpdateMessageRef" style={{ textAlign: "center" }}>{profileUpdateMessage}</p>
                                    }
                                </div>
                                <div className="col s9">

                                    <div className="profileTabHolder">
                                        <div className="cell">
                                            <Link to="/upcoming-activity">
                                                <div className="profileTab">
                                                    <img src={activityImage} alt="" />
                                                    <p>{this.context.t("_upcoming_activity")}</p>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="cell">
                                            <Link to="/admin-history">
                                                <div className="profileTab">
                                                    <img src={connectionImage} alt="" />
                                                    <p>{this.context.t("_attendance")}</p>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="cell">
                                            <Link to="/change-password">
                                                <div className="profileTab">
                                                    <img src={settingsImage} alt="" />
                                                    <p>{this.context.t("_profile_settings")}</p>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="cell">
                                            <Link to="/admin-calendar">
                                                <div className="profileTab">
                                                    <img src={calendarImage} alt="" />
                                                    <p>{this.context.t("_calendar")}</p>
                                                </div>
                                            </Link>
                                        </div>
                                        {
                                            (localStorage.getItem("role") == "manager" || localStorage.getItem("role") == "edu_specialist") ? <div className="cell">
                                                <Link to="/admin-enrolment">
                                                    <div className="profileTab">
                                                        <img src={enrolmentImage} alt="" />
                                                        <p>{this.context.t("_enrolment_listing")}</p>
                                                    </div>
                                                </Link>
                                            </div> : null
                                        }
                                        {
                                            (localStorage.getItem("role") == "manager" || localStorage.getItem("role") == "edu_specialist") ? <div className="cell"><Link to="/dashboard/student">
                                                <div className="profileTab">
                                                    <img src={dashboardImage} alt="" />
                                                    <p>{this.context.t("_dashboard")}</p>
                                                </div>
                                            </Link></div> : null
                                        }

                                    </div>
                                </div>
                            </div>
                            <div id="photoCrop" className="modal sm" role="dialog">
                                <div className="modal-content scroll-x-hidden">
                                    <span className="badge modalClose"><button type="button" aria-label="close" tabindex="0" className="close" onClick={() => this.handleCloseModal()}><i className="material-icons">close</i></button></span>
                                    <p>{this.context.t("_crop_your_image")}</p>
                                    <div className="image-crop-container">
                                        <ReactCrop
                                            // crossorigin="anonymous"
                                            src={selectedImage}
                                            crop={crop}
                                            ruleOfThirds
                                            minWidth={200}
                                            minHeight={200}
                                            keepSelection={true}
                                            imageStyle={{ maxHeight: 250 }}
                                            onImageLoaded={this.onImageLoaded}
                                            onComplete={this.onCropComplete}
                                            onChange={this.onCropChange}
                                        />
                                    </div>

                                    <div className="clearfix" />
                                    <button onClick={() => this.saveUserImage()} className="btn profileBtn yellow">{this.context.t("_upload")}</button>
                                    <button onClick={() => this.handleCloseModal()} className="btn profileBtn blue-border">{this.context.t("_cancel")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="top-line ng-scope">
                    <img src={topLineImage} alt="" />
                </div>
            </>
        )
    }

}

const mapStateToProps = ({ i18nState }) => {
    return {
        lang: i18nState.lang
    }
}
const mapDispatchToProps = {
    showLoader: LoadingAction.showLoader,
    hideLoader: LoadingAction.hideLoader,
    userLoggedIn: LoginAction.userLoggedIn,
}
TrainerProfile.contextTypes = {
    t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(TrainerProfile);
