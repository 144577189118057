import React from "react"
import { connect } from "react-redux";
import PropTypes from "prop-types"
import Main from "../main"
import { Link, Route, NavLink } from "react-router-dom"
import PageTitle from "../includes/page_title.js"
import { LoadingAction } from "./../../actions"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import _ from "lodash";
import Filter from "./filter"
class FilterMedia extends Main {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			config: this.config(),
			media: [],
			filter: [],
			id: ""
		}
		this.props.showLoader()
	}
	/*componentWillMount() {
	  this.unlisten = this.props.history.listen((location, action) => {
		this.getMedia();	    
	  });
	}
	componentWillUnmount() {
		this.unlisten();
	    
	}*/
	componentDidMount() {
		document.title = `${this.context.t("_studio5")} |`
		this.filterMenu();
	}
	filterMenu = async () => {
		const { config } = this.state
		const { lang } = this.props;
		let url = "";
		if (lang == "ar")
			url = `${config.api_url}${urlEndPoints.filter(lang)}`
		else
			url = `${config.api_url}${urlEndPoints.filter(lang)}`
		try {
			const data = await this.networkRequest(url)
			this.props.hideLoader();
			this.setState({ filter: data.data })
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
	}

	render() {
		const { filter } = this.state;
		const { lang } = this.props;
		const { match: { params } } = this.props;
		let breadcrumbTitle = {}
		breadcrumbTitle = _.find(filter, ["tid", params.id])
		document.title = `${this.context.t("_studio5")} | ${breadcrumbTitle && breadcrumbTitle.filter}`
		return (
			<div id="main-content" className="media-center-page">
				<PageTitle title={this.context.t("_navbar_media_center")} />
				<div className="container">
					<nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
						<ul>
							<li className="breadcrumb-item">
								<Link to="/">{this.context.t("_home")}</Link> {" "}
							</li>
							<li className="breadcrumb-item">
								<Link to="/media-center">{this.context.t("_navbar_media_center")}</Link>{" "}
							</li>
							<li className="breadcrumb-item">
								<Link tabIndex="0" to aria-current={this.context.t("_page")}>
									<span>{breadcrumbTitle && breadcrumbTitle.filter}</span>
								</Link>
							</li>
						</ul>
					</nav>
				</div>
				<div id="skipContent">
					<div className="container">
						<div className="row">
							<div className="col s3 left-side">
								<div className="filter-wrap">
									<h2>{this.context.t("_filter_media")}</h2>
									<ul aria-label="aside navigation">
										{filter.map((row, index) => (
											<li className={`media-item ${lang}`} key={index}>
												<NavLink to={"/filter-media/" + row.tid}>{row.filter}<span>{row.count}</span></NavLink>
											</li>
										))}
									</ul>
									<span className="grey-square-rotate"></span>
									<span className="multi-square"><b><i></i></b></span>
								</div>
							</div>
							<div className="col s9 media-content">
								<Route path="/filter-media/:id" component={Filter} />
								{/* <div className="flex-row col-3">
								<Route path="/filter-media/:id" component={Filter} />
							</div> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = ({ i18nState }) => {
	return {
		lang: i18nState.lang
	}
}
const mapDispatchToProps = {
	showLoader: LoadingAction.showLoader,
	hideLoader: LoadingAction.hideLoader,
}
FilterMedia.contextTypes = {
	t: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterMedia);
