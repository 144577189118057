import React from "react"
import Main from "../main"
import _ from "lodash"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import Pagination from "react-js-pagination";
import { PopupAction, LoadingAction } from "./../../actions"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import activity from "../../assets/images/activity-details.jpg"
import eventImage from "../../assets/images/session-test-image.jpg"

class DashboardActivityDetails extends Main {
	constructor(props) {
		super(props);
		this.state = {
			config: this.config(),
			users: [],
			completeUsers: [],
			activityDetails: {},
			pager: { count: 0, pages: 0, items_per_page: 0, current_page: 1 }
		}
	}
	componentDidMount() {
		document.title = `${this.context.t("_studio5")} | ${this.context.t("_activity_details")}`
		this.props.showLoader();
		this.getActivityDetails()
		this.getActivityUsers()
		this.getActivityCompleteUsers()
	}
	componentDidUpdate() {
		document.title = `${this.context.t("_studio5")} | ${this.context.t("_activity_details")}`
	}
	getActivityDetails = async () => {
		const { config } = this.state;
		const { lang } = this.props;
		const { match: { params: { id } } } = this.props;
		let url = ""
		if (lang == "ar")
			url = `${config.origin}${lang}/api/${urlEndPoints.activityDetails(id)}`
		else
			url = `${config.origin}api/${urlEndPoints.activityDetails(id)}`
		try {
			const data = await this.networkRequest(url);
			if (data.data.error) {

			} else {
				const activityDetails = data.data[0];
				activityDetails.field_start_and_end_time_3 = activityDetails.field_start_and_end_time_3.split("*")
				activityDetails.field_start_and_end_time_4 = activityDetails.field_start_and_end_time_4.split("*")
				this.setState({
					activityDetails
				})
			}
			this.props.hideLoader()
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
	}
	getActivityUsers = async () => {
		const { config } = this.state
		const { match: { params: { id } } } = this.props;
		const url = `${config.api_url}${urlEndPoints.getDashboardActivityStudents(id)}`
		try {
			const data = await this.networkRequest(url);
			if (data.data.error) {

			} else {
				this.setState({
					users: data.data.results,
					pager: data.data.pager
				})
			}
			// this.props.hideLoader()
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
	}
	getActivityCompleteUsers = async () => {
		const { config } = this.state
		const { match: { params: { id } } } = this.props;
		const url = `${config.api_url}${urlEndPoints.getDashboardActivityCompleteStudent(id)}`
		try {
			const data = await this.networkRequest(url);
			if (data.data.error) {

			} else {
				this.setState({
					completeUsers: data.data
				})
			}
			// this.props.hideLoader()
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
	}

	handlePrint = () => {
		return window.print();
	}
	render() {
		const { users, activityDetails, pager, completeUsers } = this.state;
		return (
			<div id="main-content" >
				<div className="page-title en">
					<div className="container">
						<h1>{this.context.t("_activity_details")}</h1>
					</div>
				</div>
				<div className="container">
					<nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
						<ul>
							<li className="breadcrumb-item">
								<Link to="/dashboard/activity">{this.context.t("_dashboard")}</Link> {" "}
							</li>
							<li className="breadcrumb-item">
								<Link tabIndex="0" to aria-current={this.context.t("_page")}>
									<span>{this.context.t("_activity_details")}</span>
								</Link>
							</li>
						</ul>
					</nav>
				</div>
				{
					_.isEmpty(activityDetails) ?
						<div /> :
						<div className="container activity-details">
							<div className="row" id="skipContent">
								<div className="col s12 export-button">
									<button onClick={() => this.handlePrint()} className="btn bgYellow waves-effect waves-light">{this.context.t("_export_activity_details")}</button>
								</div>

								<div className="col s12 content">
									<img className="activity-details-image" src={activity} alt="type image"></img>
									<div className="eventImage">
										<img style={{ marginTop: 0, }} src={eventImage} alt="type image"></img>
									</div>
									<h2 className="line">{activityDetails.eventname}</h2>
									<div dangerouslySetInnerHTML={{ __html: activityDetails.details }} />
								</div>

								<div className="col s12 profileInfo profileInfo--width">
									<ul>
										<li><span>{this.context.t("_age_group")}: </span>{activityDetails.age_group}</li>
										<li><span>{this.context.t("_zone")}:</span>{activityDetails.field_activity_zone}</li>
										<li><span>{this.context.t("_attended")}:</span>{activityDetails.attendees}</li>
										<li><span>{this.context.t("_no_of_volunteers")}:</span></li>
										<li><span>{this.context.t("_gender")}:</span>{activityDetails.gender}</li>
										<li><span>{this.context.t("_type")}:</span>{activityDetails.nothing}</li>
										<li><span>{this.context.t("_enrollments")}:</span></li>
										<li><span>{this.context.t("_trainer")}:</span>{activityDetails.field_trainer_name}</li>
										<li><span>{this.context.t("_activity_date")}:</span>{`${activityDetails.field_start_and_end_time_3[0]} - ${activityDetails.field_start_and_end_time_4[activityDetails.field_start_and_end_time_4.length - 1]}`}</li>
										<li><span>{`${this.context.t("_online")}/ ${this.context.t("_onsite")}`}:</span>Onsite</li>
										<li><span>{this.context.t("_absents")}: </span>8</li>
										<li><span>{this.context.t("_assistant")}: </span>Fatima</li>
										<li><span>{this.context.t("_zone_address")}: </span>Venue unavailable</li>
									</ul>
								</div>

							</div>
						</div>
				}

				<div className="container details-block">
					<div className="row">

						{users.length > 0 && <div className="col s12 tableHolder rmv-padding">
							<h3 tabIndex="0" aria-label={`${activityDetails.eventname} ${this.context.t("_student_details")}`} className="left">{this.context.t("_student_details")}</h3>
							<table className="responsive-table highlight">
								<thead className="blue lighten-5">
									<tr>
										<th><strong>{this.context.t("_students")}</strong></th>
										<th><strong>{this.context.t("_status")}</strong></th>
										<th><strong>{this.context.t("_attendance")}</strong></th>
									</tr>
								</thead>
								<tbody>
									{
										users.map((item, index) => {
											return (
												<tr key={index}>
													<td>{item.field_student}</td>
													<td>{item.field_published_status}</td>
													<td>{item.field_activity_attended}</td>
												</tr>
											)
										})
									}
								</tbody>
							</table>
							<nav className="pagination-wrapper" aria-label="pagination">
								<Pagination
									prevPageText={`< ${this.context.t("_previous")}`}
									nextPageText={`${this.context.t("_next")} >`}
									hideFirstLastPages={true}
									hideDisabled={true}
									linkClassPrev="linkClassPrev"
									linkClassNext="linkClassNext"
									activePage={pager.current_page + 1}
									itemsCountPerPage={pager.items_per_page}
									totalItemsCount={pager.count}
									pageRangeDisplayed={5}
									onChange={this.handleStudentPageChange}
								/>
							</nav>
						</div>}
					</div>
				</div>

				{
					/**
					 * Print
					 */
				}

				<div className="container details-block">
					<div className="row">

						<div className="col s12 tableHolder list-print">
							<h3 className="left">{this.context.t("_student_details")}</h3>
							<table>
								<thead>
									<tr>
										<th><strong>{this.context.t("_students")}</strong></th>
										<th><strong>{this.context.t("_status")}</strong></th>
										<th><strong>{this.context.t("_attendance")}</strong></th>
									</tr>
								</thead>
								<tbody>
									{
										completeUsers.map(item => {
											return (
												<tr key={item.id}>
													<td>{item.field_student}</td>
													<td>{item.field_published_status}</td>
													<td>{item.field_activity_attended}</td>
												</tr>
											)
										})
									}
								</tbody>
							</table>
						</div>
					</div>
				</div>

			</div>
		)
	}
}

const mapStateToProps = ({ i18nState }) => {
	return {
		lang: i18nState.lang
	}
}
const mapDispatchToProps = {
	showPopup: (data) => PopupAction.showPopup(data),
	hidePopup: (data) => PopupAction.hidePopup(data),
	showLoader: LoadingAction.showLoader,
	hideLoader: LoadingAction.hideLoader,
}
DashboardActivityDetails.contextTypes = {
	t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(DashboardActivityDetails);