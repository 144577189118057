import React from "react";
import { Link } from "react-router-dom";
import Main from "../../main";
import PageTitle from "../../includes/page_title.js";
import SlideToTop from "../../includes/slide_to_top.js";
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { dynamicStyles } from "./../../../constants/dynamicStyles"
import M from "materialize-css";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { emailValidate, nameValidate, qidValidate, phoneValidate, nameWordLengthCheck, gradeListNew, calenderI18n, CustomDateInput, calenderLocal } from "./../../../constants/common"
import { urlEndPoints } from "./../../../httpAPI/apiConfig"
import CountryList from "./../../../constants/country.json"
import _ from "lodash"
import { PopupAction, LoadingAction } from "./../../../actions"
import ReactCrop from "react-image-crop";
import dateformat from "dateformat";
import "react-image-crop/dist/ReactCrop.css";

import topLineImage from "../../../assets/images/top-line.png"
import blankImage from "../../../assets/images/blank.png"

class ViewProfileSettings extends Main {
    constructor(props) {
        super(props);
        this.state = {
            config: this.config(),
            formData: {},
            profileImage: "",
            intervalId: 0,
            scrollStepInPx: 50,
            delayInMs: 16.66,
            selectedImage: "",
            croppedImageUrl: "",
            submissionStatus: false,
            disableEmail: true,
            file: "",
            crop: {
                unit: "px",
                width: 200,
                height: 201,
                aspect: 2 / 2.1,
            },
            schoolList: []
        }
        this.props.showLoader();
    }
    async componentDidMount() {
        document.title = `${this.context.t("_studio5")} | ${this.context.t("_profile")} | ${this.context.t("_settings")}`
        await this.initState();
        M.AutoInit();
        let today = new Date()
        var options = {
            autoClose: true,
            onSelect: ((date) => {
                this.handleChange("dob", dateformat(date, "dd/mm/yyyy"))
            }),
            // defaultDate: new Date("01/03/2001"),
            // setDefaultDate: new Date("01/03/2001"),
            format: "dd/mm/yyyy",
            container: null,
            maxDate: new Date("12/31/2014"),
            minDate: new Date("01/01/2001"),
            yearRange: 20,
            minYear: 2001,
            maxYear: 2014
        };
        setTimeout(() => {
            var elems = document.querySelectorAll("#dob");
            var selectelem = document.querySelectorAll("select");
            options.i18n = calenderI18n(this.context);
            // M.Datepicker.init(elems, options);
            M.FormSelect.init(selectelem, options);
        }, 1000);
        this.getSchoolList();
        this.getProfileSettingsData();
        window.addEventListener('load', this.handleTabIndex());
    }
    componentDidUpdate() {
        document.title = `${this.context.t("_studio5")} | ${this.context.t("_profile")} | ${this.context.t("_settings")}`
    }
    handleTabIndex = () => {
        setTimeout(() => {
            let elem = document.querySelectorAll('select');
            elem.forEach(element => {
                element.setAttribute("tabindex", "0");
            });
        }, 1000);
    }
    getSchoolList = async () => {
        const { config } = this.state
        const { lang } = this.props;
        const url = `${config.api_url}${urlEndPoints.schoolList(lang)}`
        try {
            const data = await this.networkRequest(url)
            this.setState({ schoolList: data.data })
            // this.schoolName.focus()
            // this.initSelect()
        } catch (error) {
            this.props.hideLoader()
            console.warn("error: ", error)
        }
    }
    initState = () => {
        this.setState({
            formData: {
                name: { value: "", error: "", class: "" },
                qid: { value: "", error: "", class: "" },
                schoolName: { value: "", error: "", class: "" },
                schoolNewName: { value: "", error: "", class: "" },
                gender: { value: "Male", error: "", class: "" },
                dob: { value: "", error: "", class: "" },
                nationality: { value: "", error: "", class: "" },
                grade: { value: "", error: "", class: "" },
                email: { value: "", error: "", class: "" },
                mobile: { value: "", error: "", class: "" },
                parentName: { value: "", error: "", class: "" },
                parentMobile: { value: "", error: "", class: "" },
                parentEmail: { value: "", error: "", class: "" },
                parentQid: { value: "", error: "", class: "" },
                relation: { value: "", error: "", class: "" },
                channel: { value: "Mobile Number", error: "", class: "" },

            }
        })
    }
    handleChange = (field, value) => {
        const { formData } = this.state;
        formData[field]["class"] = value ? "" : "invalid";
        formData[field]["value"] = value;
        this.setState({ formData, submissionStatus: false });
    };
    renderInput = ({ name, label, placeholder, type = "text", isMandatory = false, max, disabled }) => {
        const { formData } = this.state;
        const { lang } = this.props;
        return (type == "phone_number" ?
            <div className="row mobile">
                <div className="col s4">
                    <div className="input-field item">
                        <input name={`${name}_country_code`}
                            placeholder="Country Code"
                            value={this.context.t("_ar_country_code")}
                            style={dynamicStyles.textAlign(lang)}
                            id={`${name}_country_code`}
                            type="text"
                            minLength="3"
                            maxLength="3"
                            disabled
                        // className={"validate " + registration_form.mobile.class}
                        // required
                        />
                        <label htmlFor={`${name}_country_code`} style={dynamicStyles.position(lang)}>{this.context.t("_country_code")}</label>
                    </div>
                </div>
                <div className="col s8">
                    <div className="input-field item">
                        <input
                            name={name}
                            placeholder={placeholder}
                            ref={(input) => { this[name] = input; }}
                            aria-label={this.context.t("_phone_aria_label")}
                            id={name}
                            maxLength={max}
                            type="text"
                            style={dynamicStyles.textAlign(lang)}
                            // readOnly={type === "date" ? "readonly" : false}
                            autoComplete="no"
                            onChange={evt =>
                                this.handleChange(name, evt.target.value)
                            }
                            onFocus={evt =>
                                this.handleChange(name, evt.target.value)
                            }
                            value={formData[name].value}
                        // className={"validate " + formData[name].class}
                        // required={isMandatory}
                        />
                        <label htmlFor={name} style={dynamicStyles.position(lang)}>
                            {`${label} `}
                            {isMandatory ? <span className="asterisk">*</span> : null}
                        </label>
                    </div>
                </div>
            </div>
            : type == "date" ? <div className="input-field item">
                <DatePicker
                    name={name}
                    placeholderText={placeholder}
                    ref={(input) => { this[name] = input; }}
                    id={name}
                    autoComplete="no"
                    // className={styleClass}
                    maxDate={new Date("12/31/2014")}
                    minDate={new Date("01/01/2001")}
                    dateFormat="yyyy-MM-dd"
                    onChange={date =>
                        this.handleChange(name, date)
                    }
                    style={dynamicStyles.textAlign(lang)}
                    value={formData[name].value}
                    locale={calenderLocal(this.context)}
                    selected={formData[name].value} customInput={<CustomDateInput ariaLabel={this.context.t("_select_dob_button")} onFieldChange={date => this.handleChange(name, new Date(date))} />}
                    onCalendarOpen={e => {
                        let elems = document.querySelector(".react-datepicker");
                        elems.setAttribute("tabindex", "0")
                        elems.focus()
                        elems = document.querySelector(".react-datepicker__month-select");
                        elems.setAttribute("aria-label", this.context.t("_select_month"))
                        elems = document.querySelector(".react-datepicker__year-select");
                        elems.setAttribute("aria-label", this.context.t("_select_year"))

                    }}
                    onCalendarClose={e => {
                        var elems = document.querySelector(".hiddenDob");
                        elems.setAttribute("aria-label", `${this.context.t("_selected_date")} ${dateformat(formData[name].value, "dd-mm-yyyy")}`)
                        elems.setAttribute("value", dateformat(formData[name].value, "yyyy-mm-dd"))
                        setTimeout(() => {
                            elems.focus()
                        }, 200);
                    }}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
                <label htmlFor={name} style={dynamicStyles.position(lang)}>
                    {`${label} `}
                    {isMandatory ? <span className="asterisk">*</span> : null}
                </label>
                <span
                    style={dynamicStyles.textAlign(lang)}
                    className="helper-text"
                    data-error={formData[name].error ? formData[name].error : "Required field."}
                ></span>
            </div> :
                <div className="input-field item">
                    <input
                        name={name}
                        placeholder={placeholder}
                        ref={(input) => { this[name] = input; }}
                        id={name}
                        maxLength={max}
                        readOnly={disabled}
                        type={type === "date" ? "text" : type}
                        style={dynamicStyles.textAlign(lang)}
                        // readOnly={type === "date" ? "readonly" : false}
                        autoComplete="no"
                        onChange={evt =>
                            this.handleChange(name, evt.target.value)
                        }
                        onFocus={evt =>
                            this.handleChange(name, evt.target.value)
                        }
                        value={formData[name].value}
                    // className={"validate " + formData[name].class}
                    // required={isMandatory}
                    />
                    <label htmlFor={name} style={dynamicStyles.position(lang)}>
                        {`${label} `}
                        {isMandatory ? <span className="asterisk">*</span> : null}
                    </label>
                </div>)
    }

    renderSelect = ({ name, label, placeholder, isMandatory = false, data = [], type = "select" }) => {
        const { formData } = this.state;
        const { lang } = this.props;
        return (
            <div className={`input-field mobileSelect item ${lang}`} >
                <label htmlFor={name} style={dynamicStyles.position(lang)}>
                    {`${label} `}
                    {isMandatory ? <span className="asterisk">*</span> : null}
                </label>
                <select
                    name={name}
                    id={name}
                    style={dynamicStyles.textAlign(lang)}
                    ref={(input) => { this[name] = input; }}
                    onChange={evt =>
                        this.handleChange(name, evt.target.value)
                    }

                    onFocus={evt =>
                        this.handleChange(name, evt.target.value)
                    }
                    value={formData[name].value}
                // className={"validate" + formData[name].class}
                >
                    <option value="" disabled> {placeholder} </option>
                    {
                        data.map((item, index) => {
                            return type == "country" ?
                                <option key={index} value={item.country_id}>{item.country[lang]}</option>
                                :
                                type == "school" ? <option key={index} value={item.id}>{item.sname}</option> :
                                    <option key={index} value={item.value}>{item.label}</option>
                        })
                    }
                    {
                        type == "school" && <option value="0">{this.context.t("_not_in_list")}</option>
                    }
                </select>
            </div>
        )
    }
    renderRadioButton = ({ data = [], name, label, defaultChecked, isMandatory = false }) => {
        const { formData } = this.state;
        const { lang } = this.props;
        return (
            <div className="input-field item">
                <div className="input-radio valign-wrapper" role="radiogroup" aria-labelledby={name}>
                    {
                        data.map((item, index) => {
                            return (
                                <div key={index} className={item.value}>
                                    <input
                                        aria-checked={formData[name].value === item.value}
                                        name={name}
                                        id={item.value}
                                        aria-labelledby={`${name} ${item.label}`}
                                        type="radio"
                                        readOnly
                                        className="radio-btn"
                                        onClick={evt =>
                                            this.handleChange(name, item.value)
                                        }
                                        checked={formData[name].value === item.value}
                                    />
                                    <label id={`_${item.label}`} htmlFor={item.value} className="custom-radio-style">
                                        <span className="custom-radio-style">{item.label}</span>
                                    </label>
                                </div>
                            )
                        })
                    }

                </div>
                <label htmlFor={name} style={dynamicStyles.position(lang)}>
                    {`${label} `}
                    {isMandatory ? <span className="asterisk">*</span> : null}
                </label>

                <span
                    className="helper-text"
                    data-error="Required field."
                ></span>
            </div>
        )
    }
    getProfileSettingsData = async () => {
        // let authResponse = await this.auth();
        // if (authResponse) {
        const { config } = this.state
        const { match: { params: { id } } } = this.props;
        let url = `${config.api_url}${urlEndPoints.viewUserProfileSettings(id)}`
        // let headers = {
        //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
        // }
        try {
            const data = await this.networkRequest(url)
            let { formData } = this.state;
            if (!data.data.message) {
                formData = {
                    name: { value: data.data.name, error: "", class: "" },
                    qid: { value: data.data.field_qid, error: "", class: "" },
                    gender: { value: data.data.field_gender, error: "", class: "" },
                    // dob: { value: data.data.field_student_dob, error: "", class: "" },
                    nationality: { value: data.data.field_nationality, error: "", class: "" },
                    grade: { value: data.data.field_grade, error: "", class: "" },
                    email: { value: data.data.field_student_email, error: "", class: "" },
                    mobile: { value: !_.isEmpty(data.data.field_student_mobile) && data.data.field_student_mobile.replace("+974", ""), error: "", class: "" },
                    parentName: { value: data.data.field_parent_name, error: "", class: "" },
                    parentMobile: { value: data.data.field_parent_mobile_number ? data.data.field_parent_mobile_number.replace("+974", "") : "", error: "", class: "" },
                    parentEmail: { value: data.data.field_parent_email || "", error: "", class: "" },
                    parentQid: { value: data.data.field_parent_qid, error: "", class: "" },
                    relation: { value: data.data.field_relation_to_the_student, error: "", class: "" },
                    schoolName: { value: data.data.field_school, error: "", class: "" },
                    schoolNewName: { value: data.data.field_new_school_name, error: "", class: "" },
                    // channel: { value: data.data.field_channels_of_communication, error: "", class: "" },

                }
                const [date, month, year] = data.data.field_student_dob.split("/");
                formData.dob = { value: new Date(`${month}/${date}/${year}`), error: "", class: "" };
            }
            this.setState({ formData, profileImage: data.data.picture, disableEmail: !_.isEmpty(data.data.field_student_email) })
            // setTimeout(() => {
            //     const [date, month, year] = data.data.field_student_dob.split("/");
            //     let elems = document.querySelector("#dob");
            //     let instance = M.Datepicker.getInstance(elems);
            //     instance.setDate(new Date(`${month}/${date}/${year}`));
            // }, 1000);
            this.props.hideLoader()
            setTimeout(() => {
                document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
            }, 500);
        } catch (error) {
            this.props.hideLoader()
            console.warn("error: ", error)
        }
        // }
    }
    scrollStep() {
        var scrollStepInPx = this.state.scrollStepInPx;
        if (window.pageYOffset <= 400) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - scrollStepInPx);
    }
    scrollToTop() {
        window.scroll({ top: 0, left: 0, behavior: "smooth" })
        // var delayInMs = this.props.delayInMs ? this.props.delayInMs : this.state.delayInMs;
        // let intervalId = setInterval(this.scrollStep.bind(this), delayInMs);
        // this.setState({ intervalId: intervalId });
    }
    validate = () => {
        const { formData } = this.state;
        for (var key in formData) {
            if (formData[key].value)
                formData[key].value = key == "dob" ? formData[key].value : formData[key].value.trim();
            formData[key].error = "";
        }
        if (_.isEmpty(formData.name.value))
            formData.name.error = this.context.t("_student_name_required");

        if (!_.isEmpty(formData.name.value) && !nameValidate.test(formData.name.value))
            formData.name.error = this.context.t("_invalid_student_name")

        if (!_.isEmpty(formData.name.value) && nameValidate.test(formData.name.value) && !nameWordLengthCheck(formData.name.value))
            formData.name.error = this.context.t("_student_name_contains_atleast_two_word")

        if (_.isEmpty(formData.qid.value))
            formData.qid.error = this.context.t("_qid_required")

        if (!_.isEmpty(formData.qid.value) && !qidValidate.test(formData.qid.value))
            formData.qid.error = this.context.t("_invalid_qid")

        if (formData.dob.value == "")
            formData.dob.error = this.context.t("_date_of_birth_required")

        if (_.isEmpty(formData.nationality.value))
            formData.nationality.error = this.context.t("_nationality_required")

        if (_.isEmpty(formData.grade.value))
            formData.grade.error = this.context.t("_grade_required")

        // if (formData.email.value == "")
        //     formData.email.error = "Email is required"

        if (!_.isEmpty(formData.email.value) && !emailValidate.test(formData.email.value))
            formData.email.error = this.context.t("_invalid_email")

        if (_.isEmpty(formData.mobile.value))
            formData.mobile.error = this.context.t("_mobile_number_required")

        if (!_.isEmpty(formData.mobile.value) && !phoneValidate.test(formData.mobile.value.replace(/ /g, "")))
            formData.mobile.error = this.context.t("_invalid_mobile_number")


        if (formData.schoolName.value == "")
            formData.schoolName.error = this.context.t("_school_name_required");

        if (formData.schoolName.value == "0" && formData.schoolNewName.value == "")
            formData.schoolNewName.error = this.context.t("_school_name_required");

        if (_.isEmpty(formData.parentQid.value))
            formData.parentQid.error = this.context.t("_parent_qid_required")

        if (!_.isEmpty(formData.parentQid.value) && !qidValidate.test(formData.parentQid.value))
            formData.parentQid.error = this.context.t("_invalid_parent_qid")

        if (_.isEmpty(formData.parentName.value))
            formData.parentName.error = this.context.t("_parent_name_required")

        if (!_.isEmpty(formData.parentName.value) && !nameValidate.test(formData.parentName.value))
            formData.parentName.error = this.context.t("_invalid_parent_name")

        if (!_.isEmpty(formData.parentName.value) && nameValidate.test(formData.parentName.value) && !nameWordLengthCheck(formData.parentName.value))
            formData.parentName.error = this.context.t("_parent_name_contains_atleast_two_word")

        if (_.isEmpty(formData.relation.value))
            formData.relation.error = this.context.t("_relationship_required")

        if (!_.isEmpty(formData.parentMobile.value) && !phoneValidate.test(formData.parentMobile.value.replace(/ /g, "")))
            formData.parentMobile.error = this.context.t("_invalid_parent_mobile")

        if (!_.isEmpty(formData.parentEmail.value) && !emailValidate.test(formData.parentEmail.value))
            formData.parentEmail.error = this.context.t("_invalid_parent_email")

        if ((!_.isEmpty(formData.parentEmail.value) && !_.isEmpty(formData.email.value)) && formData.parentEmail.value == formData.email.value)
            formData.parentEmail.error = this.context.t("_parent_student_email_cannot_be_same")

        if ((!_.isEmpty(formData.parentQid.value) && !_.isEmpty(formData.parentQid.value)) && formData.parentQid.value == formData.qid.value)
            formData.parentQid.error = this.context.t("_parent_student_qid_cannot_be_same")

        for (var key in formData) {
            if (formData[key].error != "") {
                this.setState({ formData, submissionStatus: false })
                this.scrollToTop();
                setTimeout(() => {
                    this[key + "error"].focus()
                }, 1000);
                return false;

            }
        }
        this.setState({ formData })
        return true;
    }
    displayError = error => {
        const { formData } = this.state;
        const { lang } = this.props;
        if (error.mail)
            formData.email.error = error.mail[lang]

        if (error.qid)
            formData.qid.error = error.qid[lang]

        if (error.nationality)
            formData.nationality.error = error.nationality[lang]

        if (error.grade)
            formData.grade.error = error.grade[lang]

        if (error.Student_name)
            formData.name.error = error.Student_name[lang]

        if (error.DOB)
            formData.dob.error = error.DOB[lang]

        this.setState({ formData })
        this.scrollToTop();
    }

    onSubmit = async (event) => {
        event.preventDefault();
        this.props.showLoader();
        const { formData, config, schoolList } = this.state;
        if (this.validate()) {
            const { match: { params: { id } } } = this.props;
            // const authResponse = await this.auth();
            // if (authResponse) {
            let obj = {
                id,
                "name": formData.email.value,
                "mail": formData.email.value,
                "field_student_email": formData.email.value,
                "field_qid": formData.qid.value,
                "field_student_mobile": `+974${formData.mobile.value.replace(/ /g, "")}`,
                "roles": {
                    "target_id": "student"
                },
                "field_nationality": formData.nationality.value,
                "field_student_name": formData.name.value,
                "field_grade": formData.grade.value,
                "field_gender": formData.gender.value,
                "field_student_dob": dateformat(formData.dob.value, "dd/mm/yyyy"),
                "field_parent_mobile_number": `+974${formData.parentMobile.value.replace(/ /g, "")}`,
                "field_parent_email": formData.parentEmail.value,
                "field_parent_qid": formData.parentQid.value,
                "field_parent_name": formData.parentName.value,
                "field_relation_to_the_student": formData.relation.value,
                // "field_channels_of_communication": formData.channel.value,
                "field_reference": {
                    "target_id": ""
                }
            }
            if (!_.isEmpty(formData.schoolName.value)) {
                let schoolName = schoolList.filter(item => item.id == formData.schoolName.value)
                if (formData.schoolName.value == "0")
                    schoolName = formData.schoolNewName.value
                else
                    schoolName = _.isEmpty(schoolName) ? null : schoolName[0].sname


                obj.school_id = {
                    "target_id": formData.schoolName.value,
                    "target_type": "taxonomy_term"
                };
                obj.school_name = schoolName
            }
            // let headers = {
            //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
            // }
            let url = `${config.api_url}${urlEndPoints.viewStudentProfileUpdate}`
            try {
                const data = await this.networkRequest(url, "PATCH", obj)
                if (data.data.error) {
                    this.displayError(data.data.error)
                    this.props.hideLoader();
                }
                else {
                    this.setState({ submissionStatus: true })
                    this.getProfileSettingsData();
                    // this.scrollToTop();
                    this.refs.successMessageRef.focus()
                }
            } catch (error) {
                this.props.hideLoader();
                console.warn("error: ", error)
            }
            // } else {
            //     this.props.hideLoader();
            // }
        } else {
            this.props.hideLoader();
        }
    }
    onChangeFile(event) {
        event.stopPropagation();
        event.preventDefault();
        const that = this;
        var file = event.target.files[0];
        if (file) {
            if (file.type.includes("image")) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function (e) {
                    var img = new Image;
                    img.onload = function () {
                        // if (img.width < 250) {
                        //     return that.props.showPopup({
                        //         type: "error",
                        //         title: "Invalid",
                        //         message: "Image size should be greater than 250px!",
                        //         isOpen: true,
                        //         buttonText: "Ok"
                        //     })
                        // } else if (img.width >= img.height) {
                        //     return that.props.showPopup({
                        //         type: "error",
                        //         title: "Invalid",
                        //         message: "Image height should be greater than width!",
                        //         isOpen: true,
                        //         buttonText: "Ok"
                        //     })
                        // } else {
                        //     that.saveUserImage(file, reader.result)
                        // }
                        that.setState({ selectedImage: reader.result, file })
                        let elems = document.querySelector("#photoCrop");
                        elems.setAttribute("aria-hidden", "false")
                        elems.setAttribute("tabindex", "-1")
                        let instance = M.Modal.getInstance(elems);
                        instance.open();
                        elems = document.querySelector(".ReactCrop");
                        elems.setAttribute("aria-label", that.context.t("_link_crop_image"))
                    };
                    img.src = reader.result;
                };
                // reader.onloadend = () => {
                //     this.saveUserImage(file, reader.result)
                // };
            } else {
                this.props.showPopup({
                    type: "error",
                    title: this.context.t("_invalid"),
                    message: this.context.t("_invalid_file_type"),
                    isOpen: true,
                    buttonText: this.context.t("_ok")
                })
            }
        }

    }
    saveUserImage = async () => {
        // const authResponse = await this.auth();
        // if (authResponse) {
        const { config, croppedImageUrl, file } = this.state;
        if (!_.isEmpty(croppedImageUrl)) {
            const { match: { params: { id } } } = this.props;
            this.props.showLoader()
            this.handleCloseModal();
            const { config } = this.state
            const url = `${config.api_url}${urlEndPoints.viewUpdateProfilePic}`
            // const headers = {
            //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
            // }
            const obj = {
                id,
                name: file.name,
                image: croppedImageUrl
            }
            try {
                const data = await this.networkRequest(url, "POST", obj);
                if (data.status == 200) {
                    this.setState({ submissionStatus: true, submissionStatus_1: true, successMessage: this.context.t("_profile_image_updated_successfully") })
                    this.getProfileSettingsData()
                    this.refs.successMessageRef.focus()
                }
            } catch (error) {
                this.props.hideLoader();
                console.warn("error: ", error)
            }

        }

        // }
    }
    removeProfilePic = async () => {
        // const authResponse = await this.auth();
        // if (authResponse) {
        const { match: { params: { id } } } = this.props;
        this.props.showLoader()
        const { config } = this.state
        const url = `${config.api_url}${urlEndPoints.viewRemoveProfilePic}`
        // const headers = {
        //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
        // }
        const obj = {
            id
        }
        try {
            const data = await this.networkRequest(url, "POST", obj);
            if (data.status === 200) {
                this.setState({ submissionStatus: true, submissionStatus_1: true, successMessage: this.context.t("_profile_image_removed_successfully") })
                this.getProfileSettingsData()

                this.refs.successMessageRef.focus()
            }
        } catch (error) {
            this.props.hideLoader();
            console.warn("error: ", error)
        }

        // }
    }
    handleCloseModal = () => {
        this.setState({
            selectedImage: "",
            croppedImageUrl: "",
            file: "",
        })
        this.upload.value = "";
        let elems = document.querySelector("#photoCrop");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        let instance = M.Modal.getInstance(elems);
        instance.close();
        this.refs.changeProfileImageBtn.focus()
    }

    onImageLoaded = image => {
        this.imageRef = image;
    };
    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
            this.setState({ croppedImageUrl });
        }
    }
    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            resolve(canvas.toDataURL());
        });
    }
    render() {
        const { formData, submissionStatus, profileImage, config, selectedImage, crop, disableEmail, schoolList } = this.state
        const { lang } = this.props;
        const { match: { params: { id } } } = this.props;
        let hasError = false;
        Object.keys(formData).map((item, index) => {
            if (formData[item].error != "")
                hasError = true
        })
        return (
            <div id="main-container">
                <SlideToTop />
                <PageTitle title={this.context.t("_settings")} />
                <div id="skipContent" className="container">
                    <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                        <ul>
                            <li className="breadcrumb-item">
                                <Link to="/parent-profile">{this.context.t("_profile")}</Link> {" "}

                            </li>
                            <li className="breadcrumb-item">
                                <Link to={`/student-profile/${id}`}>{this.context.t("_participants")}</Link> {" "}

                            </li>
                            <li className="breadcrumb-item">
                                <Link tabIndex="0" to aria-current={this.context.t("_page")}>
                                    <span>
                                        {this.context.t("_settings")}
                                    </span>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div>
                    <div className="container">
                        <div className="row">
                            <div className="col s3 text-center">
                                <figure className="profilePic">
                                    <img src={blankImage} alt="" />
                                    <span>
                                        <img src={`${config.origin}${profileImage}`} alt="" />
                                    </span>
                                    <button aria-label={this.context.t("_change_profile_image")}
                                        type="button" ref="changeProfileImageBtn" onClick={() => { this.upload.click() }} className="btn changePic">
                                        <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                    </button>
                                    <input id="myInput"
                                        type="file"
                                        ref={(ref) => this.upload = ref}
                                        style={{ display: "none" }}
                                        onChange={this.onChangeFile.bind(this)}
                                    />
                                    <button aria-label={this.context.t("_remove_profile_image")} onClick={() => this.removeProfilePic()} type="button" className="btn removePic">
                                        <i className="fa fa-trash" aria-hidden="true"></i>
                                    </button>
                                </figure>
                            </div>
                            <div className="col s9">
                                {/* <div className="btn-wrap type-selection profileBtnHolder">
                                    <button className="btn profileBtn yellow" onClick={() => { this.upload.click() }}>{this.context.t("_upload_new_photo")}</button>
                                    <input id="myInput"
                                        type="file"
                                        ref={(ref) => this.upload = ref}
                                        style={{ display: "none" }}
                                        onChange={this.onChangeFile.bind(this)}
                                    />
                                    <button onClick={() => this.removeProfilePic()} className="btn profileBtn">{this.context.t("_remove")}</button>
                                    <br />
                                </div> */}
                            </div>
                        </div>

                        <div className="row reverse-row">
                            {
                                hasError ? <div className="error-container fail">
                                    <h5>{this.context.t("_error_title")}</h5>
                                    <ul>
                                        {
                                            Object.keys(formData).map((item, index) => {
                                                return formData[item].error != "" ? <li key={index} ><a ref={(refItem) => { this[item + "error"] = refItem; }} tabIndex="0" href={`#${item}`} className="error" htmlFor={item}>{formData[item].error}</a></li>
                                                    :
                                                    null
                                            })}
                                    </ul>
                                </div> : null

                            }
                            {
                                submissionStatus ? <div className="error-container success" tabIndex="0" ref="successMessageRef">
                                    <h5>{`Student profile updated successfully`}</h5>
                                </div> : null
                            }
                            {
                                _.isEmpty(formData) ?
                                    <div /> :
                                    <form className="full-width" onSubmit={this.onSubmit} >
                                        <div style={{ width: "100%", display: "inline-block" }}>
                                            <div className="col s12 m6">
                                                {this.renderInput({ name: "name", label: this.context.t("_name"), placeholder: this.context.t("_name_placeholder"), isMandatory: true })}
                                                {this.renderInput({ name: "qid", label: this.context.t("_qid"), placeholder: this.context.t("_qid_placeholder"), isMandatory: true, max: 11, disabled: true })}
                                                <div className="result-type">
                                                    {
                                                        this.renderSelect({
                                                            name: "grade", label: this.context.t("_grade"), placeholder: this.context.t("_select_your_grade"), isMandatory: true, data: gradeListNew[lang]
                                                        })
                                                    }
                                                </div>
                                                <div className="result-type">
                                                    {
                                                        this.renderSelect({
                                                            name: "nationality", label: this.context.t("_nationality"), placeholder: this.context.t("_select_your_nationality"), isMandatory: true, data: CountryList, type: "country"
                                                        })
                                                    }
                                                </div>
                                                {
                                                    this.renderRadioButton({ label: this.context.t("_gender"), isMandatory: true, name: "gender", defaultChecked: "male", data: [{ label: this.context.t("_male"), value: "Male" }, { label: this.context.t("_female"), value: "Female" }] })
                                                }
                                                {/* {
                                                this.renderRadioButton({ label: this.context.t("_channel_of_communication"), name: "channel", defaultChecked: "sms", data: [{ label: this.context.t("_email"), value: "Email Address" }, { label: this.context.t("_sms"), value: "Mobile Number" }, { label: this.context.t("_phone_call"), value: "Phone Call" }] })
                                            } */}
                                            </div>
                                            <div className="col s12 m6">
                                                <div className=" date-popup">
                                                    {this.renderInput({ name: "dob", label: this.context.t("_dob"), placeholder: this.context.t("_dob_placeholder"), isMandatory: true, type: "date" })}
                                                </div>

                                                {this.renderInput({ name: "mobile", label: this.context.t("_mobile_number"), placeholder: this.context.t("_mobile_number_placeholder"), isMandatory: true, type: "phone_number", max: 8 })}
                                                {this.renderInput({ name: "parentMobile", label: this.context.t("_parent_mobile"), placeholder: this.context.t("_parent_mobile_placeholder"), type: "phone_number", max: 8 })}

                                                {this.renderInput({ name: "email", label: this.context.t("_email"), placeholder: this.context.t("_email_placeholder"), type: "email", isMandatory: true, disabled: disableEmail })}

                                                <div className="result-type">
                                                    {
                                                        this.renderSelect({
                                                            name: "schoolName", label: this.context.t("_school_name"), placeholder: this.context.t("_select_school_name"), data: schoolList, type: "school", isMandatory: true
                                                        })
                                                    }
                                                </div>
                                                {
                                                    formData.schoolName.value == "0" && this.renderInput({ name: "schoolNewName", label: this.context.t("_school_name"), placeholder: this.context.t("_select_school_name"), isMandatory: true })
                                                }
                                            </div>
                                        </div>
                                        <div className="col s12">
                                            <p><br />{this.context.t("_guardian_information")} </p>
                                        </div>
                                        <div style={{ width: "100%", display: "inline-block" }}>
                                            <div className="col s12 m6">
                                                {this.renderInput({ name: "parentName", label: this.context.t("_parent_name"), placeholder: this.context.t("_parent_name_placeholder"), isMandatory: true })}
                                                {this.renderInput({ name: "parentEmail", label: this.context.t("_parent_email"), placeholder: this.context.t("_parent_email_placeholder"), disabled: true })}

                                            </div>
                                            <div className="col s12 m6">
                                                <div className="result-type">
                                                    {
                                                        this.renderSelect({
                                                            name: "relation", label: this.context.t("_relation"), placeholder: this.context.t("_select_relation"), isMandatory: true, data: [
                                                                { label: this.context.t("_father"), value: "Father" },
                                                                { label: this.context.t("_mother"), value: "Mother" },
                                                                { label: this.context.t("_sister"), value: "Sister" },
                                                                { label: this.context.t("_brother"), value: "Brother" },
                                                                { label: this.context.t("_grand_mother"), value: "Grandmother" },
                                                                { label: this.context.t("_grand_father"), value: "Grandfather" },
                                                                { label: this.context.t("_parent"), value: "Guardian" }
                                                            ]
                                                        })
                                                    }
                                                </div>
                                                {this.renderInput({ name: "parentQid", label: this.context.t("_patent_qid"), placeholder: this.context.t("_patent_qid_placeholder"), isMandatory: true, max: 11, disabled: true })}
                                            </div>
                                        </div>


                                        <div className="col s12">
                                            <button className="btn blue">{this.context.t("_save_changes")}</button>
                                        </div>
                                    </form>
                            }
                        </div>
                        <div id="photoCrop" className="modal sm" role="dialog">
                            <div className="modal-content scroll-x-hidden">
                                <span className="badge modalClose"><button type="button" aria-label="close" tabindex="0" className="close" onClick={() => this.handleCloseModal()}><i className="material-icons">close</i></button></span>
                                <p>{this.context.t("_crop_your_image")}</p>
                                <div className="image-crop-container">
                                    <ReactCrop
                                        // crossorigin="anonymous"
                                        src={selectedImage}
                                        crop={crop}
                                        ruleOfThirds
                                        minWidth={200}
                                        minHeight={200}
                                        keepSelection={true}
                                        imageStyle={{ maxHeight: 250 }}
                                        onImageLoaded={this.onImageLoaded}
                                        onComplete={this.onCropComplete}
                                        onChange={this.onCropChange}
                                    />
                                </div>

                                <div className="clearfix" />
                                <button onClick={() => this.saveUserImage()} className="btn profileBtn yellow">{this.context.t("_upload")}</button>
                                <button onClick={() => this.handleCloseModal()} className="btn profileBtn blue-border">{this.context.t("_cancel")}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="top-line ng-scope">
                    <img src={topLineImage} alt="" />
                </div>
            </div>
        )
    }

}

const mapStateToProps = ({ i18nState }) => {
    return {
        lang: i18nState.lang
    }
}
const mapDispatchToProps = {
    showPopup: (data) => PopupAction.showPopup(data),
    hidePopup: (data) => PopupAction.hidePopup(data),
    showLoader: LoadingAction.showLoader,
    hideLoader: LoadingAction.hideLoader,
}
ViewProfileSettings.contextTypes = {
    t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewProfileSettings);
