import React from "react"
import M from "materialize-css"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import _ from "lodash"
import { Link } from "react-router-dom"

import Main from "./../main"
import PageTitle from "../includes/page_title";
import SlideToTop from "../includes/slide_to_top";
import { LoadingAction } from "./../../actions"
import { urlEndPoints } from "./../../httpAPI/apiConfig"

class MachineDetail extends Main {
    constructor(props) {
        super(props);
        this.state = {
            config: this.config(),
            machineDetails: {}
        }
    }
    componentDidMount() {
        document.title = `${this.context.t("_studio5")} | ${this.context.t("_machines")}`
        this.getMachineDetails()
    }
    componentDidUpdate() {
        document.title = `${this.context.t("_studio5")} | ${this.context.t("_machines")}`
    }
    getMachineDetails = async () => {
        const { config } = this.state
        const { match: { params: { id } } } = this.props;
        const url = `${config.origin}${urlEndPoints.getMachineDetails(id)}`
        try {
            this.props.showLoader()
            const data = await this.networkRequest(url)
            if (data.data.error) {

            } else {
                this.setState({
                    machineDetails: data.data[0]
                })
                setTimeout(() => {
                    document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
                }, 500);
            }

            M.AutoInit()
            this.props.hideLoader()
        } catch (error) {
            this.setState({ isLoaded: true })
            this.props.hideLoader()
            console.warn("error: ", error)
        }

    }
    render() {
        const { machineDetails, config } = this.state;
        return (
            <div id="main-container">
                <SlideToTop />
                <PageTitle title={this.context.t("_machines")} isDashboardItem={true} />
                <div className="container">
                    <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                        <ul>
                            <li className="breadcrumb-item">
                                <Link to="/machines">{this.context.t("_machines")}</Link>{" "}
                            </li>
                            <li className="breadcrumb-item">
                                <Link tabIndex="0" to aria-current={this.context.t("_page")}>
                                    <span>{machineDetails.title}</span>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
                {
                    !_.isEmpty(machineDetails) && <div id="skipContent" className="container">
                        <h2 className="line">{machineDetails.title}</h2>

                        <div className="row machine-detail-holder">
                            <div className="col m12 l3">
                                <figure className="machine-detail-figure">
                                    <img src={`${config.origin}${machineDetails.field_machines_image}`} alt="" />
                                </figure>
                            </div>

                            <div className="col m12 l9 machine-detail-content">
                                <h4>{machineDetails.field_sub_title} <span>{machineDetails.field_machine_type}</span></h4>
                                <h5 className="blue-text">{machineDetails.field_machine_brand}</h5>
                                <div dangerouslySetInnerHTML={{ __html: machineDetails.body }}></div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col s12">
                                <ul className="tabs machine-tab-menu">
                                    <li className="tab"><a className="active" href="#feature">{this.context.t("_feature")}</a></li>
                                    <li className="tab"><a href="#materials">{this.context.t("_materials")}</a></li>
                                    <li className="tab"><a href="#safety_procedure">{this.context.t("_safety_procedure")}</a></li>
                                </ul>
                            </div>

                            <div id="feature" className="col s12 machine-tab-content" dangerouslySetInnerHTML={{ __html: machineDetails.field_machine_features }} />
                            <div id="materials" className="col s12 machine-tab-content" dangerouslySetInnerHTML={{ __html: machineDetails.field_machine_materials }} />
                            <div id="safety_procedure" className="col s12 machine-tab-content" dangerouslySetInnerHTML={{ __html: machineDetails.field_machine_safety_procedure }} />
                        </div>
                    </div>
                }

            </div>
        )
    }
}

const mapStateToProps = ({ i18nState }) => {
    return {
        lang: i18nState.lang
    }
}
const mapDispatchToProps = {
    showLoader: LoadingAction.showLoader,
    hideLoader: LoadingAction.hideLoader,
}
MachineDetail.contextTypes = {
    t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(MachineDetail);
