import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import Main from "../main"
const axios = require("axios");
class About extends Main {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			config: this.config(),
			about: [],
		}
	}
	componentDidMount() {
		this.get_about();
	}
	get_about() {
		var self = this;
		var headers = {};
		const { config } = this.state;
		const { lang } = this.props;
		let url = "";
		if (lang == "ar")
			url = `${config.origin}${lang}/api/about`
		else
			url = `${config.origin}api/about`
		if (config.environment === "production") {
			headers = {
				"Access-Control-Allow-Credentials": true,
				"Access-Control-Allow-Headers": "Content-Type",
				"Access-Control-Allow-Methods": "GET, POST",
				"Access-Control-Allow-Origin": config.origin
			};
		}
		axios.get(url, {
			params: {
				_format: "json"
			},
			headers: headers
		})
			.then(function (response) {
				self.setState({
					about: response.data
				});
			})
			.catch(function (error) {
				console.warn(error);
			})
			.then(function () {
				// always executed
			});
	}
	render() {
		const { about } = this.state;
		return (
			<div className="about-studio container">
				{about.map((row, index) => (
					<div className="item" key={index} dangerouslySetInnerHTML={{ __html: row.body }}>
					</div>
				))}
				<img alt="" style={{ display: "none" }} src={require("../../assets/images/about-home-1.png")} />
				<span className="orange-circle"></span>
			</div>
		)
	}
}
const mapStateToProps = ({ i18nState }) => {
	return {
		lang: i18nState.lang
	};
};

const mapDispatchToProps = {}

About.contextTypes = {
	t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(About)