import Main from "../../components/main"
import _ from "lodash"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { GET_EVENT_TYPE, GET_SESSION_TYPE, GET_COMPETITION_TYPE } from "./../../constants/constants"

const MainConst = new Main();
const configConst = MainConst.config();
const getEventActivity = ({ startDate = "", endDate = "" }) => {
    return async dispatch => {
        let url = `${configConst.origin}${urlEndPoints.getDashboardTypesEvents}?`
        if (!_.isEmpty(startDate))
            url = `${url}&field_start_and_end_time_value=${startDate} 00:00:00`
        if (!_.isEmpty(endDate))
            url = `${url}&field_start_and_end_time_end_value_1=${endDate} 00:00:00`
        MainConst.networkRequest(url).then(data => {
            if (data.data.error) {
                dispatch({
                    type: GET_EVENT_TYPE,
                    payload: 0,
                })
            } else {
                dispatch({
                    type: GET_EVENT_TYPE,
                    payload: data.data.length
                })
            }
        })
    }
}
const getSessionActivity = ({ startDate = "", endDate = "" }) => {
    return async dispatch => {
        let url = `${configConst.origin}${urlEndPoints.getDashboardTypesSessions}?`
        if (!_.isEmpty(startDate))
            url = `${url}&field_start_and_end_time_value=${startDate} 00:00:00`
        if (!_.isEmpty(endDate))
            url = `${url}&field_start_and_end_time_end_value_1=${endDate} 00:00:00`
        MainConst.networkRequest(url).then(data => {
            if (data.data.error) {
                dispatch({
                    type: GET_SESSION_TYPE,
                    payload: 0,
                })
            } else {
                dispatch({
                    type: GET_SESSION_TYPE,
                    payload: data.data.length
                })
            }
        })
    }
}
const getCompetitionActivity = ({ startDate = "", endDate = "" }) => {
    return async dispatch => {
        let url = `${configConst.origin}${urlEndPoints.getDashboardTypesCompetitions}?`
        if (!_.isEmpty(startDate))
            url = `${url}&field_start_and_end_time_value=${startDate} 00:00:00`
        if (!_.isEmpty(endDate))
            url = `${url}&field_start_and_end_time_end_value_1=${endDate} 00:00:00`
        MainConst.networkRequest(url).then(data => {
            if (data.data.error) {
                dispatch({
                    type: GET_COMPETITION_TYPE,
                    payload: 0,
                })
            } else {
                dispatch({
                    type: GET_COMPETITION_TYPE,
                    payload: data.data.length
                })
            }
        })
    }
}

export default {
    getEventActivity,
    getSessionActivity,
    getCompetitionActivity,
}