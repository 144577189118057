import React from "react"
import Main from "../main"
import Slider from "react-slick"
import { Link } from "react-router-dom"
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import _ from "lodash"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { PopupAction, LoadingAction } from "./../../actions"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom";
const axios = require("axios");

class CompetitionDetails extends Main {
	constructor(props) {
		super(props);
		this.checkLogin();
		this.state = {
			isLoaded: false,
			config: this.config(),
			gallery: [],
			currentSlide: 0
		}
		this.props.showLoader()
	}
	componentDidMount() {
		const { id, lang } = this.props;
		var self = this;
		const { config } = this.state;
		let url = ""
		if (lang == "ar")
			url = `${config.origin}${lang}/api/${urlEndPoints.activityDetailsGallery(id)}`
		else
			url = `${config.origin}api/${urlEndPoints.activityDetailsGallery(id)}`

		axios.get(url, {
			params: {
				_format: "json"
			}
		})
			.then(function (response) {
				self.props.hideLoader()
				self.setState({ gallery: response.data["media-gallery"] });
			}).catch(err => {
				self.props.hideLoader()
				console.warn("Error", err)
			});

	}
	afterChangeHandler = (currentSlide) => {
		this.setState({ currentSlide })
	}
	handleEnroll = async (id) => {
		// if (_.isEmpty(localStorage.getItem("role"))) {
		// 	return this.props.history.push("/registration")
		// }
		const role = await localStorage.getItem("role");
		switch (role) {
			case "student":
				this.props.showLoader();
				this.handleEnrollStudent(id)
				break;
			case "school":
			case "parent":
				this.props.showLoader();
				this.handleEnrollParent(id)
				break;

			default:
				this.props.showPopup({
					type: "error",
					title: "",
					// message: this.context.t("_login_required"),
					message: <>
						<Link onClick={() => { this.props.hidePopup({}) }} to="/login"><b>{this.context.t("_login_link")}</b></Link> {this.context.t("_is_required_for_enrolment")}
					</>,
					isOpen: true,
					buttonText: this.context.t("_ok")
				})
				break;
		}
	}
	displayError = error => {
		const { lang } = this.props;
		if (error.name) {
			this.props.showPopup({
				type: "error",
				title: this.context.t("_failed"),
				message: error.name[lang],
				isOpen: true,
				buttonText: this.context.t("_ok")
			})
		}
	}
	handleEnrollStudent = async (id) => {
		// const authResponse = await this.auth();
		// if (authResponse) {
		const { config } = this.state
		const url = `${config.api_url}${urlEndPoints.activityEnrolment}`
		// const headers = {
		// 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
		// }
		const obj = {
			"activity_id": id
		}
		try {
			const data = await this.networkRequest(url, "POST", obj)
			this.props.hideLoader();
			if (data.data.error) {
				this.displayError(data.data.error)
			} else {
				this.props.showPopup({
					type: "success",
					title: this.context.t("_success"),
					message: this.context.t("_enroll_success"),
					isOpen: true,
					buttonText: this.context.t("_ok")
				})
			}
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
		// }

	}
	handleEnrollParent = (id) => {
		this.props.history.push(`/subscribe-activities/${id}`);
	}
	handleOpenMap = (mapUrl) => {
		window.open(mapUrl);
	}

	handleEnrolledClick = () => {
		this.props.showPopup({
			type: "error",
			title: this.context.t("_warning"),
			message: this.context.t("_already_enrolled"),
			isOpen: true,
			buttonText: this.context.t("_ok")
		})
	}
	getMapEmbeddedUrl = url => {
		var coords = /\@([0-9\.\,\-a-zA-Z]*)/.exec(url);
		if (coords != null) {
			var coordsArray = coords[1].split(",");
			return "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d20000!2d" + coordsArray[1] + "!3d" + coordsArray[0] + "!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2suk!4v1486486434098";
		}
	}

	getDateInFormat = date => {
		if (date.includes("Jan"))
			date = date.replace("Jan", this.context.t("_jan"))

		if (date.includes("Feb"))
			date = date.replace("Feb", this.context.t("_feb"))

		if (date.includes("Mar"))
			date = date.replace("Mar", this.context.t("_mar"))

		if (date.includes("Apr"))
			date = date.replace("Apr", this.context.t("_apr"))

		if (date.includes("May"))
			date = date.replace("May", this.context.t("_may"))

		if (date.includes("Jun"))
			date = date.replace("Jun", this.context.t("_jun"))

		if (date.includes("Jul"))
			date = date.replace("Jul", this.context.t("_jul"))

		if (date.includes("Aug"))
			date = date.replace("Aug", this.context.t("_aug"))

		if (date.includes("Sep"))
			date = date.replace("Sep", this.context.t("_sep"))

		if (date.includes("Oct"))
			date = date.replace("Oct", this.context.t("_oct"))

		if (date.includes("Nov"))
			date = date.replace("Nov", this.context.t("_nov"))

		if (date.includes("Dec"))
			date = date.replace("Dec", this.context.t("_dec"))

		return date
	}
	getGender = gender => {
		gender = gender.toLowerCase()
		switch (gender) {
			case "male":
				return this.context.t("_boys")
			case "female":
				return this.context.t("_girls")
			case "mixed":
				return this.context.t("_mixed")

		}
	}
	handleEnrolButton = (item, id) => {
		if (Number(item.field_number_of_attendees) > Number(item.enrolmentCount))
			return <button onClick={() => { this.handleEnroll(id) }} className="btn blue-btn ">{this.context.t("_enroll")}</button>
		else if (Number(item.enrolmentCount) < (Number(item.field_number_of_attendees) + Number(item.field_waiting_list_count)))
			return <button onClick={() => { this.handleEnroll(id) }} className="btn blue-btn ">{this.context.t("_waiting_list")}</button>
		else
			return <button onClick={() => { this.handleEnrollClosed() }} className="btn blueColor btn-disabled">
				{this.context.t("_closed")}
			</button>
	}
	getTimeInFormat = time => {
		if (time.includes("am"))
			time = time.replace(/am/g, this.context.t("_am"))

		if (time.includes("pm"))
			time = time.replace(/pm/g, this.context.t("_pm"))

		return time;
	}
	render() {
		const { result, id, isEnrolled } = this.props;
		const { gallery, currentSlide } = this.state;
		const slick_settings = {
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			afterChange: this.afterChangeHandler,

		};
		return (
			<div className="competition">
				<div className="container">
					<div className="flex-row">
						<h2>{result.eventname}</h2>
						{
							(
								localStorage.getItem("role") == "trainer" ||
								localStorage.getItem("role") == "manager" ||
								localStorage.getItem("role") == "asistant" ||
								localStorage.getItem("role") == "edu_specialist" ||
								localStorage.getItem("role") == "administrator"
							) ? null :
								result.field_need_to_show_enroll_button == "1" && (!_.isEmpty(result.field_start_and_end_time_3) && !_.isEmpty(result.field_start_and_end_time_4) && (new Date(result.field_start_and_end_time_2) >= new Date())) ?
									isEnrolled ?
										<button style={{ width: 90 }} onClick={() => { this.handleEnrolledClick() }} className="btn blue-btn btn-disabled">{this.context.t("_enrolled")}</button>
										:
										this.handleEnrolButton(result, id)
									: null
						}
					</div>
				</div>
				<div className="container">
					<div className="description" dangerouslySetInnerHTML={{ __html: result.details }}></div>
				</div>

				<div className="activity-slider">
					{
						!_.isEmpty(gallery) && <Slider {...slick_settings}>
							{gallery.map((row, index) => (
								<div className="item" key={index}>
									<img src={row} alt="" />
								</div>
							))}
						</Slider>
					}
					{
						!_.isEmpty(gallery) && <div className="topBannerCount"><strong>{`${currentSlide + 1}`}</strong>{` / ${gallery.length}`}</div>
					}

				</div>
				<div className="container">
					<div className="row details-wrap">
						<div className="col s6 main-details ">
							<h3>{this.context.t("_details")}</h3>
							<table><tbody>
								<tr>
									<th>{this.context.t("_venue")}</th>
									<td dangerouslySetInnerHTML={{ __html: _.isEmpty(result.field_activity_address) ? this.context.t("_venue_unavailable") : result.field_activity_address }}></td>
								</tr>
								<tr>
									<th>{this.context.t("_date")}</th>
									<td className="activityDetailDateRow">
										<table>
											<tbody>
												<tr>
													<th>{this.context.t("_start_date")}</th>
													<th>{this.context.t("_end_date")}</th>
												</tr>
												{
													result.field_start_and_end_time_3.map((item, index) => {
														return (
															<tr>
																<td>{`${this.getDateInFormat(item)} - ${this.getTimeInFormat(result.field_start_and_end_time[index])}`}</td>
																<td>{`${this.getDateInFormat(result.field_start_and_end_time_4[index])} - ${this.getTimeInFormat(result.field_start_and_end_time_1[index])}`}</td>
															</tr>
														)
													})
												}
											</tbody>
										</table>
									</td>
								</tr>
								{/* <tr>
								<th>{this.context.t("_date")}</th>
								<td>{this.getDateInFormat(result.field_start_and_end_time_3)} - {this.getDateInFormat(result.field_start_and_end_time_4)}</td>
							</tr> */}
								<tr>
									<th>{this.context.t("_zone")}</th>
									<td>{_.isEmpty(result.zone) ? this.context.t("_zone_unavailable") : result.zone}</td>
								</tr>
								<tr>
									<th>{this.context.t("_class_no")}</th>
									<td>{_.isEmpty(result.field_class_room) ? this.context.t("_class_no_unavailable") : result.field_class_room}</td>

								</tr>
								<tr>
									<th>{this.context.t("_trainer_name")}</th>
									<td>{_.isEmpty(result.field_trainer_name) ? this.context.t("_trainer_name_unavailable") : result.field_trainer_name}</td>

								</tr>
								<tr>
									<th>{this.context.t("_gender")}</th>
									<td>{_.isEmpty(result.gender) ? this.context.t("_gender_unavailable") : this.getGender(result.gender)}</td>
								</tr>
								<tr>
									<th>{this.context.t("_age_group")}</th>
									<td>{_.isEmpty(result.age_group) ? this.context.t("_age_group_unavailable") : result.age_group}</td>
								</tr>
								<tr>
									<th>{this.context.t("_no_of_attendee")}</th>
									<td>{_.isEmpty(result.attendees) ? this.context.t("_no_of_attendee_unavailable") : result.attendees}</td>
								</tr>
							</tbody></table>
						</div>

						<div className="col s6 main-details " />
						<div className="col s12 main-details">
							{
								!_.isEmpty(result.field_google_map) &&
								<>
									<br />
									<h3>{this.context.t("_location")}</h3>
									<div className="google-map">
										<iframe title="map" className={"map"} src={this.getMapEmbeddedUrl(result.field_google_map)} frameborder="0" allowfullscreen="" title="map" ></iframe>
										{/* <Map google={this.props.google}
								className={"map"}
								initialCenter={{
									lat: result.latitude,
									lng: result.longitude
								}}
								zoom={14}>
								<Marker
									// title={result.address}
									// name={"SOMA"}
									position={{ lat: result.latitude, lng: result.longitude }} />
							</Map> */}
										<br />
										<button onClick={() => this.handleOpenMap(result.field_google_map)} className="btn profileBtn blue-border">{this.context.t("_location_view")}</button>
									</div>
								</>
							}
						</div>
					</div>
				</div>
			</div>
		)
	}
}
var main_obj = new Main();


const mapStateToProps = ({ i18nState }) => {
	return {
		lang: i18nState.lang
	}
}
const mapDispatchToProps = {
	showPopup: (data) => PopupAction.showPopup(data),
	hidePopup: (data) => PopupAction.hidePopup(data),
	showLoader: LoadingAction.showLoader,
	hideLoader: LoadingAction.hideLoader,
}
CompetitionDetails.contextTypes = {
	t: PropTypes.func
}


export default GoogleApiWrapper({
	apiKey: main_obj.config().map_api_key
})(connect(mapStateToProps, mapDispatchToProps)(withRouter(CompetitionDetails)));