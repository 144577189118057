import React from "react";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import Main from "./../main"
import PageTitle from "../includes/page_title";
import SlideToTop from "../includes/slide_to_top";
import M from "materialize-css";
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import _ from "lodash"
import { LoadingAction } from "./../../actions"

class VirtualTour extends Main {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            config: this.config(),
            pager: { count: 0, pages: 0, items_per_page: 0, current_page: 1 },
            virtualTourList: []
        }
        this.props.showLoader()
    }
    componentDidMount() {
        M.AutoInit();
        this.getVirtualTour()
        // document.addEventListener("scroll", this.trackScrolling)
    }
    getVirtualTour = async () => {
        const { config } = this.state
        const url = `${config.api_url}${urlEndPoints.getVirtualTours}`
        try {
            const data = await this.networkRequest(url)
            if (data.data.error) {

            } else {
                this.setState({
                    virtualTourList: data.data.results,
                    pager: data.data.pager,
                    isLoaded: true
                })

            }
            this.props.hideLoader()
        } catch (error) {
            this.setState({ isLoaded: true })
            this.props.hideLoader()
            console.warn("error: ", error)
        }
    }
    render() {
        const { pager, virtualTourList, config, isLoaded } = this.state;
        return (
            <div id="main-container">
                <SlideToTop />
                <PageTitle title={"Virtual Tour"} />
                <div className="container">
                    <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                        <ul>
                            <li className="breadcrumb-item">
                                <Link to="/">{`Home`}</Link> {" "}

                            </li>
                            <li className="breadcrumb-item">
                                <span>
                                    Virtual Tour
                                </span>

                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="container">

                    <h2>Title</h2>

                    <div className="row noFlex">
                        {isLoaded && virtualTourList.length == 0 ? <div className="row"><p aria-label={this.context.t("_no_result_found")} tabIndex="0" className="col s12 no-result">{this.context.t("_no_result_found")}</p></div> :
                            virtualTourList.map(item => {
                                return (
                                    <div className="col s12 m6 l4">
                                        <div className="card virtualBox">
                                            <div className="card-image">
                                                <a className="btn-floating btn-large valign-wrapper iconPlay"><i className="material-icons large">play_arrow</i></a>
                                                <img src={`${config.origin}${item.field_360_panoramas_photo}`} alt="" />
                                            </div>
                                            <div className="card-content cardText">
                                                <h3 className="card-title activator">{item.title} <i className="material-icons right">more_vert</i></h3>
                                                <div dangerouslySetInnerHTML={{ __html: item.body }}></div>
                                            </div>
                                            <div className="card-reveal">
                                                <h3 className="card-title">{item.title} <i className="material-icons right">close</i></h3>
                                                <div dangerouslySetInnerHTML={{ __html: item.body }}></div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <nav className="pagination-wrapper" aria-label="pagination">
                    <Pagination

                        hideDisabled={true}
                        activePage={pager.current_page + 1}
                        itemsCountPerPage={pager.items_per_page}
                        totalItemsCount={pager.count}
                        pageRangeDisplayed={5}
                    // onChange={this.handlePageChange}
                    />
                </nav>
            </div>
        );
    }
}


const mapStateToProps = ({ i18nState }) => {
    return {
        lang: i18nState.lang
    }
}
const mapDispatchToProps = {
    showLoader: LoadingAction.showLoader,
    hideLoader: LoadingAction.hideLoader,
}
VirtualTour.contextTypes = {
    t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(VirtualTour);
