import React from "react"
import Main from "../../main"
import SlideToTop from "../../includes/slide_to_top.js"
import PageTitle from "../../includes/page_title.js"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import DatePicker from "react-datepicker"
import { dynamicStyles, customModalStyles } from "./../../../constants/dynamicStyles"
import { Link } from "react-router-dom"
import Pagination from "react-js-pagination";
import { urlEndPoints } from "./../../../httpAPI/apiConfig"
import M from "materialize-css"
import { LoadingAction, PopupAction } from "./../../../actions"
import _ from "lodash"
import topLineImage from "../../../assets/images/top-line.png"
import Modal from "react-modal";
import dateformat from "dateformat"
import { CustomDateInput, calenderLocal } from "./../../../constants/common"

class ActivityHistory extends Main {
	constructor(props) {
		super(props);
		this.checkLogin();
		this.state = {
			isLoaded: false,
			config: this.config(),
			currentActivityList: [],
			pager: { count: 0, pages: 0, items_per_page: 0, current_page: 1 },
			search: { type: "All", title: "", start_date: "", end_date: "", page: 0 },
			studentList: [],
			checkedList: [],
			selectedActivityId: null,
			intervalId: 0,
			scrollStepInPx: 50,
			delayInMs: 16.66,
			cancelActivityConfirm: false,
			cancelActivityId: null
		}
		this.handlePageChange = this.handlePageChange.bind(this);
		this.props.showLoader()
	}
	async componentDidMount() {
		document.title = `${this.context.t("_studio5")} | ${this.context.t("_current_activities")}`
		await this.initSearchComponents();
		M.AutoInit();
		await this.getActivity();
		window.addEventListener('load', this.handleTabIndex());
		setTimeout(() => {
			document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
		}, 500);
	}
	componentDidUpdate() {
		document.title = `${this.context.t("_studio5")} | ${this.context.t("_current_activities")}`
	}
	handleTabIndex = () => {
		setTimeout(() => {
			let elem = document.querySelectorAll('select');
			elem.forEach(element => {
				element.setAttribute("tabindex", "0");
			});
		}, 1000);
	}
	initSearchComponents() {
		var options = { autoClose: true, format: "dd/mm/yyyy" };
		var start_date_options = {
			autoClose: true,
			format: "dd/mm/yyyy",
			showClearBtn: true,
			onSelect: ((date) => {
				this.handleChange("start_date", dateformat(date, "dd/mm/yyyy"))
			}),
			format: "dd/mm/yyyy",
		};
		var end_date_options = {
			autoClose: true,
			format: "dd/mm/yyyy",
			showClearBtn: true,
			onSelect: ((date) => {
				this.handleChange("end_date", dateformat(date, "dd/mm/yyyy"))
			}),
			format: "dd/mm/yyyy",
		};
		setTimeout(() => {
			let start_date = document.querySelectorAll("#start_date");
			let end_date = document.querySelectorAll("#end_date");
			// M.Datepicker.init(start_date, start_date_options);
			// M.Datepicker.init(end_date, end_date_options);
			let elems = document.querySelectorAll("select");
			M.FormSelect.init(elems, options);
		}, 1000);
	}
	getChildren = async () => {
		const { config } = this.state
		const url = `${config.api_url}${urlEndPoints.myChildren}`

		try {
			const data = await this.networkRequest(url)
			this.props.hideLoader();
			if (!data.data.message) {
				return data.data.results && data.data.results.length > 0 ? data.data.results : []
			}
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
		// }
	}
	getActivity = async () => {
		const { config, search } = this.state;
		// const authResponse = await this.auth();
		// if (authResponse) {
		let methord = "POST"
		let param = {};
		const body = {}
		let url = `${config.api_url}${urlEndPoints.volunteerActivityHistory}`

		if (!_.isEmpty(search.title) && search.title != "All")
			body.field_event_type_value = search.title
		if (search.start_date)
			body.field_start_end_dates_value = dateformat(search.start_date, "dd-mm-yyyy")
		if (search.end_date)
			body.field_start_end_dates_end_value = dateformat(search.end_date, "dd-mm-yyyy")
		if (search.page)
			body.page = search.page


		// let date = null
		// if (!_.isEmpty(search.start_date))
		// 	date = search.start_date;
		// if (!_.isEmpty(search.end_date))
		// 	if (!_.isEmpty(search.start_date))
		// 		date = `${search.start_date}-${search.end_date}`;
		// 	else
		// 		date = search.end_date;
		// if (!_.isEmpty(date))
		// 	url = `${url}&field_start_end_dates_value=${date.replace(/[/]/g, "-")}`
		// const headers = {
		// 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
		// }
		try {
			const data = await this.networkRequest(url, methord, body)
			if (data.status == 200) {
				this.scrollToTop()
				this.props.hideLoader()
				const currentActivityList = data.data.results;
				currentActivityList.forEach(async item => {
					item.field_start_and_end_time = item.field_start_and_end_time.split("*")
					item.field_start_and_end_time_1 = item.field_start_and_end_time_1.split("*")
					item.field_start_and_end_time_2 = item.field_start_and_end_time_2.split("*")
				})
				this.setState({
					currentActivityList,
					pager: data.data.pager,
					isLoaded: true,
					checkedList: {}
				})
			}
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
		// }
	}
	calculateAge = (dateString) => {
		var today = new Date();
		var birthDate = new Date(dateString);
		var age = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	}
	handleCheck = (event, id, index) => {
		const { checkedList } = this.state
		if (event.target.checked)
			checkedList[index] = id
		else
			delete checkedList[index]
		this.setState({ checkedList })

	}
	handlePageChange(pageNumber) {
		this.props.showLoader()
		var { search } = this.state;
		search.page = pageNumber - 1;
		this.setState({ search: search });
		this.getActivity();
	}
	handleChange = (field, value) => {
		var { search } = this.state;
		search[field] = value;
		this.setState({ search: search });
	}
	handelCancelActivity = async id => {
		this.props.showLoader();
		const { config } = this.state;
		const { lang } = this.props;
		// const authResponse = await this.auth();
		// if (authResponse) {
		const url = `${config.api_url}${urlEndPoints.cancelActivity}`
		const obj = {
			"activity_id": id
		}
		// const headers = {
		// 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
		// }
		try {
			const data = await this.networkRequest(url, "POST", obj);
			if (data.status == 200) {
				if (!_.isEmpty(data.data.error)) {
					this.props.showPopup({
						type: "error",
						title: this.context.t("_error"),
						message: data.data.error.name[lang],
						isOpen: true,
						buttonText: this.context.t("_ok")
					})
				} else {
					this.props.showPopup({
						type: "success",
						title: this.context.t("_success"),
						message: this.context.t("_successfully_cancelled"),
						isOpen: true,
						buttonText: this.context.t("_ok")
					})
				}

				this.getActivity()
			}
		} catch (error) {
			this.props.showPopup({
				type: "error",
				title: this.context.t("_failed"),
				message: this.context.t("_failed_to_cancel"),
				isOpen: true,
				buttonText: this.context.t("_ok")
			})
			this.props.hideLoader()
			console.warn("error: ", error)
		}
		// }
	}
	filterFormSubmit = event => {
		event.preventDefault();
		this.props.showLoader()
		this.getActivity();
	}
	clearSearch = async () => {
		this.props.showLoader()
		this.myFormRef.reset()
		// let elems = document.querySelector("#start_date");
		// let instance = M.Datepicker.getInstance(elems);
		// instance.setInputValue();
		// elems = document.querySelector("#end_date");
		// instance = M.Datepicker.getInstance(elems);
		// instance.setInputValue();
		this.setState({ search: { type: "All", title: "", start_date: "", end_date: "", page: 0 } }, () => { this.getActivity(); })
	}
	handleGetStudentList = async id => {
		this.props.showLoader()
		// const authResponse = await this.auth();
		const { formData } = this.state;
		// if (authResponse) {
		const { config } = this.state
		const url = `${config.api_url}${urlEndPoints.enrolledUsers(id)}`

		// const headers = {
		// 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
		// }
		try {
			const data = await this.networkRequest(url)
			this.props.hideLoader();
			if (!data.data.message) {
				const studentList = _.orderBy(data.data.results, ["field_student_name"], ["asc"])
				this.setState({ studentList, selectedActivityId: id })

				let elems = document.querySelector("#enrollments");
				elems.setAttribute("aria-hidden", "false")
				elems.setAttribute("tabindex", "-1")
				let instance = M.Modal.getInstance(elems);
				instance.open();
			}
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
		// } else {
		// 	this.props.hideLoader()
		// }
	}
	handleCloseModal = () => {
		let elems = document.querySelector("#enrollments");
		elems.removeAttribute("aria-hidden")
		elems.removeAttribute("tabindex")
		let instance = M.Modal.getInstance(elems);
		instance.close();
	}
	handleDetailRedirect = id => {
		this.props.history.push(`/activity/${id}`);
	}
	handleCancelMultipleActivity = async () => {
		this.props.showLoader();
		const { config, checkedList, selectedActivityId } = this.state;
		const { lang } = this.props;
		if (!_.isEmpty(checkedList)) {
			// const authResponse = await this.auth();
			// if (authResponse) {
			const url = `${config.api_url}${urlEndPoints.cancelActivity}`
			const obj = {
				"activity_id": selectedActivityId,
				user_ids: checkedList
			}
			// const headers = {
			// 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
			// }
			try {
				const data = await this.networkRequest(url, "POST", obj);
				if (data.status == 200) {
					if (!_.isEmpty(data.data.error)) {
						this.props.showPopup({
							type: "error",
							title: this.context.t("_error"),
							message: data.data.error.name[lang],
							isOpen: true,
							buttonText: this.context.t("_ok")
						})
					} else {
						this.props.showPopup({
							type: "success",
							title: this.context.t("_success"),
							message: this.context.t("_successfully_cancelled"),
							isOpen: true,
							buttonText: this.context.t("_ok")
						})
					}
					this.handleCloseModal();
					this.getActivity()
				}
			} catch (error) {
				this.props.showPopup({
					type: "error",
					title: this.context.t("_failed"),
					message: this.context.t("_failed_to_cancel"),
					isOpen: true,
					buttonText: this.context.t("_ok")
				})
				this.props.hideLoader()
				console.warn("error: ", error)
			}
			// }
		} else {
			this.handleCloseModal();
			this.getActivity()
		}

	}
	scrollStep() {
		var scrollStepInPx = this.props.scrollStepInPx ? this.props.scrollStepInPx : this.state.scrollStepInPx;
		if (window.pageYOffset === 0) {
			clearInterval(this.state.intervalId);
		}
		window.scroll(0, window.pageYOffset - scrollStepInPx);
	}
	scrollToTop() {
		window.scroll({ top: 0, left: 0, behavior: "smooth" })
		// var delayInMs = this.props.delayInMs ? this.props.delayInMs : this.state.delayInMs;
		// let intervalId = setInterval(this.scrollStep.bind(this), delayInMs);
		// this.setState({ intervalId: intervalId });
	}
	handleCloseConfirmModal = (state) => {
		this.setState({ [state]: false, cancelActivityId: null })
	}
	handleOpenCancelActivityConfirm = id => {
		this.setState({ cancelActivityConfirm: true, cancelActivityId: id })
	}
	handleContinueCancelActivity = () => {
		const { cancelActivityId } = this.state;
		if (!_.isEmpty(cancelActivityId))
			this.handelCancelActivity(cancelActivityId)
		this.handleCloseConfirmModal("cancelActivityConfirm")
	}
	showStatus = status => {
		switch (status) {
			case "Unpublished":
				return this.context.t("_pending");
			case "Waiting List":
				return this.context.t("_waiting_list");

			default:
				return status;
		}
	}
	render() {
		const { currentActivityList, pager, isLoaded, search, studentList, checkedList, cancelActivityConfirm } = this.state;
		const listActivity = search.type == "All" ? currentActivityList : currentActivityList.filter(data => data.field_event_type.toLowerCase() == search.type)
		const { lang } = this.props;
		return (
			<div id="main-content" className="activiti-list">
				<SlideToTop />
				<PageTitle title={this.context.t("_current_activities")} />
				<div className="container">
					<nav className="breadcrumb aside-breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
						<ul>
							<li className="breadcrumb-item">
								<Link to="/profile">{this.context.t("_profile")}</Link> {" "}

							</li>
							<li className="breadcrumb-item">
								<span>{this.context.t("_current_activities")} </span>

							</li>
						</ul>
					</nav>
					<Link className="activityLink" to="/activities">{this.context.t("_explore_activity_1")}</Link>
					<div className="clearfix"></div>
				</div>
				<div id="main-container">
					<div id="skipContent" className="container">
						<form onSubmit={this.filterFormSubmit} ref={(el) => this.myFormRef = el}>
							<div className="row topFormHolder">
								<div className="col s12 m6 l3">
									<div className={`input-field mobileSelect item ${lang}`}>
										<label className="active" style={dynamicStyles.position(lang)}>{this.context.t("_activity_type")}</label>
										<select className="browser-default" value={search.title} onChange={evt => this.handleChange("title", evt.target.value)}>
											<option value="" disabled >{this.context.t("_select_activity_type")}</option>
											<option value="All" >{this.context.t("_all")}</option>
											<option value="competition">{this.context.t("_competitions")}</option>
											<option value="session">{this.context.t("_sessions")}</option>
											<option value="event">{this.context.t("_events")}</option>
											<option value="challenge">{this.context.t("_challenges")}</option>
											<option value="project">{this.context.t("_projects")}</option>
										</select>
										<span className="helper-text"></span>
									</div>
								</div>

								<div className="col s12 m6 l3 date-popup">
									<div className="input-field item">
										<DatePicker
											name="start_date"
											placeholderText={this.context.t("_date")}
											id="start_date"
											autoComplete="off"
											dateFormat="yyyy-MM-dd"
											locale={calenderLocal(this.context)}
											customInput={<CustomDateInput ariaLabel={this.context.t("_select_date_button")} onFieldChange={date => this.handleChange("start_date", new Date(date))} />}
											onChange={date =>
												this.handleChange("start_date", date)
											}
											onCalendarOpen={e => {
												let elems = document.querySelector(".react-datepicker");
												elems.setAttribute("tabindex", "0")
												elems.focus()
												elems = document.querySelector(".react-datepicker__month-select");
												elems.setAttribute("aria-label", this.context.t("_select_month"))
												elems = document.querySelector(".react-datepicker__year-select");
												elems.setAttribute("aria-label", this.context.t("_select_year"))

											}}
											onCalendarClose={e => {
												var elems = document.querySelector("#start_date");
												elems.setAttribute("aria-label", `${this.context.t("_selected_date")} ${dateformat(search.start_date, "dd-mm-yyyy")}`)
												elems.setAttribute("value", dateformat(search.start_date, "yyyy-mm-dd"))
												setTimeout(() => {
													elems.focus()
												}, 200);
											}}
											style={dynamicStyles.textAlign(lang)}
											value={search.start_date}
											selected={search.start_date}
											peekNextMonth
											showMonthDropdown
											showYearDropdown
											dropdownMode="select"
										/>
										<label htmlFor="start_date" style={dynamicStyles.position(lang)}>{this.context.t("_start_date")}</label>
									</div>
								</div>

								<div className="col s12 m6 l3 date-popup">
									<div className="input-field item">
										<DatePicker
											name="end_date"
											placeholderText={this.context.t("_date")}
											id="end_date"
											autoComplete="off"
											dateFormat="yyyy-MM-dd"
											locale={calenderLocal(this.context)}
											customInput={<CustomDateInput ariaLabel={this.context.t("_select_date_button")} onFieldChange={date => this.handleChange("end_date", new Date(date))} />}
											onChange={date =>
												this.handleChange("end_date", date)
											}
											onCalendarOpen={e => {
												let elems = document.querySelector(".react-datepicker");
												elems.setAttribute("tabindex", "0")
												elems.focus()
												elems = document.querySelector(".react-datepicker__month-select");
												elems.setAttribute("aria-label", this.context.t("_select_month"))
												elems = document.querySelector(".react-datepicker__year-select");
												elems.setAttribute("aria-label", this.context.t("_select_year"))

											}}
											onCalendarClose={e => {
												var elems = document.querySelector("#end_date");
												elems.setAttribute("aria-label", `${this.context.t("_selected_date")} ${dateformat(search.end_date, "dd-mm-yyyy")}`)
												elems.setAttribute("value", dateformat(search.end_date, "yyyy-mm-dd"))
												setTimeout(() => {
													elems.focus()
												}, 200);
											}}
											style={dynamicStyles.textAlign(lang)}
											value={search.end_date}
											selected={search.end_date}
											peekNextMonth
											showMonthDropdown
											showYearDropdown
											dropdownMode="select"
										/>
										<label htmlFor="end_date" style={dynamicStyles.position(lang)}>{this.context.t("_end_date")}</label>
									</div>
								</div>

								<div className="col s12 m6 l3 valign-wrapper center-align search-btn-holder">
									<button className="btn noWidth profileBtn yellow waves-effect waves-light">
										{this.context.t("_search")} <i className={`tiny material-icons ${lang}`} >{`${lang == "en" ? "arrow_forward" : "arrow_backward"}`}</i>
									</button>
									<button type="button" onClick={() => this.clearSearch()} className="btn noWidth  blue-btn waves-effect waves-light">
										{this.context.t("_clear")}
									</button>
									<br /><br />
								</div>
							</div>
						</form>

						<div className="row">
							<div className="col s7 result-status">
								{isLoaded ? this.context.t("_search_result") + " ( " + (listActivity.length) + " of " + pager.count + " )" : ""}

							</div>
							<div className="col s5 result-type">
								<div className="input-field mobileSelect col s12 filter-select">
									<label htmlFor="result-type">{this.context.t("_select_activity")}</label>
									<select className="browser-default" id="result-type" value={search.type} onChange={evt => this.handleChange("type", evt.target.value)}>
										<option value="All" >{this.context.t("_all")}</option>
										<option value="competition">{this.context.t("_competitions")}</option>
										<option value="session">{this.context.t("_sessions")}</option>
										<option value="event">{this.context.t("_events")}</option>
										<option value="challenge">{this.context.t("_challenges")}</option>
										<option value="project">{this.context.t("_projects")}</option>
									</select>
								</div>
							</div>
						</div>
						<div className="activities">
							{listActivity.map((row, index) => (
								<div className="row" key={index}>
									<div className="col s7">
										<Link to={`/activity/${row.nid}`}>
											<h3 className="title">{row.title} <span className={row.field_event_type.toLowerCase()}>{row.field_event_type}</span></h3>
										</Link>

										<div className="details">
											{row.field_start_and_end_time &&
												<span><i className="calendar-icons"></i> {this.context.t("_start_date")} <span className="date">{row.field_start_and_end_time[0]}</span></span>
											}
											{row.field_start_and_end_time_1 &&
												<span><i className="calendar-icons"></i> {this.context.t("_end_date")} <span className="date">{row.field_start_and_end_time_1[row.field_start_and_end_time_1.length - 1]}</span></span>
											}
										</div>
									</div>
									<div className="col s5 btn-wrap flex-row justify-end align-center">
										{
											localStorage.getItem("role") == "volenteer" ?
												null :
												localStorage.getItem("role") == "student" ?
													<button onClick={() => this.handleOpenCancelActivityConfirm(row.nid)} className="btn blue-border">Cancel</button>
													:
													<button onClick={() => this.handleGetStudentList(row.nid)} className="btn blue-border">Enrollments</button>
										}

										{/* <button onClick={() => { this.handleDetailRedirect(row.nid) }} className="btn blue-border">Details</button> */}

									</div>
								</div>
							))}
							{!currentActivityList.length && isLoaded &&
								<div className="row"><p aria-label={this.context.t("_no_result_found")} className="col s12 no-result">{this.context.t("_no_result_found")}</p></div>
							}
						</div>
						<div id="enrollments" className="modal user-add-modal" role="dialog">
							<div className="modal-content">
								{/* <form onSubmit={this.handleAddNewStudent} className="col full-width"> */}
								<span className="badge modalClose"><a onClick={() => this.handleCloseModal()}><i className="material-icons">close</i></a></span>
								<h2 className="line">{this.context.t("_enrollments")}</h2>
								<div className="tableHolder">

									<table className="responsive-table highlight">
										<thead className="blue lighten-5">
											<tr>
												<th><strong>{this.context.t("_name")}</strong></th>
												<th><strong>{this.context.t("_qid")}</strong></th>
												<th><strong>{this.context.t("_phone_number")}</strong></th>
												<th><strong>{this.context.t("_grade")}</strong></th>
												<th><strong>{this.context.t("_gender")}</strong></th>
												<th><strong>{this.context.t("_age")}</strong></th>
												<th width="50"><strong><span style={{ display: "none" }}>status</span></strong></th>
											</tr>
										</thead>

										<tbody>
											{
												studentList.map((item, index) => {
													return (
														<tr key={index}>
															<td>{item.field_student_name}</td>
															<td>{item.field_qid}</td>
															<td>{item.field_student_mobile}</td>
															<td>{item.field_grade}</td>
															<td>{item.field_gender}</td>
															<td>{!_.isEmpty(item.field_student_dob) ? this.calculateAge(item.field_student_dob) : null}</td>
															<td>

																{
																	item.field_published_status == "Published" ? <p className="checkboxHolder checkboxNew">
																		<input onChange={(e) => this.handleCheck(e, item.uid, index)} checked={checkedList[index] ? true : false} type="checkbox" />
																		<label>
																			<span></span>
																		</label></p> : this.showStatus(item.field_published_status)
																}


															</td>
														</tr>
													)
												})
											}
										</tbody>
									</table>
								</div>
								<button onClick={() => { this.handleCancelMultipleActivity() }} className="btn blue-border">Cancel</button>
								{/* </form> */}
								{/* {
									_.isEmpty(studentFormData) ?
										<div /> :
										<form onSubmit={this.handleAddNewStudent} className="col full-width">
											<span className="badge modalClose"><a onClick={() => this.handleCloseModal()}><i className="material-icons">close</i></a></span>
											<h2 className="line">{this.context.t("_add_student")}</h2>

											<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit, tellus arcu maximus ipsum, ut pulvinar erat lacus at libero.</p>
											{hasError ? <div className="error-container fail">
												<h5>{this.context.t("_error_title")}</h5>
												<ul>
													{
														Object.keys(studentFormData).map((item, index) => {
															return studentFormData[item].error != "" ? <li key={index} ref={(refItem) => { this[item + "error"] = refItem; }}><label className="error" htmlFor={item}>{studentFormData[item].error}</label></li>
																:
																null
														})}
												</ul>
											</div> : null
											}
											<p>{this.context.t("_required_field")}{" "} <span className="asterisk">*</span></p>

											<div className="row">
												<div className="col s12 m6">
													{this.renderInput({ name: "name", label: this.context.t("_student_name"), placeholder: this.context.t("_student_name_placeholder"), isMandatory: true })}
													{this.renderInput({ name: "email", label: this.context.t("_email"), placeholder: this.context.t("_email_placeholder"), type: "email" })}

												</div>
												<div className="col s12 m6">
													<div className="date-popup">
														{this.renderInput({ name: "dob", label: this.context.t("_dob"), placeholder: this.context.t("_dob_placeholder"), isMandatory: true, type: "date" })}
													</div>

													{this.renderInput({ name: "mobile", label: this.context.t("_mobile_number"), placeholder: this.context.t("_mobile_number_placeholder"), isMandatory: true, type: "phone_number" })}
												</div>
												<div className="col s12">
													<button className="btn profileBtn blue waves-effect waves-light">{this.context.t("_save_changes")}</button>
												</div>
												<div className="clearfix" />
											</div>
										</form>
								} */}
							</div>
						</div>
						<nav className="pagination-wrapper" aria-label="pagination">
							{(currentActivityList.length && isLoaded) ? (
								<Pagination
									prevPageText={`< ${this.context.t("_previous")}`}
									nextPageText={`${this.context.t("_next")} >`}
									hideFirstLastPages={true}
									hideDisabled={true}
									linkClassPrev="linkClassPrev"
									linkClassNext="linkClassNext"
									activePage={pager.current_page + 1}
									itemsCountPerPage={pager.items_per_page}
									totalItemsCount={pager.count}
									pageRangeDisplayed={5}
									onChange={this.handlePageChange}
								/>) : ""
							}
						</nav>
					</div>
					<div className="sparkles">
						<span className="orange-circle"></span>
						<span className="multi-square two"><b><i></i></b></span>
						<span className="grey-square-rotate red-sq one"></span>
						<span className="grey-square-rotate red-sq two"></span>
					</div>
				</div>
				<div className="top-line ng-scope">
					<img src={topLineImage} alt="" />
				</div>
				<Modal
					isOpen={cancelActivityConfirm}
					style={customModalStyles}
					contentLabel="unlink parent confirm modal"
					ariaHideApp={false}
				>
					<div className="popupAlert warning">
						<br />
						<h3>{this.context.t("_confirm")}</h3>
						<p>{this.context.t("_cancel_activity_confirm")}</p>
						<div className="row">
							<button className="col btn blue" onClick={() => this.handleCloseConfirmModal("cancelActivityConfirm")}>{this.context.t("_cancel")}</button>
							<button className="col btn profileBtn yellow" onClick={() => this.handleContinueCancelActivity()}>{this.context.t("_continue")}</button>
						</div>
					</div>
				</Modal>
			</div>
		)
	}
}

const mapStateToProps = ({ i18nState }) => {
	return {
		lang: i18nState.lang
	}
}
const mapDispatchToProps = {
	showLoader: LoadingAction.showLoader,
	hideLoader: LoadingAction.hideLoader,
	showPopup: (data) => PopupAction.showPopup(data),
	hidePopup: (data) => PopupAction.hidePopup(data),
}
ActivityHistory.contextTypes = {
	t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(ActivityHistory);
