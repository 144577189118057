import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import M from "materialize-css";
import Pagination from "react-js-pagination";
import dateformat from "dateformat"
import Modal from "react-modal";
import Main from "../../main";
import PageTitle from "../../includes/page_title.js";
import SlideToTop from "../../includes/slide_to_top.js";
import { qidValidate, emailValidate, gradeListNew } from "./../../../constants/common"
import { dynamicStyles, customModalStyles } from "./../../../constants/dynamicStyles"
import { urlEndPoints } from "./../../../httpAPI/apiConfig"
import { PopupAction, LoadingAction } from "./../../../actions"

import topLineImage from "../../../assets/images/top-line.png"
const today = new Date()
class MarkAttendance extends Main {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            formData: {},
            config: this.config(),
            intervalId: 0,
            scrollStepInPx: 50,
            delayInMs: 16.66,
            studentList: [],
            studentEnrolmentList: [],
            pager: { count: 0, pages: 0, items_per_page: 0, current_page: 1 },
            search: { _format: "json", type: "All", title: "", field_start_end_dates_value: "", page: 0 },
            studentAttendance: {},
            errors: {},
            removeAttendanceConfirm: false,
            sendNotificationConfirm: false,
            removeStudentId: null,
            editable: {},
            dateList: [],
            selectedDate: ""
        };
        // this.props.showLoader();
    }
    async componentDidMount() {
        document.title = `${this.context.t("_studio5")} | ${this.context.t("_attendance")}`
        this.props.showLoader()
        // this.initComponent();
        this.initState()
        M.AutoInit();
        await this.getStudentList()
        this.getActivityDetails()
        setTimeout(() => {
            var elems = document.querySelectorAll(".tooltipped");
            M.Tooltip.init(elems);
            elems = document.querySelectorAll(".modal");
            M.Modal.init(elems, {
                autoClose: true,
                dismissible: true
            });
            setTimeout(() => {
                document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
            }, 500);
        }, 1000);
        window.addEventListener('load', this.handleTabIndex());
    }
    componentDidUpdate() {
        document.title = `${this.context.t("_studio5")} | ${this.context.t("_attendance")}`
    }
    handleTabIndex = () => {
        setTimeout(() => {
            let elem = document.querySelectorAll('select');
            elem.forEach(element => {
                element.setAttribute("tabindex", "0");
            });
        }, 1000);
    }
    initState = () => {
        this.setState({
            formData: {
                studentId: { value: "", error: "", class: "" },
                email: { value: "", error: "", class: "" },
            }
        })
    }
    getActivityDetails = async () => {
        const { match: { params } } = this.props;
        const { config, studentAttendance } = this.state
        let url = `${config.api_url}${urlEndPoints.getActivityDate}`
        const body = {
            actiity_id: params.activityId
        }
        try {
            const data = await this.networkRequest(url, "POST", body)
            const result = data.data.results;
            result.field_start_and_end_time_4 = result.field_start_and_end_time_4.split("*")
            result.field_start_and_end_time = result.field_start_and_end_time.split("*")
            result.field_start_and_end_time_1 = result.field_start_and_end_time_1.split("*")
            result.field_start_and_end_time_5 = result.field_start_and_end_time_5.split("*")
            result.field_start_and_end_time_6 = result.field_start_and_end_time_6.split("*")
            const dateList = []
            result.field_start_and_end_time_5.map((item, index) => {
                let tempObj = {
                    startDate: item,
                    endDate: result.field_start_and_end_time_6[index],
                    startTime: result.field_start_and_end_time[index],
                    endTime: result.field_start_and_end_time_1[index],
                    date: result.field_start_and_end_time_5[index],
                    displayDate: result.field_start_and_end_time_4[index],
                }
                dateList.push(tempObj)
            })
            this.setState({ dateList, selectedDate: dateList.length > 0 ? `${dateList[0].startDate}*${dateList[0].endDate}*${dateList[0].date}` : "" }, () =>
                this.getFullAttendance()
            )
        } catch (error) {
            this.props.hideLoader()
            console.warn("error: ", error)
        }
    }
    getStudentList = async () => {
        const { match: { params } } = this.props;
        const { config, pager, selectedDate, studentAttendance } = this.state
        const [start, end] = selectedDate.split("*")
        // const studentAttendance = {};
        let url = `${config.api_url}${urlEndPoints.getActivityEnrollments(params.activityId)}`
        if (pager.page)
            url = `${url}?page=${pager.page}`
        try {
            const data = await this.networkRequest(url)
            if (!data.data.message) {
                const studentEnrolmentList = _.orderBy(data.data.results, ["field_student_name"], ["asc"]);
                const pager = data.data.pager;
                this.setState({ studentEnrolmentList, studentAttendance, editable: {}, pager })
            }
            // this.props.hideLoader();
        } catch (error) {
            this.props.hideLoader()
            console.warn("error: ", error)
        }
    }
    getFullAttendance = async () => {
        const { match: { params } } = this.props;
        const { config, selectedDate, studentEnrolmentList, studentAttendance } = this.state
        const [start, end, date] = selectedDate.split("*")
        const url = `${config.api_url}${urlEndPoints.getFullAttendance(params.activityId)}?field_student_in_time_value=${date}`

        try {
            const data = await this.networkRequest(url)
            // const studentAttendance = {};
            studentEnrolmentList.forEach(enrolItem => {
                data.data.forEach(attendanceItem => {
                    if (attendanceItem.field_student == enrolItem.uid) {
                        enrolItem.field_in = attendanceItem.field_in;
                        enrolItem.field_out = attendanceItem.field_out;
                        enrolItem.field_attendence_removed = attendanceItem.field_attendence_removed;
                    }
                })
                if (_.isEmpty(studentAttendance[enrolItem.uid])) {
                    studentAttendance[enrolItem.uid] = {};
                    studentAttendance[enrolItem.uid].in = (_.isEmpty(enrolItem.field_in) || enrolItem.field_in == "0") ? false : true;
                    studentAttendance[enrolItem.uid].out = (_.isEmpty(enrolItem.field_out) || enrolItem.field_out == "0") ? false : true;
                    // studentAttendance[elems.uid].nid = elems.nid;
                    studentAttendance[enrolItem.uid].startDate = start;
                    studentAttendance[enrolItem.uid].endDate = end;
                } else {
                    studentAttendance[enrolItem.uid].in = studentAttendance[enrolItem.uid].in ? studentAttendance[enrolItem.uid].in : (_.isEmpty(enrolItem.field_in) || enrolItem.field_in == "0") ? false : true;
                    studentAttendance[enrolItem.uid].out = studentAttendance[enrolItem.uid].out ? studentAttendance[enrolItem.uid].out : (_.isEmpty(enrolItem.field_out) || enrolItem.field_out == "0") ? false : true;
                    // studentAttendance[elems.uid].nid = elems.nid;
                    studentAttendance[enrolItem.uid].startDate = start;
                    studentAttendance[enrolItem.uid].endDate = end;
                }
            })
            this.setState({
                studentList: studentEnrolmentList,
                studentAttendance,
                isLoaded: true
            })
            this.props.hideLoader();
        } catch (error) {
            this.setState({ isLoaded: true })
            this.props.hideLoader()
            console.warn("error: ", error)
        }
    }
    initComponent = (elem) => {
        const minDate = new Date(elem.field_start_and_end_time);
        const weekBefore = minDate.getDate() - 7
        minDate.setDate(weekBefore);
        let startDefaultDate = ""
        let endDefaultDate = ""
        if (!_.isEmpty(elem.field_student_in_time)) {
            const [date, month, year] = elem.field_student_in_time.split("/");
            startDefaultDate = new Date(`${month}/${date}/${year}`)
        }
        if (!_.isEmpty(elem.field_student_out_time)) {
            const [date, month, year] = elem.field_student_out_time.split("/");
            endDefaultDate = new Date(`${month}/${date}/${year}`)
        }
        const startDateOptions = {
            autoClose: true,
            onSelect: ((date) => {
                this.handleChange(elem.uid, "startDate", dateformat(date, "dd/mm/yyyy"))
            }),
            format: "dd/mm/yyyy",
            container: null,
            minDate: minDate,
            maxDate: new Date(elem.field_start_and_end_time),
            defaultDate: startDefaultDate,
            setDefaultDate: startDefaultDate,
            yearRange: [1950, today.getFullYear()],
        };
        const startTimeOptions = {
            autoClose: true,
            twelveHour: false,
            defaultTime: elem.field_student_in_time_1,
            // autoClose: true,
            onSelect: ((hour, min) => {
                this.handleChange(elem.uid, "startTime", `${hour}:${min}`)
            }),
        };
        const endDateOptions = {
            autoClose: true,
            onSelect: ((date) => {
                this.handleChange(elem.uid, "endDate", dateformat(date, "dd/mm/yyyy"))
            }),
            format: "dd/mm/yyyy",
            container: null,
            minDate: minDate,
            maxDate: new Date(elem.field_start_and_end_time),

            defaultDate: endDefaultDate,
            setDefaultDate: endDefaultDate,
            yearRange: [1950, today.getFullYear()],
        };
        const endTimeOptions = {
            autoClose: true,
            twelveHour: false,
            onSelect: ((hour, min) => {
                this.handleChange(elem.uid, "endTime", `${hour}:${min}`)
            }),
        };
        setTimeout(() => {
            // const startDobElem = document.querySelectorAll(`.startDatePicker-${elem.uid}`);
            // const startTimeElem = document.querySelectorAll(`.startTimePicker-${elem.uid}`);
            // const endDobElem = document.querySelectorAll(`.endDatePicker-${elem.uid}`);
            // const endTimeElem = document.querySelectorAll(`.endTimePicker-${elem.uid}`);
            // M.Datepicker.init(startDobElem, startDateOptions);
            // M.Timepicker.init(startTimeElem, startTimeOptions);
            // M.Datepicker.init(endDobElem, endDateOptions);
            // M.Timepicker.init(endTimeElem, endTimeOptions);
        }, 1000);
    }
    calculateAge = (dateString) => {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
    // handleCheck = (event, id, index) => {
    //     const { checkedList } = this.state
    //     if (event.target.checked)
    //         checkedList[index] = id
    //     else
    //         delete checkedList[index]
    //     this.setState({ checkedList })
    // }
    handleChange = (studentId, fieldType, value) => {
        const { studentAttendance } = this.state;
        if (_.isEmpty(studentAttendance[studentId])) {
            studentAttendance[studentId] = {}
            studentAttendance[studentId][fieldType] = value
        } else {
            studentAttendance[studentId][fieldType] = value
        }
        this.setState({ studentAttendance })
    }
    handleFormChange = (field, value, state) => {
        if (field == "parentQid" || field == "studentId")
            if (isNaN(value))
                return false
        const tempState = this.state[state];
        tempState[field]["class"] = value ? "" : "invalid";
        tempState[field]["value"] = value;
        this.setState({ [state]: tempState });
    };
    handleValidate = () => {
        const { studentList, studentAttendance } = this.state;
        let errors = {}
        studentList.map(studentItem => {
            if (!_.isEmpty(studentAttendance)) {
                Object.keys(studentAttendance).map(key => {
                    if (studentItem.uid == key && !_.isEmpty(studentAttendance[key])) {
                        // if (_.isEmpty(studentAttendance[key].startDate))
                        //     errors[`${studentItem.field_student_name}startDate`] = `${studentItem.field_student_name} entry date is required`;

                        // if (!_.isEmpty(studentAttendance[key].startDate) && _.isEmpty(studentAttendance[key].startTime))
                        //     errors[`${studentItem.field_student_name}startTime`] = `${studentItem.field_student_name} ${this.context.t("_in_time_required")}`;

                        // if (_.isEmpty(studentAttendance[key].endDate))
                        //     errors[`${studentItem.field_student_name}endDate`] = `${studentItem.field_student_name} exit date is required`;

                        // if (!_.isEmpty(studentAttendance[key].endDate) && _.isEmpty(studentAttendance[key].endTime))
                        //     errors[`${studentItem.field_student_name}endTime`] = `${studentItem.field_student_name} ${this.context.t("_out_time_required")}`

                        // if (!_.isEmpty(studentAttendance[key].endTime) && _.isEmpty(studentAttendance[key].startTime))
                        //     errors[`${studentItem.field_student_name}startTime`] = `${studentItem.field_student_name} ${this.context.t("_in_time_required")}`;
                    } else {
                        // errors.common = "Attendance for all students is mandatory"
                    }
                })
            } else {
                // errors[`${studentItem.field_student_name}`] = `${studentItem.field_student_name} attendance is required`;
            }

        })
        this.setState({ errors })
        if (_.isEmpty(errors))
            return true
        return false

    }
    cleanObject = (el) => {
        function internalClean(el) {
            return _.transform(el, function (result, value, key) {
                var isCollection = _.isObject(value);
                var cleaned = isCollection ? internalClean(value) : value;

                if (isCollection && _.isEmpty(cleaned)) {
                    return;
                }

                _.isArray(result) ? result.push(cleaned) : (result[key] = cleaned);
            });
        }

        return _.isObject(el) ? internalClean(el) : el;
    }
    handleSubmit = async () => {
        if (this.handleValidate()) {
            const { config, studentAttendance } = this.state;
            const attendanceObj = await this.cleanObject(studentAttendance);
            const { match: { params } } = this.props;
            this.props.showLoader()
            const obj = {
                activityId: params.activityId,
                studentAttendance: attendanceObj
            }
            const url = `${config.api_url}${urlEndPoints.markAttendance}`
            try {
                const data = await this.networkRequest(url, "POST", obj)
                if (data.data.status == 200) {
                    this.setState({ studentAttendance: {}, editable: {} }, () => {
                        this.getFullAttendance()
                        this.scrollToTop()
                    })

                } else {
                    this.props.hideLoader()
                }
            } catch (error) {
                this.props.hideLoader()
                console.warn("error: ", error)
            }
        } else {
            this.scrollToTop()
        }

    }
    scrollToTop = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" })
    }
    handleDeleteAttendance = studentId => {
        this.setState({ removeAttendanceConfirm: true, removeStudentId: studentId })
    }
    handleCloseConfirmModal = (state) => {
        this.setState({ [state]: false, removeStudentId: null })
    }
    handleConfirmRemoveAttendance = async () => {
        const { config, removeStudentId, selectedDate } = this.state;
        const [start, end] = selectedDate.split("*")
        const { match: { params } } = this.props;
        this.handleCloseConfirmModal("removeAttendanceConfirm");
        this.props.showLoader()
        const obj = {
            activityId: params.activityId,
            student_id: removeStudentId,
            startDate: start,
            endDate: end
        }
        const url = `${config.api_url}${urlEndPoints.cancelAttendance}`
        try {
            const data = await this.networkRequest(url, "POST", obj)
            if (data.data.status == 200) {
                // const elems = document.querySelector(`.startDatePicker-${removeStudentId}`);
                // const instance = M.Datepicker.getInstance(elems);
                // instance.setInputValue();
                this.setState({ studentAttendance: {} })
                this.getFullAttendance()
                this.scrollToTop()
            } else {
                this.props.hideLoader()
            }
        } catch (error) {
            this.props.hideLoader()
            console.warn("error: ", error)
        }
    }

    handleOpenQidCheck = () => {
        const { formData } = this.state;
        for (var key in formData) {
            formData[key].error = "";
        }
        this.setState({ formData })
        let elems = document.querySelector("#checkQid");
        elems.setAttribute("aria-hidden", "false")
        elems.setAttribute("tabindex", "-1")
        let instance = M.Modal.getInstance(elems);
        instance.open();
    }
    handleOpenSendNotification = () => {
        this.setState({ sendNotificationConfirm: true })
    }
    handleCloseModal = () => {
        this.initState();
        let elems = document.querySelector("#checkQid");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        let instance = M.Modal.getInstance(elems);
        instance.close();
        elems = document.querySelector("#addStudent");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        instance = M.Modal.getInstance(elems);
        instance.close();
        setTimeout(() => {
            elems = document.querySelector("select");
            M.FormSelect.init(elems)
        }, 1000);
    }
    validate = (type) => {
        const { formData } = this.state;
        for (var key in formData) {
            formData[key].value = formData[key].value.trim();
            formData[key].error = "";
        }
        if (type == "qid") {
            if (formData.studentId.value == "")
                formData.studentId.error = this.context.t("_qid_required");

            if (formData.studentId.value != "" && !qidValidate.test(formData.studentId.value))
                formData.studentId.error = this.context.t("_invalid_qid");

        } else {
            // if (formData.parentName.value == "")
            //     formData.parentName.error = this.context.t("_parent_name_required");

            // if (formData.parentName.value != "" && !nameValidate.test(formData.parentName.value))
            //     formData.parentName.error = this.context.t("_invalid_parent_name");

            // if (formData.parentName.value != "" && nameValidate.test(formData.parentName.value) && !nameWordLengthCheck(formData.parentName.value))
            //     formData.parentName.error = this.context.t("_parent_name_contains_atleast_two_word");

            // if (formData.dob.value == "")
            //     formData.dob.error = this.context.t("_date_of_birth_required");

            if (_.isEmpty(formData.email.value))
                formData.email.error = this.context.t("_email_required");

            if (formData.email.value != "" && !emailValidate.test(formData.email.value))
                formData.email.error = this.context.t("_invalid_email");

            // if (formData.mobileNumber.value == "")
            //     formData.mobileNumber.error = this.context.t("_mobile_number_required");

            // if (formData.mobileNumber.value != "" && !phoneValidate.test(formData.mobileNumber.value))
            //     formData.mobileNumber.error = this.context.t("_invalid_mobile_number");
        }
        for (var key in formData) {
            if (formData[key].error != "") {
                this.setState({ formData, submissionStatus: false })
                // this.scrollToTop();
                setTimeout(() => {
                    this.refs[key].focus()
                }, 1000);
                return false;

            }
        }
        return true;
    }
    displayError = error => {
        const { formData } = this.state;
        const { lang } = this.props;
        if (error.qid)
            formData.studentId.error = error.qid[lang]

        if (error.name)
            formData.name.error = error.name[lang]

        if (error.mail)
            formData.email.error = error.mail[lang]

        if (error.DOB)
            formData.dob.error = error.DOB[lang]

        for (var key in formData) {
            if (formData[key].error != "") {
                this.setState({ formData, })
                // this.scrollToTop();
                setTimeout(() => {
                    this.refs[key].focus()
                }, 1000);
                return false;

            }
        }

    }
    handleCheckQid = async (event) => {
        event.preventDefault();
        const { formData, config } = this.state;

        if (this.validate("qid")) {
            this.props.showLoader()
            const url = `${config.api_url}${urlEndPoints.checkStudentQid(formData.studentId.value)}`
            try {
                const data = await this.networkRequest(url)
                if (data.status == 200) {
                    if (data.data.error) {
                        this.props.hideLoader()
                        this.displayError(data.data.error)
                    } else {
                        if (data.data && data.data.length == 0) {
                            this.props.hideLoader()
                            this.handleOpenAddStudentModal();
                        } else {
                            this.handleCheckStudentEnrolment(data.data[0].uid)
                            // const parentList = await this.getMyParents();
                            // this.setState({ parentList })
                            // this.props.hideLoader()
                            this.handleCloseModal()
                        }
                    }
                }
            } catch (error) {
                this.props.hideLoader()
                console.warn("error: ", error)
            }
        }
    }
    handleCheckStudentEnrolment = async id => {
        const { match: { params } } = this.props
        const { config } = this.state
        const { lang } = this.props
        const url = `${config.api_url}${urlEndPoints.checkEnrolment(params.activityId, id)}`
        try {
            const data = await this.networkRequest(url)
            this.props.hideLoader();
            if (!data.data.message && data.data.results && data.data.results.length > 0) {
                // const studentList = _.orderBy(data.data.results, ["field_student_name"], ["asc"])
                // this.setState({ studentList, selectedActivityId: id })

                // let elems = document.querySelector("#enrollments");
                // let instance = M.Modal.getInstance(elems);
                // instance.open();
                this.props.showPopup({
                    type: "error",
                    title: this.context.t("_error"),
                    message: this.context.t("_student_already_enrolled"),
                    isOpen: true,
                    buttonText: this.context.t("_ok")
                })
            } else {
                this.enrollActivity(id)
            }
        } catch (error) {
            this.props.hideLoader()
            console.warn("error: ", error)
        }
    }
    enrollActivity = async (id) => {
        const { match: { params: { activityId } } } = this.props
        const { lang } = this.props;
        if (activityId) {
            this.props.showLoader();
            const { config } = this.state
            const url = `${config.api_url}${urlEndPoints.activityEnrolment}`
            const obj = {
                "activity_id": activityId,
                "user_ids": { "0": id }
            }
            try {
                const data = await this.networkRequest(url, "POST", obj)
                if (data.data.error) {
                    this.props.showPopup({
                        type: "error",
                        title: this.context.t("_error"),
                        message: `${data.data.error.name[lang]}`,
                        isOpen: true,
                        buttonText: this.context.t("_ok")
                    })
                    this.props.hideLoader()
                } else {
                    const message = this.context.t("_enroll_success");
                    this.props.showPopup({
                        type: "success",
                        title: this.context.t("_success"),
                        message,
                        isOpen: true,
                        buttonText: this.context.t("_ok")
                    })
                    await this.getStudentList();
                    this.getFullAttendance();
                }
            } catch (error) {
                this.props.hideLoader()
                console.warn("error: ", error)
            }
        }
    }

    handleInviteStudent = async (event) => {
        event.preventDefault();
        const { formData, config } = this.state;
        const { lang } = this.props
        if (this.validate()) {
            this.props.showLoader()
            let obj = {
                email: formData.email.value
            }
            const url = `${config.api_url}${urlEndPoints.sendInvitation}`
            try {
                const data = await this.networkRequest(url, "POST", obj)
                if (data.data.error) {
                    this.displayError(data.data.error)
                    this.props.hideLoader()
                }
                else {
                    this.props.showPopup({
                        type: "success",
                        title: this.context.t("_success"),
                        message: data.data.message[lang],
                        isOpen: true,
                        buttonText: this.context.t("_ok")
                    })
                    this.handleCloseModal();
                    this.getStudentList()
                }
            } catch (error) {
                this.props.hideLoader()
                console.warn("error: ", error)
            }
        }
    }
    handleOpenAddStudentModal = () => {
        const { formData } = this.state;
        for (var key in formData) {
            formData[key].error = "";
        }
        this.setState({ formData })
        let elems = document.querySelector("#checkQid");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        let instance = M.Modal.getInstance(elems);
        instance.close();
        elems = document.querySelector("#addStudent");
        elems.setAttribute("aria-hidden", "false")
        elems.setAttribute("tabindex", "-1")
        instance = M.Modal.getInstance(elems);
        instance.open();
    }
    handleGradeName = (grade) => {
        if (!_.isEmpty(grade)) {
            const { lang } = this.props;
            const gradeValue = _.find(gradeListNew[lang], ["value", grade])
            return !_.isEmpty(gradeValue) ? gradeValue.label : ""
        }
        return grade
    }
    handleCancelEnrolment = async (studentId) => {
        this.props.showLoader();
        const { config } = this.state;
        const { match: { params } } = this.props;
        const { lang } = this.props;
        if (!_.isEmpty(studentId)) {
            const url = `${config.api_url}${urlEndPoints.cancelActivity}`
            const obj = {
                "activity_id": params.activityId,
                "user_ids": {
                    0: studentId
                }
            }
            try {
                const data = await this.networkRequest(url, "POST", obj);
                if (data.status == 200) {
                    if (!_.isEmpty(data.data.error)) {
                        this.props.showPopup({
                            type: "error",
                            title: this.context.t("_error"),
                            message: data.data.error.name[lang],
                            isOpen: true,
                            buttonText: this.context.t("_ok")
                        })
                    } else {
                        this.props.showPopup({
                            type: "success",
                            title: this.context.t("_success"),
                            message: this.context.t("_successfully_cancelled"),
                            isOpen: true,
                            buttonText: this.context.t("_ok")
                        })
                    }
                    this.handleCloseModal();
                    this.getStudentList()
                }
            } catch (error) {
                this.props.showPopup({
                    type: "error",
                    title: this.context.t("_failed"),
                    message: this.context.t("_failed_to_cancel"),
                    isOpen: true,
                    buttonText: this.context.t("_ok")
                })
                this.props.hideLoader()
                console.warn("error: ", error)
            }
        }

    }
    handleCheck = (event, item, fieldType) => {
        const { studentAttendance, selectedDate } = this.state;
        const [start, end] = selectedDate.split("*")
        if (_.isEmpty(studentAttendance[item.uid])) {
            studentAttendance[item.uid] = {}
            studentAttendance[item.uid][fieldType] = event.target.checked
            // studentAttendance[item.uid].nid = item.nid
            studentAttendance[item.uid].startDate = start
            studentAttendance[item.uid].endDate = end
        } else {
            studentAttendance[item.uid][fieldType] = event.target.checked
            // studentAttendance[item.uid].nid = item.nid
            studentAttendance[item.uid].startDate = start
            studentAttendance[item.uid].endDate = end
        }
        this.setState({ studentAttendance })
    }
    handleEditAttendance = studentId => {
        const { editable } = this.state;
        editable[studentId] = true;
        this.setState({ editable })
    }
    handleSendNotification = () => {

    }

    handlePageChange = async (pageNumber) => {
        this.props.showLoader()
        var { pager } = this.state;
        pager.page = pageNumber - 1;
        this.setState({ pager }, async () => {
            await this.getStudentList();
            this.getFullAttendance();
        })
    }
    handleDateChange = async (selectedDate) => {
        this.props.showLoader();
        this.setState({ selectedDate, studentAttendance: {} }, async () => {
            await this.getStudentList();
            this.getFullAttendance();
        });
    }
    getDateInFormat = date => {
        // if (!_.isEmpty(date)) {
        if (date.includes("Jan"))
            date = date.replace(/Jan/g, this.context.t("_jan"))

        if (date.includes("Feb"))
            date = date.replace(/Feb/g, this.context.t("_feb"))

        if (date.includes("Mar"))
            date = date.replace(/Mar/g, this.context.t("_mar"))

        if (date.includes("Apr"))
            date = date.replace(/Apr/g, this.context.t("_apr"))

        if (date.includes("May"))
            date = date.replace(/May/g, this.context.t("_may"))

        if (date.includes("Jun"))
            date = date.replace(/Jun/g, this.context.t("_jun"))

        if (date.includes("Jul"))
            date = date.replace(/Jul/g, this.context.t("_jul"))

        if (date.includes("Aug"))
            date = date.replace(/Aug/g, this.context.t("_aug"))

        if (date.includes("Sep"))
            date = date.replace(/Sep/g, this.context.t("_sep"))

        if (date.includes("Oct"))
            date = date.replace(/Oct/g, this.context.t("_oct"))

        if (date.includes("Nov"))
            date = date.replace(/Nov/g, this.context.t("_nov"))

        if (date.includes("Dec"))
            date = date.replace(/Dec/g, this.context.t("_dec"))
        // }

        return date
    }
    getTimeInFormat = time => {
        if (!_.isEmpty(time)) {
            if (time.includes("am"))
                time = time.replace(/am/g, this.context.t("_am"))

            if (time.includes("pm"))
                time = time.replace(/pm/g, this.context.t("_pm"))
        }
        return time;
    }
    render() {
        const { studentList, studentAttendance, errors, removeAttendanceConfirm, sendNotificationConfirm, formData, editable, pager, dateList, selectedDate, isLoaded } = this.state;
        const { lang } = this.props;
        let hasError = false;
        Object.keys(formData).map(item => {
            if (formData[item].error != "")
                hasError = true
        })
        return (
            <>
                <div id="main-container">
                    <SlideToTop />
                    <PageTitle title={this.context.t("_attendance")} />
                    <div id="skipContent">
                        <div className="container">
                            <nav className="breadcrumb aside-breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                                <ul>
                                    <li className="breadcrumb-item">
                                        <Link to="/admin-profile">{this.context.t("_profile")}</Link> {" "}
                                        {/* <Link to="/admin-history">{this.context.t("_activity_history")}</Link> {" "} */}
                                    </li>
                                    <li className="breadcrumb-item">
                                        <span>{this.context.t("_attendance")} </span>
                                        {/* <Link className="activityLink" to="/activities">Explore our Activities, and Enroll Now!</Link> */}
                                    </li>
                                </ul>
                            </nav>
                            <div className="result-type">
                                <div className={`input-field  mobileSelect pull-${lang == "ar" ? "left" : "right"} filter-select`} style={{ padding: 0 }}>
                                    <label htmlFor="nationality" style={dynamicStyles.position(lang)}>{this.context.t("_select_activity_time")}</label>
                                    <select
                                        className="browser-default"
                                        id="nationality"
                                        name="nationality"
                                        value={selectedDate}
                                        onChange={evt => this.handleDateChange(evt.target.value)}
                                    // onFocus={evt => this.handleChange("nationality", evt.target.value)}
                                    >
                                        <option value="" disabled>{this.context.t("_select_activity_time")}</option>
                                        {
                                            dateList.map(item => <option key={item.date} value={`${item.startDate}*${item.endDate}*${item.date}`}>{`${this.getDateInFormat(item.displayDate)} ${this.getTimeInFormat(item.startTime)} - ${this.getTimeInFormat(item.endTime)}`}</option>)
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                    <div>
                        <div className="container">
                            {
                                !_.isEmpty(errors) ? <div className="error-container fail">
                                    <h5>{this.context.t("_error_title")}</h5>
                                    <ul>
                                        {
                                            Object.keys(errors).map((item, index) => {
                                                return !_.isEmpty(errors[item]) ? <li key={index}><label className="error" htmlFor={item}>{errors[item]}</label></li> : null
                                            })}
                                    </ul>
                                </div> : null
                            }

                            <div className="tableHolder">
                                {
                                    isLoaded && (studentList.length > 0 ?
                                        <>
                                            <table className="new-responsive-table highlight attendanceTable">
                                                <thead className="blue lighten-5">
                                                    <tr>
                                                        <th><strong>{this.context.t("_name")}</strong></th>
                                                        <th><strong>{this.context.t("_qid")}</strong></th>
                                                        {/* <th><strong>{this.context.t("_phone_number")}</strong></th> */}
                                                        <th><strong>{this.context.t("_grade")}</strong></th>
                                                        {/* <th><strong>{this.context.t("_gender")}</strong></th> */}
                                                        {/* <th><strong>{this.context.t("_age")}</strong></th> */}
                                                        <th width="150" className="newEntry"><strong>{this.context.t("_entry")}</strong></th>
                                                        <th width="150" className="newExit"><strong>{this.context.t("_exit")}</strong></th>
                                                        <th><strong>{this.context.t("_status")}</strong></th>
                                                        <th width="50"><strong>{this.context.t("_cancel")}</strong></th>
                                                        <th width="50"><strong>{this.context.t("_edit")}</strong></th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        studentList.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{item.field_student_name}</td>
                                                                    <td className="fontEnglish">{item.field_qid}</td>
                                                                    {/* <td>{item.field_student_mobile}</td> */}
                                                                    <td>{this.handleGradeName(item.field_grade)}</td>
                                                                    {/* <td>{item.field_gender}</td> */}
                                                                    {/* <td>{this.calculateAge(item.field_student_dob)}</td> */}
                                                                    <td>
                                                                        <p className="margin0">
                                                                            <input
                                                                                type="checkbox"
                                                                                disabled={editable[item.uid] ? false : (item.field_in == "1" && item.field_out == "1")}
                                                                                onChange={(e) => this.handleCheck(e, item, "in")}
                                                                                checked={studentAttendance[item.uid] && studentAttendance[item.uid]["in"] ? true : false}
                                                                                id={`markIn${index}`}
                                                                            />
                                                                            <label htmlFor={`markIn${index}`}>
                                                                                <span style={{ display: "none" }}>select</span>
                                                                            </label>
                                                                        </p>
                                                                        {/* <div className="date-popup attendanceField">
                                                                            <div className="input-field item">
                                                                                <input
                                                                                    // name="name"
                                                                                    placeholder={this.context.t("_entry_date")}
                                                                                    // id="dob"
                                                                                    type="text"
                                                                                    readOnly
                                                                                    value={_.has(studentAttendance, `${item.uid}.startDate`) ? studentAttendance[item.uid].startDate : ""}
                                                                                    // value="2020/05/02"
                                                                                    style={dynamicStyles.textAlign(lang)}
                                                                                    autoComplete="no"
                                                                                    className={`startDatePicker-${item.uid} fontEnglish`}
                                                                                />
                                                                            </div>
                                                                        </div> */}
                                                                        {/* <div className="date-popup attendanceField">
                                                                            <div className="input-field item fontEnglish">
                                                                                <input
                                                                                    // name="name"
                                                                                    placeholder={this.context.t("_entry_time")}
                                                                                    // id="time"
                                                                                    type="text"
                                                                                    readOnly
                                                                                    value={_.has(studentAttendance, `${item.uid}.startTime`) ? studentAttendance[item.uid].startTime : ""}
                                                                                    style={dynamicStyles.textAlign(lang)}
                                                                                    autoComplete="no"
                                                                                    className={`startTimePicker-${item.uid} fontEnglish`}
                                                                                />
                                                                            </div>
                                                                        </div> */}
                                                                    </td>
                                                                    <td>
                                                                        <p className="margin0">
                                                                            <input
                                                                                type="checkbox"
                                                                                disabled={editable[item.uid] ? false : (item.field_in == "1" && item.field_out == "1")}
                                                                                onChange={(e) => this.handleCheck(e, item, "out")}
                                                                                checked={studentAttendance[item.uid] && studentAttendance[item.uid]["out"] ? true : false}
                                                                                id={`markOut${index}`}
                                                                            />
                                                                            <label htmlFor={`markOut${index}`}>
                                                                                <span style={{ display: "none" }}>select</span>
                                                                            </label>
                                                                        </p>
                                                                        {/* <div className="date-popup attendanceField">
                                                                        <div className="input-field item">
                                                                            <input
                                                                                // name="name"
                                                                                placeholder={this.context.t("_exit_date")}
                                                                                // id="dob"
                                                                                type="text"
                                                                                readOnly
                                                                                value={_.has(studentAttendance, `${item.uid}.endDate`) ? studentAttendance[item.uid].endDate : ""}
                                                                                style={dynamicStyles.textAlign(lang)}
                                                                                autoComplete="no"
                                                                                className={`endDatePicker-${item.uid} fontEnglish`}
                                                                            />
                                                                        </div>

                                                                    </div> */}
                                                                        {/* <div className="date-popup attendanceField">
                                                                                <div className="input-field item fontEnglish">
                                                                                    <input
                                                                                        // name="name"
                                                                                        placeholder={this.context.t("_exit_time")}
                                                                                        // id="time"
                                                                                        type="text"
                                                                                        readOnly
                                                                                        value={_.has(studentAttendance, `${item.uid}.endTime`) ? studentAttendance[item.uid].endTime : ""}
                                                                                        style={dynamicStyles.textAlign(lang)}
                                                                                        autoComplete="no"
                                                                                        className={`endTimePicker-${item.uid} fontEnglish`}
                                                                                    />
                                                                                </div>
                                                                            </div> */}

                                                                    </td>

                                                                    <td >
                                                                        {
                                                                            (item.field_in == "1" && item.field_out == "1") ? this.context.t("_attended") : this.context.t("_not_attended")
                                                                        }
                                                                    </td>
                                                                    <td >
                                                                        <i onClick={() => this.handleDeleteAttendance(item.uid)} className="material-icons attendanceClose tooltipped" data-position={lang == "ar" ? "left" : "right"} data-tooltip={this.context.t("_remove_attendance")}>highlight_off</i>
                                                                    </td>
                                                                    <td >
                                                                        {
                                                                            (item.field_in == "1" && item.field_out == "1") ? <i onClick={() => { this.handleEditAttendance(item.uid) }} className="material-icons attendanceClose tooltipped" data-position="right" data-tooltip="Edit attendance">edit</i> : null
                                                                        }
                                                                    </td>

                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            <nav className="pagination-wrapper" aria-label="pagination">
                                                <Pagination
                                                    prevPageText={`< ${this.context.t("_previous")}`}
                                                    nextPageText={`${this.context.t("_next")} >`}
                                                    hideFirstLastPages={true}
                                                    hideDisabled={true}
                                                    linkClassPrev="linkClassPrev"
                                                    linkClassNext="linkClassNext"
                                                    activePage={pager.current_page + 1}
                                                    itemsCountPerPage={pager.items_per_page}
                                                    totalItemsCount={pager.count}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.handlePageChange}
                                                />
                                            </nav>
                                            <br />
                                            <div className="attendanceButtonContainer">
                                                <button type="button" onClick={() => { this.handleSubmit() }} className="btn noWidth  blue-btn waves-effect waves-light">{this.context.t("_mark")}</button>
                                                {/* <button type="button" onClick={() => { this.handleOpenSendNotification() }} className="btn noWidth  blue-btn waves-effect waves-light">Send Remainder for absent students</button> */}
                                                <button type="button" onClick={() => { this.handleOpenQidCheck() }} className="btn noWidth  blue-btn waves-effect waves-light pull-right">{this.context.t("_add_student")}</button>
                                            </div>

                                        </>
                                        :
                                        <div className="page-not-found center">
                                            <br />
                                            <h4>{this.context.t("_no_student_to_list")}</h4>
                                            {/* <button style={{ margin: "0 15px" }} onClick={() => this.handleGoBack()} className="btn profileBtn">{this.context.t("_go_back")}</button> */}
                                            {/* <Link to={`/${localStorage.getItem("role")}-connections`} className="btn profileBtn waves-effect waves-light">{this.context.t("_add_relatives")}</Link> */}
                                        </div>)
                                }
                            </div>
                            <div id="checkQid" className="modal sm" role="dialog">
                                <div className="modal-content modal-no-close">
                                    {
                                        _.isEmpty(formData) ?
                                            <div /> :
                                            <div>
                                                <span className="badge modalClose"><button aria-label="close" className="close" onClick={() => this.handleCloseModal()}><i className="material-icons">close</i></button></span>
                                                <h2 className="line">{this.context.t("_add_student")}</h2>
                                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit.</p> */}
                                                {hasError ? <div className="error-container fail">
                                                    <h5>{this.context.t("_error_title")}</h5>
                                                    <ul>
                                                        {formData.studentId.error != "" ? <li ><a ref="studentId" tabIndex="0" href="#studentId" className="error" htmlFor="studentId">{formData.studentId.error}</a></li> : null}
                                                    </ul>
                                                </div> : null
                                                }
                                                <form onSubmit={this.handleCheckQid} className="row full-width">
                                                    <div className="col s12">
                                                        <div className="input-field item">
                                                            <input
                                                                placeholder={this.context.t("_parent_id_placeholder")}
                                                                type="text"
                                                                name="studentId"
                                                                id="studentId"
                                                                maxLength={11}
                                                                onChange={(evt) => this.handleFormChange("studentId", evt.target.value, "formData")}
                                                                onFocus={(evt) => this.handleFormChange("studentId", evt.target.value, "formData")}
                                                                value={formData.studentId.value}
                                                            />
                                                            <label htmlFor="studentId" style={dynamicStyles.position(lang)}>{this.context.t("_student_qid")} <span className="asterisk">*</span></label>
                                                            <span
                                                                style={dynamicStyles.textAlign(lang)}
                                                                className="helper-text"
                                                                data-error="Required field."
                                                            ></span>
                                                        </div>
                                                    </div>

                                                    <div className="col s12">
                                                        <button type="button" onClick={this.handleCloseModal} className="btn profileBtn none waves-effect waves-dark">{this.context.t("_cancel")}</button>
                                                        <button className="btn profileBtn blue noWidth waves-effect waves-light">{this.context.t("_add")}</button>
                                                    </div>
                                                </form>
                                            </div>
                                    }
                                </div>
                            </div>
                            <div id="addStudent" className="modal sm user-add-modal" role="dialog">
                                <div className="modal-content modal-no-close">
                                    {
                                        _.isEmpty(formData) ?
                                            <div /> :
                                            <form onSubmit={this.handleInviteStudent} className="col full-width">
                                                <span className="badge modalClose"><a onClick={() => this.handleCloseModal()}><i className="material-icons">close</i></a></span>
                                                <h2 className="line">{this.context.t("_add_student")}</h2>

                                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit, tellus arcu maximus ipsum, ut pulvinar erat lacus at libero.</p> */}
                                                {hasError ? <div className="error-container fail">
                                                    <h5>{this.context.t("_error_title")}</h5>
                                                    <ul>
                                                        {formData.email.error != "" ? <li ><a ref="email" tabIndex="0" href="#email" className="error" htmlFor="email">{formData.email.error}</a></li> : null}
                                                    </ul>
                                                </div> : null
                                                }
                                                <p>{this.context.t("_required_field")}{" "} <span className="asterisk">*</span></p>

                                                <div className="row">
                                                    <div className="col s12">
                                                        <div className="input-field item">
                                                            <input
                                                                placeholder={this.context.t("_parent_email_address_placeholder")}
                                                                type="text"
                                                                name="email"
                                                                id="email"
                                                                onChange={(evt) => this.handleFormChange("email", evt.target.value, "formData")}
                                                                onFocus={(evt) => this.handleFormChange("email", evt.target.value, "formData")}
                                                                value={formData.email.value}
                                                            />
                                                            <label htmlFor="email" style={dynamicStyles.position(lang)}>{this.context.t("_parent_email_address")} <span className="asterisk">*</span></label>
                                                            <span
                                                                style={dynamicStyles.textAlign(lang)}
                                                                className="helper-text"
                                                                data-error="Required field."
                                                            ></span>
                                                        </div>
                                                    </div>
                                                    <div className="col s12">
                                                        <button className="btn profileBtn blue waves-effect waves-light">{this.context.t("_save_changes")}</button>
                                                        <button type="button" onClick={() => this.handleCloseModal()} className="btn profileBtn waves-effect waves-light  blue-border">{this.context.t("_close")}</button>
                                                    </div>
                                                    <div className="clearfix" />
                                                </div>
                                            </form>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="top-line ng-scope">
                    <img src={topLineImage} alt="" />
                </div>
                <Modal
                    isOpen={removeAttendanceConfirm}
                    style={customModalStyles}
                    contentLabel="unlink parent confirm modal"
                    ariaHideApp={false}
                >
                    <div className="popupAlert warning">
                        <br />
                        <h3>{this.context.t("_confirm")}</h3>
                        <p>{this.context.t("_delete_attendance_confirmation")}</p>
                        <div className="row">
                            <button className="col btn blue" onClick={() => this.handleCloseConfirmModal("removeAttendanceConfirm")}>{this.context.t("_no")}</button>
                            <button className="col btn profileBtn yellow" onClick={() => this.handleConfirmRemoveAttendance()}>{this.context.t("_yes")}</button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={sendNotificationConfirm}
                    style={customModalStyles}
                    contentLabel="unlink parent confirm modal"
                    ariaHideApp={false}
                >
                    <div className="popupAlert warning">
                        <br />
                        <h3>{this.context.t("_confirm")}</h3>
                        <p>Are you sure want to send mail to absent students</p>
                        <div className="row">
                            <button className="col btn blue" onClick={() => this.handleCloseConfirmModal("sendNotificationConfirm")}>{this.context.t("_no")}</button>
                            <button className="col btn profileBtn yellow" onClick={() => this.handleSendNotification()}>{this.context.t("_yes")}</button>
                        </div>
                    </div>
                </Modal>
            </>
        )
    }

}

const mapStateToProps = ({ i18nState }) => {
    return {
        lang: i18nState.lang
    }
}
const mapDispatchToProps = {
    showPopup: (data) => PopupAction.showPopup(data),
    hidePopup: (data) => PopupAction.hidePopup(data),
    showLoader: LoadingAction.showLoader,
    hideLoader: LoadingAction.hideLoader,
}
MarkAttendance.contextTypes = {
    t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(MarkAttendance);
