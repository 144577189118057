import React from "react";
import { NavLink, Link, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import M from "materialize-css"
import Main from "../main"
import { withRouter } from "react-router-dom";
import { dynamicStyles } from "./../../constants/dynamicStyles"
import { LoginAction, SwitchLanguageAction } from "./../../actions"
const logo = require("./../../assets/images/logo56.png");
const logoMTC = require("./../../assets/images/logoMTC.png");
class Navigation extends Main {
	constructor(props) {
		super(props);
		this.state = {
			side_nav: "",
			token: null,
			config: this.config(),
			profileData: {},
			isOpen: false
		};
		this.logOut = this.logOut.bind(this);
	}
	async componentDidMount() {
		this.getProfileData()
		setTimeout(() => {
			M.AutoInit();
			let options = {
				startingTop: "0%"
			}
			let elem = document.querySelectorAll("#main-nav");
			M.Sidenav.init(elem, {});

			// elem = document.querySelector("#menuPopup");
			// let instance = M.Modal.getInstance(elem, options);
			// instance.open();
		}, 500);
		this.checkToken()

		setTimeout(() => {
			this.handleProfileListiner()
		}, 1000);
	}


	shouldComponentUpdate(nextProps) {
		if (this.props.isLogin != nextProps.isLogin) {
			setTimeout(() => {
				this.handleProfileListiner()
			}, 1000);
			// return false
		}
		return true
	}

	handleProfileListiner = () => {
		const bannerButton = document.querySelector(".navigation-profile");
		if (bannerButton && bannerButton.addEventListener) {
			bannerButton.addEventListener('click', () => {
				const bannerElement = document.querySelectorAll(".navigation-profile");
				bannerElement[0].classList.toggle('hover');
			});
			bannerButton.addEventListener('keypress', () => {
				const bannerElement = document.querySelectorAll(".navigation-profile");
				bannerElement[0].classList.toggle('hover');
			});
			bannerButton.addEventListener('focusIn', () => {
				const bannerElement = document.querySelectorAll(".navigation-profile");
				bannerElement[0].classList.add('hover');
			});
			bannerButton.addEventListener('focusOut', () => {
				const bannerElement = document.querySelectorAll(".navigation-profile");
				bannerElement[0].classList.remove('hover');
			});

		}
		const popoverList = document.querySelector(".languageFocus");
		if (popoverList && popoverList.addEventListener) {
			popoverList.addEventListener('focusin', () => {
				const bannerElement = document.querySelectorAll("#profile-nav");
				bannerElement.length > 0 && bannerElement[0].classList.remove('hover');
			});
		}
		const contactPopover = document.querySelector(".contactPopover");
		if (contactPopover && contactPopover.addEventListener) {
			contactPopover.addEventListener('focusin', () => {
				const bannerElement = document.querySelectorAll("#profile-nav");
				bannerElement.length > 0 && bannerElement[0].classList.remove('hover');
			});
		}
		const contactFocus = document.querySelector(".hideMenuPopover");
		if (contactFocus && contactFocus.addEventListener) {
			contactFocus.addEventListener('focusin', () => {
				const bannerElement = document.querySelectorAll("#profile-nav");
				bannerElement.length > 0 && bannerElement[0].classList.remove('hover');
			});
		}
	}

	async checkToken() {
		let token = await localStorage.getItem("refreshToken");
		this.setState({
			token
		});
	}
	getProfileData = async () => {
		// const authResponse = await thi\s.auth();
		// if (authResponse) {
		// const { config } = this.state
		// const url = `${config.api_url}${urlEndPoints.userDetails}`
		// const headers = {
		//     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
		// }
		// try {
		// 	const data = await this.networkRequest(url)
		// 	this.setState({ profileData: data.data })
		// } catch (error) {
		// 	console.warn("error: ", error)
		// }
		// }
	}
	logOut() {

		localStorage.removeItem("accessToken");
		localStorage.removeItem("refreshToken");
		localStorage.removeItem("tokenType");
		localStorage.removeItem("role");
		// const { token, config } = this.state;
		this.props.userLogout();
		this.props.history.push("/login")
		// axios.post(config.origin + "studioservices/user/logout?_format=json", {
		// 	headers: {
		// 		"Content-Type": "application/json",
		// 		"Access-Control-Allow-Origin": "*",
		// 		"Accept": "application/json",
		// 		"Connection": "keep-alive",
		// 		"X-CSRF-Token": token
		// 	}
		// }).then(function (response) {
		// 	// localStorage.removeItem("token");
		// 	this.setState({
		// 		token: ""
		// 	});
		// return <Redirect to="" />
		// 	// this.props.history.push("/");
		// }).catch(function (error) {
		// 	console.log(error);
		// });

	}
	async switchLanguage(event) {
		event.preventDefault();
		const that = this;
		let { lang } = that.props;
		await localStorage.setItem("lang", lang == "en" ? "ar" : "en");
		lang = await localStorage.getItem("lang");
		let pathName = window.location.pathname.toString();
		const urlLang = pathName.split("/")
		if (urlLang[1] != lang) {
			pathName = pathName.replace(urlLang[1], lang)
			window.location = pathName
		}
		setTimeout(() => {
			// that.props.switchLanguage(lang)
		}, 2000);
		// window.location.reload();
	}
	handleCloseMenu = () => {
		const elems = document.querySelector("#menuPopup");
		const instance = M.Modal.getInstance(elems);
		instance.close();
	}
	handelOpenMenu = () => {
		const elems = document.querySelector("#menuPopup");
		const instance = M.Modal.getInstance(elems);
		instance.open();
	}
	handleMenuClick = () => {
	}
	handleProfile = () => {
		const role = localStorage.getItem("role");
		switch (role) {
			case "trainer":
			case "manager":
			case "asistant":
			case "edu_specialist":
			case "administrator":
				return "/admin-profile";
			case "parent":
				return "/parent-profile";

			default:
				return "/profile";
		}

	}
	render() {
		const { token } = this.state;
		const { lang, isLogin, profileImage } = this.props;
		return (
			<header id="main-header" className={`navbar ${lang}`} ref={elem => this.nv = elem}>
				<div id="menuPopup" className="modal menuHolder">
					<ColouredItems />
					<div className="modalContentInner">

						<div className="modal-content">
							<span className="badge modalClose"><a onClick={() => this.handleCloseMenu()}><i className="material-icons">close</i></a></span>
							<h3>{this.context.t("_main_menu")}</h3>
							<div className="row">
								<div className="col">
									<ul>
										<li>
											<NavLink onClick={() => this.handleCloseMenu()} exact to="/" >{this.context.t("_home_page")}</NavLink>
										</li>
										<li>
											<NavLink onClick={() => this.handleCloseMenu()} exact to="/activities" >{this.context.t("_activities")}</NavLink>
										</li>
										<li>
											<NavLink onClick={() => this.handleCloseMenu()} exact to="/media-center" >{this.context.t("_navbar_media_center")}</NavLink>
										</li>
										<li>
											<NavLink onClick={() => this.handleCloseMenu()} exact to="/contact" >{this.context.t("_contact_us")}</NavLink>
										</li>
										{/* <li>
											<NavLink onClick={()=>this.handleCloseMenu()} exact to="/equipment-used" >Equipment</NavLink>
										</li> */}
										<li>
											<NavLink onClick={() => this.handleCloseMenu()} exact to="/subscribe" >{this.context.t("_subscribe")}</NavLink>
										</li>
									</ul>
								</div>

								<div className="col">
									<ul className="menuStyle2">
										<li>
											<NavLink onClick={() => this.handleCloseMenu()} exact to="/about" >{this.context.t("_about_us")}</NavLink>
										</li>
										{/* <li>
											<NavLink onClick={()=>this.handleCloseMenu()} exact to >Publications</NavLink>
										</li> */}
										{/* <li>
											<NavLink onClick={()=>this.handleCloseMenu()} exact to="/announcements" >Announcement</NavLink>
										</li> */}
										{/* <li>
											<NavLink onClick={()=>this.handleCloseMenu()} exact to="/fab-labs" >Fab Lab</NavLink>
										</li> */}
										<li>
											<NavLink onClick={() => this.handleCloseMenu()} exact to="/machines" >{this.context.t("_machines")}</NavLink>
										</li>
										<li>
											<NavLink onClick={() => this.handleCloseMenu()} exact to="/registration" >{this.context.t("_registration_menu")}</NavLink>
										</li>
									</ul>
								</div>
								<div className="col">
									<ul className="menuStyle2">
										{/* <li>
											<NavLink onClick={()=>this.handleCloseMenu()} exact to>Terms and Conditions</NavLink>
										</li> */}
										<li>
											<NavLink onClick={() => this.handleCloseMenu()} exact to="/privacy-policies">{this.context.t("_privacy_policy")}</NavLink>
										</li>
										{/* <li>
											<NavLink onClick={()=>this.handleCloseMenu()} exact to>Best Practices</NavLink>
										</li> */}
										<li>
											<NavLink onClick={() => this.handleCloseMenu()} exact to="/faq" >{this.context.t("_faq")}</NavLink>
										</li>
										{/* <li>
											<NavLink onClick={()=>this.handleCloseMenu()} exact to="/virtual-tour" >Virtual Tour</NavLink>
										</li> */}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="container flex-row" style={dynamicStyles.direction(lang)}>

					<nav className="navbar top-menu">
						<div className="nav-wrapper">

							<span className="brand-logo">
								<a href={`https://www.mcit.gov.qa/${lang}`} target="blank">
									<img src={logoMTC} alt={this.context.t("ministry_logo_alt")} />
								</a>
								<NavLink to="/" id="headerLogo" className="hideMenuPopover">
									<h1>
										<img src={logo} alt={this.context.t("studio_logo_alt")} />
									</h1>
								</NavLink>
							</span>
						</div>
					</nav>
					{/* <div className="headerRight">
						<div className="lang"><NavLink to="" onClick={evt => this.switchLanguage(evt)} activeClassName="active" className="sidenav-close" >{lang == "en" ? `عربى` : "English"}<span></span></NavLink></div>
						<div onClick={() => this.handelOpenMenu()} className="btnMenu">
							<span>
								Menu
							</span>
						</div>
						{isLogin ?

							<span className="navigation-profile" tabIndex="0" type="button" aria-label="profile navigation">
								<img src={profileImage} alt="" />
								<menu>
									<ul>
										<li>
											<NavLink exact to={(
														localStorage.getItem("role") == "trainer" ||
														localStorage.getItem("role") == "manager" ||
														localStorage.getItem("role") == "asistant"|| 
														localStorage.getItem("role") == "edu_specialist"
													)? "/admin-profile" : "/profile"} ><i className="fa fa-user-circle-o" aria-hidden="true"></i> {this.context.t("_profile")}</NavLink>
										</li>
										<li>
											<NavLink exact to="/notification" ><i className="fa fa-bell-o" aria-hidden="true"></i> {this.context.t("_notification")}</NavLink>
										</li>
										<li>
											<a onClick={() => this.logOut()}><i className="fa fa-sign-out" aria-hidden="true"></i> {this.context.t("_logout")}</a>
										</li>
									</ul>
								</menu>
							</span>
							:
							<NavLink exact to="/login" activeClassName="active" className="sidenav-close" >{this.context.t("_login")}</NavLink>
						}
					</div> */}
					<ul id="main-nav" className="sidenav sidenav-fixed align-center" style={dynamicStyles.direction(lang)}>
						{
							(
								localStorage.getItem("role") == "trainer" ||
								localStorage.getItem("role") == "manager" ||
								localStorage.getItem("role") == "asistant" ||
								localStorage.getItem("role") == "edu_specialist" ||
								localStorage.getItem("role") == "administrator"
							)
								?
								null :
								<>
									<li><NavLink aria-label={this.context.t("_home")} exact to="/" activeClassName="active" className="sidenav-close" >{this.context.t("_home")}</NavLink></li>
									<li><NavLink aria-label={this.context.t("_about_us")} exact to="/about" activeClassName="active" className="sidenav-close" >{this.context.t("_about_us")}</NavLink></li>
									<li><NavLink aria-label={this.context.t("_activities")} to="/activities" activeClassName="active" className="sidenav-close" >{this.context.t("_activities")}</NavLink></li>
									<li><NavLink aria-label={this.context.t("_navbar_media_center")} to="/media-center" activeClassName="active" className="sidenav-close" >{this.context.t("_navbar_media_center")}</NavLink></li>
									<li><NavLink aria-label={this.context.t("_machines")} to="/machines" activeClassName="active" className="sidenav-close" >{this.context.t("_machines")}</NavLink></li>
									<li><NavLink aria-label={this.context.t("_equipment")} to="/equipment-used" activeClassName="active" className="sidenav-close" >{this.context.t("_equipment")}</NavLink></li>
									<li className="contactPopover"><NavLink aria-label={this.context.t("_contact_us")} to="/contact" activeClassName="active" className="sidenav-close" >{this.context.t("_contact_us")}</NavLink></li>
								</>
						}

						{/* {isLogin ?
							<li><Link to="/" onClick={evt => this.logOut()} className="sidenav-close">{this.context.t("_navbar_logout")}</Link></li>
							:
							<li><NavLink exact to="/login" activeClassName="active" className="sidenav-close" >{this.context.t("_login")}</NavLink></li>

						} */}

						<li className="dropdown">{isLogin ?
							<span tabIndex="0" id="profile-nav" className="navigation-profile" role="button" aria-haspopup="true" aria-expanded="false" type="button" aria-label="profile navigation">
								<img src={profileImage} alt="" />
								<menu className='dropmenu' aria-labelledby="profile-nav" role="menu">
									<ul>
										<li role="menuitem">
											<NavLink tabIndex="0"
												exact
												to={this.handleProfile} ><i className="fa fa-user-circle-o" aria-hidden="true"></i> {this.context.t("_profile")}</NavLink>
										</li>
										{
											(
												localStorage.getItem("role") == "trainer" ||
												localStorage.getItem("role") == "manager" ||
												localStorage.getItem("role") == "asistant" ||
												localStorage.getItem("role") == "edu_specialist" ||
												localStorage.getItem("role") == "administrator"
											)
												?
												null :
												<li>
													<NavLink exact to="/notification" ><i className="fa fa-bell-o" aria-hidden="true"></i> {this.context.t("_notification")}</NavLink>
												</li>
										}
										<li>
											<button tabIndex="0" onClick={() => this.logOut()}><i className="fa fa-sign-out" aria-hidden="true"></i> {this.context.t("_logout")}</button>
										</li>
									</ul>
								</menu>
							</span>
							:
							<NavLink to="/login" aria-label={this.context.t("sign_up_button_label")} activeClassName="active" className="sidenav-close get-started" style={dynamicStyles.direction(lang)}>
								{this.context.t("_login_nav")}
								<i className={`material-icons ${lang}`}>{`${lang == "en" ? "arrow_forward" : "arrow_backward"}`}</i>
							</NavLink>
						}
						</li>
						<li className="lang languageFocus"><NavLink to="" style={{ fontFamily: "politicaextrabold" }} onClick={evt => this.switchLanguage(evt)} activeClassName="" className="sidenav-close nav-lang" aria-label={lang == "ar" ? "language button to english" : "language button to arabic"} >{lang == "en" ? "ع" : "En"}{/* <span></span> */}</NavLink></li>
						{/* <li><NavLink to="/subscribe" activeClassName="active" className="sidenav-close get-started" style={dynamicStyles.direction(lang)}>
							{this.context.t("_navbar_get_started")}
							<i className={`material-icons ${lang}`}>{`${lang == "en" ? "arrow_forward" : "arrow_backward"}`}</i>
						</NavLink></li> */}
						{
							// !isLogin && <li><NavLink to="/registration" aria-label={this.context.t("sign_up_button_label")} activeClassName="active" className="sidenav-close get-started" style={dynamicStyles.direction(lang)}>
							// 	{this.context.t("_sign_up")}
							// 	<i className={`material-icons ${lang}`}>{`${lang == "en" ? "arrow_forward" : "arrow_backward"}`}</i>
							// </NavLink></li>
						}

					</ul>
					<a href="#" data-target="main-nav" className="sidenav-trigger sasi"><i className="material-icons">menu</i></a>

					{/* <ul id="main-nav" className="sidenav sidenav-fixed align-center" style={dynamicStyles.direction(lang)}>
						<li><NavLink exact to="/" activeClassName="active" className="sidenav-close" >{this.context.t("_home")}</NavLink></li>
						<li><NavLink exact to="/activities" activeClassName="active" className="sidenav-close" >{this.context.t("_activities")}</NavLink></li>
						<li><NavLink to="/media-center" activeClassName="active" className="sidenav-close" >{this.context.t("_navbar_media_center")}</NavLink></li>
						{isLogin ?
							<li><Link to="/" onClick={evt => this.logOut()} className="sidenav-close">{this.context.t("_navbar_logout")}</Link></li>
							:
							<li><NavLink exact to="/login" activeClassName="active" className="sidenav-close" >{this.context.t("_login")}</NavLink></li>

						}
						<li className="lang"><NavLink to="" onClick={evt => this.switchLanguage(evt)} activeClassName="active" className="sidenav-close" >{lang == "en" ? `عربى` : "English"}<span></span></NavLink></li>
						{isLogin ?
							<li>
								<span className="navigation-profile" tabIndex="0" >
									<img src={profileImage} alt="" />
									<menu>
										<ul>
											<li>
												<NavLink exact to="/profile" ><i className="fa fa-user-circle-o" aria-hidden="true"></i> {this.context.t("_profile")}</NavLink>
											</li>
											<li>
												<NavLink exact to="/notification" ><i className="fa fa-bell-o" aria-hidden="true"></i> {this.context.t("_notification")}</NavLink>
											</li>
											<li>
												<a onClick={evt => this.logOut()}><i className="fa fa-sign-out" aria-hidden="true"></i> {this.context.t("_logout")}</a>
											</li>
										</ul>
									</menu>
								</span>
								<NavLink exact to="/profile" activeClassName="active" className="sidenav-close navigation-profile" >
									<img src={profileImage} alt="" />
								</NavLink>
							</li>
							:
							null}

						<li><NavLink to="/subscribe" activeClassName="active" className="sidenav-close get-started" style={dynamicStyles.direction(lang)}>
							{this.context.t("_navbar_get_started")}
							<i className={`material-icons ${lang}`}>{`${lang == "en" ? "arrow_forward" : "arrow_backward"}`}</i>
						</NavLink></li>

					</ul> */}
				</div>
			</header>
		)
	}
}
const mapStateToProps = ({ i18nState, loginReducer }) => {
	return {
		lang: i18nState.lang,
		isLogin: loginReducer.isLogin,
		profileImage: loginReducer.profileImage
	}
}
const mapDispatchToProps = {
	userLogout: LoginAction.userLogout,
	switchLanguage: (lang) => SwitchLanguageAction.switchLanguage(lang),
}
Navigation.contextTypes = {
	t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navigation))

const ColouredItems = () => {
	return (
		<div className="menu-icon-container">
			<span className="grey-square-rotate red-sq one"></span>
			<span className="grey-square-rotate red-sq two"></span>
			<span className="orange-circle one"></span>
			<span className="orange-circle two"></span>
		</div>
	)
}