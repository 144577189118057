import React from "react"
import Main from "../main"
import { Link } from "react-router-dom"
import PageTitle from "../includes/page_title.js"
import SlideToTop from "../includes/slide_to_top.js"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import Slider from "react-slick";
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { LoadingAction } from "./../../actions"
const axios = require("axios");
class AboutUs extends Main {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			config: this.config(),
			media: [],
			slider: [],
			page_key: "",
			id: "",
			aboutUsData: [],
			currentSlide: 0,
			aboutData: {},
			visionData: {},
			missionData: {},
		}
		this.props.showLoader()
	}
	async componentDidMount() {
		document.title = `${this.context.t("_studio5")} | ${this.context.t("_about_us")}`
		await this.getAboutUS();
	}
	componentDidUpdate() {
		document.title = `${this.context.t("_studio5")} | ${this.context.t("_about_us")}`
	}
	getAboutUS = async () => {
		const { config } = this.state
		const { lang } = this.props;
		let url = ""
		if (lang == "ar")
			url = `${config.origin}${lang}/api/${urlEndPoints.aboutPage}`
		else
			url = `${config.origin}api/${urlEndPoints.aboutPage}`

		try {
			const data = await this.networkRequest(url)
			if (!data.data.error)
				this.setState({ aboutData: data.data[0] })
			this.props.hideLoader();
			setTimeout(() => {
				document.getElementById("pageHeading") && document.getElementById("pageHeading").focus()
			}, 1000);


			// else
			// 	return []
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
	}
	getMission = async () => {
		const { config } = this.state
		const { lang } = this.props;
		let url = ""
		if (lang == "ar")
			url = `${config.origin}${lang}/api/${urlEndPoints.ourMission}`
		else
			url = `${config.origin}api/${urlEndPoints.ourMission}`

		try {
			const data = await this.networkRequest(url)
			if (!data.data.error)
				this.setState({ missionData: data.data[0] })
			// else
			// 	return []
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
	}
	getVision = async () => {
		const { config } = this.state
		const { lang } = this.props;
		let url = ""
		if (lang == "ar")
			url = `${config.origin}${lang}/api/${urlEndPoints.ourVision}`
		else
			url = `${config.origin}api/${urlEndPoints.ourVision}`

		try {
			const data = await this.networkRequest(url)
			if (!data.data.error)
				this.setState({ visionData: data.data[0] })
			// else
			// 	return []
			this.props.hideLoader();
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
	}
	render() {
		const { aboutData, missionData, visionData, config } = this.state;
		const topLineImage = require("../../assets/images/top-line.png");
		return (
			<>
				<div id="main-container">
					<SlideToTop />
					<PageTitle title={this.context.t("_about_us")} isDashboardItem={true} />
					<div id="skipContent" dangerouslySetInnerHTML={{ __html: aboutData.body }} />
				</div>

				<div className="top-line ng-scope"><img src={topLineImage} alt="" /></div>
			</>
		)
	}
}

const mapStateToProps = ({ i18nState }) => {
	return {
		lang: i18nState.lang
	}
}
const mapDispatchToProps = {
	showLoader: LoadingAction.showLoader,
	hideLoader: LoadingAction.hideLoader,

}
AboutUs.contextTypes = {
	t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
