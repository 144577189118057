import React from "react"
import SlideToTop from "./slide_to_top.js"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { dynamicStyles } from "./../../constants/dynamicStyles"
class Footer extends React.Component {
  render() {
    const { lang } = this.props;
    const year = new Date().getFullYear();
    const footer_logo = require("../../assets/images/footer-logo.png");
    return (
      <div id="footer" className="ng-scope">
        <div className="container">
          {/* <img src={footer_logo} alt={this.context.t("footer_logo_alt")} className="footer-logo" style={dynamicStyles.position(lang)}/> */}
          <div className="clearfix" />
          <div className="copy-text">{`${this.context.t("_footer_all_rights")}${year}`}</div>
          {
            lang == "ar" ? <a href="https://mada.org.qa/?lang=ar" target="_blank"><img src="http://madaportal.org/accr/g.php?36yYE0DL8NsOzx8BGLTRnehtaaG6dEQ2UrA3zrpvmsM=" alt="اعتماد المواقع الالكترونية الوطنية - مدى, اعتماد النفاذ الرقمي" /></a> : <a href="https://mada.org.qa/" target="_blank">
              <img src="http://madaportal.org/accr/g.php?36yYE0DL8NsOzx8BGLTRnehtaaG6dEQ2UrA3zrpvmsM=@" alt="Mada National Web Accreditation, Access Certified" />
            </a>
          }

        </div>
        <SlideToTop button="true" />
      </div>
    )
  }
}
const mapStateToProps = ({ i18nState }) => {
  return {
    lang: i18nState.lang
  }
}
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
Footer.contextTypes = {
  t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(Footer);