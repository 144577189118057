import React from "react";
import { Link } from "react-router-dom";
import Main from "./../../main"
import PageTitle from "../../includes/page_title";
import SlideToTop from "../../includes/slide_to_top";
import M from "materialize-css";
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { urlEndPoints } from "./../../../httpAPI/apiConfig"
import _ from "lodash"
import dateformat from "dateformat"
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { LoadingAction } from "./../../../actions"
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";

class Calendar extends Main {
    constructor(props) {
        super(props);
        this.state = {
            config: this.config(),
            calendarWeekends: true,
            calendarEvents: [],
            filterDate: new Date()
        }
        this.props.showLoader()
        this.handleEventClick = this.handleEventClick.bind(this)
    }
    componentDidMount() {
        document.title = `${this.context.t("_studio5")} | ${this.context.t("_calendar")}`
        M.AutoInit();
        const date = new Date(), y = date.getFullYear(), m = date.getMonth();
        const firstDay = new Date(y, m, 1);
        const lastDay = new Date(y, m + 1, 0);
        this.getCalendarData(dateformat(firstDay, "yyyy-mm-dd"), dateformat(lastDay, "yyyy-mm-dd"));
    }
    componentDidUpdate() {
        document.title = `${this.context.t("_studio5")} | ${this.context.t("_calendar")}`
    }
    calendarComponentRef = React.createRef();
    getCalendarData = async (firstDay, lastDay) => {
        const { config } = this.state
        const { lang } = this.props;
        let url = ""
        if (localStorage.getItem("role") == "trainer" || localStorage.getItem("role") == "asistant") {
            if (lang == "ar")
                url = `${config.origin}${lang}/api/${urlEndPoints.trainerCalendar}?`
            else
                url = `${config.api_url}${urlEndPoints.trainerCalendar}?`
        }
        if (localStorage.getItem("role") == "manager" || localStorage.getItem("role") == "edu_specialist") {
            if (lang == "ar")
                url = `${config.origin}${lang}/api/${urlEndPoints.managerCalendar}?`
            else
                url = `${config.api_url}${urlEndPoints.managerCalendar}?`
        }
        if (!_.isEmpty(firstDay))
            url = `${url}&field_start_and_end_time_value=${firstDay}`
        if (!_.isEmpty(lastDay))
            url = `${url}&field_start_and_end_time_end_value=${lastDay}`
        try {
            const data = await this.networkRequest(url)

            if (data.data.error) {

            } else {
                const calendarEvents = [];
                data.data.results.forEach(item => {
                    let tempObj = {
                        title: item.title,
                        start: new Date(item.field_start_and_end_time),
                        end: new Date(item.field_start_and_end_time_1),
                        url: item.nid
                    }
                    calendarEvents.push(tempObj)
                });

                this.setState({ calendarEvents })
            }
            this.props.hideLoader()
            setTimeout(() => {
                document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
            }, 500);
        } catch (error) {
            this.props.hideLoader()
            console.warn("error: ", error)
        }
    }
    handleEventClick(info) {
        info.jsEvent.preventDefault();
        if (info.event.url) {
            this.props.history.push(`/activity/${info.event.url}`)
        }
    }
    toggleWeekends = () => {
        this.setState({
            // update a property
            calendarWeekends: !this.state.calendarWeekends
        });
    };

    goPreviousMonth = () => {
        this.props.showLoader()
        const calendarApi = this.calendarComponentRef.current.getApi();
        const currentMonth = calendarApi.state.currentDate;
        const current = currentMonth.setMonth(currentMonth.getMonth() - 1)
        const date = new Date(current), y = date.getFullYear(), m = date.getMonth();
        const firstDay = new Date(y, m, 1);
        const lastDay = new Date(y, m + 1, 0);
        this.getCalendarData(dateformat(firstDay, "yyyy-mm-dd"), dateformat(lastDay, "yyyy-mm-dd"));
        calendarApi.gotoDate(new Date(current));
    };
    goNextMonth = () => {
        this.props.showLoader()
        const calendarApi = this.calendarComponentRef.current.getApi();
        const currentMonth = calendarApi.state.currentDate;
        const current = currentMonth.setMonth(currentMonth.getMonth() + 1)
        const date = new Date(current), y = date.getFullYear(), m = date.getMonth();
        const firstDay = new Date(y, m, 1);
        const lastDay = new Date(y, m + 1, 0);
        this.getCalendarData(dateformat(firstDay, "yyyy-mm-dd"), dateformat(lastDay, "yyyy-mm-dd"));
        calendarApi.gotoDate(new Date(current));
    };

    handleDateClick = arg => {
    };
    render() {
        const { calendarEvents, calendarWeekends } = this.state;
        const that = this;
        return (
            <div id="main-container">
                <SlideToTop />
                <PageTitle title={this.context.t("_calendar")} />
                <div id="skipContent" className="container">
                    <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                        <ul>
                            <li className="breadcrumb-item">
                                <Link to="/admin-profile">{this.context.t("_profile")}</Link> {" "}
                            </li>
                            <li className="breadcrumb-item">
                                <Link tabIndex="0" to aria-current={this.context.t("_page")}>
                                    <span>
                                        {this.context.t("_calendar")}
                                    </span>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="container">
                    <div className="profileCalendar fontEnglish">
                        <FullCalendar
                            defaultView="dayGridMonth"
                            header={{
                                left: "myCustomButton1",
                                center: "title",
                                right: "myCustomButton2"
                            }}
                            plugins={[dayGridPlugin, interactionPlugin]}
                            ref={this.calendarComponentRef}
                            weekends={calendarWeekends}
                            events={calendarEvents}
                            customButtons={{
                                myCustomButton1: {
                                    text: this.context.t("_previous"),
                                    click: function () {
                                        that.goPreviousMonth();
                                    }
                                }, myCustomButton2: {
                                    text: this.context.t("_next"),
                                    click: function () {
                                        that.goNextMonth();
                                    }
                                }
                            }}
                            eventClick={this.handleEventClick}
                            dateClick={this.handleDateClick}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ i18nState }) => {
    return {
        lang: i18nState.lang
    }
}
const mapDispatchToProps = {
    showLoader: LoadingAction.showLoader,
    hideLoader: LoadingAction.hideLoader,
}
Calendar.contextTypes = {
    t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
