import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import Main from "../main"
// import { Link } from "react-router-dom"

class PageTitle extends Main {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      config: this.config(),
      error: "",
    }
  }
  handleTwitterRedirect = (e) => {
    e.preventDefault();
    const config = this.config()
    window.open(config.social_media_links.twitter, '_blank').focus()

  }
  render() {
    const { config } = this.state;
    const { lang, isDashboardItem } = this.props;
    return (
      <div className={`page-title ${lang}`}>
        <div className="container">
          <div className="head-title">

            <div>
              {
                isDashboardItem ?
                  <div className={`share-page ${lang}`}>
                    <h2>{this.context.t("_title_share_page")}</h2>
                    {config.social_media_links.facebook &&
                      <a target="_blank" rel="noopener noreferrer" href={config.social_media_links.facebook} className="fb-xfbml-parse-ignore">
                        <span>acebook</span>
                        <i aria-label="Facebook" className="fa fa-facebook">&nbsp;</i>
                      </a>
                    }
                    {config.social_media_links.twitter &&
                      <a target="_blank" rel="noopener noreferrer" onClick={this.handleTwitterRedirect} href={config.social_media_links.twitter} >
                        <span>acebook</span>
                        <i aria-label="Twitter" className="fa fa-twitter">&nbsp;</i>
                      </a>
                    }
                    {/* <span className="yellow-box"></span> */}
                  </div>
                  :
                  null
              }
            </div>
            <h2 id="pageHeading" role="heading" tabindex="0" aria-label={`page heading ${this.props.title}`}>{this.props.title}</h2>
          </div>
        </div>

      </div>
    )
  }
}
const mapStateToProps = ({ i18nState }) => {
  return {
    lang: i18nState.lang
  }
}
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
PageTitle.contextTypes = {
  t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(PageTitle)