import React from "react"
import Main from "../main"
import SlideToTop from "../includes/slide_to_top.js"
import PageTitle from "../includes/page_title.js"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { dynamicStyles } from "./../../constants/dynamicStyles"
import { Link } from "react-router-dom"
import _ from "lodash"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { LoadingAction, PopupAction } from "./../../actions"
import { passwordValidation } from "./../../constants/common"

class ChangePassword extends Main {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			config: this.config(),
			formData: {},
			error: "",
			intervalId: 0,
			scrollStepInPx: 50,
			delayInMs: 16.66,
			submissionStatus: false,
			showOldPassword: false,
			showNewPassword: false,
			showConfirmPassword: false,
		}
		this.handleChange = this.handleChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}
	componentDidMount() {
		document.title = `${this.context.t("_studio5")} | ${this.context.t("_change_password")}`
		this.initState()
		setTimeout(() => {
			document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
		}, 500);
	}
	componentDidUpdate() {
		document.title = `${this.context.t("_studio5")} | ${this.context.t("_change_password")}`
	}
	initState = () => {
		this.setState({
			formData: {
				oldPassword: { value: "", error: "", class: "" },
				newPassword: { value: "", error: "", class: "" },
				cpassword: { value: "", error: "", class: "" },
			}
		})
	}
	handleChange = (field, value) => {
		const { formData } = this.state;
		formData[field]["class"] = value ? "" : "invalid";
		formData[field]["value"] = value;
		// contact_form.submit = submit;
		this.setState({ formData, submissionStatus: false });
	}
	scrollStep() {
		var scrollStepInPx = this.state.scrollStepInPx;
		if (window.pageYOffset <= 400) {
			clearInterval(this.state.intervalId);
		}
		window.scroll(0, window.pageYOffset - scrollStepInPx);
	}
	scrollToTop() {
		window.scroll({ top: 0, left: 0, behavior: "smooth" })
		// var delayInMs = this.props.delayInMs ? this.props.delayInMs : this.state.delayInMs;
		// let intervalId = setInterval(this.scrollStep.bind(this), delayInMs);
		// this.setState({ intervalId: intervalId });
	}
	validate = () => {
		const { formData } = this.state;
		for (var key1 in formData) {
			formData[key1].value = formData[key1].value.trim();
			formData[key1].error = "";
		}

		if (formData.oldPassword.value === "")
			formData.oldPassword.error = this.context.t("_old_password_is_required");

		if (formData.newPassword.value === "")
			formData.newPassword.error = this.context.t("_password_is_required");

		if (formData.newPassword.value != "" && formData.newPassword.value.length < 8 && !passwordValidation.test(formData.newPassword.value))
			formData.newPassword.error = this.context.t("_password_condition");

		if (formData.cpassword.value === "")
			formData.cpassword.error = this.context.t("_confirm_password_required");

		if ((formData.newPassword.value !== "" && formData.cpassword.value !== "") && (formData.newPassword.value !== formData.cpassword.value))
			formData.newPassword.error = this.context.t("_password_mismatch");

		for (var key in formData) {
			if (formData[key].error !== "") {
				this.setState({ formData, submissionStatus: false })
				setTimeout(() => {
					// this.refs.errorListRef.focus()
					this[key + "error"].focus()
				}, 1000);
				return false;

			}
		}
		return true;
	}
	displayError = error => {
		const { formData } = this.state;
		const { lang } = this.props;
		if (error.name)
			formData.oldPassword.error = error.name[lang]

		for (var key in formData) {
			if (formData[key].error !== "") {
				this.setState({ formData, })
				setTimeout(() => {
					// this.refs.errorListRef.focus()
					this[key + "error"].focus()
				}, 1000);
				return false;

			}
		}
		// this.setState({ formData })
		// this.scrollToTop();
	}
	onSubmit = async (event) => {
		event.preventDefault();
		const { formData, config } = this.state;
		if (this.validate()) {
			this.props.showLoader();
			const obj = {
				"pass": formData.newPassword.value,
				"oldpass": formData.oldPassword.value
			}
			const url = `${config.api_url}${urlEndPoints.updatePassword}`
			try {
				const data = await this.networkRequest(url, "POST", obj)
				if (data.data.error) {
					this.props.hideLoader();
					this.displayError(data.data.error)
				}
				else {
					// localStorage.removeItem("accessToken");
					// localStorage.removeItem("refreshToken");
					// localStorage.removeItem("tokenType");
					// localStorage.removeItem("role");
					this.props.showPopup({
						type: "success",
						title: this.context.t("_success"),
						message: this.context.t("_password_changed_success"),
						isOpen: true,
						buttonText: this.context.t("_ok")
					})
					if (
						localStorage.getItem("role") == "trainer" ||
						localStorage.getItem("role") == "manager" ||
						localStorage.getItem("role") == "asistant" ||
						localStorage.getItem("role") == "edu_specialist" ||
						localStorage.getItem("role") == "administrator"
					)
						this.props.history.push("/admin-profile");
					else
						this.props.history.push("/profile");
				}
			} catch (error) {
				this.props.hideLoader();
				console.warn("error: ", error)
			}
		}
	}
	handleShowPassword = stateValue => {
		this.setState({ [stateValue]: !this.state[stateValue] })
	}
	render() {
		const { formData, error, submissionStatus, showConfirmPassword, showNewPassword, showOldPassword } = this.state;
		const { lang } = this.props;
		let hasError = false;
		Object.keys(formData).forEach((item, index) => {
			if (formData[item].error !== "")
				hasError = true
		})
		return (
			<div id="main-content">
				<SlideToTop />
				<PageTitle title={this.context.t("_change_password")} />
				<div className="container">
					<nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
						<ul>
							<li className="breadcrumb-item">
								<Link to="/">{this.context.t("_home")} </Link> {" "}
							</li>
							<li className="breadcrumb-item">
								<Link tabIndex="0" to aria-current={this.context.t("_page")}>
									<span>{this.context.t("_change_password")}</span>
								</Link>
							</li>
						</ul>
					</nav>
				</div>
				<div id="skipContent">
					<div id="main-container">
						<div className="container">
							<div className="row col-8">
								{
									_.isEmpty(formData) ?
										<div /> :
										<form name="login" onSubmit={this.onSubmit} className="login-form">
											{error &&
												<div className="error-container fail">
													<ul>
														<li><label className="error" >{error}</label></li>
													</ul>
												</div>
											}
											{
												hasError ? <div className="error-container fail">
													<h5>{this.context.t("_error_title")}</h5>
													<ul>
														{
															Object.keys(formData).map((item, index) => {
																return formData[item].error !== "" ? <li key={index} ><a ref={(refItem) => { this[item + "error"] = refItem; }} tabIndex="0" href={`#${item}`} className="error" htmlFor={item}>{formData[item].error}</a></li>
																	:
																	null
															})}
													</ul>
												</div> : null

											}
											{
												submissionStatus ? <div className="error-container success" tabIndex="0" ref="successMessageRef">
													<h5>{this.context.t("_reset_password_success")}</h5>
												</div> : null
											}
											<div className="input-field item">
												<input name="oldPassword"
													placeholder={this.context.t("_old_password")}
													className="fontEnglish"
													onChange={evt => this.handleChange("oldPassword", evt.target.value)}
													onFocus={evt => this.handleChange("oldPassword", evt.target.value)}
													value={formData.oldPassword.value}
													id="oldPassword"
													type={showOldPassword ? "text" : "password"}
												/>
												<button aria-label="Show password" onClick={() => { this.handleShowPassword("showOldPassword") }} type="button" className="showEyes">
													<i className={showOldPassword ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i>
												</button>
												<label htmlFor="oldPassword" style={dynamicStyles.position(lang)}>{this.context.t("_old_password")}</label>
												<span className="helper-text" data-error="Required field."></span>
											</div>
											<div className="input-field item">
												<input name="newPassword"
													className="fontEnglish"
													placeholder={this.context.t("_password")}
													onChange={evt => this.handleChange("newPassword", evt.target.value)}
													onFocus={evt => this.handleChange("newPassword", evt.target.value)}
													value={formData.newPassword.value}
													id="newPassword"
													type={showNewPassword ? "text" : "password"}
												/>
												<button aria-label="Show password" onClick={() => { this.handleShowPassword("showNewPassword") }} type="button" className="showEyes">
													<i className={showNewPassword ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i>
												</button>
												<label htmlFor="newPassword" style={dynamicStyles.position(lang)}>{this.context.t("_password")}</label>
												<small className="info">{this.context.t("_password_condition")}</small>
												<span className="helper-text" data-error="Required field."></span>
											</div>
											<div className="input-field item">
												<input name="cpassword"
													className="fontEnglish"
													placeholder={this.context.t("_confirm_password")}
													onChange={evt => this.handleChange("cpassword", evt.target.value)}
													onFocus={evt => this.handleChange("cpassword", evt.target.value)}
													value={formData.cpassword.value}
													id="cpassword"
													type={showConfirmPassword ? "text" : "password"}
												/>
												<button aria-label="Show password" onClick={() => { this.handleShowPassword("showConfirmPassword") }} type="button" className="showEyes">
													<i className={showConfirmPassword ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i>
												</button>
												<label htmlFor="cpassword" style={dynamicStyles.position(lang)}>{this.context.t("_confirm_password")}</label>
												<span className="helper-text" data-error="Required field."></span>
											</div>

											<div className="btn-wrap reset-button-container">
												<button className="btn red login" >{this.context.t("_change_password")} <i className={`material-icons ${lang}`}>{`${lang === "en" ? "arrow_forward" : "arrow_backward"}`}</i>
												</button>
											</div>
										</form>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
const mapStateToProps = ({ i18nState }) => {
	return {
		lang: i18nState.lang
	}
}
const mapDispatchToProps = {
	showLoader: LoadingAction.showLoader,
	hideLoader: LoadingAction.hideLoader,
	showPopup: (data) => PopupAction.showPopup(data),

}
ChangePassword.contextTypes = {
	t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
