import _ from "lodash"
import { gradeListNew } from "./../../../constants/common"
import CountryList from "./../../../constants/country.json"



const getGender = (gender, context) => {
    if (gender) {
        gender = gender.toLowerCase()
        switch (gender) {
            case "male":
                return context.t("_male")
            case "female":
                return context.t("_female")
        }
    }
    return gender
}
const handleComa = (text, lang) => {
    if (text) {
        return text.replace(/,/g, this.context.t("_coma"))
    }
    return text
}

const handleGradeName = (grade, lang) => {
    if (!_.isEmpty(grade)) {
        // const { lang } = this.props;
        const gradeValue = _.find(gradeListNew[lang], ["value", grade])
        return !_.isEmpty(gradeValue) ? gradeValue.label : ""
    }
    return grade
}
const calculateAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}
const handleCountryName = (country, lang) => {
    if (!_.isEmpty(country)) {
        // const { lang } = this.props;
        const countryValue = _.find(CountryList, ["country", { "en": country }])
        return !_.isEmpty(countryValue) ? countryValue.country[lang] : ""
    }
    return country
}


const getType = (type, context) => {
    if (type) {
        type = type.toLowerCase()
        switch (type) {
            case "event":
                return context.t("_events")
            case "session":
                return context.t("_sessions")
            case "competition":
                return context.t("_competitions")
            case "challenge":
                return context.t("_challenges")
            case "project":
                return context.t("_projects")
        }
    }
}
const getFieldType = (field, context) => {
    if (field) {
        field = field.toLowerCase()
        switch (field) {
            case "online":
                return context.t("_online")
            case "onsite":
                return context.t("_onsite")
        }
    }
    return field
}
const getZone = (zone, context) => {
    if (zone) {
        zone = zone.toLowerCase()
        switch (zone) {
            case "creativity zone":
                return context.t("_creative_zone")
            case "software zone":
                return context.t("_software_zone")
            case "technology zone":
                return context.t("_technology_zone")
        }
    }
}
export {
    getGender,
    handleGradeName,
    calculateAge,
    handleCountryName,
    getType,
    getFieldType,
    getZone,
    handleComa
}