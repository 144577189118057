import React from "react"
import Main from "../main"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { dynamicStyles } from "./../../constants/dynamicStyles"
const axios = require("axios");
class News extends Main {
	constructor(props) {
		super(props);
		this.state = {
			config: this.config(),
			news: [],
			news_video: [],
			news_gallery: [],
		}
	}
	componentDidMount() {
		this.get_news();
		this.get_news_video();
		this.get_news_gallery();
	}
	get_news_gallery() {
		const { config } = this.state;
		const { lang } = this.props;
		let url = "";
		if (lang == "ar")
			url = `${config.origin}${lang}/api/featured-news-gallery`
		else
			url = `${config.origin}api/featured-news-gallery`

		var self = this;
		axios.get(url, {
			params: {
				_format: "json"
			}
		})
			.then(function (response) {
				self.setState({
					news_gallery: response.data
				});
			})
			.catch(function (error) {
				console.warn(error);
			});
	}
	get_news_video() {
		const { config } = this.state;
		const { lang } = this.props;
		let url = "";
		if (lang == "ar")
			url = `${config.origin}${lang}/api/featured-news-video`
		else
			url = `${config.origin}api/featured-news-video`

		var self = this;
		axios.get(url, {
			params: {
				_format: "json"
			}
		})
			.then(function (response) {
				self.setState({
					news_video: response.data
				});
			})
			.catch(function (error) {
				console.warn(error);
			});
	}
	get_news() {
		const { config } = this.state;
		const { lang } = this.props;
		let url = "";
		if (lang == "ar")
			url = `${config.origin}${lang}/api/featured-news-article`
		else
			url = `${config.origin}api/featured-news-article`

		var self = this;
		axios.get(url, {
			params: {
				_format: "json"
			}
		})
			.then(function (response) {
				self.setState({
					news: response.data
				});
			})
			.catch(function (error) {
				console.warn(error);
			})
			.then(function () {
				// always executed
			});
	}
	render() {
		const { news, news_gallery, news_video, config } = this.state;
		const { lang } = this.props;
		return (
			<div className="news-wrap">
				<div className="container">
					<h2 id="news-media" className="line">
						{/* <span className="grey-text">{this.context.t("_updates")}</span> */}
						{this.context.t("_news")} {this.context.t("_and_symbol")} {this.context.t("_media")}
						<span className="grey-square-rotate"></span>
					</h2>
					<div className="flex-row col-2">
						<div className="item news-item">
							<div className="flex-row col-3">
								{news.map((row, index) => (
									<div className="homeNewsParent" key={index}>
										<Link to={"/news/" + row.nid} className="item homeNewsText1" aria-label={`${row.title} ${row.category} ${row.date}`}>
											<h3 dangerouslySetInnerHTML={{ __html: row.title }}></h3>
										</Link>
										<div className="img-wrap"
										// dangerouslySetInnerHTML={{ __html: row.image }}
										>
											<img src={config.origin + row.field_newsimage} alt="news image" />
										</div>
										<div className="homeNewsText2">
											<div dangerouslySetInnerHTML={{ __html: row.category }} className="category-wrap"></div>
											<div className="desc" dangerouslySetInnerHTML={{ __html: row.description }}></div>
											<p className="date-wrap" aria-label={row.date} dangerouslySetInnerHTML={{ __html: row.date }} ></p>
										</div>

									</div>
								))}
							</div>
						</div>
						<div className={`item gal-item ${lang}`}>
							<div className="flex-row col-2">
								<div className="item">
									{news_video.map((row, index) => (
										<div className="" key={index}>
											<div className="videowrapper">
												<object
													data={row.video} tabIndex="-1">
													<param name="movie" value={row.video} />
													<param name="allowFullScreen" mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen" value="true"></param>
													<span>Video</span>
												</object>
											</div>
											<div className="category-wrap Video" dangerouslySetInnerHTML={{ __html: row.category }} ></div>
											<Link to={"/news/" + row.nid} aria-label={`${row.title} ${row.category} ${row.date}`}>
												<div className="desc" dangerouslySetInnerHTML={{ __html: row.title }}></div>
											</Link>
											<p className="date-wrap" aria-label={row.date} dangerouslySetInnerHTML={{ __html: row.date }}></p>
										</div>
									))}
								</div>
								<div className="item">
									{news_gallery.map((row, index) => (
										<div className="" key={index}>
											<div className="img-wrap" dangerouslySetInnerHTML={{ __html: row.image }}></div>
											<div className="category-wrap Gallery" dangerouslySetInnerHTML={{ __html: row.category }} ></div>
											<Link to={"/news/" + row.nid} aria-label={`${row.title} ${row.category} ${row.date}`}>
												<div className="desc" dangerouslySetInnerHTML={{ __html: row.title }}></div>
											</Link>
											<p className="date-wrap" aria-label={row.date} dangerouslySetInnerHTML={{ __html: row.date }}></p>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
					<div className="view-more home">
						<Link to="/media-center" aria-label={this.context.t("_view_more_news_and_media")}>{this.context.t("_view_more")}
							<i className={`material-icons ${lang}`}>{`${lang == "en" ? "" : ""}`}</i>
						</Link>
					</div>
					<div className="multi-square"><b><i></i></b></div>
					<div className="multi-circle"><b><i></i></b></div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = ({ i18nState }) => {
	return {
		lang: i18nState.lang
	}
}
const mapDispatchToProps = dispatch => {
	return {
		dispatch
	}
}
News.contextTypes = {
	t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(News);
