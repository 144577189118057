import React, { forwardRef } from "react"
import { Link } from "react-router-dom";
import Main from "../main";
import PageTitle from "../includes/page_title.js";
import SlideToTop from "../includes/slide_to_top.js";
import { connect } from "react-redux"
import PropTypes from "prop-types"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { dynamicStyles } from "./../../constants/dynamicStyles"
import M from "materialize-css";
import RegisterTypes from "./registerTypes"
import { emailValidate, nameValidate, qidValidate, phoneValidate, nameWordLengthCheck, gradeListNew, calenderI18n, CustomDateInput, calenderLocal } from "./../../constants/common"
import _ from "lodash"
import { LoadingAction } from "./../../actions"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import "react-toastify/dist/ReactToastify.css";
import dateformat from "dateformat";
import CountryList from "./../../constants/country.json"
import topLineImage from "../../assets/images/top-line.png"

class StudentRegistration extends Main {
    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            schoolList: [],
            config: this.config(),
            intervalId: 0,
            scrollStepInPx: 50,
            delayInMs: 16.66,
            submissionStatus: false,
            termsAndCondition: {}
        }
    }
    renderDayContents = (day, date) => {
        const tooltipText = `Tooltip for date: ${date}`;
        return <span title={tooltipText}>{"4"}</span>;
    };
    componentDidMount() {
        document.title = `${this.context.t("_studio5")} | ${this.context.t("_registration")} | ${this.context.t("_student")}`
        // this.refs.studentHeading.focus()
        this.isLoggedInUsers();
        this.initState();
        this.getSchoolList();
        this.getTermsAndCondition();
        M.AutoInit();
        let today = new Date()
        var options = {

            autoClose: true,
            onSelect: ((date) => {
                this.handleChange("dob", dateformat(date, "dd/mm/yyyy"))
            }),
            onDraw: ((e) => {
                // setTimeout(() => {
                //     let elem = document.querySelectorAll('select');
                //     console.log("elemelem->", elem);
                //     elem.forEach(element => {
                //         element.setAttribute("tabindex", "0");
                //     });
                // }, 300);
            }),
            // defaultDate: new Date("01/01/2001"),
            // setDefaultDate: new Date("12/31/2014"),
            format: "dd-mm-yyyy",
            container: null,
            maxDate: new Date("12/31/2014"),
            minDate: new Date("01/01/2001"),
            yearRange: 20,
            minYear: 2001,
            maxYear: 2014
        };

        setTimeout(() => {
            options.i18n = calenderI18n(this.context);
            // options.isRTL = this.props.lang == "ar"
            var elems = document.querySelectorAll("#dob");
            var selectelem = document.querySelectorAll("select");
            // options.i18n = calenderI18n(this.context);
            // let instance = M.Datepicker.init(elems, options);

            M.FormSelect.init(selectelem, options);
            let component = document.getElementById("nationality");
            if (component)
                component.tabIndex = 0;
            component = document.getElementById("grade");
            if (component)
                component.tabIndex = 0;
            component = document.getElementById("relation");
            if (component)
                component.tabIndex = 0;
        }, 1000);
    }
    componentDidUpdate() {
        document.title = `${this.context.t("_studio5")} | ${this.context.t("_registration")} | ${this.context.t("_student")}`
    }
    initState = () => {
        this.setState({
            formData: {
                name: { value: "", error: "", class: "" },
                nameArabic: { value: "", error: "", class: "" },
                schoolName: { value: "", error: "", class: "" },
                schoolNewName: { value: "", error: "", class: "" },
                qid: { value: "", error: "", class: "" },
                gender: { value: "", error: "", class: "" },
                dob: { value: "", error: "", class: "" },
                nationality: { value: "", error: "", class: "" },
                grade: { value: "", error: "", class: "" },
                email: { value: "", error: "", class: "" },
                mobile: { value: "", error: "", class: "" },
                parentName: { value: "", error: "", class: "" },
                parentNameArabic: { value: "", error: "", class: "" },
                parentMobile: { value: "", error: "", class: "" },
                parentMobile2: { value: "", error: "", class: "" },
                parentEmail: { value: "", error: "", class: "" },
                parentQid: { value: "", error: "", class: "" },
                relation: { value: "", error: "", class: "" },
                channel: { value: "", error: "", class: "" },
                agree: { value: false, error: "", class: "" },

            }
        })
    }
    initSelect = () => {
        const { formData } = this.state;
        if (formData.schoolName) {
            var options = {
                autoClose: true,
                classes: this.state.formData.schoolName.class

            };
            var elems = document.querySelectorAll("select");
            M.FormSelect.init(elems, options);
        }

    }
    handleChange = (field, value) => {
        if (field == "qid" || field == "parentQid" || field == "mobile" || field == "parentMobile" || field == "parentMobile2")
            if (isNaN(value))
                return false
        const { formData } = this.state;
        formData[field]["class"] = value ? "" : "invalid";
        formData[field]["value"] = value;
        this.setState({ formData, submissionStatus: false });
    };
    getSchoolList = async () => {
        const { config } = this.state
        const { lang } = this.props;
        const url = `${config.api_url}${urlEndPoints.schoolList(lang)}`
        try {
            const data = await this.networkRequest(url)
            this.setState({ schoolList: data.data })
            // this.schoolName.focus()
            this.initSelect()
        } catch (error) {
            this.props.hideLoader()
            console.warn("error: ", error)
        }
    }
    getTermsAndCondition = async () => {
        const { config } = this.state
        const { lang } = this.props
        let url = "";
        if (lang == "ar")
            url = `${config.origin}${lang}/${urlEndPoints.termsAndCondition}`
        else
            url = `${config.origin}${urlEndPoints.termsAndCondition}`
        try {
            const data = await this.networkRequest(url)
            if (data.status == 200 && data.data && data.data.length > 0) {
                this.setState({ termsAndCondition: data.data[0] })
            }
        } catch (error) {
            this.props.hideLoader()
            console.warn("error: ", error)
        }
    }
    renderInput = ({ name, label, placeholder, type = "text", isMandatory = false, max, styleClass = "" }) => {
        const { formData } = this.state;
        const { lang } = this.props;
        return (type == "phone_number" ?
            <div className="row mobile">
                <div className="col s4">
                    <div className="input-field item">
                        <input name={`${name}_country_code`}
                            className={styleClass}
                            placeholder="Country Code"
                            value={this.context.t("_ar_country_code")}
                            style={dynamicStyles.textAlign(lang)}
                            id={`${name}_country_code`}
                            type="text"
                            minLength="3"
                            maxLength="3"
                            disabled
                        // className={"validate " + registration_form.mobile.class}
                        // required
                        />
                        <label htmlFor={`${name}_country_code`} style={dynamicStyles.position(lang)}>{this.context.t("_country_code")}</label>
                        <span className="helper-text" data-error="Enter a valid code."></span>
                    </div>
                </div>
                <div className="col s8">
                    <div className="input-field item">
                        <input
                            name={name}
                            className={styleClass}
                            placeholder={placeholder}
                            ref={(input) => { this[name] = input; }}
                            aria-label={this.context.t("_phone_aria_label")}
                            id={name}
                            maxLength={max}
                            type="text"
                            style={dynamicStyles.textAlign(lang)}
                            // readOnly={type === "date" ? "readonly" : false}
                            autoComplete="no"
                            onChange={evt =>
                                this.handleChange(name, evt.target.value)
                            }
                            onFocus={evt =>
                                this.handleChange(name, evt.target.value)
                            }
                            value={formData[name].value}
                        // className={"validate " + formData[name].class}
                        // required={isMandatory}
                        />
                        <label htmlFor={name} style={dynamicStyles.position(lang)}>
                            {`${label} `}
                            {isMandatory ? <span className="asterisk">*</span> : null}
                        </label>
                        <span
                            style={dynamicStyles.textAlign(lang)}
                            className="helper-text"
                            data-error={formData[name].error ? formData[name].error : "Required field."}
                        ></span>
                    </div>
                </div>
            </div>
            : type == "date" ? <div className="input-field item">
                <DatePicker
                    name={name}
                    placeholderText={placeholder}
                    ref={(input) => { this[name] = input; }}
                    id={name}
                    locale={calenderLocal(this.context)}
                    autoComplete="off"
                    className={styleClass}
                    maxDate={new Date("12/31/2014")}
                    minDate={new Date("01/01/2001")}
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomDateInput maxDate={new Date("12/31/2014")} minDate={new Date("01/01/2001")} onFieldChange={date => this.handleChange(name, new Date(date))} ariaLabel={this.context.t("_select_dob_button")} />}
                    onChange={date =>
                        this.handleChange(name, date)
                    }
                    onCalendarOpen={e => {
                        let elems = document.querySelector(".react-datepicker");
                        elems.setAttribute("tabindex", "0")
                        elems.focus()
                        elems = document.querySelector(".react-datepicker__month-select");
                        elems.setAttribute("aria-label", this.context.t("_select_month"))
                        elems = document.querySelector(".react-datepicker__year-select");
                        elems.setAttribute("aria-label", this.context.t("_select_year"))

                    }}
                    onCalendarClose={e => {
                        var elems = document.querySelector(".hiddenDob");
                        elems.setAttribute("aria-label", `${this.context.t("_selected_date")} ${dateformat(formData[name].value, "dd-mm-yyyy")}`)
                        elems.setAttribute("value", dateformat(formData[name].value, "yyyy-mm-dd"))

                        setTimeout(() => {
                            elems.focus()
                        }, 200);
                    }}
                    style={dynamicStyles.textAlign(lang)}
                    value={formData[name].value}
                    selected={formData[name].value}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
                <label htmlFor={name} style={dynamicStyles.position(lang)}>
                    {`${label} `}
                    {isMandatory ? <span className="asterisk">*</span> : null}
                </label>
                <span
                    style={dynamicStyles.textAlign(lang)}
                    className="helper-text"
                    data-error={formData[name].error ? formData[name].error : "Required field."}
                ></span>
            </div> :
                <div className="input-field item">
                    <input
                        name={name}
                        placeholder={placeholder}
                        ref={(input) => { this[name] = input; }}
                        id={name}
                        className={styleClass}
                        data-date="" data-date-format="DD MMMM YYYY"
                        maxLength={max}
                        type={type === "date" ? "text" : type}
                        style={dynamicStyles.textAlign(lang)}
                        // readOnly={type === "date" ? "readonly" : false}
                        autoComplete="no"
                        onChange={evt =>
                            this.handleChange(name, evt.target.value)
                        }
                        onFocus={evt =>
                            this.handleChange(name, evt.target.value)
                        }
                        value={formData[name].value}
                    // className={"validate " + formData[name].class}
                    // required={isMandatory}
                    />
                    <label htmlFor={name} style={dynamicStyles.position(lang)}>
                        {`${label} `}
                        {isMandatory ? <span className="asterisk">*</span> : null}
                    </label>
                    <span
                        style={dynamicStyles.textAlign(lang)}
                        className="helper-text"
                        data-error={formData[name].error ? formData[name].error : "Required field."}
                    ></span>
                </div>)
    }

    renderSelect = ({ name, label, placeholder, isMandatory = false, data = [], type = "select" }) => {
        const { formData } = this.state;
        const { lang } = this.props;
        return (
            <div className="input-field mobileSelect">
                <label htmlFor={name} style={dynamicStyles.position(lang)}>
                    {`${label} `}
                    {isMandatory ? <span className="asterisk">*</span> : null}
                </label>
                <select
                    className="browser-default"
                    name={name}
                    id={name}
                    style={dynamicStyles.textAlign(lang)}
                    ref={(input) => { this[name] = input; }}
                    onChange={evt =>
                        this.handleChange(name, evt.target.value)
                    }

                    onFocus={evt =>
                        this.handleChange(name, evt.target.value)
                    }
                    value={formData[name].value}
                // className={"validate" + formData[name].class}
                >
                    <option value="" disabled> {placeholder} </option>
                    {
                        data.map((item, index) => {
                            return type == "country" ?
                                <option key={index} value={item.country_id}>{item.country[lang]}</option>
                                :
                                type == "school" ? <option key={index} value={item.id}>{item.sname}</option> :
                                    <option key={index} value={item.key}>{item.label}</option>
                        })
                    }
                    {
                        type == "school" && <option value="0">{this.context.t("_not_in_list")}</option>
                    }
                </select>

                <span
                    style={dynamicStyles.textAlign(lang)}
                    className="helper-text"
                    data-error={formData[name].error ? formData[name].error : "Required field."}
                ></span>
            </div>
        )
    }
    renderRadioButton = ({ data = [], name, label, defaultChecked, isMandatory = false }) => {
        const { formData } = this.state;
        const { lang } = this.props;
        return (
            <div className="input-field item">
                <div className="input-radio valign-wrapper" role="radiogroup" aria-labelledby={name}>
                    {
                        data.map((item, index) => {
                            return (
                                <div key={index} className={item.value}>
                                    <input
                                        aria-checked={formData[name].value === item.value}
                                        name={name}
                                        id={item.value}
                                        aria-labelledby={`${name} ${item.label}`}
                                        type="radio"
                                        readOnly
                                        className="radio-btn"
                                        onClick={evt =>
                                            this.handleChange(name, item.value)
                                        }
                                        checked={formData[name].value === item.value}
                                    />
                                    <label id={`_${item.label}`} htmlFor={item.value} className="custom-radio-style">
                                        <span className="custom-radio-style">{item.label}</span>
                                    </label>
                                </div>
                            )
                        })
                    }

                </div>
                <label htmlFor={name} id={name} style={dynamicStyles.position(lang)}>
                    {`${label} `}
                    {isMandatory ? <span className="asterisk">*</span> : null}
                </label>
                <span
                    className="helper-text"
                    data-error="Required field."
                ></span>
            </div>
        )
    }
    renderCheckbox = ({ label, name }) => {
        const { lang } = this.props;
        const { formData } = this.state;
        return (
            <div className="item subscribe-items">
                <p>
                    <input type="checkbox"
                        name={name}
                        id={name}
                        tabIndex="0"
                        style={dynamicStyles.textAlign(lang)}
                        ref={(input) => { this[name] = input; }}
                        onChange={(event) => this.handleChange(name, event.target.checked)}
                        checked={formData[name].value}
                    />
                    <label for={name}>
                        <span className={lang}>{this.context.t("_i_agree")}
                            <button type="button" style={{ border: 0, background: "none" }} aria-label={this.context.t("_read_terms_condition")} tabindex="0" className="modal-trigger underline-text" onClick={() => this.handleOpenModal()}>{label}</button>
                        </span>
                    </label>
                </p>
            </div>

        )
    }
    scrollStep() {
        var scrollStepInPx = this.state.scrollStepInPx;
        if (window.pageYOffset <= 400) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - scrollStepInPx);
    }
    scrollToTop() {
        window.scroll({ top: 0, left: 0, behavior: "smooth" })
        // var delayInMs = this.props.delayInMs ? this.props.delayInMs : this.state.delayInMs;
        // let intervalId = setInterval(this.scrollStep.bind(this), delayInMs);
        // this.setState({ intervalId: intervalId });
    }
    validate = () => {
        const { formData } = this.state;
        const { lang } = this.props;
        for (var key in formData) {
            formData[key].value = typeof (formData[key].value) == "boolean" || key == "dob" ? formData[key].value : formData[key].value.trim();
            formData[key].error = "";
        }
        if (formData.name.value == "")
            formData.name.error = this.context.t("_student_name_required");

        if (formData.name.value != "" && !nameValidate.test(formData.name.value))
            formData.name.error = this.context.t("_invalid_student_name");

        if (formData.name.value != "" && nameValidate.test(formData.name.value) && !nameWordLengthCheck(formData.name.value))
            formData.name.error = this.context.t("_student_name_contains_atleast_two_word");

        if (formData.nameArabic.value != "" && nameValidate.test(formData.nameArabic.value) && !nameWordLengthCheck(formData.nameArabic.value))
            formData.nameArabic.error = this.context.t("_student_name_contains_atleast_two_word");

        if (formData.qid.value == "")
            formData.qid.error = this.context.t("_qid_required");

        if (formData.qid.value != "" && !qidValidate.test(formData.qid.value))
            formData.qid.error = this.context.t("_invalid_qid");

        if (formData.gender.value == "")
            formData.gender.error = this.context.t("_gender_required");

        if (formData.dob.value == "")
            formData.dob.error = this.context.t("_date_of_birth_required");

        if (formData.dob.value == "")
            formData.dob.error = this.context.t("_date_of_birth_required");

        if (formData.nationality.value == "")
            formData.nationality.error = this.context.t("_nationality_required");

        if (formData.schoolName.value == "")
            formData.schoolName.error = this.context.t("_school_name_required");

        if (formData.schoolName.value == "0" && formData.schoolNewName.value == "")
            formData.schoolNewName.error = this.context.t("_school_name_required");

        if (formData.grade.value == "")
            formData.grade.error = this.context.t("_grade_required");

        if (formData.email.value == "")
            formData.email.error = this.context.t("_email_required");

        if (formData.email.value != "" && !emailValidate.test(formData.email.value))
            formData.email.error = this.context.t("_invalid_email");

        if (formData.mobile.value == "")
            formData.mobile.error = this.context.t("_mobile_number_required");

        if (formData.mobile.value != "" && !phoneValidate.test(formData.mobile.value.replace(/ /g, "")))
            formData.mobile.error = this.context.t("_invalid_mobile_number");

        if (formData.mobile.value != "")
            if (formData.mobile.value.toString().startsWith("3") || formData.mobile.value.toString().startsWith("5") || formData.mobile.value.toString().startsWith("6") || formData.mobile.value.toString().startsWith("7")) {

            } else {
                formData.mobile.error = this.context.t("_invalid_mobile_number");
            }

        if (formData.parentQid.value == "")
            formData.parentQid.error = this.context.t("_parent_qid_required");

        if (formData.parentQid.value != "" && !qidValidate.test(formData.parentQid.value))
            formData.parentQid.error = this.context.t("_invalid_parent_qid");

        // if (lang == "en") {
        if (formData.parentName.value == "")
            formData.parentName.error = this.context.t("_parent_name_required");

        if (formData.parentName.value != "" && !nameValidate.test(formData.parentName.value))
            formData.parentName.error = this.context.t("_invalid_parent_name");

        if (formData.parentName.value != "" && nameValidate.test(formData.parentName.value) && !nameWordLengthCheck(formData.parentName.value))
            formData.parentName.error = this.context.t("_parent_name_contains_atleast_two_word");
        // } else {
        //     if (formData.parentNameArabic.value == "")
        //         formData.parentNameArabic.error = this.context.t("_parent_name_required");

        //     if (formData.parentNameArabic.value != "" && nameValidate.test(formData.parentNameArabic.value) && !nameWordLengthCheck(formData.parentNameArabic.value))
        //         formData.parentNameArabic.error = this.context.t("_parent_name_contains_atleast_two_word");
        // }

        if (formData.relation.value == "")
            formData.relation.error = this.context.t("_relationship_required");

        if (formData.parentMobile.value == "")
            formData.parentMobile.error = this.context.t("_parent_mobile_number_1_required");

        if (formData.parentMobile.value != "" && !phoneValidate.test(formData.parentMobile.value.replace(/ /g, "")))
            formData.parentMobile.error = this.context.t("_invalid_parent_mobile");

        if (formData.parentMobile.value != "")
            if (formData.parentMobile.value.toString().startsWith("3") || formData.parentMobile.value.toString().startsWith("5") || formData.parentMobile.value.toString().startsWith("6") || formData.parentMobile.value.toString().startsWith("7")) {

            } else {
                formData.parentMobile.error = this.context.t("_invalid_parent_mobile");
            }

        if (formData.parentMobile2.value == "")
            formData.parentMobile2.error = this.context.t("_parent_mobile_number_2_required");

        if (formData.parentMobile2.value != "" && !phoneValidate.test(formData.parentMobile2.value.replace(/ /g, "")))
            formData.parentMobile2.error = this.context.t("_invalid_parent_mobile_2");

        if (formData.parentMobile2.value != "")
            if (formData.parentMobile2.value.toString().startsWith("3") || formData.parentMobile2.value.toString().startsWith("5") || formData.parentMobile2.value.toString().startsWith("6") || formData.parentMobile2.value.toString().startsWith("7")) {

            } else {
                formData.parentMobile2.error = this.context.t("_invalid_parent_mobile_2");
            }

        if (formData.parentEmail.value == "")
            formData.parentEmail.error = this.context.t("_parent_email_required");

        if (formData.parentEmail.value != "" && !emailValidate.test(formData.parentEmail.value))
            formData.parentEmail.error = this.context.t("_invalid_parent_email");

        if ((formData.parentEmail.value != "" && formData.email.value != "") && formData.parentEmail.value == formData.email.value)
            formData.parentEmail.error = this.context.t("_parent_student_email_cannot_be_same");

        if ((formData.parentQid.value != "" && formData.parentQid.value != "") && formData.parentQid.value == formData.qid.value)
            formData.parentQid.error = this.context.t("_parent_student_qid_cannot_be_same");

        if (!(formData.agree.value))
            formData.agree.error = this.context.t("_terms_and_condition_agree");

        // if (formData.channel.value == "")
        //     formData.channel.error = this.context.t("_channel_of_communication_required");
        for (var key in formData) {
            if (formData[key].error != "") {
                this.setState({ formData, submissionStatus: false })
                this.scrollToTop();
                setTimeout(() => {
                    this[key + "error"].focus()
                }, 1000);
                return false;
            }
        }
        this.setState({ formData })
        return true;
    }
    displayError = error => {
        const { formData } = this.state;
        const { lang } = this.props
        if (error.mail)
            formData.email.error = error.mail[lang]

        if (error.qid)
            formData.qid.error = error.qid[lang]

        if (error.nationality)
            formData.nationality.error = error.nationality[lang]

        if (error.grade)
            formData.grade.error = error.grade[lang]

        if (error.Student_name)
            formData.name.error = error.Student_name[lang]

        if (error.DOB)
            formData.dob.error = error.DOB[lang]

        for (var key in formData) {
            if (formData[key].error != "") {
                this.setState({ formData })
                this.scrollToTop();
                setTimeout(() => {
                    // this.refs.errorListRef.focus()
                    this[key + "error"].focus()
                }, 1000);
                return false;

            }
        }

        this.setState({ formData })
        this.scrollToTop();
    }
    onSubmit = async (event) => {
        event.preventDefault();
        const { formData, config, schoolList } = this.state;
        if (this.validate()) {
            this.props.showLoader();

            let obj = {
                "name": formData.email.value,
                "mail": formData.email.value,
                "field_student_email": formData.email.value,
                "field_qid": formData.qid.value,
                "field_student_mobile": `+974${formData.mobile.value.replace(/ /g, "")}`,
                "roles": {
                    "target_id": "student"
                },
                "field_nationality": formData.nationality.value,
                "field_student_name": formData.name.value,
                "field_student_name_arabic": formData.nameArabic.value,
                "field_grade": formData.grade.value,
                "field_gender": formData.gender.value,
                "field_student_dob": dateformat(formData.dob.value, "dd/mm/yyyy"),
                "field_parent_mobile_number": `+974${formData.parentMobile.value.replace(/ /g, "")}`,
                "field_parent_mobile_number_2": `+974${formData.parentMobile2.value.replace(/ /g, "")}`,
                "field_parent_email": formData.parentEmail.value,
                "field_parent_qid": formData.parentQid.value,
                "field_parent_name": formData.parentName.value,
                // "field_parent_name_arabic": formData.parentNameArabic.value,
                "field_relation_to_the_student": formData.relation.value,
                // "field_channels_of_communication": formData.channel.value,
                "field_reference": {
                    "target_id": ""
                }
            }

            if (!_.isEmpty(formData.schoolName.value)) {
                let schoolName = schoolList.filter(item => item.id == formData.schoolName.value)
                if (formData.schoolName.value == "0")
                    schoolName = formData.schoolNewName.value
                else
                    schoolName = _.isEmpty(schoolName) ? null : schoolName[0].sname


                obj.school_id = {
                    "target_id": formData.schoolName.value,
                    "target_type": "taxonomy_term"
                };
                obj.school_name = schoolName
            }
            const url = `${config.api_url}${urlEndPoints.registerStudent}`
            // let sendNotificationUrl = `${config.api_url}${urlEndPoints.sendNotification}`
            // sendNotificationUrl = `${sendNotificationUrl}&email=${formData.parentEmail.value}`
            try {
                const data = await this.networkRequest(url, "POST", obj, null, true)
                if (data.data.error) {
                    this.displayError(data.data.error)

                }
                else {
                    this.setState({ submissionStatus: true })
                    this.initState();
                    M.AutoInit();
                    // this.scrollToTop();
                    this.refs.successMessageRef.focus()
                    // await this.networkRequest(sendNotificationUrl)
                }
                this.props.hideLoader();
            } catch (error) {
                this.props.hideLoader()
                console.warn("error: ", error)
            }
        }
    }
    handleCloseModal = () => {
        let elems = document.querySelector("#termsAndConditionModal");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        let instance = M.Modal.getInstance(elems);
        instance.close();
    }

    handleOpenModal = () => {
        let elems = document.querySelector("#termsAndConditionModal");
        elems.setAttribute("aria-hidden", "false")
        elems.setAttribute("tabindex", "-1")
        let instance = M.Modal.getInstance(elems);
        instance.open();
    }
    render() {
        const { formData, submissionStatus, schoolList, termsAndCondition } = this.state;
        const { lang } = this.props;
        let hasError = false;
        Object.keys(formData).map((item, index) => {
            if (formData[item].error != "")
                hasError = true
        })
        return (
            <div id="main-container">
                <SlideToTop />
                <PageTitle title={this.context.t("_registration")} />
                <div className="container">
                    <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                        <ul>
                            <li className="breadcrumb-item">
                                <Link to="/registration">{this.context.t("_registration")}</Link> {" "}

                            </li>
                            <li className="breadcrumb-item">
                                <Link tabIndex="0" to aria-current={this.context.t("_page")}>
                                    <span>
                                        {this.context.t("_student")}
                                    </span>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
                <ColouredItems />
                <div id="skipContent" className="container registration-form student-registration-container">
                    <RegisterTypes {...this.props} />
                    <h2 tabIndex="0" ref="studentHeading" aria-label={this.context.t("_for_student_form")} className="primary-heading" style={dynamicStyles.textAlign(lang)}>{this.context.t("_for_student")}</h2>
                    <div className="row">
                        <div className="col s6 form-container">
                            <p style={dynamicStyles.textAlign(lang)}>
                                {this.context.t("_required_field")}{" "}
                                <span className="asterisk">*</span>
                            </p>
                            {
                                (hasError && !submissionStatus) ? <div className="error-container fail">
                                    <h5>{this.context.t("_error_title")}</h5>
                                    <ul aria-label="error" >
                                        {
                                            Object.keys(formData).map((item, index) => {
                                                return formData[item].error != "" ? <li key={index} ><a ref={(refItem) => { this[item + "error"] = refItem; }} tabIndex="0" href={`#${item}`} className="error" htmlFor={item}>{formData[item].error}</a></li>
                                                    :
                                                    null
                                            })}
                                    </ul>
                                </div> : null

                            }
                            {
                                submissionStatus ? <div className="error-container success" tabIndex="0" ref="successMessageRef" >
                                    <h5> {this.context.t("_success_title_student_1")}</h5>
                                    <p>{this.context.t("_success_title_student_2")}</p>
                                    <p>{this.context.t("_success_title_student_3")}</p>
                                </div> : null
                            }

                            {
                                _.isEmpty(formData) ?
                                    <div /> :
                                    <form onSubmit={this.onSubmit} autoComplete="no">
                                        {this.renderInput({ name: "name", label: this.context.t("_student_name_english"), placeholder: this.context.t("_student_name_english_placeholder"), isMandatory: lang == "en" })}
                                        {this.renderInput({ name: "nameArabic", label: this.context.t("_student_name_arabic"), placeholder: this.context.t("_student_name_arabic_placeholder"), isMandatory: lang == "ar" })}


                                        <div className=" date-popup">
                                            {this.renderInput({ name: "dob", label: this.context.t("_dob"), placeholder: this.context.t("_dob_placeholder"), isMandatory: true, type: "date", styleClass: "fontEnglish" })}
                                        </div>
                                        <div className="result-type1">
                                            {
                                                this.renderSelect({
                                                    name: "nationality", label: this.context.t("_nationality"), placeholder: this.context.t("_select_your_nationality"), isMandatory: true, data: _.orderBy(CountryList, [`country.${lang}`], ["asc"]), type: "country"
                                                })
                                            }
                                        </div>
                                        <div className="result-type1">
                                            {
                                                this.renderSelect({
                                                    name: "schoolName", label: this.context.t("_school_name"), placeholder: this.context.t("_select_school_name"), data: schoolList, type: "school", isMandatory: true
                                                })
                                            }
                                        </div>
                                        {
                                            formData.schoolName.value == "0" && this.renderInput({ name: "schoolNewName", label: this.context.t("_school_name"), placeholder: this.context.t("_select_school_name"), isMandatory: true })
                                        }
                                        {this.renderInput({ name: "qid", label: this.context.t("_qid"), placeholder: this.context.t("_qid_placeholder"), isMandatory: true, max: 11, })}
                                        {
                                            this.renderRadioButton({ label: this.context.t("_gender"), isMandatory: true, name: "gender", defaultChecked: "male", data: [{ label: this.context.t("_male"), value: "Male" }, { label: this.context.t("_female"), value: "Female" }] })
                                        }
                                        <div className="result-type1">
                                            {
                                                this.renderSelect({
                                                    name: "grade", label: this.context.t("_grade"), placeholder: this.context.t("_select_your_grade"), isMandatory: true, data: gradeListNew[lang]
                                                })
                                            }
                                        </div>
                                        {this.renderInput({ name: "email", label: this.context.t("_email"), placeholder: this.context.t("_email_placeholder"), type: "email", isMandatory: true, styleClass: "fontEnglish" })}
                                        {this.renderInput({ name: "mobile", label: this.context.t("_mobile_number"), placeholder: this.context.t("_mobile_number_placeholder"), isMandatory: true, type: "phone_number", max: 8 })}

                                        <p><br />{this.context.t("_guardian_information")} </p>
                                        {this.renderInput({ name: "parentQid", label: this.context.t("_patent_qid"), placeholder: this.context.t("_patent_qid_placeholder"), isMandatory: true, max: 11 })}


                                        {this.renderInput({ name: "parentName", label: this.context.t("_parent_name"), placeholder: this.context.t("_parent_name_placeholder"), isMandatory: true })}
                                        <div className="result-type1">
                                            {
                                                this.renderSelect({
                                                    name: "relation", label: this.context.t("_relation"), placeholder: this.context.t("_select_relation"), isMandatory: true, data: [
                                                        { label: this.context.t("_father"), value: "Father" },
                                                        { label: this.context.t("_mother"), value: "Mother" },
                                                        { label: this.context.t("_sister"), value: "Sister" },
                                                        { label: this.context.t("_brother"), value: "Brother" },
                                                        { label: this.context.t("_grand_mother"), value: "Grandmother" },
                                                        { label: this.context.t("_grand_father"), value: "Grandfather" },
                                                        { label: this.context.t("_parent"), value: "Guardian" }
                                                    ]
                                                })
                                            }
                                        </div>
                                        {this.renderInput({ name: "parentMobile", label: this.context.t("_parent_mobile"), placeholder: this.context.t("_parent_mobile_placeholder"), type: "phone_number", isMandatory: true, max: 8 })}
                                        {this.renderInput({ name: "parentMobile2", label: this.context.t("_parent_mobile_2"), placeholder: this.context.t("_parent_mobile_placeholder_2"), type: "phone_number", isMandatory: true, max: 8 })}
                                        {this.renderInput({ name: "parentEmail", label: this.context.t("_parent_email"), placeholder: this.context.t("_parent_email_placeholder"), isMandatory: true, type: "email", styleClass: "fontEnglish" })}
                                        {/* {
                                            this.renderRadioButton({ label: this.context.t("_channel_of_communication"), name: "channel", defaultChecked: "sms", data: [{ label: this.context.t("_email"), value: "Email Address" }, { label: this.context.t("_sms"), value: "Mobile Number" }, { label: this.context.t("_phone_call"), value: "Phone Call" }] })
                                        } */}
                                        {this.renderCheckbox({ label: this.context.t("_terms_and_condition"), name: "agree" })}
                                        <div className="btn-wrap">
                                            <button className="btn blue">{this.context.t("_registration")}</button>
                                        </div>
                                    </form>
                            }

                        </div>
                        <div className="col s6" />
                    </div>

                </div>
                <div id="termsAndConditionModal" className="modal" role="dialog">
                    <div className="modal-content">
                        <span className="badge modalClose"><button type="button" aria-label="close" tabindex="0" className="close" onClick={() => this.handleCloseModal()}><i className="material-icons">close</i></button></span>
                        <h4>{termsAndCondition.title}</h4>
                        <div className="description" dangerouslySetInnerHTML={{ __html: termsAndCondition.body }}></div>

                        <button className={`modal-close waves-effect waves-green btn blue ${lang == "ar" ? "pull-left" : "pull-right"}`}>{this.context.t("_agree")}</button>
                    </div>
                </div>
                <div className="top-line ng-scope">
                    <img src={topLineImage} alt="" />
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ i18nState }) => {
    return {
        lang: i18nState.lang
    }
}

const mapDispatchToProps = {
    showLoader: LoadingAction.showLoader,
    hideLoader: LoadingAction.hideLoader,
}
StudentRegistration.contextTypes = {
    t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(StudentRegistration);

const ColouredItems = () => {
    return (
        <div className="student-color-container">
            <span className="grey-square-rotate red-sq one"></span>
            <span className="grey-square-rotate red-sq two"></span>
            <span className="grey-square-rotate red-sq three"></span>
            <span className="grey-square-rotate red-sq four"></span>
            <span className="orange-circle one"></span>
            <span className="orange-circle two"></span>
            <span className="orange-circle three"></span>
            <span className="orange-circle four"></span>
            <span className="multi-square one"><b><i></i></b></span>
            <span className="multi-square two"><b><i></i></b></span>
            <span className="multi-square three"><b><i></i></b></span>
            <span className="multi-square four"><b><i></i></b></span>
        </div>
    )
}