import React from "react";
import Main from "./../main"
import { Link } from "react-router-dom";
import PageTitle from "../includes/page_title";
import SlideToTop from "../includes/slide_to_top";
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { LoadingAction } from "./../../actions"

class FabLabs extends Main {
    constructor(props) {
        super(props);
        this.state = {
            config: this.config(),
            fabContent: {}
        }
        this.props.showLoader();
    }
    componentDidMount() {
        this.getFabLabs();
    }
    getFabLabs = async () => {
        const { config } = this.state
        const url = `${config.api_url}${urlEndPoints.getFabLabs}`
        try {
            const data = await this.networkRequest(url)
            if (data.data.error) {

            } else {
                this.setState({
                    fabContent: data.data.results[0],
                })

            }
            this.props.hideLoader()
        } catch (error) {
            this.props.hideLoader()
            console.warn("error: ", error)
        }
    }
    render() {
        const { fabContent } = this.state;
        return (
            <div id="main-container">
                <SlideToTop />
                <PageTitle title={`Fab labs`} />
                <div className="container">
                    <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                        <ul>
                            <li className="breadcrumb-item">
                                <Link to="/">{`Home`}</Link> {" "}
                            </li>
                            <li className="breadcrumb-item">
                                <span>
                                    What is Fab Lab
                                </span>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="container">

                    <h2>{fabContent.title}</h2>
                    <div dangerouslySetInnerHTML={{ __html: fabContent.body }}></div>

                </div>
                <br />

            </div>
        );
    }
}


const mapStateToProps = ({ i18nState }) => {
    return {
        lang: i18nState.lang
    }
}
const mapDispatchToProps = {
    showLoader: LoadingAction.showLoader,
    hideLoader: LoadingAction.hideLoader,
}
FabLabs.contextTypes = {
    t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(FabLabs);
