import React, { Component } from "react";
import M from "materialize-css";
import { connect } from "react-redux"
import _ from "lodash"
import Modal from "react-modal";
import PropTypes from "prop-types"
import { PopupAction } from "./../../actions"
const successImage = require("../../assets/images/img_success.png");
const failImage = require("../../assets/images/img_fail.png");
const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        padding: 0,
        transform: "translate(-50%, -50%)"
    }
};

class Popup extends Component {
    static defaultProps = {
        modalContent: {
            type: "success",
            title: "",
            message: "",
            isOpen: false,
            buttonText: ""
        }
    }
    constructor(props) {
        super(props);
    }
    render() {
        const { modalContent: { isOpen, type, title, message, buttonText }, lang } = this.props;
        return (
            <div>
                <Modal
                    isOpen={isOpen}
                    // onAfterOpen={this.afterOpenModal}
                    // onRequestClose={this.closeModal}
                    aria-label="modal"
                    style={customStyles}
                    contentLabel="Modal"
                    ariaHideApp={false}
                    id="popupmodal"
                >
                    <button data-dismiss="modal" type="button" ref="closeBtnRef" onClick={() => this.props.hidePopup({})} aria-label="modal close" className={`badge modalClose`}><i className="material-icons">close</i></button>
                    <div className={`popupAlert ${type == "success" ? "pass" : "failed"} `}>
                        <div className="pulsating-circle">
                            <span><img src={type == "success" ? successImage : failImage} alt="" /></span>
                        </div>

                        <h4 aria-label={`${this.context.t("_title")} ${title}`}>{title}</h4>
                        <div className="popupMessage">
                            <p aria-label={`${this.context.t("_message")} ${message}`}>{message}</p>
                        </div>

                        {
                            buttonText ? <button aria-label={`${buttonText} ${this.context.t("_button")}`} onClick={() => this.props.hidePopup({})} className="btn blue-border">{buttonText}</button> : null
                        }

                    </div>
                </Modal>
            </div>
        )
    }
}

Popup.contextTypes = {
    t: PropTypes.func
}
const mapStateToProps = ({ popupReducer, i18nState }) => {
    return {
        modalContent: popupReducer.popupState,
        lang: i18nState.lang
    }
}
const mapDispatchToProps = {
    hidePopup: (data) => PopupAction.hidePopup(data),
}
export default connect(mapStateToProps, mapDispatchToProps)(Popup);
