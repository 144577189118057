import React from "react";
import { Link } from "react-router-dom";
import Main from "../../main";
import PageTitle from "../../includes/page_title.js";
import SlideToTop from "../../includes/slide_to_top.js";
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { dynamicStyles } from "./../../../constants/dynamicStyles"
import M from "materialize-css";
import { emailValidate, nameValidate, phoneValidate, nameWordLengthCheck, qidValidate, calenderI18n } from "./../../../constants/common"
import { urlEndPoints } from "./../../../httpAPI/apiConfig"
import _ from "lodash"
import { LoadingAction, PopupAction, LoginAction } from "./../../../actions"
import dateformat from "dateformat";
import ReactCrop from "react-image-crop";
import topLineImage from "../../../assets/images/top-line.png";
import blankImage from "../../../assets/images/blank.png";
import "react-image-crop/dist/ReactCrop.css";

class SchoolSettings extends Main {
    constructor(props) {
        super(props);
        this.state = {
            config: this.config(),
            formData: {},
            schoolList: [],
            profileImage: "",
            intervalId: 0,
            scrollStepInPx: 50,
            delayInMs: 16.66,
            selectedImage: "",
            croppedImageUrl: "",
            submissionStatus: false,
            submissionStatus_1: false,
            successMessage: null,
            file: "",
            crop: {
                unit: "px",
                width: 200,
                height: 201,
                aspect: 1 / 1.1,
            },
        }
        this.props.showLoader();
    }
    async componentDidMount() {
        document.title = `${this.context.t("_studio5")} | ${this.context.t("_settings")}`
        this.initState();
        M.AutoInit();
        let today = new Date()
        var options = {
            autoClose: true,
            onSelect: ((date) => {
                this.handleChange("dob", dateformat(date, "dd/mm/yyyy"))
            }),
            format: "dd/mm/yyyy",
            container: null,
            // defaultDate: new Date("01/01/2001"),
            maxDate: new Date("12/31/2014"),
            minDate: new Date("01/01/2001"),
            yearRange: [1950, today.getFullYear()],
        };
        setTimeout(() => {
            var elems = document.querySelectorAll("#dob");
            var selectelem = document.querySelectorAll("select");
            options.i18n = calenderI18n(this.context);
            M.Datepicker.init(elems, options);
            M.FormSelect.init(selectelem, options);
        }, 1000);
        await this.getSchoolList();
        this.getProfileSettingsData();
        window.addEventListener('load', this.handleTabIndex());
        setTimeout(() => {
            document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
        }, 500);
    }
    componentDidUpdate() {
        document.title = `${this.context.t("_studio5")} | ${this.context.t("_settings")}`
    }
    handleTabIndex = () => {
        setTimeout(() => {
            let elem = document.querySelectorAll('select');
            elem.forEach(element => {
                element.setAttribute("tabindex", "0");
            });
        }, 1000);
    }
    initSelect = () => {
        const { formData } = this.state;
        if (formData.schoolName) {
            var options = {
                autoClose: true,
                classes: this.state.formData.schoolName.class

            };
            var elems = document.querySelectorAll("select");
            M.FormSelect.init(elems, options);
        }
    }
    initState = () => {
        this.setState({
            formData: {
                schoolName: { value: "", error: "", class: "" },
                schoolNewName: { value: "", error: "", class: "" },
                schoolPhone: { value: "", error: "", class: "" },
                qid: { value: "", error: "", class: "" },
                schoolEmail: { value: "", error: "", class: "" },
                repName: { value: "", error: "", class: "" },
                repPosition: { value: "", error: "", class: "" },
                repMobile: { value: "", error: "", class: "" },
                repEmail: { value: "", error: "", class: "" },
                channel: { value: "", error: "", class: "" }
            }
        }, () => {
            this.initSelect()
        })
    }

    getSchoolList = async () => {
        const { config } = this.state
        const url = `${config.api_url}${urlEndPoints.schoolListWithRep}`
        try {
            const data = await this.networkRequest(url)
            if (data.data && data.data.length > 0) {
                this.setState({ schoolList: data.data })

            }
            // this.schoolName.focus()
            this.initSelect()
        } catch (error) {
            this.props.hideLoader()
            console.warn("error: ", error)
        }
    }
    handleChange = (field, value) => {
        if (field == "schoolName") {
            const { formData, schoolList } = this.state;
            const school = schoolList.filter(item => item.tid == value)
            formData[field]["class"] = value ? "" : "invalid";
            formData[field]["value"] = value;
            if (school && school[0]) {
                formData.schoolPhone.value = school[0].field_s.replace("+974", "")
                formData.schoolEmail.value = school[0].field_school_email
            } else {
                formData.schoolPhone.value = ""
                formData.schoolEmail.value = ""
            }
            this.setState({ formData, submissionStatus: false }, () => { this.initSelect() });
        } else {
            const { formData } = this.state;
            formData[field]["class"] = value ? "" : "invalid";
            formData[field]["value"] = value;
            this.setState({ formData, submissionStatus: false }, () => { this.initSelect() });
        }

    };
    renderInput = ({ name, label, placeholder, type = "text", isMandatory = false, max, disabled, }) => {
        const { formData } = this.state;
        const { lang } = this.props;
        return (type === "phone_number" ?
            <div className="row mobile">
                <div className="col s4">
                    <div className="input-field item">
                        <input name={`${name}_country_code`}
                            placeholder="Country Code"
                            value={this.context.t("_ar_country_code")}
                            style={dynamicStyles.textAlign(lang)}
                            id={`${name}_country_code`}
                            type="text"
                            minLength="3"
                            maxLength="3"
                            disabled
                        // className={"validate " + registration_form.mobile.class}
                        // required
                        />
                        <label htmlFor={`${name}_country_code`} style={dynamicStyles.position(lang)}>{this.context.t("_country_code")}</label>
                    </div>
                </div>
                <div className="col s8">
                    <div className="input-field item">
                        <input
                            name={name}
                            placeholder={placeholder}
                            ref={(input) => { this[name] = input; }}
                            aria-label={this.context.t("_phone_aria_label")}
                            id={name}
                            maxLength={max}
                            type="text"
                            style={dynamicStyles.textAlign(lang)}
                            // readOnly={type === "date" ? "readonly" : false}
                            autoComplete="no"
                            readOnly={disabled}
                            onChange={evt =>
                                this.handleChange(name, evt.target.value)
                            }
                            onFocus={evt =>
                                this.handleChange(name, evt.target.value)
                            }
                            value={formData[name].value}
                        // className={"validate " + formData[name].class}
                        // required={isMandatory}
                        />
                        <label htmlFor={name} style={dynamicStyles.position(lang)}>
                            {`${label} `}
                            {isMandatory ? <span className="asterisk">*</span> : null}
                        </label>
                    </div>
                </div>
            </div>
            :
            <div className="input-field item">
                <input
                    name={name}
                    placeholder={placeholder}
                    ref={(input) => { this[name] = input; }}
                    id={name}
                    maxLength={max}
                    type={type === "date" ? "text" : type}
                    style={dynamicStyles.textAlign(lang)}
                    readOnly={disabled}
                    // readOnly={type === "date" ? "readonly" : false}
                    autoComplete="no"
                    onChange={evt =>
                        this.handleChange(name, evt.target.value)
                    }
                    onFocus={evt =>
                        this.handleChange(name, evt.target.value)
                    }
                    value={formData[name].value}
                // className={"validate " + formData[name].class}
                // required={isMandatory}
                />
                <label htmlFor={name} style={dynamicStyles.position(lang)}>
                    {`${label} `}
                    {isMandatory ? <span className="asterisk">*</span> : null}
                </label>
            </div>)
    }

    renderSelect = ({ name, label, placeholder, isMandatory = false, data = [] }) => {
        const { formData } = this.state;
        const { lang } = this.props;
        return (
            <div className="input-field mobileSelect">
                <label htmlFor={name} style={dynamicStyles.position(lang)}>
                    {`${label} `}
                    {isMandatory ? <span className="asterisk">*</span> : null}
                </label>
                <select
                    className="browser-default"
                    name={name}
                    id={name}
                    input={<input ref={(input) => { this[name] = input; }} />}
                    style={dynamicStyles.textAlign(lang)}
                    ref="selectRef"
                    onChange={evt =>
                        this.handleChange(name, evt.target.value)
                    }

                    // onFocus={evt =>
                    //   this.handleChange(name, evt.target.value)
                    // }
                    value={formData[name].value}
                // className={"validate" + formData[name].class}
                >
                    <option value="" disabled> {placeholder} </option>
                    {
                        data.map((item) => {
                            return <option key={item.tid} value={item.tid}>{item.name}</option>
                        })
                    }

                    <option value="0">{this.context.t("_not_in_list")}</option>
                </select>

                <span
                    style={dynamicStyles.textAlign(lang)}
                    className="helper-text"
                    data-error={formData[name].error ? formData[name].error : "Required field."}
                ></span>
            </div>
        )
    }
    renderRadioButton = ({ data = [], name, label, defaultChecked, isMandatory = false }) => {
        const { formData } = this.state;
        const { lang } = this.props;
        return (
            <div className="input-field item">
                <div className="input-radio valign-wrapper" role="radiogroup" aria-labelledby={name}>
                    {
                        data.map(item => {
                            return (
                                <div key={item.value} className={item.value}>
                                    <input
                                        aria-checked={formData[name].value === item.value}
                                        name={name}
                                        id={item.value}
                                        aria-labelledby={`${name} ${item.label}`}
                                        type="radio"
                                        readOnly
                                        className="radio-btn"
                                        onClick={evt =>
                                            this.handleChange(name, item.value)
                                        }
                                        checked={formData[name].value === item.value}
                                    />
                                    <label id={`_${item.label}`} htmlFor={item.value} className="custom-radio-style">
                                        <span className="custom-radio-style">{item.label}</span>
                                    </label>
                                </div>
                            )
                        })
                    }

                </div>
                <label htmlFor={name} style={dynamicStyles.position(lang)}>
                    {`${label} `}
                    {isMandatory ? <span className="asterisk">*</span> : null}
                </label>

                <span
                    className="helper-text"
                    data-error="Required field."
                ></span>
            </div>
        )
    }
    getProfileSettingsData = async () => {
        // const authResponse = await this.auth();
        // if (authResponse) {
        const { config } = this.state
        const url = `${config.api_url}${urlEndPoints.userSettings}`
        // const headers = {
        //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
        // }
        try {
            const data = await this.networkRequest(url)
            let { formData } = this.state;
            if (!data.data.message) {
                formData = {
                    schoolName: { value: data.data.field_school, error: "", class: "" },
                    schoolNewName: { value: "", error: "", class: "" },
                    schoolPhone: { value: data.data.field_school_mobile_number, error: "", class: "" },
                    schoolEmail: { value: data.data.field_school_email, error: "", class: "" },
                    repName: { value: data.data.field_rep_name, error: "", class: "" },
                    repPosition: { value: data.data.field_rep_position, error: "", class: "" },
                    repMobile: { value: !_.isEmpty(data.data.field_rep_mobile_number_) ? data.data.field_rep_mobile_number_.replace("+974", "") : "", error: "", class: "" },
                    repEmail: { value: data.data.field_rep_email_address, error: "", class: "" },
                    qid: { value: data.data.field_school_reps_qid, error: "", class: "" },
                    channel: { value: data.data.field_channels_of_communication, error: "", class: "" }
                }
            }

            this.props.hideLoader()
            localStorage.setItem("profileImage", `${config.origin}${data.data.picture}`)
            this.props.userLoggedIn(`${config.origin}${data.data.picture}`)
            this.setState({ formData, profileImage: data.data.picture })
            M.AutoInit();
        } catch (error) {
            this.props.hideLoader()
            console.warn("error: ", error)
        }
        // } else {
        //     this.props.hideLoader()
        // }
    }
    scrollStep() {
        var scrollStepInPx = this.state.scrollStepInPx;
        if (window.pageYOffset <= 400) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - scrollStepInPx);
    }
    scrollToTop() {
        window.scroll({ top: 0, left: 0, behavior: "smooth" })
        // var delayInMs = this.props.delayInMs ? this.props.delayInMs : this.state.delayInMs;
        // let intervalId = setInterval(this.scrollStep.bind(this), delayInMs);
        // this.setState({ intervalId: intervalId });
    }
    validate = () => {
        const { formData } = this.state;
        for (const key in formData) {
            if (formData[key].value)
                formData[key].value = formData[key].value.trim();
            formData[key].error = "";
        }
        if (formData.schoolName.value == "")
            formData.schoolName.error = this.context.t("_school_name_required");

        if (formData.schoolName.value == "0" && formData.schoolNewName.value == "")
            formData.schoolNewName.error = this.context.t("_school_name_required");

        if (formData.schoolPhone.value == "")
            formData.schoolPhone.error = this.context.t("_school_phone_required");

        if (formData.schoolPhone.value != "" && !phoneValidate.test(formData.schoolPhone.value.replace(/ /g, "")))
            formData.schoolPhone.error = this.context.t("_invalid_school_phone");

        if (formData.schoolEmail.value == "")
            formData.schoolEmail.error = this.context.t("_school_email_required");

        if (formData.schoolEmail.value != "" && !emailValidate.test(formData.schoolEmail.value))
            formData.schoolEmail.error = this.context.t("_invalid_school_email");

        if (formData.repName.value == "")
            formData.repName.error = this.context.t("_rep_name_required");

        if (formData.repName.value != "" && !nameValidate.test(formData.repName.value))
            formData.repName.error = this.context.t("_invalid_rep_name");

        if (formData.repName.value != "" && nameValidate.test(formData.repName.value) && !nameWordLengthCheck(formData.repName.value))
            formData.repName.error = this.context.t("_rep_name_contains_atleast_two_word");

        if (formData.qid.value == "")
            formData.qid.error = this.context.t("_qid_required");

        if (formData.qid.value != "" && !qidValidate.test(formData.qid.value))
            formData.qid.error = this.context.t("_invalid_qid");

        if (formData.repPosition.value == "")
            formData.repPosition.error = this.context.t("_rep_position_required");

        if (formData.repMobile.value == "")
            formData.repMobile.error = this.context.t("_rep_mobile_required");

        if (formData.repMobile.value != "" && !phoneValidate.test(formData.repMobile.value.replace(/ /g, "")))
            formData.repMobile.error = this.context.t("_invalid_rep_mobile");

        if (formData.repEmail.value == "")
            formData.repEmail.error = this.context.t("_rep_email_required");

        if (formData.repEmail.value != "" && !emailValidate.test(formData.repEmail.value))
            formData.repEmail.error = this.context.t("_invalid_rep_email");

        for (const key in formData) {
            if (formData[key].error != "") {
                this.setState({ formData, submissionStatus: false, submissionStatus_1: false })
                this.scrollToTop();
                setTimeout(() => {
                    this[key + "error"].focus()
                }, 1000);
                return false;

            }
        }
        this.setState({ formData })
        return true;
    }
    displayError = error => {
        const { formData } = this.state;
        const { lang } = this.props;
        if (error.mail)
            formData.repEmail.error = error.mail[lang]

        if (error.name)
            formData.schoolName.error = error.name[lang]

        if (error.school_email)
            formData.schoolEmail.error = error.school_email[lang]

        if (error.School)
            formData.schoolName.error = error.School[lang]

        if (error.school_mob)
            formData.schoolPhone.error = error.school_mob[lang]

        if (error.repname)
            formData.repName.error = error.repname[lang]

        if (error.repmail)
            formData.repEmail.error = error.repmail[lang]

        if (error.rep_mob)
            formData.repMobile.error = error.rep_mob[lang]

        if (error.position)
            formData.repPosition.error = error.position[lang]

        if (error.qid)
            formData.qid.error = error.qid[lang]

        this.setState({ formData })
        this.scrollToTop();

    }

    onSubmit = async (event) => {
        event.preventDefault();
        this.props.showLoader()
        this.setState({ submissionStatus_1: false, submissionStatus: false })
        const { formData, config, schoolList } = this.state;
        let schoolName = schoolList.filter(item => item.tid == formData.schoolName.value)
        if (formData.schoolName.value == "0")
            schoolName = formData.schoolNewName.value
        else
            schoolName = schoolName[0].name
        if (this.validate()) {
            // const authResponse = await this.auth();
            // if (authResponse) {
            const obj = {
                "name": formData.repEmail.value,
                "mail": formData.repEmail.value,
                "school_id": {
                    "target_id": formData.schoolName.value,
                    "target_type": "taxonomy_term"
                },
                "school_name": schoolName,
                "school_mob": formData.schoolPhone.value.replace(/ /g, ""),
                "school_email": formData.schoolEmail.value,
                "repname": formData.repName.value,
                "position": formData.repPosition.value,
                "field_qid": formData.qid.value,
                "rep_mob": formData.repMobile.value.replace(/ /g, ""),
                "repmail": formData.repEmail.value,
                "communication_value": formData.channel.value,
                "field_reference": {
                    "target_id": ""
                },
                "roles": {
                    "target_id": "school"
                }

            }
            // const headers = {
            //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
            // }
            const url = `${config.api_url}${urlEndPoints.registerSchoolRep}`
            try {
                const data = await this.networkRequest(url, "PATCH", obj)
                if (data.data.error) {
                    this.props.hideLoader();
                    this.displayError(data.data.error)
                }
                else {
                    this.setState({ submissionStatus: true, submissionStatus_1: true, successMessage: this.context.t("_profile_updated_successfully") })
                    this.getProfileSettingsData();
                    this.refs.successMessageRef.focus()
                }
            } catch (error) {
                this.props.hideLoader();
                console.warn("error: ", error)
            }
            // } else {
            //     this.props.hideLoader();
            // }
        } else {
            this.props.hideLoader();
        }
    }
    onChangeFile(event) {
        event.stopPropagation();
        event.preventDefault();
        const that = this;
        var file = event.target.files[0];
        if (file) {
            if (file.type.includes("image")) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function (e) {
                    const img = new Image();
                    img.onload = function () {
                        // if (img.width < 250) {
                        //     return that.props.showPopup({
                        //         type: "error",
                        //         title: "Invalid",
                        //         message: "Image size should be greater than 250px!",
                        //         isOpen: true,
                        //         buttonText: "Ok"
                        //     })
                        // } else if (img.width >= img.height) {
                        //     return that.props.showPopup({
                        //         type: "error",
                        //         title: "Invalid",
                        //         message: "Image height should be greater than width!",
                        //         isOpen: true,
                        //         buttonText: "Ok"
                        //     })
                        // } else {
                        //     that.saveUserImage(file, reader.result)
                        // }

                        that.setState({ selectedImage: reader.result, file, submissionStatus: false, submissionStatus_1: false })
                        let elems = document.querySelector("#photoCrop");
                        elems.setAttribute("aria-hidden", "false")
                        elems.setAttribute("tabindex", "-1")
                        let instance = M.Modal.getInstance(elems);
                        instance.open();
                        elems = document.querySelector(".ReactCrop");
                        elems.setAttribute("aria-label", that.context.t("_link_crop_image"))
                    };
                    img.src = reader.result;
                };
                // reader.onloadend = () => {
                //     this.saveUserImage(file, reader.result)
                // };
            } else {
                this.props.showPopup({
                    type: "error",
                    title: this.context.t("_invalid"),
                    message: this.context.t("_invalid_file_type"),
                    isOpen: true,
                    buttonText: this.context.t("_ok")
                })
            }
        }

    }
    saveUserImage = async () => {
        const { config, croppedImageUrl, file } = this.state;
        if (!_.isEmpty(croppedImageUrl)) {
            this.handleCloseModal();
            this.props.showLoader()
            // const authResponse = await this.auth();
            // if (authResponse) {
            const { config } = this.state
            const url = `${config.api_url}${urlEndPoints.updateUserPic}`
            // const headers = {
            //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
            // }
            const obj = {
                name: file.name,
                image: croppedImageUrl
            }
            try {
                const data = await this.networkRequest(url, "POST", obj);
                if (data.status === 200) {
                    this.setState({ submissionStatus: true, submissionStatus_1: true, successMessage: this.context.t("_profile_image_updated_successfully") })
                    this.getProfileSettingsData()
                    this.refs.successMessageRef.focus()
                }
            } catch (error) {
                this.props.hideLoader()
                console.warn("error: ", error)
            }
        }

        // } else {
        //     this.props.hideLoader()
        // }
    }
    removeProfilePic = async () => {
        // const authResponse = await this.auth();
        // if (authResponse) {
        this.props.showLoader()
        const { config } = this.state
        const url = `${config.api_url}${urlEndPoints.removeUserPic}`
        // const headers = {
        //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
        // }
        const obj = {}
        try {
            const data = await this.networkRequest(url, "POST", obj);
            if (data.status === 200) {
                this.setState({ submissionStatus: true, submissionStatus_1: true, successMessage: this.context.t("_profile_image_removed_successfully") })
                this.getProfileSettingsData()
                this.refs.successMessageRef.focus()

            }
        } catch (error) {
            this.props.hideLoader()
            console.warn("error: ", error)
        }

        // } else {
        //     this.props.hideLoader()
        // }
    }
    handleChangePassword = () => {
        this.props.history.push("change-password")
    }
    handleCloseModal = () => {
        this.setState({
            selectedImage: "",
            croppedImageUrl: "",
            file: "",
        })
        this.upload.value = "";
        let elems = document.querySelector("#photoCrop");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        let instance = M.Modal.getInstance(elems);
        instance.close();
        this.refs.changeProfileImageBtn.focus()
    }

    onImageLoaded = image => {
        this.imageRef = image;
    };
    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
            this.setState({ croppedImageUrl });
        }
    }
    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            resolve(canvas.toDataURL());
        });
    }
    render() {
        const { formData, submissionStatus, submissionStatus_1, profileImage, config, schoolList, selectedImage, crop, successMessage } = this.state
        let hasError = false;
        Object.keys(formData).forEach(item => {
            if (formData[item].error !== "")
                hasError = true
        })
        return (
            <div id="main-container">
                <SlideToTop />
                <PageTitle title={this.context.t("_settings")} />
                <div id="skipContent" className="container">
                    <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                        <ul>
                            <li className="breadcrumb-item">
                                <Link to="/profile">{this.context.t("_profile")}</Link> {" "}

                            </li>
                            <li className="breadcrumb-item">
                                <Link tabIndex="0" to aria-current={this.context.t("_page")}>
                                    <span>
                                        {this.context.t("_settings")}
                                    </span>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div>
                    <div className="container">
                        <div className="row">
                            <div className="col s3 text-center">
                                <figure className="profilePic">
                                    <img src={blankImage} alt="" />
                                    <span>
                                        <img src={`${config.origin}${profileImage}`} alt="" />
                                    </span>
                                    <button aria-label={this.context.t("_change_profile_image")}
                                        type="button" ref="changeProfileImageBtn" onClick={() => { this.upload.click() }} className="btn changePic">
                                        <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                    </button>
                                    <input id="myInput"
                                        type="file"
                                        ref={(ref) => this.upload = ref}
                                        style={{ display: "none" }}
                                        onChange={this.onChangeFile.bind(this)}
                                    />
                                    <label style={{ display: "none" }} htmlFor="myInput" aria-label="select profile image">select profile image</label>
                                    <button aria-label={this.context.t("_remove_profile_image")} onClick={() => this.removeProfilePic()} type="button" className="btn removePic">
                                        <i className="fa fa-trash" aria-hidden="true"></i>
                                    </button>
                                </figure>
                            </div>
                            <div className="col s9">
                                {/* <div className="btn-wrap type-selection profileBtnHolder">
                                    <button className="btn profileBtn yellow" onClick={() => { this.upload.click() }}>{this.context.t("_upload_new_photo")}</button>
                                    <input id="myInput"
                                        type="file"
                                        ref={(ref) => this.upload = ref}
                                        style={{ display: "none" }}
                                        onChange={this.onChangeFile.bind(this)}
                                    />
                                    <button onClick={() => this.removeProfilePic()} className="btn profileBtn">{this.context.t("_remove")}</button>
                                    <br />
                                </div> */}
                            </div>
                        </div>
                        <div className="col">
                            <div className="row">
                                {
                                    hasError ? <div className="error-container fail">
                                        <h5>{this.context.t("_error_title")}</h5>
                                        <ul>
                                            {
                                                Object.keys(formData).map((item, index) => {
                                                    return formData[item].error !== "" ? <li key={index} ><a ref={(refItem) => { this[item + "error"] = refItem; }} tabIndex="0" href={`#${item}`} className="error" htmlFor={item}>{formData[item].error}</a></li>
                                                        :
                                                        null
                                                })}
                                        </ul>
                                    </div> : null

                                }
                                {
                                    (submissionStatus || submissionStatus_1) ? <div className="error-container success" tabIndex="0" ref="successMessageRef">
                                        <h5>{successMessage}</h5>
                                    </div> : null
                                }
                                {
                                    _.isEmpty(formData) ?
                                        <div /> :
                                        <form className="full-width" style={{ paddingTop: 28 }} onSubmit={this.onSubmit} >
                                            <div className="col s12">
                                                <div className="result-type">
                                                    {
                                                        this.renderSelect({
                                                            name: "schoolName", label: this.context.t("_school_name"), placeholder: this.context.t("_select_school_name"), isMandatory: true, data: schoolList
                                                        })
                                                    }
                                                </div>
                                                {
                                                    formData.schoolName.value == "0" && this.renderInput({ name: "schoolNewName", label: this.context.t("_school_name"), placeholder: this.context.t("_select_school_name"), isMandatory: true })
                                                }
                                            </div>
                                            <div className="col s12 m6">
                                                {this.renderInput({ name: "schoolPhone", label: this.context.t("_school_phone_number"), placeholder: this.context.t("_school_phone_number_placeholder"), isMandatory: true, disabled: formData.schoolName.value != "0", type: "phone_number", max: 8 })}
                                            </div>
                                            <div className="col s12 m6">
                                                {this.renderInput({ name: "schoolEmail", label: this.context.t("_school_email_address"), placeholder: this.context.t("_school_email_address_placeholder"), isMandatory: true, type: "email", disabled: formData.schoolName.value != "0" })}
                                            </div>
                                            <div className="col s12">
                                                <p><br />{this.context.t("_school_representative")} </p>
                                            </div>
                                            <div className="col s12 m6">
                                                {this.renderInput({ name: "repName", label: this.context.t("_name"), placeholder: this.context.t("_name_placeholder"), isMandatory: true })}
                                            </div>
                                            <div className="col s12 m6">
                                                {this.renderInput({ name: "repPosition", label: this.context.t("_position"), placeholder: this.context.t("_position_placeholder"), isMandatory: true })}
                                            </div>
                                            <div className="col s12 m6">
                                                {this.renderInput({ name: "qid", label: this.context.t("_qid"), placeholder: this.context.t("_qid_placeholder"), isMandatory: true, max: 11, disabled: true })}
                                            </div>
                                            <div className="col s12 m6">
                                                {this.renderInput({ name: "repMobile", label: this.context.t("_mobile_number"), placeholder: this.context.t("_mobile_number_placeholder"), isMandatory: true, type: "phone_number", max: 8 })}
                                            </div>
                                            <div className="col s12 m6">
                                                {this.renderInput({ name: "repEmail", label: this.context.t("_email"), placeholder: this.context.t("_email_placeholder"), isMandatory: true, type: "email", disabled: true })}
                                            </div>
                                            {/* <div className="col s12 m6">
                                                {
                                                    this.renderRadioButton({ label: this.context.t("_channel_of_communication"), name: "channel", defaultChecked: "sms", isMandatory: true, data: [{ label: this.context.t("_email"), value: "Email Address" }, { label: this.context.t("_sms"), value: "Mobile Number" }, { label: this.context.t("_phone_call"), value: "Phone Call" }] })
                                                }
                                            </div> */}
                                            <div className="col s12 flex-row">
                                                <button className="btn blue">{this.context.t("_save_changes")}</button>
                                                <button type="button" onClick={() => { this.handleChangePassword() }} className="btn profileBtn">{this.context.t("_change_password")}</button>
                                            </div>
                                        </form>
                                }
                            </div>
                        </div>
                        <div id="photoCrop" className="modal sm" role="dialog">
                            <div className="modal-content scroll-x-hidden">
                                <span className="badge modalClose"><button type="button" aria-label="close" tabindex="0" className="close" onClick={() => this.handleCloseModal()}><i className="material-icons">close</i></button></span>
                                <p>{this.context.t("_crop_your_image")}</p>
                                <div className="image-crop-container">
                                    <ReactCrop
                                        // crossorigin="anonymous"
                                        src={selectedImage}
                                        crop={crop}
                                        ruleOfThirds
                                        minWidth={200}
                                        minHeight={200}
                                        keepSelection={true}
                                        imageStyle={{ maxHeight: 250 }}
                                        onImageLoaded={this.onImageLoaded}
                                        onComplete={this.onCropComplete}
                                        onChange={this.onCropChange}
                                    />
                                </div>

                                <div className="clearfix" />
                                <button onClick={() => this.saveUserImage()} className="btn profileBtn yellow">{this.context.t("_upload")}</button>
                                <button onClick={() => this.handleCloseModal()} className="btn profileBtn blue-border">{this.context.t("_cancel")}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="top-line ng-scope">
                    <img src={topLineImage} alt="" />
                </div>
            </div>
        )
    }

}

const mapStateToProps = ({ i18nState }) => {
    return {
        lang: i18nState.lang
    }
}
const mapDispatchToProps = {
    showLoader: LoadingAction.showLoader,
    hideLoader: LoadingAction.hideLoader,
    showPopup: (data) => PopupAction.showPopup(data),
    hidePopup: (data) => PopupAction.hidePopup(data),
    userLoggedIn: LoginAction.userLoggedIn,
}
SchoolSettings.contextTypes = {
    t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(SchoolSettings);
