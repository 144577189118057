import React from "react";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import Slider from "react-slick"
import Main from "./../main"
import PageTitle from "../includes/page_title";
import SlideToTop from "../includes/slide_to_top";
import M from "materialize-css";
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import _ from "lodash"
import ReactCrop from "react-image-crop";
import { LoadingAction } from "./../../actions"
import topLineImage from "../../assets/images/top-line.png"
import blankImage from "../../assets/images/blank.png"
import activityImage from "../../assets/images/ico_activities.png"
import connectionImage from "../../assets/images/ico_connections.png"
import settingsImage from "../../assets/images/ico_settings.png"

class VirtualTour extends Main {
    constructor(props) {
        super(props);
        this.state = {
            config: this.config(),
            pager: { count: 0, pages: 0, items_per_page: 0, current_page: 1 },
            profileData: {
                picture: "",
                name: ""
            },
            selectedImage: "",
            crop: {
                unit: "px",
                width: 200,
                height: 201,
                aspect: 2 / 2.1,
            },
        }
    }
    componentDidMount() {
    }
    render() {
        const slick_machines = {
            speed: 500,
            slidesToShow: 4.5,
            infinite: false,
            slidesToScroll: 1,
            afterChange: this.afterChangeHandler,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1700,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 1500,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        arrows: true
                    }
                }
            ]
        };


        const slick_equipments = {
            speed: 500,
            slidesToShow: 2.65,
            infinite: false,
            slidesToScroll: 1,
            afterChange: this.afterChangeHandler,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1770,
                    settings: {
                        slidesToShow: 2.4,
                    }
                },
                {
                    breakpoint: 1600,
                    settings: {
                        slidesToShow: 2.2,
                    }
                },
                {
                    breakpoint: 1470,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 1330,
                    settings: {
                        slidesToShow: 1,
                        arrows: true
                    }
                }
            ]
        };












        const { profileData, config, selectedImage, crop } = this.state;
        return (
            <div id="main-container">
                <SlideToTop />
                <PageTitle title={"UI Screens"} />
                <div className="container">
                    <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                        <ul>
                            <li className="breadcrumb-item">
                                <Link to="/">{`Home`}</Link> {" "}

                            </li>
                            <li className="breadcrumb-item">
                                <span>
                                    UI Screens
                                </span>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="container profile-container">
                    <div className="row">
                        <div className="col s12 text-center">
                            {/* <figure className="profilePic">
                                    <span>
                                        <img src={!_.isEmpty(profileData.picture) ? `${config.origin}${profileData.picture}` : "../../assets/images/profile_pic.jpg"} alt={`${profileData.name}`} />
                                    </span>
                                </figure> */}
                            <figure className="profilePic profileNew">
                                {
                                    _.isEmpty(profileData.picture) ?
                                        <img src={blankImage} alt="" />
                                        :
                                        <>
                                            <img src={blankImage} alt="" />
                                            <span>
                                                <img src={`${config.origin}${profileData.picture}`} alt="" />
                                            </span>
                                        </>
                                }
                                <button type="button" onClick={() => { this.upload.click() }} className="btn changePic">
                                    <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                </button>
                                <input id="myInput"
                                    type="file"
                                    ref={(ref) => this.upload = ref}
                                    style={{ display: "none" }}
                                // onChange={this.onChangeFile.bind(this)}
                                />
                                <button onClick={() => this.removeProfilePic()} type="button" className="btn removePic">
                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                </button>

                            </figure>
                            <h4 className="profileheading profileNew">{`profileData.name`}</h4>
                            {/* <div className="starHolder">
                                    <span className="fa fa-star checked"></span>
                                    <span className="fa fa-star checked"></span>
                                    <span className="fa fa-star checked"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                </div> */}
                        </div>
                        <div className="col s12">
                            <div className="profileStatus profileNew">
                                <ul>
                                    <li>
                                        <strong>
                                            {_.has(profileData, "enrollment_count.challenge.count") ? profileData.enrollment_count.challenge.count : 0}
                                        </strong>
                                        <span>{this.context.t("_challenges")}</span></li>
                                    <li>
                                        <strong>
                                            {_.has(profileData, "enrollment_count.competitions.count") ? profileData.enrollment_count.competitions.count : 0}
                                        </strong>
                                        <span>{this.context.t("_competitions")}</span></li>
                                    <li>
                                        <strong>
                                            {_.has(profileData, "enrollment_count.sessions.count") ? profileData.enrollment_count.sessions.count : 0}
                                        </strong>
                                        <span>{this.context.t("_sessions")}</span></li>
                                    <li>
                                        <strong>
                                            {_.has(profileData, "enrollment_count.events.count") ? profileData.enrollment_count.events.count : 0}
                                        </strong>
                                        <span>{this.context.t("_events")}</span></li>
                                    <li>
                                        <strong>
                                            {_.has(profileData, "enrollment_count.Projects.count") ? profileData.enrollment_count.Projects.count : 0}
                                        </strong>
                                        <span>{this.context.t("_projects")}</span></li>
                                </ul>
                            </div>

                            <div className="profileTabHolder profileNew">
                                <div className="cell">
                                    <Link to="/current-activities">
                                        <div className="profileTab item4">
                                            <img src={activityImage} alt="" />
                                            <p>{this.context.t("_current_activities")}</p>
                                        </div>
                                    </Link>
                                    {
                                        localStorage.getItem("role") == "volenteer" && <Link to="volenteer-history">
                                            <div className="profileTab item4">
                                                <img src={connectionImage} alt="" />
                                                <p>{this.context.t("_activity_history")}</p>
                                            </div>
                                        </Link>
                                    }
                                    {
                                        localStorage.getItem("role") != "volenteer" && <Link to={`/${localStorage.getItem("role")}-connections`}>
                                            <div className="profileTab item4">
                                                <img src={connectionImage} alt="" />
                                                <p>{this.context.t("_manage_connections")}</p>
                                            </div>
                                        </Link>
                                    }
                                </div>
                                <div className="cell">
                                    <Link to={`/${localStorage.getItem("role")}-settings`}>
                                        <div className="profileTab item4">
                                            <img src={settingsImage} alt="" />
                                            <p>{this.context.t("_profile_settings")}</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="cell">
                                    <Link to={`/${localStorage.getItem("role")}-settings`}>
                                        <div className="profileTab item4">
                                            <img src={settingsImage} alt="" />
                                            <p>{this.context.t("_profile_settings")}</p>
                                        </div>
                                    </Link>
                                </div>
                                {/* {
                                        localStorage.getItem("role") == "school" && <Link to="waiting-list">
                                            <div className="profileTab">
                                                <img src={waitingImage} />
                                                <p>Waiting List</p>
                                            </div>
                                        </Link>
                                    } */}
                            </div>
                        </div>

                    </div>

                    <div id="photoCrop" className="modal sm">
                        <div className="modal-content scroll-x-hidden">
                            <span className="badge modalClose"><a onClick={() => this.handleCloseModal()}><i className="material-icons">close</i></a></span>
                            <p>{this.context.t("_crop_your_image")}</p>
                            <div className="image-crop-container">
                                <ReactCrop
                                    // crossorigin="anonymous"
                                    src={selectedImage}
                                    crop={crop}
                                    ruleOfThirds
                                    minWidth={200}
                                    minHeight={200}
                                    keepSelection={true}
                                    imageStyle={{ maxHeight: 250 }}
                                // onImageLoaded={this.onImageLoaded}
                                // onComplete={this.onCropComplete}
                                // onChange={this.onCropChange}
                                />
                            </div>

                            <div className="clearfix" />
                            <button onClick={() => this.saveUserImage()} className="btn profileBtn yellow">{this.context.t("_upload")}</button>
                            <button onClick={() => this.handleCloseModal()} className="btn profileBtn blue-border">{this.context.t("_cancel")}</button>
                        </div>
                    </div>

                    xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

                    <div className="row">
                        <div className="col s12 text-center">
                            {/* <figure className="profilePic">
                                    <span>
                                        <img src={!_.isEmpty(profileData.picture) ? `${config.origin}${profileData.picture}` : "../../assets/images/profile_pic.jpg"} alt={`${profileData.name}`} />
                                    </span>
                                </figure> */}
                            <figure className="profilePic profileNew">
                                {
                                    _.isEmpty(profileData.picture) ?
                                        <img src={blankImage} alt="" />
                                        :
                                        <>
                                            <img src={blankImage} alt="" />
                                            <span>
                                                <img src={`${config.origin}${profileData.picture}`} alt="" />
                                            </span>
                                        </>
                                }
                                <button type="button" onClick={() => { this.upload.click() }} className="btn changePic">
                                    <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                </button>
                                <input id="myInput"
                                    type="file"
                                    ref={(ref) => this.upload = ref}
                                    style={{ display: "none" }}
                                // onChange={this.onChangeFile.bind(this)}
                                />
                                <button onClick={() => this.removeProfilePic()} type="button" className="btn removePic">
                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                </button>

                            </figure>
                            <h4 className="profileheading profileNew">{`profileData.name`}</h4>
                            {/* <div className="starHolder">
                                    <span className="fa fa-star checked"></span>
                                    <span className="fa fa-star checked"></span>
                                    <span className="fa fa-star checked"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                </div> */}
                        </div>

                        <div className="col s12">
                            <div className="profileStatus profileNew">
                                <ul>
                                    <li>
                                        <strong>
                                            {_.has(profileData, "enrollment_count.challenge.count") ? profileData.enrollment_count.challenge.count : 0}
                                        </strong>
                                        <span>{this.context.t("_challenges")}</span></li>
                                    <li>
                                        <strong>
                                            {_.has(profileData, "enrollment_count.competitions.count") ? profileData.enrollment_count.competitions.count : 0}
                                        </strong>
                                        <span>{this.context.t("_competitions")}</span></li>
                                    <li>
                                        <strong>
                                            {_.has(profileData, "enrollment_count.sessions.count") ? profileData.enrollment_count.sessions.count : 0}
                                        </strong>
                                        <span>{this.context.t("_sessions")}</span></li>
                                    <li>
                                        <strong>
                                            {_.has(profileData, "enrollment_count.events.count") ? profileData.enrollment_count.events.count : 0}
                                        </strong>
                                        <span>{this.context.t("_events")}</span></li>
                                    <li>
                                        <strong>
                                            {_.has(profileData, "enrollment_count.Projects.count") ? profileData.enrollment_count.Projects.count : 0}
                                        </strong>
                                        <span>{this.context.t("_projects")}</span></li>
                                </ul>
                            </div>

                            <div className="attendance-holder">

                                <div className="attendance-item">
                                    <figure>
                                        <img src="http://52.157.102.50/drupal-app/sites/default/files/2020-08/Web%20BYG-2_0.jpg" alt="Coding 101 image" />
                                    </figure>

                                    <aside>
                                        <h4>
                                            <i className="topHeadNA"></i>Robotics competition enrollment1
                                            <span>School session</span>
                                        </h4>
                                        <div className="details"><span><i className="calendar-icons"></i> Start date <span className="date">16 Aug,2020</span></span><span><i className="calendar-icons"></i> End date <span className="date">20 Aug,2020</span></span></div>
                                    </aside>

                                    <div className="attendance-day-holder">
                                        <h5>Attendance</h5>
                                        <div className="attendance-day">
                                            <span>
                                                17
                                            <small>jan 2021</small>
                                            </span>
                                            <em>Present</em>
                                        </div>

                                        <div className="attendance-day absent">
                                            <span>
                                                18
                                            <small>jan 2021</small>
                                            </span>
                                            <em>Absent</em>
                                        </div>

                                        <div className="attendance-day">
                                            <span>
                                                19
                                            <small>jan 2021</small>
                                            </span>
                                            <em>Present</em>
                                        </div>

                                        <div className="attendance-day">
                                            <span>
                                                20
                                            <small>jan 2021</small>
                                            </span>
                                            <em>Present</em>
                                        </div>

                                        <div className="attendance-day">
                                            <span>
                                                20
                                            <small>jan 2021</small>
                                            </span>
                                            <em>Present</em>
                                        </div>

                                        <div className="attendance-day">
                                            <span>
                                                20
                                            <small>jan 2021</small>
                                            </span>
                                            <em>Present</em>
                                        </div>

                                        <div className="attendance-day">
                                            <span>
                                                20
                                            <small>jan 2021</small>
                                            </span>
                                            <em>Present</em>
                                        </div>
                                    </div>
                                </div>

                                <div className="attendance-item Software-Zone">
                                    <figure>
                                        <img src="http://52.157.102.50/drupal-app/sites/default/files/2020-08/Web%20BYG-2_0.jpg" alt="Coding 101 image" />
                                    </figure>

                                    <aside>
                                        <h4 className="pinkalert">
                                            <i className="topHeadNA"></i>Robotics
                                            <span>Public competition</span>
                                        </h4>
                                        <div className="details">
                                            <span><i className="calendar-icons"></i> Start date <span className="date">16 Aug,2020</span></span>
                                            <span><i className="calendar-icons"></i> End date <span className="date">20 Aug,2020</span></span>
                                        </div>
                                    </aside>

                                    <div className="attendance-day-holder">
                                        <h5>Attendance</h5>

                                        <div className="attendance-day">
                                            <span>
                                                17
                                            <small>jan 2021</small>
                                            </span>
                                            <em>Present</em>
                                        </div>

                                        <div className="attendance-day absent">
                                            <span>
                                                18
                                            <small>jan 2021</small>
                                            </span>
                                            <em>Absent</em>
                                        </div>

                                        <div className="attendance-day">
                                            <span>
                                                19
                                            <small>jan 2021</small>
                                            </span>
                                            <em>Present</em>
                                        </div>

                                        <div className="attendance-day">
                                            <span>
                                                20
                                            <small>jan 2021</small>
                                            </span>
                                            <em>Present</em>
                                        </div>

                                        <div className="attendance-day">
                                            <span>
                                                21
                                            <small>jan 2021</small>
                                            </span>
                                            <em>Present</em>
                                        </div>

                                        <div className="attendance-day">
                                            <span>
                                                22
                                            <small>jan 2021</small>
                                            </span>
                                            <em>Present</em>
                                        </div>

                                        <div className="attendance-day">
                                            <span>
                                                23
                                            <small>jan 2021</small>
                                            </span>
                                            <em>Present</em>
                                        </div>

                                        <div className="attendance-day">
                                            <span>
                                                24
                                            <small>jan 2021</small>
                                            </span>
                                            <em>Present</em>
                                        </div>

                                        <div className="attendance-day">
                                            <span>
                                                25
                                            <small>jan 2021</small>
                                            </span>
                                            <em>Present</em>
                                        </div>

                                    </div>
                                </div>

                                <div className="attendance-item Technology-Zone">
                                    <figure>
                                        <img src="http://52.157.102.50/drupal-app/sites/default/files/2020-08/Web%20BYG-2_0.jpg" alt="Coding 101 image" />
                                    </figure>

                                    <aside>
                                        <h4>
                                            <i className="topHeadNA"></i>Checking Activity enrollment1
                                            <span>School session</span>
                                        </h4>
                                        <div className="details"><span><i className="calendar-icons"></i> Start date <span className="date">16 Aug,2020</span></span><span><i className="calendar-icons"></i> End date <span className="date">20 Aug,2020</span></span></div>
                                    </aside>

                                    <div className="attendance-day-holder">
                                        <h5>Attendance</h5>

                                        <div className="attendance-day">
                                            <span>
                                                17
                                            <small>jan 2021</small>
                                            </span>
                                            <em>Present</em>
                                        </div>

                                        <div className="attendance-day absent">
                                            <span>
                                                18
                                            <small>jan 2021</small>
                                            </span>
                                            <em>Absent</em>
                                        </div>

                                        <div className="attendance-day">
                                            <span>
                                                19
                                            <small>jan 2021</small>
                                            </span>
                                            <em>Present</em>
                                        </div>

                                        <div className="attendance-day">
                                            <span>
                                                20
                                            <small>jan 2021</small>
                                            </span>
                                            <em>Present</em>
                                        </div>

                                        <div className="attendance-day">
                                            <span>
                                                21
                                            <small>jan 2021</small>
                                            </span>
                                            <em>Present</em>
                                        </div>

                                        <div className="attendance-day">
                                            <span>
                                                22
                                            <small>jan 2021</small>
                                            </span>
                                            <em>Present</em>
                                        </div>

                                        <div className="attendance-day">
                                            <span>
                                                23
                                            <small>jan 2021</small>
                                            </span>
                                            <em>Present</em>
                                        </div>

                                        <div className="attendance-day">
                                            <span>
                                                24
                                            <small>jan 2021</small>
                                            </span>
                                            <em>Present</em>
                                        </div>

                                        <div className="attendance-day">
                                            <span>
                                                25
                                            <small>jan 2021</small>
                                            </span>
                                            <em>Present</em>
                                        </div>
                                    </div>
                                </div>

                            </div>





                        </div>
                    </div>

                    XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX

                    <div className="row">

                        <div className="col m12 l3 text-center">
                            1111111

                            <div className="parent-profile-main">

                                {/* <figure className="profilePic">
                                        <span>
                                            <img src={!_.isEmpty(profileData.picture) ? `${config.origin}${profileData.picture}` : "../../assets/images/profile_pic.jpg"} alt={`${profileData.name}`} />
                                        </span>
                                    </figure> */}
                                <figure className="profilePic profileNew">
                                    {
                                        _.isEmpty(profileData.picture) ?
                                            <img src={blankImage} alt="" />
                                            :
                                            <>
                                                <img src={blankImage} alt="" />
                                                <span>
                                                    <img src={`${config.origin}${profileData.picture}`} alt="" />
                                                </span>
                                            </>
                                    }
                                    <button type="button" onClick={() => { this.upload.click() }} className="btn changePic">
                                        <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                    </button>
                                    <input id="myInput"
                                        type="file"
                                        ref={(ref) => this.upload = ref}
                                        style={{ display: "none" }}
                                    // onChange={this.onChangeFile.bind(this)}
                                    />
                                    <button onClick={() => this.removeProfilePic()} type="button" className="btn removePic">
                                        <i className="fa fa-trash" aria-hidden="true"></i>
                                    </button>

                                </figure>
                                <h4 className="profileheading profileNew">{`profileData.name`}</h4>
                                {/* <div className="starHolder">
                                        <span className="fa fa-star checked"></span>
                                        <span className="fa fa-star checked"></span>
                                        <span className="fa fa-star checked"></span>
                                        <span className="fa fa-star"></span>
                                        <span className="fa fa-star"></span>
                                    </div> */}



                                <ul>
                                    <li>
                                        <Link to="/current-activities" className="active">
                                            <img src={activityImage} alt="" />
                                            <p>{this.context.t("_current_activities")}</p>
                                        </Link>
                                    </li>
                                    {
                                        localStorage.getItem("role") == "volenteer" && <li><Link to="volenteer-history">
                                            <img src={connectionImage} alt="" />
                                            <p>{this.context.t("_activity_history")}</p>
                                        </Link></li>
                                    }
                                    {
                                        localStorage.getItem("role") != "volenteer" && <li><Link to={`/${localStorage.getItem("role")}-connections`}>
                                            <img src={connectionImage} alt="" />
                                            <p>{this.context.t("_manage_connections")}</p>
                                        </Link></li>
                                    }

                                    <li><Link to={`/${localStorage.getItem("role")}-settings`}>
                                        <img src={settingsImage} alt="" />
                                        <p>{this.context.t("_profile_settings")}</p>
                                    </Link></li>

                                    <li><Link to={`/${localStorage.getItem("role")}-settings`}>
                                        <img src={settingsImage} alt="" />
                                        <p>{this.context.t("_profile_settings")}</p>
                                    </Link></li>

                                    {/* {
                                            localStorage.getItem("role") == "school" && <Link to="waiting-list">
                                                <div className="profileTab">
                                                    <img src={waitingImage} />
                                                    <p>Waiting List</p>
                                                </div>
                                            </Link>
                                        } */}
                                </ul>

                            </div>

                        </div>

                        <div className="col m12 l9">
                            22222222222222

                            <div className="attendance-holder">

                                <div className="attendance-heading">
                                    <h2 className="line">Current Activities</h2>
                                    <aside>
                                        <button type="submit" className="btn profileBtn yellow" aria-label="Activity search">View All <i className="material-icons en">arrow_forward</i></button>
                                    </aside>
                                </div>

                                <div className="attendance-item attendance-item__two">
                                    <figure>
                                        <img src="http://52.157.102.50/drupal-app/sites/default/files/2020-08/Web%20BYG-2_0.jpg" alt="Coding 101 image" />
                                    </figure>

                                    <aside>
                                        <h4>
                                            <i className="topHeadNA"></i>Robotics competition enrollment1
                                            <span>School session</span>
                                        </h4>
                                        <div className="details"><span><i className="calendar-icons"></i> Start date <span className="date">16 Aug,2020</span></span><span><i className="calendar-icons"></i> End date <span className="date">20 Aug,2020</span></span></div>
                                    </aside>

                                    <div className="attendance-button-holder">
                                        <button type="submit" className="btn profileBtn blue" aria-label="Activity search">Participants</button>
                                    </div>
                                </div>

                                <div className="attendance-item Software-Zone attendance-item__two">
                                    <figure>
                                        <img src="http://52.157.102.50/drupal-app/sites/default/files/2020-08/Web%20BYG-2_0.jpg" alt="Coding 101 image" />
                                    </figure>

                                    <aside>
                                        <h4 className="pinkalert">
                                            <i className="topHeadNA"></i>Robotics enrollment1
                                            <span>Public competition</span>
                                        </h4>
                                        <div className="details">
                                            <span><i className="calendar-icons"></i> Start date <span className="date">16 Aug,2020</span></span>
                                            <span><i className="calendar-icons"></i> End date <span className="date">20 Aug,2020</span></span>
                                        </div>
                                    </aside>

                                    <div className="attendance-button-holder">
                                        <button type="submit" className="btn profileBtn blue" aria-label="Activity search">Participants</button>
                                    </div>
                                </div>

                                <div className="attendance-item Technology-Zone attendance-item__two">
                                    <figure>
                                        <img src="http://52.157.102.50/drupal-app/sites/default/files/2020-08/Web%20BYG-2_0.jpg" alt="Coding 101 image" />
                                    </figure>

                                    <aside>
                                        <h4>
                                            <i className="topHeadNA"></i>Checking Activity enrollment1
                                            <span>School session</span>
                                        </h4>
                                        <div className="details"><span><i className="calendar-icons"></i> Start date <span className="date">16 Aug,2020</span></span><span><i className="calendar-icons"></i> End date <span className="date">20 Aug,2020</span></span></div>
                                    </aside>

                                    <div className="attendance-button-holder">
                                        <button type="submit" className="btn profileBtn blue" aria-label="Activity search">Participants</button>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                    XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX

                    <div className="machine-item-holder">
                        <h2 class="line">Machines</h2>

                        <div className="machine-item">
                            <figure className="machine-item-figure">
                                <img src="http://52.157.102.50/drupal-app/sites/default/files/2020-08/Web%20BYG-2_0.jpg" alt="Coding 101 image" />
                            </figure>
                            <aside className="machine-item-content">
                                <h4>Ultimaker 3 &amp; Ultimaker 3</h4>
                                <h5>Ultimaker</h5>
                                <p>The 3D printer is the machine manufactured to perform the process of 3D printing.
                                3D printing or additive manufacturing is the process of creating a 3D solid object by
                                going through several steps. Starting by producing the digital file that describes the
                                specifications of the target object (dimensions, thickness …etc.). After sending the digital
                                file to the printer, it will start making the object by adding together multiple successive
                                layers of a specific material. Every layer can be described as a thinly sliced horizontal
                                cross-section of the final object. The Ultimaker 3 3D printer was designed and engineered
                                for efficiency and precision, featuring a dual extrusion with water-soluble support,
                                    open filament system, and an advanced connectivity. </p>
                                <a href="#" className="machine-btn">View More </a>
                            </aside>
                        </div>

                        <div className="machine-item">
                            <figure className="machine-item-figure">
                                <img src="http://52.157.102.50/drupal-app//sites/default/files/2021-02/index_0_2.jpg" alt="Coding 101 image" />
                            </figure>
                            <aside className="machine-item-content">
                                <h4>Ultimaker 3 &amp; Ultimaker 3</h4>
                                <h5>Ultimaker</h5>
                                <p>The 3D printer is the machine manufactured to perform the process of 3D printing.
                                3D printing or additive manufacturing is the process of creating a 3D solid object by
                                going through several steps. Starting by producing the digital file that describes the
                                specifications of the target object (dimensions, thickness …etc.). After sending the digital
                                file to the printer, it will start making the object by adding together multiple successive
                                layers of a specific material. Every layer can be described as a thinly sliced horizontal
                                cross-section of the final object. The Ultimaker 3 3D printer was designed and engineered
                                for efficiency and precision, featuring a dual extrusion with water-soluble support,
                                    open filament system, and an advanced connectivity. </p>
                                <a href="#" className="machine-btn">View More </a>
                            </aside>
                        </div>

                        <div className="machine-item">
                            <figure className="machine-item-figure">
                                <img src="http://52.157.102.50/drupal-app//sites/default/files/2020-09/Arduino%20Digital%20Piano-300x250_0.jpg" alt="Coding 101 image" />
                            </figure>
                            <aside className="machine-item-content">
                                <h4>Ultimaker 3 &amp; Ultimaker 3</h4>
                                <h5>Ultimaker</h5>
                                <p>The 3D printer is the machine manufactured to perform the process of 3D printing.
                                3D printing or additive manufacturing is the process of creating a 3D solid object by
                                going through several steps. Starting by producing the digital file that describes the
                                specifications of the target object (dimensions, thickness …etc.). After sending the digital
                                file to the printer, it will start making the object by adding together multiple successive
                                layers of a specific material. Every layer can be described as a thinly sliced horizontal
                                cross-section of the final object. The Ultimaker 3 3D printer was designed and engineered
                                for efficiency and precision, featuring a dual extrusion with water-soluble support,
                                    open filament system, and an advanced connectivity. </p>
                                <a href="#" className="machine-btn">View More </a>
                            </aside>
                        </div>

                    </div>

                    XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX

                    <h2 class="line">Equipments</h2>

                    <div className="row">
                        <div className="col m12 l6">
                            <div className="machine-item small">
                                <figure className="machine-item-figure small">
                                    <img src="http://52.157.102.50/drupal-app//sites/default/files/2020-09/Arduino%20Digital%20Piano-300x250_0.jpg" alt="Coding 101 image" />
                                </figure>
                                <aside className="machine-item-content">
                                    <h5>Ultimaker <span>3D printer</span></h5>
                                    <h4>Ultimaker 3+</h4>
                                    <div className="machine-small-btn">
                                        <a href="#">
                                            <span>Updated Model</span>
                                            <em>Ultimaker S5 Pro</em>
                                        </a>
                                    </div>
                                </aside>
                            </div>
                        </div>

                        <div className="col m12 l6">
                            <div className="machine-item small">
                                <figure className="machine-item-figure small">
                                    <img src="http://52.157.102.50/drupal-app//sites/default/files/2020-09/Arduino%20Digital%20Piano-300x250_0.jpg" alt="Coding 101 image" />
                                </figure>
                                <aside className="machine-item-content">
                                    <h5>Ultimaker <span>Vinyl Cutter</span></h5>
                                    <h4>Oculus Rift + Touch Virtual Reality System</h4>
                                    <div className="machine-small-btn">
                                        <a href="#">
                                            <span>Updated Model</span>
                                            <em>Code Kit Expansion Pack: Technology ClassrCode Kit Expansion Pack: Technology Classr</em>
                                        </a>
                                    </div>
                                </aside>
                            </div>
                        </div>

                        <div className="col m12 l6">
                            <div className="machine-item small">
                                <figure className="machine-item-figure small">
                                    <img src="http://52.157.102.50/drupal-app//sites/default/files/2020-09/Arduino%20Digital%20Piano-300x250_0.jpg" alt="Coding 101 image" />
                                </figure>
                                <aside className="machine-item-content">
                                    <h5>Ultimaker <span>3D printer</span></h5>
                                    <h4>Ultimaker 3+</h4>
                                </aside>
                            </div>
                        </div>

                        <div className="col m12 l6">
                            <div className="machine-item small">
                                <figure className="machine-item-figure small">
                                    <img src="http://52.157.102.50/drupal-app//sites/default/files/2020-09/Arduino%20Digital%20Piano-300x250_0.jpg" alt="Coding 101 image" />
                                </figure>
                                <aside className="machine-item-content">
                                    <h5>Ultimaker <span>Vinyl Cutter</span></h5>
                                    <h4>Oculus Rift + Touch Virtual Reality System</h4>
                                </aside>
                            </div>
                        </div>
                        <div className="col m12 l6">
                            <div className="machine-item small">
                                <figure className="machine-item-figure small">
                                    <img src="http://52.157.102.50/drupal-app//sites/default/files/2020-09/Arduino%20Digital%20Piano-300x250_0.jpg" alt="Coding 101 image" />
                                </figure>
                                <aside className="machine-item-content">
                                    <h5>Ultimaker <span>3D printer</span></h5>
                                    <h4>Ultimaker 3+</h4>
                                    <div className="machine-small-btn">
                                        <a href="#">
                                            <span>Updated Model</span>
                                            <em>Ultimaker S5 Pro</em>
                                        </a>
                                    </div>
                                </aside>
                            </div>
                        </div>

                        <div className="col m12 l6">
                            <div className="machine-item small">
                                <figure className="machine-item-figure small">
                                    <img src="http://52.157.102.50/drupal-app//sites/default/files/2020-09/Arduino%20Digital%20Piano-300x250_0.jpg" alt="Coding 101 image" />
                                </figure>
                                <aside className="machine-item-content">
                                    <h5>Ultimaker <span>Vinyl Cutter</span></h5>
                                    <h4>Oculus Rift + Touch Virtual Reality System</h4>
                                </aside>
                            </div>
                        </div>

                        <div className="col m12 l6">
                            <div className="machine-item small">
                                <figure className="machine-item-figure small">
                                    <img src="http://52.157.102.50/drupal-app//sites/default/files/2020-09/Arduino%20Digital%20Piano-300x250_0.jpg" alt="Coding 101 image" />
                                </figure>
                                <aside className="machine-item-content">
                                    <h5>Ultimaker <span>3D printer</span></h5>
                                    <h4>Ultimaker 3+</h4>
                                </aside>
                            </div>
                        </div>

                        <div className="col m12 l6">
                            <div className="machine-item small">
                                <figure className="machine-item-figure small">
                                    <img src="http://52.157.102.50/drupal-app//sites/default/files/2020-09/Arduino%20Digital%20Piano-300x250_0.jpg" alt="Coding 101 image" />
                                </figure>
                                <aside className="machine-item-content">
                                    <h5>Ultimaker <span>Vinyl Cutter</span></h5>
                                    <h4>Oculus Rift + Touch Virtual Reality System</h4>
                                    <div className="machine-small-btn">
                                        <a href="#">
                                            <span>Updated Model</span>
                                            <em>Code Kit Expansion Pack: Technology ClassrCode Kit Expansion Pack: Technology Classr</em>
                                        </a>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>

                    <h2 class="line">3Doodler Create +</h2>

                    <div className="row machine-detail-holder">
                        <div className="col m12 l3">
                            <figure className="machine-detail-figure">
                                <img src="http://52.157.102.50/drupal-app//sites/default/files/2020-09/Arduino%20Digital%20Piano-300x250_0.jpg" alt="Coding 101 image" />
                            </figure>
                        </div>

                        <div className="col m12 l9 machine-detail-content">
                            <h4>3Doodler Create+ <span>3D printer</span></h4>
                            <h5 className="blue-text">3Doodle</h5>
                            <h5>Software Required : None</h5>
                            <p>The 3D printer is the machine manufactured to perform the process of 3D printing. 3D printing or
                            additive manufacturing is the process of creating a 3D solid object by going through several steps.
                            Starting by producing the digital file that describes the specifications of the target object
                            (dimensions, thickness …etc.). After sending the digital file to the printer, it will start making
                            the object by adding together multiple successive layers of a specific material. Every layer can be
                            described as a thinly sliced horizontal cross-section of the final object. The Ultimaker 3 3D printer
                            was designed and engineered for efficiency and precision, featuring a dual extrusion with water-soluble
                                support, open filament system, and an advanced connectivity. </p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col s12">
                            <ul class="tabs machine-tab-menu">
                                <li class="tab"><a class="active" href="#test16">Test 1</a></li>
                                <li class="tab"><a href="#test17">Materials</a></li>
                                <li class="tab"><a href="#test18">Safety procedure </a></li>
                            </ul>
                        </div>

                        <div id="test16" class="col s12 machine-tab-content">
                            <ul>
                                <li>Dual extension and Water-soluble support: allows creation of complex mechanical parts, and ability to print with two colors.</li>
                                <li>Open filament system:  allows freedom in trying and testing other existing or new types of filaments.</li>
                                <li>Advanced connectivity: Wi-Fi, Ethernet and USB connectivity.</li>
                                <li>Heated glass build plate: allows printing using different materials and with improved build plate adhesion.</li>
                                <li>Optimized cooling: ensures better cooling, faster print runs and smooth print surfaces, using two new radial fans and fan shrouds.</li>
                                <li>Bed size: the Ultimaker 3 is 197 x 215 x 200 mm and the Ultimaker Extended is 197 x 215 x 300 mm.</li>
                                <li>Filament diameter:  2.85 mm. </li>
                            </ul>
                        </div>
                        <div id="test17" class="col s12 machine-tab-content"><p>Materials </p></div>
                        <div id="test18" class="col s12 machine-tab-content"><p>Safety procedure</p></div>
                    </div>

                    <h2 class="header-two">Machines Used</h2>

                    <div className="machines-scroll-item-holder">
                        
                        <Slider {...slick_machines}>
                            <div className="machines-scroll-item">
                                <figure className="machines-scroll-item-figure">
                                    <img src="http://52.157.102.50/drupal-app//sites/default/files/2020-09/Arduino%20Digital%20Piano-300x250_0.jpg" alt="Coding 101 image" />
                                </figure>
                                <h4>Ultimaker 3 &amp; Ultimaker 3</h4>
                                <p>Ultimaker</p>
                            </div>

                            <div className="machines-scroll-item">
                                <figure className="machines-scroll-item-figure">
                                    <img src="http://52.157.102.50/drupal-app//sites/default/files/2020-09/Arduino%20Digital%20Piano-300x250_0.jpg" alt="Coding 101 image" />
                                </figure>
                                <h4>Ultimaker 3 &amp; Ultimaker 3</h4>
                                <p>Ultimaker</p>
                            </div>

                            <div className="machines-scroll-item">
                                <figure className="machines-scroll-item-figure">
                                    <img src="http://52.157.102.50/drupal-app//sites/default/files/2020-09/Arduino%20Digital%20Piano-300x250_0.jpg" alt="Coding 101 image" />
                                </figure>
                                <h4>Ultimaker 3 &amp; Ultimaker 3</h4>
                                <p>Ultimaker</p>
                            </div>

                            <div className="machines-scroll-item">
                                <figure className="machines-scroll-item-figure">
                                    <img src="http://52.157.102.50/drupal-app//sites/default/files/2020-09/Arduino%20Digital%20Piano-300x250_0.jpg" alt="Coding 101 image" />
                                </figure>
                                <h4>Ultimaker 3 &amp; Ultimaker 3</h4>
                                <p>Ultimaker</p>
                            </div>

                            <div className="machines-scroll-item">
                                <figure className="machines-scroll-item-figure">
                                    <img src="http://52.157.102.50/drupal-app//sites/default/files/2020-09/Arduino%20Digital%20Piano-300x250_0.jpg" alt="Coding 101 image" />
                                </figure>
                                <h4>Ultimaker 3 &amp; Ultimaker 3</h4>
                                <p>Ultimaker</p>
                            </div>

                            <div className="machines-scroll-item">
                                <figure className="machines-scroll-item-figure">
                                    <img src="http://52.157.102.50/drupal-app//sites/default/files/2020-09/Arduino%20Digital%20Piano-300x250_0.jpg" alt="Coding 101 image" />
                                </figure>
                                <h4>Ultimaker 3 &amp; Ultimaker 3</h4>
                                <p>Ultimaker</p>
                            </div>
                        </Slider>
                  
                    </div>

                    <div className="activity-details">
                        <div className="machines-scroll-item-holder">                            
                            <Slider {...slick_machines}>
                                <div className="machines-scroll-item">
                                    <figure className="machines-scroll-item-figure">
                                        <img src="http://52.157.102.50/drupal-app//sites/default/files/2020-09/Arduino%20Digital%20Piano-300x250_0.jpg" alt="Coding 101 image" />
                                    </figure>
                                    <h4>Ultimaker 3 &amp; Ultimaker 3</h4>
                                    <p>Ultimaker</p>
                                </div>

                                <div className="machines-scroll-item">
                                    <figure className="machines-scroll-item-figure">
                                        <img src="http://52.157.102.50/drupal-app//sites/default/files/2020-09/Arduino%20Digital%20Piano-300x250_0.jpg" alt="Coding 101 image" />
                                    </figure>
                                    <h4>Ultimaker 3 &amp; Ultimaker 3</h4>
                                    <p>Ultimaker</p>
                                </div>

                                <div className="machines-scroll-item">
                                    <figure className="machines-scroll-item-figure">
                                        <img src="http://52.157.102.50/drupal-app//sites/default/files/2020-09/Arduino%20Digital%20Piano-300x250_0.jpg" alt="Coding 101 image" />
                                    </figure>
                                    <h4>Ultimaker 3 &amp; Ultimaker 3</h4>
                                    <p>Ultimaker</p>
                                </div>

                                <div className="machines-scroll-item">
                                    <figure className="machines-scroll-item-figure">
                                        <img src="http://52.157.102.50/drupal-app//sites/default/files/2020-09/Arduino%20Digital%20Piano-300x250_0.jpg" alt="Coding 101 image" />
                                    </figure>
                                    <h4>Ultimaker 3 &amp; Ultimaker 3</h4>
                                    <p>Ultimaker</p>
                                </div>

                                <div className="machines-scroll-item">
                                    <figure className="machines-scroll-item-figure">
                                        <img src="http://52.157.102.50/drupal-app//sites/default/files/2020-09/Arduino%20Digital%20Piano-300x250_0.jpg" alt="Coding 101 image" />
                                    </figure>
                                    <h4>Ultimaker 3 &amp; Ultimaker 3</h4>
                                    <p>Ultimaker</p>
                                </div>

                                <div className="machines-scroll-item">
                                    <figure className="machines-scroll-item-figure">
                                        <img src="http://52.157.102.50/drupal-app//sites/default/files/2020-09/Arduino%20Digital%20Piano-300x250_0.jpg" alt="Coding 101 image" />
                                    </figure>
                                    <h4>Ultimaker 3 &amp; Ultimaker 3</h4>
                                    <p>Ultimaker</p>
                                </div>
                            </Slider>                    
                        </div>
                    </div>

                    <br />
                    <h2 class="header-two">Equipments Used</h2>

                    <div className="equipments-scroll-item-holder">
                        <Slider {...slick_equipments}>
                            <div className="equipments-scroll-item">
                                <div className="equipments-scroll-item-inner">
                                    <figure className="equipments-scroll-item-figure">
                                        <img src="http://52.157.102.50/drupal-app//sites/default/files/2020-09/Arduino%20Digital%20Piano-300x250_0.jpg" alt="Coding 101 image" />
                                    </figure>
                                    <div className="equipments-scroll-item-content">
                                        <p>zSpace <span>Vinyl Cutter</span></p>
                                        <h4>zSpace 300 All-In-One system</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="equipments-scroll-item">
                                <div className="equipments-scroll-item-inner">
                                    <figure className="equipments-scroll-item-figure">
                                        <img src="http://52.157.102.50/drupal-app//sites/default/files/2020-09/Arduino%20Digital%20Piano-300x250_0.jpg" alt="Coding 101 image" />
                                    </figure>
                                    <div className="equipments-scroll-item-content">
                                        <p>zSpace <span>Vinyl Cutter</span></p>
                                        <h4>zSpace 300 All-In-One system</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="equipments-scroll-item">
                                <div className="equipments-scroll-item-inner">
                                    <figure className="equipments-scroll-item-figure">
                                        <img src="http://52.157.102.50/drupal-app//sites/default/files/2020-09/Arduino%20Digital%20Piano-300x250_0.jpg" alt="Coding 101 image" />
                                    </figure>
                                    <div className="equipments-scroll-item-content">
                                        <p>zSpace <span>Vinyl Cutter</span></p>
                                        <h4>zSpace 300 All-In-One system</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="equipments-scroll-item">
                                <div className="equipments-scroll-item-inner">
                                    <figure className="equipments-scroll-item-figure">
                                        <img src="http://52.157.102.50/drupal-app//sites/default/files/2020-09/Arduino%20Digital%20Piano-300x250_0.jpg" alt="Coding 101 image" />
                                    </figure>
                                    <div className="equipments-scroll-item-content">
                                        <p>zSpace <span>Vinyl Cutter</span></p>
                                        <h4>zSpace 300 All-In-One system</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="equipments-scroll-item">
                                <div className="equipments-scroll-item-inner">
                                    <figure className="equipments-scroll-item-figure">
                                        <img src="http://52.157.102.50/drupal-app//sites/default/files/2020-09/Arduino%20Digital%20Piano-300x250_0.jpg" alt="Coding 101 image" />
                                    </figure>
                                    <div className="equipments-scroll-item-content">
                                        <p>zSpace <span>Vinyl Cutter</span></p>
                                        <h4>zSpace 300 All-In-One system</h4>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>





                </div>
            </div>
        );
    }
}


const mapStateToProps = ({ i18nState }) => {
    return {
        lang: i18nState.lang
    }
}
const mapDispatchToProps = {
    showLoader: LoadingAction.showLoader,
    hideLoader: LoadingAction.hideLoader,
}
VirtualTour.contextTypes = {
    t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(VirtualTour);
