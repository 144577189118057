import React from "react"
import Main from "../main"
import SlideToTop from "../includes/slide_to_top.js"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import PageTitle from "../includes/page_title.js"
import CompetitionDetails from "./competition_details"
import EventDetails from "./event_details"
import SessionDetails from "./session_details"
import { Link } from "react-router-dom"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { PopupAction, LoadingAction } from "./../../actions"
import { dynamicStyles } from "./../../constants/dynamicStyles"
import _ from "lodash"
import topLineImage from "../../assets/images/top-line.png"
import axios from "axios"

class ActivityDetails extends Main {
	constructor(props) {
		super(props);
		this.checkLogin();
		this.state = {
			isLoaded: false,
			config: this.config(),
			result: [],
			userActivityList: [],
			page_title: ""
		}
		this.props.showLoader()
	}
	async componentDidMount() {
		document.title = `${this.context.t("_studio5")} |`
		var self = this;
		const { match: { params } } = this.props;
		const { lang } = this.props;
		const { config } = this.state;
		if (localStorage.getItem("role") == "student")
			await this.checkStudentEnrolled();
		let url = ""
		if (lang == "ar")
			url = `${config.origin}${lang}/api/${urlEndPoints.activityDetails(params.id)}`
		else
			url = `${config.origin}api/${urlEndPoints.activityDetails(params.id)}`
		const that = this;
		axios.get(url, {
			params: {
				_format: "json"
			}
		}).then(function (response) {
			var type = response.data[0]["field_event_type"];
			let page_title;
			if (type == "event") {
				page_title = that.context.t("_event_details")
			}
			if (type == "competition") {
				page_title = that.context.t("_competitions")
			}
			if (type == "session") {
				page_title = that.context.t("_session_details")
			}
			if (type == "project") {
				page_title = that.context.t("_project_details")
			}
			if (type == "challenge") {
				page_title = that.context.t("_challenge_details")
			}
			document.title = `${self.context.t("_studio5")} | ${response.data[0]?.eventname}`
			const result = response.data[0];
			result.field_start_and_end_time_2 = result.field_start_and_end_time_2.split("*")
			result.field_start_and_end_time_2 = result.field_start_and_end_time_2.map(date => new Date(date))
			result.field_start_and_end_time_2 = new Date(Math.min.apply(null, result.field_start_and_end_time_2));
			result.field_start_and_end_time_3 = result.field_start_and_end_time_3.split("*")
			result.field_start_and_end_time_4 = result.field_start_and_end_time_4.split("*")
			result.field_start_and_end_time = result.field_start_and_end_time.split("*")
			result.field_start_and_end_time_1 = result.field_start_and_end_time_1.split("*")

			self.setState({
				page_title: page_title,
				// result,
				isLoaded: true
			});
			self.setEnrolCount(result)

		}).catch(error => {
			console.log(error);
			this.props.hideLoader()
		})
	}
	setEnrolCount = async (activity) => {
		const { match: { params }, lang } = this.props;
		const { config } = this.state
		let url = `${config.api_url}${urlEndPoints.getEnrolmentCount(params.id)}`
		try {
			const data = await this.networkRequest(url)
			activity.enrolmentCount = !_.isEmpty(data.data) ? data.data.length : 0
			url = `${config.api_url}${urlEndPoints.getActivityAccessories(params.id, lang)}`
			const activityData = await this.networkRequest(url)
			this.setState({ result: { ...activity, ...activityData.data } })
			this.props.hideLoader()
			document.getElementById("pageHeading") && document.getElementById("pageHeading").focus()
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
		this.props.hideLoader()
	}
	checkStudentEnrolled = async () => {
		const { config } = this.state
		let url = `${config.api_url}${urlEndPoints.getUserActivityById}`
		try {
			const data = await this.networkRequest(url)
			this.setState({ userActivityList: data.data })
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
	}
	render() {
		const { page_title, isLoaded, result, userActivityList } = this.state;
		const { match: { params } } = this.props;
		return (
			<div id="main-content" className="activity-details">
				<SlideToTop />
				<PageTitle title={page_title} isDashboardItem={true} />
				<div className="container">
					<div className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
						<ul>
							<li className="breadcrumb-item">
								<Link to="/">{this.context.t("_home")}</Link> {" "}
							</li>
							<li className="breadcrumb-item">
								<Link to="/activities">{this.context.t("_activities")}</Link> {" "}
							</li>
							<li className="breadcrumb-item">
								<span>{page_title}</span>
							</li>
						</ul>
					</div>
				</div>
				<div id="skipContent">
					<div id="main-container">
						<div className="container1">
							{
								isLoaded && <EventDetails result={result} id={params.id} isEnrolled={_.find(userActivityList, { field_activity_id: params.id }) ? true : false} />
							}

						</div>
					</div>
				</div>
				<div className="top-line ng-scope">
					<img src={topLineImage} alt="" />
				</div>
				<div className="sparkles">
					<span className="orange-circle"></span>
					<span className="multi-square one"><b><i></i></b></span>
					<span className="multi-square two"><b><i></i></b></span>
					<span className="grey-square-rotate red-sq one"></span>
					<span className="grey-square-rotate red-sq two"></span>
				</div>
			</div>
		)
	}
}
const mapStateToProps = ({ i18nState }) => {
	return {
		lang: i18nState.lang
	}
}
const mapDispatchToProps = {
	showPopup: (data) => PopupAction.showPopup(data),
	hidePopup: (data) => PopupAction.hidePopup(data),
	showLoader: LoadingAction.showLoader,
	hideLoader: LoadingAction.hideLoader,
}
ActivityDetails.contextTypes = {
	t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(ActivityDetails);
