import { USER_LOGGED_IN, USER_LOGOUT, SET_USER_IMAGE } from "./../constants/constants";
// import blankImage from "../assets/images/blank.png"
const token = localStorage.getItem("refreshToken")
const blankImage = localStorage.getItem("profileImage")
const initialState = {
  isLogin: !!token,
  profileImage: blankImage
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGGED_IN:
      return {
        isLogin: action.payload,
        profileImage: action.image
      };
    case USER_LOGOUT:
      return {
        isLogin: action.payload,
        profileImage: null

      };
    case SET_USER_IMAGE:
      return {
        profileImage: action.payload
      };

    default:
      return { ...state };
  }
};

export default loginReducer;
