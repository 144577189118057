export const dynamicStyles = {
    textAlign: (lang = "en") => {
        return { textAlign: lang === "en" ? "left" : "right" }
    },

    // alignItems: (lang = "en") => {
    //     //return { alignItems: `flex-${lang === "en" ? "start" : "end"}` }
    //     return { alignItems: `flex-${I18nManager.isRTL ? "end" : "start"}` }
    // },

    "justifyContent": (lang = "en") => {
        return { justifyContent: `flex-${lang === "en" ? "end" : "start"}` }
    },

    // alignContent: (lang = "en") => {
    //     return { alignContent: `flex-${I18nManager.isRTL ? "end" : "start"}` }
    // },

    "flexDirection": (lang = "en") => {
        return { flexDirection: `row${lang === "en" ? "" : "-reverse"}` }
    },
    "direction": (lang = "en") => {
        return { direction: `${lang === "en" ? "ltr" : "rtl"}` }
    },
    "position": (lang = "en") => {
        return { left: `${lang === "en" ? 0 : "auto"}`, right: `${lang === "en" ? "auto" : 0} ` }
    },

}
export const customModalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        padding: 0,
        transform: "translate(-50%, -50%)"
    }
};
