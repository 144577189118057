import { combineReducers } from "redux";
import mediaActionReducer from "./media_action_reducer";
import loginReducer from "./login_reducer"
import popupReducer from "./popup_reducer"
import loaderReducer from "./loader_reducer";
import studentCountReducer from "./dashboard/student_count_reducer"
import activityCountReducer from "./dashboard/activity_count_reducer"
import typeCountReducer from "./dashboard/type_count_reducer"
import zoneCountReducer from "./dashboard/zone_count_reducer"
import studentListReducer from "./dashboard/student_list_reducer"
import activityListReducer from "./dashboard/activity_list_reducer"
// import switchLanguageReducer from "./switch_language_reducer"
import { i18nState } from "redux-i18n";

export default combineReducers({
  i18nState,
  loginReducer,
  loaderReducer,
  popupReducer,
  mediaActionReducer,
  // switchLanguageReducer,
  studentCountReducer,
  activityCountReducer,
  typeCountReducer,
  zoneCountReducer,
  studentListReducer,
  activityListReducer
});
