import React from "react"
import Main from "../main"
import { Link } from "react-router-dom"
import PageTitle from "../includes/page_title.js"
import SlideToTop from "../includes/slide_to_top.js"
import { urlEndPoints } from "../../httpAPI/apiConfig"
import Slider from "react-slick";
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { LoadingAction } from "../../actions"
const axios = require("axios");
class News extends Main {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			config: this.config(),
			media: [],
			slider: [],
			page_key: "",
			id: "",
			aboutUsData: [],
			currentSlide: 0,
			privacyPolicyBody: "",
			privacyPolicyTitle: ""
		}
		this.props.showLoader()
	}
	componentDidMount() {
		document.title = `${this.context.t("_studio5")} | ${this.context.t("_privacy_policy")}`
		this.getPrivacyPolicy()
	}
	componentDidUpdate() {
		document.title = `${this.context.t("_studio5")} | ${this.context.t("_privacy_policy")}`
	}
	getPrivacyPolicy = async () => {
		const { config } = this.state
		const { lang } = this.props;
		let url = ""
		if (lang == "ar")
			url = `${config.origin}${lang}/${urlEndPoints.getPrivacyPolicy}`
		else
			url = `${config.origin}${urlEndPoints.getPrivacyPolicy}`

		try {
			const data = await this.networkRequest(url)
			if (data.status == 200 && data.data && data.data.length > 0) {
				this.setState({
					privacyPolicyBody: data.data[0].body,
					privacyPolicyTitle: data.data[0].title,
				})
				this.props.hideLoader()
			}
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}

	}
	afterChangeHandler = (currentSlide) => {
		this.setState({ currentSlide })
	}
	render() {
		let { media, page_key, slider, currentSlide, aboutUsData, privacyPolicyBody, privacyPolicyTitle } = this.state;
		const topLineImage = require("../../assets/images/top-line.png");
		const slider_settings = {
			className: "slider",
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			centerMode: true,
			centerPadding: "60px",
			afterChange: this.afterChangeHandler
		};
		return (
			<>
				<div id="main-container">
					<SlideToTop />
					<PageTitle title={this.context.t("_privacy_policy")} isDashboardItem={true} />
					<div id="skipContent" className="container">
						<nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
							<ul>
								<li className="breadcrumb-item">
									<Link to="/">{this.context.t("_home")}</Link>  {" "}
								</li>
								<li className="breadcrumb-item">
									<Link tabIndex="0" to aria-current={this.context.t("_page")}>
										<span>{this.context.t("_privacy_policy")}</span>
									</Link>
								</li>
							</ul>
						</nav>
					</div>

					<div className="container">
						<h2>{privacyPolicyTitle}</h2>
						<div dangerouslySetInnerHTML={{ __html: privacyPolicyBody }}>
						</div>

					</div>
				</div>
				<div className="top-line ng-scope"><img src={topLineImage} alt="" /></div>
			</>
		)
	}
}

const mapStateToProps = ({ i18nState }) => {
	return {
		lang: i18nState.lang
	}
}
const mapDispatchToProps = {
	showLoader: LoadingAction.showLoader,
	hideLoader: LoadingAction.hideLoader,

}
News.contextTypes = {
	t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(News);
