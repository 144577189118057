import React from "react"
import Main from "../main"
import SlideToTop from "../includes/slide_to_top.js"
import PageTitle from "../includes/page_title.js"
import { Link } from "react-router-dom"
import Popup from "reactjs-popup";
import _ from "lodash"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import M from "materialize-css";
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { LoadingAction } from "./../../actions"
import subscribeRightImage from "../../assets/images/subscribe-right.png"
import { dynamicStyles } from "./../../constants/dynamicStyles"
import { nameWordLengthCheck, nameValidate, emailValidate, phoneValidate } from "./../../constants/common"

const axios = require("axios");
var contact_form = {
	name: { value: "", error: "", class: "" },
	email_address: { value: "", error: "", class: "" },
	mobile_number: { value: "", error: "", class: "" },
	best_describe: { value: "schoolrepresent", error: "", class: "" },
	other_text: { value: "", error: "", class: "" },
	agree: { value: false, error: "", class: "" },

};
class Subscribe extends Main {
	constructor(props) {
		super(props);
		this.checkLogin();
		this.state = {
			isLoaded: false,
			config: this.config(),
			contact_form: contact_form,
			privacyPolicyBody: "",
			privacyPolicyTitle: "",
			submissionStatus: false,
			// error : "",
		}
		this.handleChange = this.handleChange.bind(this);
	}
	componentDidMount() {
		M.AutoInit();
		this.getPrivacyPolicy()

		setTimeout(() => {
			document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
		}, 500);
		// await this.filter_menu();
		// const { contact_form, config } = this.state;

	}
	getPrivacyPolicy = async () => {
		const { config } = this.state
		const { lang } = this.props;
		let url = ""
		if (lang == "ar")
			url = `${config.origin}${lang}/${urlEndPoints.getPrivacyPolicy}`
		else
			url = `${config.origin}${urlEndPoints.getPrivacyPolicy}`

		try {
			const data = await this.networkRequest(url)
			if (data.status == 200 && data.data && data.data.length > 0) {
				this.setState({
					privacyPolicyBody: data.data[0].body,
					privacyPolicyTitle: data.data[0].title,
				})
			}
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}

	}
	scrollToTop() {
		window.scroll({ top: 0, left: 0, behavior: "smooth" })
		// var delayInMs = this.props.delayInMs ? this.props.delayInMs : this.state.delayInMs;
		// let intervalId = setInterval(this.scrollStep.bind(this), delayInMs);
		// this.setState({ intervalId: intervalId });
	}
	handleChange = (field, value) => {
		// <Redirect to="/" push={true} />
		// let history = useHistory();	
		if (field == "mobile_number")
			if (isNaN(value))
				return false
		var { contact_form } = this.state;
		switch (field) {
			case "email_address":
				var emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
				contact_form[field]["class"] = emailValid ? "" : "invalid";
				contact_form[field]["value"] = value;
				break;
			case "best_describe":
				contact_form[field]["value"] = value;
				contact_form["other_text"]["value"] = "";
				break;
			default:
				contact_form[field]["class"] = value ? "" : "invalid";
				contact_form[field]["value"] = value;
				break;
		}
		// var submit = true;
		// for (var key in contact_form) {
		// 	var data = contact_form[key];
		// 	if ((key === "iagree" && !data) ||
		// 		(data.hasOwnProperty("value") && key !== "other_text" && (data["class"] || !data["value"])) ||
		// 		(key === "other_text" && contact_form.best_describe.value === "_other_" && (data["class"] || !data["value"]))

		// 	) {
		// 		submit = false;
		// 	}
		// }
		// contact_form.submit = submit;
		this.setState({
			contact_form: contact_form,
			submissionStatus: false,
		});
	}
	validate = () => {
		const { contact_form } = this.state
		for (var key in contact_form) {
			contact_form[key].value = typeof (contact_form[key].value) == "boolean" ? contact_form[key].value : contact_form[key].value.trim();
			contact_form[key].error = "";
		}

		if (contact_form.name.value == "")
			contact_form.name.error = this.context.t("_name_required");

		if (contact_form.name.value != "" && !nameValidate.test(contact_form.name.value))
			contact_form.name.error = this.context.t("_invalid_name");

		if (contact_form.name.value != "" && nameValidate.test(contact_form.name.value) && !nameWordLengthCheck(contact_form.name.value))
			contact_form.name.error = this.context.t("_name_contain_atleast_two_words");

		if (contact_form.email_address.value == "")
			contact_form.email_address.error = this.context.t("_email_required");

		if (contact_form.email_address.value != "" && !emailValidate.test(contact_form.email_address.value))
			contact_form.email_address.error = this.context.t("_invalid_email");

		if (contact_form.mobile_number.value == "")
			contact_form.mobile_number.error = this.context.t("_mobile_number_required");

		if (contact_form.mobile_number.value != "" && !phoneValidate.test(contact_form.mobile_number.value.replace(/ /g, "")))
			contact_form.mobile_number.error = this.context.t("_invalid_mobile_number");

		if (contact_form.mobile_number.value != "")
			if (contact_form.mobile_number.value.toString().startsWith("3") || contact_form.mobile_number.value.toString().startsWith("5") || contact_form.mobile_number.value.toString().startsWith("6") || contact_form.mobile_number.value.toString().startsWith("7")) {

			} else {
				contact_form.mobile_number.error = this.context.t("_invalid_mobile_number");
			}

		if (contact_form.best_describe.value == "_other_" && contact_form.other_text.value == "")
			contact_form.best_describe.error = this.context.t("_other_description_required");

		if (!(contact_form.agree.value))
			contact_form.agree.error = this.context.t("_terms_and_condition_agree");

		for (var key in contact_form) {
			if (contact_form[key].error != "") {
				this.setState({ contact_form, submissionStatus: false })
				this.scrollToTop();
				setTimeout(() => {
					this[key + "error"].focus()
				}, 1000);
				return false;
			}
		}
		this.setState({ contact_form })
		return true;

	}
	onSubmit = (event) => {
		// const data = new FormData(event.target);
		event.preventDefault();
		if (this.validate()) {
			const that = this;
			that.props.showLoader();
			var self = this;
			const { contact_form, config } = this.state;
			var submit_data = {};
			submit_data["terms_of_service"] = "1";
			submit_data["best_describe_other"] = "";
			submit_data["webform_id"] = "subscribe";
			for (var key in contact_form) {
				var data = contact_form[key];
				if (key === "other_text") {
					submit_data["best_describe_other"] = data.value;
				}
				else if (data.hasOwnProperty("value")) {
					submit_data[key] = data.value;
				}
			}

			axios.get(config.origin + "session/token/", {
				params: {
					_format: "json"
				}
			})
				.then(function (response) {
					var headers = {};
					if (config.environment === "production") {
						headers = {
							"Content-Type": "application/json",
							"Access-Control-Allow-Origin": "*",
							"Accept": "application/json",
							"X-CSRF-Token": response.data
						};
					}
					axios.post(config.origin + "webform_rest/submit?_format=json", submit_data, {
						headers: headers
					})
						.then(function (response) {
							that.props.hideLoader()
							self.props.history.push("/thankyou");
						})
						.catch(function (error) {
							that.props.hideLoader()
							console.warn(error);
						});

				})
				.catch(function (error) {
					that.props.hideLoader()
					console.warn(error);
				});

		}


		// this.props.history.push("/")

	}

	handleCloseModal = () => {
		let elems = document.querySelector("#modal1");
		elems.removeAttribute("aria-hidden")
		elems.removeAttribute("tabindex")
		let instance = M.Modal.getInstance(elems);
		instance.close();
	}
	handleOpenModal = () => {
		let elems = document.querySelector("#modal1");
		elems.setAttribute("aria-hidden", "false")
		elems.setAttribute("tabindex", "-1")
		let instance = M.Modal.getInstance(elems);
		instance.open();
	}
	render() {
		const topLineImage = require("../../assets/images/top-line.png");
		const { contact_form, privacyPolicyBody, privacyPolicyTitle, submissionStatus } = this.state;
		const { lang } = this.props;
		let hasError = false;
		Object.keys(contact_form).map((item, index) => {
			if (contact_form[item].error != "")
				hasError = true
		})
		return (
			<div id="main-content">
				<SlideToTop />
				<PageTitle title={this.context.t("_subscribe")} isDashboardItem={true} />
				<div className="container">
					<nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
						<ul>
							<li className="breadcrumb-item">
								<Link to="/">{this.context.t("_home")}</Link>  {" "}

							</li>
							<li className="breadcrumb-item">
								<Link tabIndex="0" to aria-current={this.context.t("_page")}>
									<span>{this.context.t("_subscribe")}</span>
								</Link>
							</li>
						</ul>
					</nav>
				</div>
				<div id="main-container">
					<div id="skipContent" className="container">
						<div className="row col-2">
							<div className="item subscribe-form">
								<h2 style={{ marginBottom: 10 }}>{this.context.t("_subscribe")}</h2>
								<p>{this.context.t("_subscribe_heading_2")}</p>
								{
									(hasError && !submissionStatus) ? <div className="error-container fail">
										<h5>{this.context.t("_error_title")}</h5>
										<ul aria-label="error" >
											{
												Object.keys(contact_form).map((item, index) => {
													return contact_form[item].error != "" ? <li key={index} ><a ref={(refItem) => { this[item + "error"] = refItem; }} tabIndex="0" href={`#${item}`} className="error" htmlFor={item}>{contact_form[item].error}</a></li>
														:
														null
												})}
										</ul>
									</div> : null

								}
								<form name="subscribe" onSubmit={this.onSubmit} className="subscribe-form">
									<div className="row col-2">
										<div className="input-field item">
											<input name="name"
												placeholder={this.context.t("_name")}
												onChange={evt => this.handleChange("name", evt.target.value)}
												onFocus={evt => this.handleChange("name", evt.target.value)}
												value={contact_form.name.value}
												id="name"
												type="text"
												for="name"
											// className={"validate " + contact_form.name.class}
											// required
											/>
											<label for="name" style={dynamicStyles.position(lang)} htmlFor="name">{this.context.t("_name")}</label>
											<span className="helper-text" data-error="Required field."></span>
										</div>
										<div className="input-field item">
											<input name="email_address"
												placeholder={this.context.t("_email")}
												onChange={evt => this.handleChange("email_address", evt.target.value)}
												onFocus={evt => this.handleChange("email_address", evt.target.value)}
												id="email_address"
												for="email_address"
												type="email"
												value={contact_form.email_address.value}
											// className={"validate " + contact_form.email_address.class}
											// required
											/>
											<label for="email_address" style={dynamicStyles.position(lang)} htmlFor="email">{this.context.t("_email")}</label>
											<span className="helper-text" data-error="Please enter a valid email address"></span>
										</div>
									</div>
									<div className="row col-2">
										<div className="input-field item">
											<input name="mobile_number"
												placeholder={this.context.t("_mobile_number")}
												onChange={evt => this.handleChange("mobile_number", evt.target.value)}
												onFocus={evt => this.handleChange("mobile_number", evt.target.value)}
												id="mobile_number"
												for="mobile_number"
												type="text"
												value={contact_form.mobile_number.value}
												maxLength={8}
											// className={"validate " + contact_form.mobile_number.class}
											// required
											/>
											<label for="mobile_number" style={dynamicStyles.position(lang)} htmlFor="mobile_number">{this.context.t("_mobile_number")}</label>
											<span className="helper-text" data-error="Required field"></span>
										</div>
									</div>
									<div className="form-check">
										<div className="text-item">
											<h4 id="describe-title">{this.context.t("_describe_your_self")}</h4>
										</div>


										<div className="input-field  subscribe-items">
											<div style={{ margin: 0 }}>
												<div className="input-radio valign-wrapper" role="radiogroup" aria-labelledby={this.context.t("_describe_your_self")}>
													<div>
														<input
															aria-checked={contact_form.best_describe.value === "schoolrepresent"}
															name="schoolrepresent"
															id="schoolrepresent"
															aria-labelledby="describe-title sub-rep"
															type="radio"
															readOnly
															className="radio-btn"
															onClick={evt =>
																this.handleChange("best_describe", "schoolrepresent")
															}
															checked={contact_form.best_describe.value === "schoolrepresent"}
														/>
														<label id='sub-rep' htmlFor="schoolrepresent" className="custom-radio-style">
															<span className="custom-radio-style">{this.context.t("_school_representative")}</span>
														</label>
													</div>
												</div>
												<div style={{ margin: 0 }}>
													<div>
														<input
															aria-checked={contact_form.best_describe.value === "Parents"}
															name="Parents"
															id="Parents"
															aria-labelledby="describe-title sub-parent"
															type="radio"
															readOnly
															className="radio-btn"
															onClick={evt =>
																this.handleChange("best_describe", "Parents")
															}
															checked={contact_form.best_describe.value === "Parents"}
														/>
														<label id='sub-parent' htmlFor="Parents" className="custom-radio-style">
															<span className="custom-radio-style">{this.context.t("_parents_1")}</span>
														</label>
													</div>
												</div>
												<div style={{ margin: 0 }}>
													<div>
														<input
															aria-checked={contact_form.best_describe.value === "Student"}
															name="Student"
															id="Student"
															aria-labelledby="describe-title sub-student"
															type="radio"
															readOnly
															className="radio-btn"
															onClick={evt =>
																this.handleChange("best_describe", "Student")
															}
															checked={contact_form.best_describe.value === "Student"}
														/>
														<label id='sub-student' htmlFor="Student" className="custom-radio-style">
															<span className="custom-radio-style">{this.context.t("_student718")}</span>
														</label>
													</div>
												</div>
												<div style={{ margin: 0 }}>
													<div>
														<input
															aria-checked={contact_form.best_describe.value === "_other_"}
															name="_other_"
															id="_other_"
															aria-labelledby="describe-title sub-notlist"
															type="radio"
															readOnly
															className="radio-btn"
															onClick={evt =>
																this.handleChange("best_describe", "_other_")
															}
															checked={contact_form.best_describe.value === "_other_"}
														/>
														<label id='sub-notlist' htmlFor="_other_" className="custom-radio-style">
															<span className="custom-radio-style">{this.context.t("_not_in_list")}</span>
														</label>
													</div>
												</div>
											</div>

										</div>




										{contact_form.best_describe.value === "_other_" &&
											<div id="other-field" className="input-field item">
												<input name="other_text"
													id="not-list" aria-labelledby="describe-title dec-notlist"
													onChange={evt => this.handleChange("other_text", evt.target.value)}
													onFocus={evt => this.handleChange("other_text", evt.target.value)}
													id="other_text"
													placeholder={this.context.t("_not_in_list")}
													type="text"
												// className={"validate " + contact_form.other_text.class}
												// required
												/>
												<span className="helper-text" data-error="Required field"></span>
											</div>
										}
										<input type="hidden" defaultValue={contact_form.webform_id} name="webform_id" />
										<div className="input-check subscribe-items checkboxNew">
											<div className="item">
												<p>
													<input type="checkbox"
														name="agree"
														id="agree"
														tabIndex="0"
														aria-label={`${this.context.t("_i_agree_1")} ${this.context.t("_privacy_policy")}`}
														style={dynamicStyles.textAlign(lang)}
														// ref={(input) => { this[name] = input; }}
														onChange={event => this.handleChange("agree", event.target.checked)}
														checked={contact_form.agree.value}
													/>
													<label for='agree' id="desc-agree">
														<span className={lang}>{this.context.t("_i_agree")} <u><a className="modal-trigger" onClick={() => this.handleOpenModal()}>{this.context.t("_privacy_policy")}</a></u></span>
													</label>
												</p>
											</div>
										</div>
									</div>

									<div className="btn-wrap">
										<button className="btn blue-btn  subscribe" style={{ margin: "0 0 5px 0", textTransform: "uppercase" }}>
											{this.context.t("_subscribe")} <i className={`material-icons ${lang}`}>{`${lang == "en" ? "arrow_forward" : "arrow_backward"}`}</i>
										</button>
									</div>
									<div id="modal1" className="modal" role="dialog">
										<div className="modal-content">
											<span className="badge modalClose"><button type="button" aria-label="close" tabindex="0" className="close" onClick={() => this.handleCloseModal()}><i className="material-icons">close</i></button></span>
											<div><h3 dangerouslySetInnerHTML={{ __html: privacyPolicyTitle }}></h3></div>
											<div dangerouslySetInnerHTML={{ __html: privacyPolicyBody }}>
											</div>
										</div>
										<div className="modal-footer">
											<a href="#!" className="modal-close waves-effect waves-green btn-flat">{this.context.t("_ok")}</a>
										</div>
									</div>
								</form>
							</div>
							<div className="item">
								<img src={subscribeRightImage} alt="" />
							</div>
						</div>
					</div>
				</div>
				<div className="top-line ng-scope">
					<img src={topLineImage} alt="" />
				</div>
			</div>
		)
	}
}

const mapStateToProps = ({ i18nState }) => {
	return {
		lang: i18nState.lang
	}
}
const mapDispatchToProps = {
	showLoader: LoadingAction.showLoader,
	hideLoader: LoadingAction.hideLoader,

}
Subscribe.contextTypes = {
	t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(Subscribe);
