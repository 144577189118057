import React from "react"
import Main from "../main"
import SlideToTop from "../includes/slide_to_top.js"
import PageTitle from "../includes/page_title.js"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { dynamicStyles } from "./../../constants/dynamicStyles"
import { Link } from "react-router-dom"
import _ from "lodash"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { LoadingAction, LoginAction } from "./../../actions"
import { passwordValidation } from "./../../constants/common"

class ResetPassword extends Main {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			config: this.config(),
			formData: {},
			error: "",
			intervalId: 0,
			scrollStepInPx: 50,
			delayInMs: 16.66,
			submissionStatus: false,
			isLoading: true,
			isLinkValid: false,
			showPassword: false,
			showConfirmPassword: false,
		}

		this.props.showLoader()
		this.handleChange = this.handleChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}
	async componentDidMount() {
		document.title = `${this.context.t("_studio5")} | ${this.context.t("_reset_password")}`
		// const { match: { params } } = this.props
		// const { lang } = this.props;
		// if (lang != params.lang) {
		// 	localStorage.setItem("lang", lang == "en" ? "ar" : "en");
		// 	window.location.reload();
		// }
		this.isLoggedInUsers();
		this.initState();
		this.checkTokenValidity()
	}
	componentDidUpdate() {
		document.title = `${this.context.t("_studio5")} | ${this.context.t("_reset_password")}`
	}
	checkTokenValidity = async () => {
		const { match: { params } } = this.props
		const { config } = this.state;
		const obj = {
			"url_token": params.token
		}
		const url = `${config.api_url}${urlEndPoints.linkValidity}`
		try {
			const data = await this.networkRequest(url, "POST", obj, null, true)
			this.props.hideLoader()
			if (data.status == 200 && data.data.valid) {
				this.setState({
					isLoading: false,
					isLinkValid: true
				})
			} else {
				this.setState({
					isLoading: false,
					isLinkValid: false
				})
			}
			// if (data.data.error) {
			// 	this.props.hideLoader();
			// 	this.displayError(data.data.error)
			// }
			// else {
			// 	this.setState({ submissionStatus: true })
			// 	this.initState();
			// 	this.scrollToTop();
			// 	this.props.hideLoader();
			// }
		} catch (error) {
			this.props.hideLoader();
			console.warn("error: ", error)
		}
	}
	initState = () => {
		this.setState({
			formData: {
				password: { value: "", error: "", class: "" },
				cpassword: { value: "", error: "", class: "" },
			}
		})
	}
	handleChange = (field, value) => {
		const { formData } = this.state;
		formData[field]["class"] = value ? "" : "invalid";
		formData[field]["value"] = value;
		// contact_form.submit = submit;
		this.setState({ formData, submissionStatus: false });
	}
	scrollStep() {
		var scrollStepInPx = this.state.scrollStepInPx;
		if (window.pageYOffset <= 400) {
			clearInterval(this.state.intervalId);
		}
		window.scroll(0, window.pageYOffset - scrollStepInPx);
	}
	scrollToTop() {
		window.scroll({ top: 0, left: 0, behavior: "smooth" })
		// var delayInMs = this.props.delayInMs ? this.props.delayInMs : this.state.delayInMs;
		// let intervalId = setInterval(this.scrollStep.bind(this), delayInMs);
		// this.setState({ intervalId: intervalId });
	}
	validate = () => {
		const { formData } = this.state;
		for (var key1 in formData) {
			formData[key1].value = formData[key1].value.trim();
			formData[key1].error = "";
		}

		if (formData.password.value === "")
			formData.password.error = this.context.t("_password_is_required");

		if (formData.password.value != "" && formData.password.value.length < 8 && !passwordValidation.test(formData.password.value))
			formData.password.error = this.context.t("_password_condition");

		if (formData.cpassword.value === "")
			formData.cpassword.error = this.context.t("_confirm_password_required");

		if ((formData.password.value !== "" && formData.cpassword.value !== "") && (formData.password.value !== formData.cpassword.value))
			formData.password.error = this.context.t("_password_mismatch");


		for (var key in formData) {
			if (formData[key].error !== "") {
				this.setState({ formData, submissionStatus: false })
				setTimeout(() => {
					// this.refs.errorListRef.focus()
					this[key + "error"].focus()
				}, 1000);
				return false;

			}
		}
		return true;
	}
	onSubmit = async (event) => {
		event.preventDefault();
		const { formData, config } = this.state;
		const { match: { params } } = this.props
		if (this.validate()) {
			this.props.showLoader();
			const obj = {
				"url_token": params.token,
				"pass": formData.password.value
			}
			const url = `${config.api_url}${urlEndPoints.resetPassword}`
			try {
				const data = await this.networkRequest(url, "POST", obj, null, true)
				if (data.data.error) {
					this.props.hideLoader();
					this.displayError(data.data.error)
				}
				else {
					this.setState({ submissionStatus: true })
					this.handleAuthAPi(data.data.email, formData.password.value)
					// this.scrollToTop();
					// this.props.hideLoader();
					// this.props.history.push("/login?type=relogin");
				}
			} catch (error) {
				this.props.hideLoader();
				console.warn("error: ", error)
			}
		}
	}
	handleGoBack = () => {
		this.props.history.push("/forgot-password");
	}
	handleShowPassword = stateValue => {
		this.setState({ [stateValue]: !this.state[stateValue] })
	}

	handleAuthAPi = async (username, password) => {
		// event.preventDefault();
		const { config } = this.state;
		this.props.showLoader();
		if (this.validate()) {
			const obj = {
				"username": username.trim().replace(/[\u200B-\u200D\uFEFF]/g, ""),
				"password": password,
				"client_secret": config.client_secret,
				"grant_type": config.grant_type,
				"client_id": config.client_id
			}
			const authUrl = `${config.origin}${urlEndPoints.auth}`
			this.authRequest(authUrl, "POST", obj).then(authResponse => {
				if (!_.has(authResponse, "error")) {
					localStorage.setItem("accessToken", authResponse.data.access_token);
					localStorage.setItem("refreshToken", authResponse.data.refresh_token);
					localStorage.setItem("tokenType", authResponse.data.token_type);
					this.loginApi(authResponse)
					this.initState()
					// this.loginForm.reset();
				} else {
					this.props.hideLoader();
					const errorResponse = authResponse.data.message;
					this.setState({
						"success": "",
						"error": errorResponse
					});
				}
			}).catch(errorResponse => {
				console.warn(JSON.parse(JSON.stringify(errorResponse)))
				this.props.hideLoader();
				this.setState({
					"success": "",
					"error": this.context.t("_invalid_credentials")
				});
			})

		} else {
			this.props.hideLoader();
		}
	}
	loginApi = async (authResponse) => {
		const { config } = this.state;
		const { lang } = this.props;
		const loginUrl = `${config.api_url}${urlEndPoints.login}`
		// const loginHeader = {
		// 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
		// }

		try {
			const loginResponse = await this.networkRequest(loginUrl, "POST")
			this.getProfileData();
			this.props.hideLoader();
			if (loginResponse.data.success && loginResponse.data.status === 200) {
				localStorage.setItem("role", loginResponse.data.role);
				this.props.userLoggedIn();
				if (
					loginResponse.data.role == "trainer" ||
					loginResponse.data.role == "manager" ||
					loginResponse.data.role == "asistant" ||
					loginResponse.data.role == "edu_specialist" ||
					localStorage.getItem("role") == "administrator"
				) {
					// this.props.history.push("/admin-profile");
					window.location.href = `/${lang}/admin-profile`;
				} else {
					// this.props.history.push("/activities");
					window.location.href = `/${lang}/activities`;
				}
			}
		} catch (error) {
			this.props.hideLoader();
			console.warn("loginResponse  error: ", JSON.parse(JSON.stringify(error)))
		}

	}
	getProfileData = async () => {
		// const authResponse = await this.auth();
		// if (authResponse) {
		const { config } = this.state
		const url = `${config.api_url}${urlEndPoints.userDetails}`
		// const headers = {
		//     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
		// }
		try {
			const data = await this.networkRequest(url)
			localStorage.setItem("profileImage", `${config.origin}${data.data.picture}`)
			this.props.userLoggedIn(`${config.origin}${data.data.picture}`)
			// this.props.hideLoader()
			// this.setState({ profileData: data.data })
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
		// }
	}
	render() {
		const { formData, error, submissionStatus, isLinkValid, isLoading, showPassword, showConfirmPassword } = this.state;
		const { lang } = this.props;
		let hasError = false;
		Object.keys(formData).forEach((item, index) => {
			if (formData[item].error !== "")
				hasError = true
		})
		return (
			<div id="main-content">
				<SlideToTop />
				<PageTitle title={this.context.t("_reset_password")} />
				<div className="container">
					<nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
						<ul>
							<li className="breadcrumb-item">
								<Link to="/">{this.context.t("_home")} </Link> {" "}
							</li>
							<li className="breadcrumb-item">
								<Link tabIndex="0" to aria-current={this.context.t("_page")}>
									<span>{this.context.t("_reset_password")}</span>
								</Link>
							</li>
						</ul>
					</nav>
				</div>
				<div id="skipContent">
					<div id="main-container">
						{
							!isLoading ? isLinkValid ?
								<div className="container "><div className="row col-8">
									{
										_.isEmpty(formData) ?
											<div /> :
											<form name="login" onSubmit={this.onSubmit} className="login-form">
												{error &&
													<div className="error-container fail">
														<ul>
															<li><label className="error" >{error}</label></li>
														</ul>
													</div>
												}
												{
													hasError ? <div className="error-container fail">
														<h5>{this.context.t("_error_title")}</h5>
														<ul>
															{
																Object.keys(formData).map((item, index) => {
																	return formData[item].error !== "" ? <li key={index}><a href={`#${item}`} ref={(refItem) => { this[item + "error"] = refItem; }} className="error" htmlFor={item}>{formData[item].error}</a></li>
																		:
																		null
																})}
														</ul>
													</div> : null

												}
												{
													submissionStatus ? <div className="error-container success">
														<h5>{this.context.t("_reset_password_success")}</h5>
													</div> : null
												}
												<div className="input-field item">
													<input name="password"
														aria-describedby="passinfo"
														className="fontEnglish"
														placeholder={this.context.t("_password")}
														onChange={evt => this.handleChange("password", evt.target.value)}
														onFocus={evt => this.handleChange("password", evt.target.value)}
														value={formData.password.value}
														id="password"
														type={showPassword ? "text" : "password"}
													/>
													<button onClick={() => { this.handleShowPassword("showPassword") }} type="button" aria-label={showPassword ? this.context.t("_hide_password") : this.context.t("_show_password")} className="showEyes">
														<i className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i>
													</button>
													<label htmlFor="password" style={dynamicStyles.position(lang)}>{this.context.t("_password")}</label>
													<small id="passinfo" className="passinfo">{this.context.t("_password_condition")}</small>
													<span className="helper-text" data-error="Required field."></span>
												</div>
												<div className="input-field item">
													<input name="cpassword"
														className="fontEnglish"
														placeholder={this.context.t("_confirm_password")}
														onChange={evt => this.handleChange("cpassword", evt.target.value)}
														onFocus={evt => this.handleChange("cpassword", evt.target.value)}
														value={formData.cpassword.value}
														id="cpassword"
														type={showConfirmPassword ? "text" : "password"}
													/>
													<button onClick={() => { this.handleShowPassword("showConfirmPassword") }} type="button" aria-label={showPassword ? this.context.t("_hide_password") : this.context.t("_show_password")} className="showEyes">
														<i className={showConfirmPassword ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i>
													</button>
													<label htmlFor="cpassword" style={dynamicStyles.position(lang)}>{this.context.t("_confirm_password")}</label>
													<span className="helper-text" data-error="Required field."></span>
												</div>

												<div className="btn-wrap reset-button-container">
													<button className="btn red login" >
														{this.context.t("_reset_password")} <i className={`material-icons ${lang}`}>{`${lang === "en" ? "arrow_forward" : "arrow_backward"}`}</i>
													</button>
												</div>
											</form>
									}
								</div>

								</div>
								:
								<div className="page-not-found center">
									<h4>{this.context.t("_invalid_token")}</h4>
									<button onClick={() => this.handleGoBack()} className="btn profileBtn">{this.context.t("_go_forgot_password")}</button>
								</div>
								: null
						}
					</div>
				</div>
			</div>
		)
	}
}
const mapStateToProps = ({ i18nState }) => {
	return {
		lang: i18nState.lang
	}
}
const mapDispatchToProps = {
	showLoader: LoadingAction.showLoader,
	hideLoader: LoadingAction.hideLoader,
	userLoggedIn: LoginAction.userLoggedIn,

}
ResetPassword.contextTypes = {
	t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
