import React from "react"
import Main from "../main"
import SlideToTop from "../includes/slide_to_top.js"
import PageTitle from "../includes/page_title.js"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { dynamicStyles } from "./../../constants/dynamicStyles"
import { Link } from "react-router-dom"
import _ from "lodash"
import { PopupAction, LoadingAction, LoginAction } from "./../../actions"
import { urlEndPoints } from "./../../httpAPI/apiConfig"

class Login extends Main {
	constructor(props) {
		super(props);
		this.state = {
			config: this.config(),
			loginForm: {},
			error: "",
			showPassword: false
		}
		// this.handleChange = this.handleChange.bind(this);
		// this.onSubmit = this.onSubmit.bind(this);
		this.props.hideLoader();
	}
	componentDidMount() {
		document.title = `${this.context.t("_studio5")} | ${this.context.t("_login")}`
		this.isLoggedInUsers();
		if (_.isEmpty(localStorage.getItem("refreshToken")) || _.isEmpty(localStorage.getItem("accessToken"))) {
			this.props.userLogout();
		}
		this.initState();
		setTimeout(() => {
			document.getElementById("pageHeading") && document.getElementById("pageHeading").focus()
		}, 500);
	}
	componentDidUpdate() {
		document.title = `${this.context.t("_studio5")} | ${this.context.t("_login")}`
	}
	initState = () => {
		this.setState({
			loginForm: {
				username: { value: "", error: "", class: "" },
				password: { value: "", error: "", class: "" },
			}
		})

	}
	handleChange = (field, value) => {
		const { loginForm } = this.state;
		loginForm[field]["class"] = value ? "" : "invalid";
		loginForm[field]["value"] = value;
		// contact_form.submit = submit;
		this.setState({ loginForm: loginForm });
	}
	validate = () => {
		const { loginForm } = this.state;
		for (var key1 in loginForm) {
			loginForm[key1].error = "";
		}

		if (_.isEmpty(loginForm.username.value))
			loginForm.username.error = this.context.t("_email_required");

		if (_.isEmpty(loginForm.password.value))
			loginForm.password.error = this.context.t("_password_is_required");

		for (var key in loginForm) {
			if (loginForm[key].error !== "") {
				this.setState({ loginForm })
				setTimeout(() => {
					// this.refs.errorListRef.focus()
					this[key + "error"].focus()
				}, 1000);
				return false;
			}
		}
		return true;
	}
	onSubmit = async (event) => {
		event.stopPropagation();
		event.preventDefault();
		const { loginForm, config } = this.state;
		this.props.showLoader();
		if (this.validate()) {
			const obj = {
				"username": loginForm.username.value.trim().replace(/[\u200B-\u200D\uFEFF]/g, ""),
				"password": loginForm.password.value,
				"client_secret": config.client_secret,
				"grant_type": config.grant_type,
				"client_id": config.client_id
			}
			const authUrl = `${config.origin}${urlEndPoints.auth}`
			this.authRequest(authUrl, "POST", obj).then(authResponse => {
				if (!_.has(authResponse, "error")) {
					localStorage.setItem("accessToken", authResponse.data.access_token);
					localStorage.setItem("refreshToken", authResponse.data.refresh_token);
					localStorage.setItem("tokenType", authResponse.data.token_type);
					this.loginApi(authResponse)
					this.initState()
					this.loginForm.reset();
				} else {
					this.props.hideLoader();
					const errorResponse = authResponse.data.message;
					this.setState({
						"success": "",
						"error": errorResponse
					});
					this.refs.loginError && this.refs.loginError.focus()
				}
			}).catch(errorResponse => {
				console.warn(JSON.parse(JSON.stringify(errorResponse)))
				this.props.hideLoader();
				this.setState({
					"success": "",
					"error": this.context.t("_invalid_credentials")
				});
				this.refs.loginError && this.refs.loginError.focus()
			})

		} else {
			this.props.hideLoader();
		}
	}
	loginApi = async (authResponse) => {
		const { config } = this.state;
		const { lang } = this.props;
		const loginUrl = `${config.api_url}${urlEndPoints.login}`
		// const loginHeader = {
		// 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
		// }

		try {
			const loginResponse = await this.networkRequest(loginUrl, "POST")
			await this.getProfileData();
			this.props.hideLoader();
			if (loginResponse.data.success && loginResponse.data.status === 200) {
				localStorage.setItem("role", loginResponse.data.role);
				this.props.userLoggedIn();
				if (
					loginResponse.data.role == "trainer" ||
					loginResponse.data.role == "asistant" ||
					loginResponse.data.role == "edu_specialist"
				) {
					this.props.history.push("/admin-profile");
				} else if (loginResponse.data.role == "manager" || loginResponse.data.role == "administrator") {
					// this.props.history.push("/admin-profile");
					window.location.href = `/${lang}/admin-profile`;
					// this.props.history.push("/dashboard/student");
					// } else if (loginResponse.data.role == "parent") {
					// 	this.props.history.push("/parent-profile");
				} else {
					// this.props.history.push("/activities");
					window.location.href = `/${lang}/activities`;
				}
				setTimeout(() => {
					const bannerButton = document.querySelector(".navigation-profile");
					if (bannerButton && bannerButton.addEventListener) {
						bannerButton.addEventListener('click', () => {
							const bannerElement = document.querySelectorAll(".navigation-profile");
							bannerElement[0].classList.toggle('hover');
						});
						bannerButton.addEventListener('keypress', () => {
							const bannerElement = document.querySelectorAll(".navigation-profile");
							bannerElement[0].classList.toggle('hover');
						});
						bannerButton.addEventListener('focusIn', () => {
							const bannerElement = document.querySelectorAll(".navigation-profile");
							bannerElement[0].classList.add('hover');
						});
						bannerButton.addEventListener('focusOut', () => {
							const bannerElement = document.querySelectorAll(".navigation-profile");
							bannerElement[0].classList.remove('hover');
						});

					}
				}, 1000);
			}
		} catch (error) {
			this.props.hideLoader();
			console.warn("loginResponse  error: ", error)
		}

	}
	handleShowPassword = stateValue => {
		this.setState({ [stateValue]: !this.state[stateValue] })
	}
	getProfileData = async () => {
		// const authResponse = await this.auth();
		// if (authResponse) {
		const { config } = this.state
		const url = `${config.api_url}${urlEndPoints.userDetails}`
		// const headers = {
		//     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
		// }
		try {
			const data = await this.networkRequest(url)
			localStorage.setItem("profileImage", `${config.origin}${data.data.picture}`)
			this.props.userLoggedIn(`${config.origin}${data.data.picture}`)
			// this.props.hideLoader()
			// this.setState({ profileData: data.data })
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
		// }
	}
	render() {
		const { loginForm, error, showPassword } = this.state;
		const { lang } = this.props;
		const { location: { search } } = this.props;
		let hasError = false;
		Object.keys(loginForm).forEach((item, index) => {
			if (loginForm[item].error !== "")
				hasError = true
		})
		return (
			<div id="main-content">
				<SlideToTop />
				<PageTitle title={this.context.t("_login")} />
				<div id="skipContent">
					<div id="main-container">
						<ColouredItems />
						<div className="container">
							<br /><br />
							<div className="center"></div>
							<div className="row col-8">
								{
									_.isEmpty(loginForm) ?
										<div />
										: <form name="login" onSubmit={this.onSubmit} className="login-form loginForm" autoComplete="on" ref={(el) => this.loginForm = el}>
											{
											/* {
									Object.keys(loginForm).map((item, index) => {
										return loginForm[item].error !== "" ?
											<p key={index} ref={(refItem) => { this[item + "error"] = refItem; }}>
												<span className="asterisk">*</span>
												<label className="error" htmlFor={item}>{loginForm[item].error}</label>
											</p>
											: null
									})
								} */}
											<h2>{this.context.t("_login")}</h2>


											{
												search == "?type=relogin" && <div className="error-container success">
													<h5>{this.context.t("_password_set_successfully")}</h5>
												</div>
											}
											{error &&
												<div className="error-container fail">
													<ul>
														<li ref="loginError" tabIndex="0" aria-label={error}><label className="error" >{error}</label></li>
													</ul>
												</div>
											}
											{
												hasError ? <div className="error-container fail">
													<h5>{this.context.t("_error_title")}</h5>
													<ul>
														{
															Object.keys(loginForm).map((item, index) => {
																return loginForm[item].error !== "" ? <li key={index}><a tabIndex="0" className="error" href={`#${item}`} ref={(refItem) => { this[item + "error"] = refItem; }} htmlFor={item}>{loginForm[item].error}</a></li>
																	:
																	null
															})}
													</ul>
												</div> : null

											}
											<div className="input-field item">
												<input name="username"
													placeholder={this.context.t("_email_placeholder")}
													onChange={evt => this.handleChange("username", evt.target.value)}
													value={loginForm.username.value}
													autoComplete="no"
													className="fontEnglish"
													id="username"
													name="username"
													type="text"
												/>
												<label htmlFor="username" style={dynamicStyles.position(lang)}>{this.context.t("_email")}</label>
												<span className="helper-text" data-error="Required field."></span>
											</div>
											<div className="input-field item">
												<input name="password"
													autoComplete="no"
													placeholder={this.context.t("_password_placeholder")}
													onChange={evt => this.handleChange("password", evt.target.value)}
													value={loginForm.password.value}
													id="password"
													name="password"
													className="fontEnglish"
													type={showPassword ? "text" : "password"}
												/>
												<button onClick={() => { this.handleShowPassword("showPassword") }} type="button" aria-label={showPassword ? this.context.t("_hide_password") : this.context.t("_show_password")} className="showEyes">
													<i className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i>
													<span>show password</span>
												</button>
												<label htmlFor="password" style={dynamicStyles.position(lang)}>{this.context.t("_password")}</label>
												<span className="helper-text" data-error="Required field."></span>
											</div>
											<div className={`${lang == "ar" ? "right" : "left"}-align`}>
												<Link className="underline-text" to="/forgot-password"><b>{this.context.t("_forget_password")}</b></Link>
											</div>

											<div className={`${lang == "ar" ? "right" : "left"}-align btn-wrap`}>
												<button aria-label="login" className="btn blue login">{this.context.t("_login")} <i className={`material-icons ${lang}`}>{`${lang === "en" ? "arrow_forward" : "arrow_backward"}`}</i></button>
											</div>
											<div className={`${lang == "ar" ? "right" : "left"}-align`}>
												{this.context.t("_dont_have")} <Link className="underline-text" to="/registration"><b>{this.context.t("_sign_up")}</b></Link>
											</div>
											{/* <div className="btn-wrap">
											<button className="btn red login" >
												{this.context.t("_login")} <i className={`material-icons ${lang}`}>{`${lang === "en" ? "arrow_forward" : "arrow_backward"}`}</i>
											</button>
										</div>
										<div className="login-bottom">
											<Link to="/registration">{this.context.t("_register")}</Link>
											<Link to="/forgot-password">{this.context.t("_forgot_password")}</Link>
										</div> */}

										</form>
								}

							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = ({ i18nState }) => {
	return {
		lang: i18nState.lang
	}
}
const mapDispatchToProps = {
	showPopup: (data) => PopupAction.showPopup(data),
	hidePopup: (data) => PopupAction.hidePopup(data),
	showLoader: LoadingAction.showLoader,
	hideLoader: LoadingAction.hideLoader,
	userLoggedIn: LoginAction.userLoggedIn,
	userLogout: LoginAction.userLogout,
}
Login.contextTypes = {
	t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);

const ColouredItems = () => {
	return (
		<div className="student-color-container">
			<span className="grey-square-rotate red-sq one"></span>
			{/* <span className="grey-square-rotate red-sq two"></span> */}
			{/* <span className="grey-square-rotate red-sq three"></span> */}
			<span className="grey-square-rotate red-sq four"></span>
			<span className="orange-circle one"></span>
			{/* <span className="orange-circle two"></span> */}
			<span className="orange-circle three"></span>
			{/* <span className="orange-circle four"></span> */}
			<span className="multi-square one"><b><i></i></b></span>
			{/* <span className="multi-square two"><b><i></i></b></span> */}
			<span className="multi-square three"><b><i></i></b></span>
			{/* <span className="multi-square four"><b><i></i></b></span> */}
		</div>
	)
}