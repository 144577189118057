import React from "react";
import Main from "./../main"
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import PageTitle from "../includes/page_title";
import SlideToTop from "../includes/slide_to_top";
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import _ from "lodash"
import { LoadingAction } from "./../../actions"
import logo from "./../../assets/images/logo56.png";

class EquipmentUsed extends Main {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            config: this.config(),
            equipmentUsedList: [],
            pager: { count: 0, pages: 0, items_per_page: 0, current_page: 1 },
        }
        this.props.showLoader();
    }
    componentDidMount() {
        document.title = `${this.context.t("_studio5")} | ${this.context.t("_equipment")}`
        this.getEquipmentUsed();
    }
    componentDidUpdate() {
        document.title = `${this.context.t("_studio5")} | ${this.context.t("_equipment")}`
    }
    getEquipmentUsed = async () => {
        const { config, pager } = this.state
        const url = `${config.api_url}${urlEndPoints.getEquipmentUsed}?page=${pager.pages}`
        try {
            const data = await this.networkRequest(url)
            if (data.data.error) {

            } else {
                this.setState({
                    equipmentUsedList: data.data.results,
                    pager: data.data.pager,
                    isLoaded: true,
                })
                setTimeout(() => {
                    document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
                }, 500);
            }
            this.props.hideLoader()
        } catch (error) {
            this.setState({ isLoaded: true, })
            this.props.hideLoader()
            console.warn("error: ", error)
        }
    }
    handlePageChange = (pageNumber) => {
        this.props.showLoader()
        const { pager } = this.state;
        pager.pages = pageNumber - 1;
        this.setState({ pager }, () => this.getEquipmentUsed(true));
        // this.getActivity(true);
    }
    render() {
        const { pager, equipmentUsedList, config, isLoaded } = this.state;
        return (
            <div id="skipContent">
                <div id="main-container">
                    <SlideToTop />
                    <PageTitle title={this.context.t("_equipment")} isDashboardItem={true} />
                    <div className="container">
                        <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                            <ul>
                                <li className="breadcrumb-item">
                                    <Link to="/">{this.context.t("_home")}</Link> {" "}
                                </li>
                                <li className="breadcrumb-item">
                                    <Link tabIndex="0" to aria-current={this.context.t("_page")}>
                                        <span>{this.context.t("_equipments_used")}</span>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    <div className="container">

                        <h2 class="line">{this.context.t("_equipment")}</h2>

                        <div className="row machine-item-small-holder">
                            {isLoaded && equipmentUsedList.length == 0 ? <div className="row"><p aria-label={this.context.t("_no_result_found")} tabIndex="0" className="col s12 no-result">{this.context.t("_no_result_found")}</p></div> :
                                equipmentUsedList.map(item => {
                                    return (<div className="col m12 l6" key={item.nid}>
                                        <div className="machine-item small">
                                            <figure className="machine-item-figure small">
                                                <img src={!_.isEmpty(item.field_equipment_image) ? `${config.origin}${item.field_equipment_image}` : logo} alt="Coding 101 image" />
                                            </figure>
                                            <aside className="machine-item-content">
                                                <h5>{item.title} {!_.isEmpty(item.field_equipment_type) && <span>{item.field_equipment_type}</span>} </h5>
                                                {!_.isEmpty(item.field_sub_title) && <h4>{item.field_sub_title}</h4>}

                                                {
                                                    item.field_is_there_an_update_model_ == 1 && <div className="machine-small-btn">
                                                        <a target="_black" href={item.field_updated_model_website}>
                                                            <span>{this.context.t("_updated_model")}</span>
                                                            <em>{item.field_updated_model}</em>
                                                        </a>
                                                    </div>
                                                }

                                            </aside>
                                        </div>
                                    </div>)
                                })
                            }



                        </div>
                        <nav className="pagination-wrapper" aria-label="pagination">
                            <Pagination
                                prevPageText={`< ${this.context.t("_previous")}`}
                                nextPageText={`${this.context.t("_next")} >`}
                                hideFirstLastPages={true}
                                hideDisabled={true}
                                linkClassPrev="linkClassPrev"
                                linkClassNext="linkClassNext"
                                activePage={pager.current_page + 1}
                                itemsCountPerPage={pager.items_per_page}
                                totalItemsCount={pager.count}
                                pageRangeDisplayed={5}
                                onChange={this.handlePageChange}
                            />
                        </nav>
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ i18nState }) => {
    return {
        lang: i18nState.lang
    }
}
const mapDispatchToProps = {
    showLoader: LoadingAction.showLoader,
    hideLoader: LoadingAction.hideLoader,
}
EquipmentUsed.contextTypes = {
    t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(EquipmentUsed);
