import { GET_EVENT_TYPE, GET_SESSION_TYPE, GET_COMPETITION_TYPE } from "./../../constants/constants";

const initialState = {
    eventActivityCount: 0,
    sessionActivityCount: 0,
    competitionActivityCount: 0,
};

const typeCountReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENT_TYPE:
            return {
                ...state,
                eventActivityCount: action.payload
            };
        case GET_SESSION_TYPE:
            return {
                ...state,
                sessionActivityCount: action.payload
            };
        case GET_COMPETITION_TYPE:
            return {
                ...state,
                competitionActivityCount: action.payload
            };
        default:
            return { ...state };
    }
};

export default typeCountReducer;
