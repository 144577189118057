import React from "react";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import Main from "./../main"
import PageTitle from "../includes/page_title";
import SlideToTop from "../includes/slide_to_top";
import M from "materialize-css";

var pager = { count: 0, pages: 0, items_per_page: 0, current_page: 1 };
class Faq extends Main {
    constructor(props) {
        super(props);
        this.state = {
            pager
        }
    }
    componentDidMount() {
        M.AutoInit();
    }
    render() {
        const { pager } = this.state;
        return (
            <div id="main-container">
                <SlideToTop />
                <PageTitle title={"Publications"} isDashboardItem={true} />
                {/* <div id="breadcrumb-wrap">
                    <div className="container">
                        <Link to="/">{`Home`}</Link> \{" "}
                        <span>
                        Publications
                        </span>
                    </div>
                </div> */}
                <div className="container">

                    <div className="row noFlex announcementBox">
                        <div className="col s12">
                            <p className="textBlue"><strong>Title</strong></p>
                            <div className="textHolder">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sed dui id mauris pretium tincidunt sit amet eget nisi. Nullam scelerisque tellus Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sed dui id mauris pretium tincidunt sit amet eget nisi. Nullam scelerisque tellus </p>
                            </div>
                            <a href="javascript:void(0);" className="btn profileBtn blue waves-effect waves-light">Click here</a>

                        </div>
                    </div>

                    <div className="row noFlex announcementBox">
                        <div className="col s12">
                            <p className="textBlue"><strong>Title</strong></p>
                            <div className="textHolder">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sed dui id mauris pretium tincidunt sit amet eget nisi. Nullam scelerisque tellus Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sed dui id mauris pretium tincidunt sit amet eget nisi. Nullam scelerisque tellus </p>
                            </div>
                            <a href="javascript:void(0);" className="btn profileBtn blue waves-effect waves-light">Click here</a>

                        </div>
                    </div>

                    <div className="row noFlex announcementBox">
                        <div className="col s12">
                            <p className="textBlue"><strong>Title</strong></p>
                            <div className="textHolder">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sed dui id mauris pretium tincidunt sit amet eget nisi. Nullam scelerisque tellus Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sed dui id mauris pretium tincidunt sit amet eget nisi. Nullam scelerisque tellus </p>
                            </div>
                            <a href="javascript:void(0);" className="btn profileBtn blue waves-effect waves-light">Click here</a>

                        </div>
                    </div>

                    <div className="row noFlex announcementBox">
                        <div className="col s12">
                            <p className="textBlue"><strong>Title</strong></p>
                            <div className="textHolder">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sed dui id mauris pretium tincidunt sit amet eget nisi. Nullam scelerisque tellus Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sed dui id mauris pretium tincidunt sit amet eget nisi. Nullam scelerisque tellus </p>
                            </div>
                            <a href="javascript:void(0);" className="btn profileBtn blue waves-effect waves-light">Click here</a>

                        </div>
                    </div>

                    <div className="row noFlex announcementBox">
                        <div className="col s12">
                            <p className="textBlue"><strong>Title</strong></p>
                            <div className="textHolder">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sed dui id mauris pretium tincidunt sit amet eget nisi. Nullam scelerisque tellus Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sed dui id mauris pretium tincidunt sit amet eget nisi. Nullam scelerisque tellus </p>
                            </div>
                            <a href="javascript:void(0);" className="btn profileBtn blue waves-effect waves-light">Click here</a>

                        </div>
                    </div>

                </div>
                <nav className="pagination-wrapper" aria-label="pagination">
                    <Pagination
                        prevPageText="< Previous"
                        nextPageText="Next >"
                        hideFirstLastPages={true}
                        hideDisabled={true}
                        linkClassPrev="linkClassPrev"
                        linkClassNext="linkClassNext"
                        activePage={pager.current_page + 1}
                        itemsCountPerPage={pager.items_per_page}
                        totalItemsCount={pager.count}
                        pageRangeDisplayed={5}
                    // onChange={this.handlePageChange}
                    />
                </nav>
            </div>
        );
    }
}

export default Faq;