import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash"
import Main from "../../main";
import PageTitle from "../../includes/page_title.js";
import SlideToTop from "../../includes/slide_to_top.js";
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { urlEndPoints } from "./../../../httpAPI/apiConfig"
import M from "materialize-css";
import { LoadingAction, PopupAction } from "./../../../actions"
import dateformat from "dateformat";
import Pagination from "react-js-pagination";
import topLineImage from "../../../assets/images/top-line.png"
import blankImage from "../../../assets/images/blank.png"

class StudentConnections extends Main {
    constructor(props) {
        super(props);
        this.state = {
            config: this.config(),
            profileData: {
                picture: "",
                name: ""
            },
            selectedImage: "",
            croppedImageUrl: "",
            submissionStatus: false,
            submissionStatus_1: false,
            successMessage: null,
            file: "",
            crop: {
                unit: "px",
                width: 200,
                height: 201,
                aspect: 2 / 2.1,
            },
            search: { page: 0 },
            activityList: [],
            pager: { count: 0, page: 0, items_per_page: 0, current_page: 0 },
        }
        this.handlePageChange = this.handlePageChange.bind(this);
        this.props.showLoader()
    }
    async componentDidMount() {
        document.title = `${this.context.t("_studio5")} | ${this.context.t("_attendance")}`
        this.getProfileData()
        this.getActivity()
    }
    componentDidUpdate() {
        document.title = `${this.context.t("_studio5")} | ${this.context.t("_attendance")}`
    }
    getProfileData = async () => {
        const { config } = this.state
        const role = localStorage.getItem("role")
        let url = ""
        if (role == "student") {
            url = `${config.api_url}${urlEndPoints.userDetails}`
        } else {
            const { match: { params: { id } } } = this.props;
            url = `${config.api_url}${urlEndPoints.getProfile(id)}`
        }

        try {
            const data = await this.networkRequest(url)
            localStorage.setItem("profileImage", `${config.origin}${data.data.picture}`)
            this.props.hideLoader()
            this.setState({ profileData: data.data })
            setTimeout(() => {
                document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
            }, 500);
        } catch (error) {
            this.props.hideLoader()
            console.warn("error: ", error)
        }
    }
    getActivity = async (isPagination = false) => {
        const { match: { params: { id } } } = this.props;
        const { config, pager } = this.state;
        const { lang } = this.props;
        let url = ""
        if (lang == "ar")
            url = `${config.origin}${lang}/api/${urlEndPoints.studentAttendance}`
        else
            url = `${config.origin}api/${urlEndPoints.studentAttendance}`
        // title: "", field_start_end_dates_value: "",
        const body = {
            page: pager.page
        }

        if (id)
            body.stu_id = id

        try {
            const data = await this.networkRequest(url, "POST", body)
            if (data.status == 200) {
                this.scrollToTop()
                this.setState({
                    activityList: data.data.results || [],
                    pager: data.data.pager,
                })
            }
            this.props.hideLoader()
        } catch (error) {
            this.props.hideLoader()
            console.warn("error: ", error)
        }
    }
    scrollToTop() {
        window.scroll({ top: 0, left: 0, behavior: "smooth" })
    }
    onChangeFile(event) {
        event.stopPropagation();
        event.preventDefault();
        const that = this;
        var file = event.target.files[0];
        if (file) {
            if (file.type.includes("image")) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function (e) {
                    var img = new Image;
                    img.onload = function () {

                        that.setState({ selectedImage: reader.result, file, submissionStatus: false, submissionStatus_1: false })
                        let elems = document.querySelector("#photoCrop");
                        elems.setAttribute("aria-hidden", "false")
                        elems.setAttribute("tabindex", "-1")
                        let instance = M.Modal.getInstance(elems);
                        instance.open();
                    };
                    img.src = reader.result;
                };
            } else {
                this.props.showPopup({
                    type: "error",
                    title: this.context.t("_invalid"),
                    message: this.context.t("_invalid_file_type"),
                    isOpen: true,
                    buttonText: this.context.t("_ok")
                })
            }
        }

    }
    handlePageChange(pageNumber) {
        this.props.showLoader()
        var { pager } = this.state;
        pager.page = pageNumber - 1;
        this.setState({ pager });
        this.getActivity();
    }

    getDateInFormat = date => {
        if (date) {
            date = dateformat(date, "dd mmm, yyyy")
            if (!_.isEmpty(date)) {
                if (date.includes("Jan"))
                    date = date.replace(/Jan/g, this.context.t("_jan"))

                if (date.includes("Feb"))
                    date = date.replace(/Feb/g, this.context.t("_feb"))

                if (date.includes("Mar"))
                    date = date.replace(/Mar/g, this.context.t("_mar"))

                if (date.includes("Apr"))
                    date = date.replace(/Apr/g, this.context.t("_apr"))

                if (date.includes("May"))
                    date = date.replace(/May/g, this.context.t("_may"))

                if (date.includes("Jun"))
                    date = date.replace(/Jun/g, this.context.t("_jun"))

                if (date.includes("Jul"))
                    date = date.replace(/Jul/g, this.context.t("_jul"))

                if (date.includes("Aug"))
                    date = date.replace(/Aug/g, this.context.t("_aug"))

                if (date.includes("Sep"))
                    date = date.replace(/Sep/g, this.context.t("_sep"))

                if (date.includes("Oct"))
                    date = date.replace(/Oct/g, this.context.t("_oct"))

                if (date.includes("Nov"))
                    date = date.replace(/Nov/g, this.context.t("_nov"))

                if (date.includes("Dec"))
                    date = date.replace(/Dec/g, this.context.t("_dec"))
            }
        }
        return date
    }
    render() {
        const { profileData, config, activityList, pager } = this.state;
        const { match: { params: { id } } } = this.props;
        const { lang } = this.props;
        const that = this;
        return (
            <div id="main-container">
                <SlideToTop />
                <PageTitle title={this.context.t("_attendance")} />
                <div className="container">
                    <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                        <ul>
                            <li className="breadcrumb-item">
                                <Link to={id ? "/parent-profile" : "/profile"}>{this.context.t("_profile")}</Link> {" "}

                            </li>
                            {
                                id && <li className="breadcrumb-item">
                                    <Link to={`/student-profile/${id}`}>{this.context.t("_participants")}</Link> {" "}

                                </li>
                            }
                            {/* <li className="breadcrumb-item">
                                <Link to={`/student-profile/${id}`}>{this.context.t("_student")}</Link> {" "}

                            </li> */}
                            <li className="breadcrumb-item">
                                <Link tabIndex="0" to aria-current={this.context.t("_page")}>
                                    <span>
                                        {this.context.t("_attendance")}
                                    </span>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div id="skipContent">
                    <div className="container dashboardContainer">
                        <div className="row">
                            <div className="col s12 text-center">
                                {/* <figure className="profilePic">
                                    <span>
                                        <img src={!_.isEmpty(profileData.picture) ? `${config.origin}${profileData.picture}` : "../../assets/images/profile_pic.jpg"} alt={`${profileData.name}`} />
                                    </span>
                                </figure> */}
                                <figure className="profilePic profileNew">
                                    {
                                        _.isEmpty(profileData.picture) ?
                                            <img src={blankImage} alt="" />
                                            :
                                            <>
                                                <img src={blankImage} alt="" />
                                                <span>
                                                    <img src={`${config.origin}${profileData.picture}`} alt="" />
                                                </span>
                                            </>
                                    }


                                </figure>
                                <h4 className="profileheading profileNew">{profileData.name}</h4>
                                {/* <div className="starHolder">
                                    <span className="fa fa-star checked"></span>
                                    <span className="fa fa-star checked"></span>
                                    <span className="fa fa-star checked"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                </div> */}
                            </div>

                            <div className="col s12">
                                <div className="profileStatus profileNew">
                                    <ul>
                                        <li tabIndex="0" aria-label={`${this.context.t("_challenge_count")} ${_.has(profileData, "enrollment_count.challenge.count") ? profileData.enrollment_count.challenge.count : 0}`}>
                                            <strong>
                                                {_.has(profileData, "enrollment_count.challenge.count") ? profileData.enrollment_count.challenge.count : 0}
                                            </strong>
                                            <span>{this.context.t("_challenges")}</span></li>
                                        <li tabIndex="0" aria-label={`${this.context.t("_competition_count")} ${_.has(profileData, "enrollment_count.competition.count") ? profileData.enrollment_count.competition.count : 0}`}>
                                            <strong>
                                                {_.has(profileData, "enrollment_count.competition.count") ? profileData.enrollment_count.competition.count : 0}
                                            </strong>
                                            <span>{this.context.t("_competitions")}</span></li>
                                        <li tabIndex="0" aria-label={`${this.context.t("_session_count")} ${_.has(profileData, "enrollment_count.session.count") ? profileData.enrollment_count.session.count : 0}`}>
                                            <strong>
                                                {_.has(profileData, "enrollment_count.session.count") ? profileData.enrollment_count.session.count : 0}
                                            </strong>
                                            <span>{this.context.t("_sessions")}</span></li>
                                        <li tabIndex="0" aria-label={`${this.context.t("_event_count")} ${_.has(profileData, "enrollment_count.event.count") ? profileData.enrollment_count.event.count : 0}`}>
                                            <strong>
                                                {_.has(profileData, "enrollment_count.event.count") ? profileData.enrollment_count.event.count : 0}
                                            </strong>
                                            <span>{this.context.t("_events")}</span></li>
                                        <li tabIndex="0" aria-label={`${this.context.t("_project_count")} ${_.has(profileData, "enrollment_count.project.count") ? profileData.enrollment_count.project.count : 0}`}>
                                            <strong>
                                                {_.has(profileData, "enrollment_count.project.count") ? profileData.enrollment_count.project.count : 0}
                                            </strong>
                                            <span>{this.context.t("_projects")}</span></li>
                                    </ul>
                                </div>

                                <div className="attendance-holder">
                                    {activityList.length > 0 &&
                                        activityList.map(item => {
                                            return (
                                                <div key={item.nid} className="attendance-item" tabIndex="0">
                                                    <figure>
                                                        <img src={item.image} alt="Coding 101 image" />
                                                    </figure>

                                                    <aside>
                                                        <h4>
                                                            <i className="topHeadNA"></i>{item.title}
                                                            <span>{item.event_type}</span>
                                                        </h4>
                                                        <div className="details"><span><i className="calendar-icons"></i> {this.context.t("_start_date")} <span className="date">{this.getDateInFormat(Object.keys(item.attendence)[0])}</span></span><span><i className="calendar-icons"></i> {this.context.t("_end_date")} <span className="date">{this.getDateInFormat(Object.keys(item.attendence)[Object.keys(item.attendence).length - 1])}</span></span></div>
                                                    </aside>

                                                    <div className="attendance-day-holder">
                                                        <h5>{this.context.t("_attendance")}</h5>
                                                        {
                                                            Object.keys(item.attendence).map(function (key, index) {
                                                                return (
                                                                    <div key={index} className={`attendance-day ${item.attendence[key] == 0 ? "absent" : ""}`}>
                                                                        <span>
                                                                            {dateformat(key, "dd")}
                                                                            <small>{dateformat(key, "mmm yyyy")}</small>
                                                                        </span>
                                                                        <em>{item.attendence[key] == 0 ? that.context.t("_absent") : that.context.t("_present")}</em>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>

                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {
                                    activityList.length == 0 && <div className="page-not-found center">
                                        <br />
                                        <h4 aria-label={this.context.t("_no_attendance")} tabIndex="0" >{this.context.t("_no_attendance")}</h4>
                                    </div>
                                }
                                {(activityList.length > 0) ? (
                                    <Pagination
                                        prevPageText={`< ${this.context.t("_previous")}`}
                                        nextPageText={`${this.context.t("_next")} >`}
                                        hideFirstLastPages={true}
                                        hideDisabled={true}
                                        linkClassPrev="linkClassPrev"
                                        linkClassNext="linkClassNext"
                                        activePage={pager.current_page + 1}
                                        itemsCountPerPage={pager.items_per_page}
                                        totalItemsCount={pager.count}
                                        pageRangeDisplayed={5}
                                        onChange={this.handlePageChange}
                                    />) : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="top-line ng-scope">
                    <img src={topLineImage} alt="" />
                </div>

            </div>
        )
    }

}

const mapStateToProps = ({ i18nState }) => {
    return {
        lang: i18nState.lang
    }
}
const mapDispatchToProps = {
    showLoader: LoadingAction.showLoader,
    hideLoader: LoadingAction.hideLoader,
    showPopup: (data) => PopupAction.showPopup(data),
    hidePopup: (data) => PopupAction.hidePopup(data),
}
StudentConnections.contextTypes = {
    t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(StudentConnections);
