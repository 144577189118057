import React from "react"
import Main from "../main"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import M from "materialize-css"
import dateformat from "dateformat"
import _ from "lodash"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { PopupAction, LoadingAction, ActivityListAction, StudentListAction, StudentCountAction, ActivityCountAction, TypeCountAction, ZoneCountAction } from "./../../actions"

import StudentItem from "./pages/students"
import ActivityItem from "./pages/activity"
import TypesItem from "./pages/type"
import ZoneItem from "./pages/zone"
import ActivityList from "./pages/activityList"
import StudentList from "./pages/studentList"
import GenderList from "./pages/gender"
import CitizenList from "./pages/citizen"
import NewStudentList from "./pages/newStudentList"
import { getGender, handleGradeName, calculateAge, handleCountryName, getType, getFieldType, getZone } from "./pages/utilFunctions"
import { calenderI18n, CustomDateInput, calenderLocal } from "./../../constants/common"

class Dashboard extends Main {
	constructor(props) {
		super(props);
		this.studentRef = React.createRef();
		this.activityRef = React.createRef();
		this.state = {
			config: this.config(),
			search: {
				startDate: {},
				endDate: {},
				type: "",
				zone: "",
				fieldType: "",
				studentLevel: "",
				studentPoint: "",
			},
			dashboardCount: {},
			studentFullList: [],
			activityFullList: [],
			resetFlag: true,
			studentLevel: [],
			studentPoints: [],
		}
	}
	async componentDidMount() {
		document.title = `${this.context.t("_studio5")} | ${this.context.t("_dashboard")}`
		const { match: { params: { type } } } = this.props;
		const { lang } = this.props
		if (_.isEmpty(type)) {
			this.props.history.push("/dashboard/student")
		}
		this.props.showLoader();
		M.AutoInit();
		this.initDatepicker()
		await this.getDashboardCount();
		if (_.isEmpty(type) || type == "student") {
			await this.props.getStudentsList({});
			setTimeout(() => {
				this.props.getNewStudentsList({});
				this.props.getActivityList({ lang });
			}, 1000);
		} else if (type == "activity") {
			await this.props.getActivityList({ lang });
			setTimeout(() => {
				this.props.getNewStudentsList({});
				this.props.getStudentsList({});
			}, 1000);
		} else {
			await this.props.getStudentsList({ lang });
			setTimeout(() => {
				this.props.getNewStudentsList({});
				this.props.getActivityList({});
			}, 1000);

		}

	}
	componentDidUpdate() {
		document.title = `${this.context.t("_studio5")} | ${this.context.t("_dashboard")}`
	}

	initDatepicker = () => {
		const { search } = this.state;
		// const startOptions = {
		// 	autoClose: true,
		// 	format: "d mmmm, yyyy",
		// 	onSelect: ((date) => {
		// 		this.handleChange("startDate", dateformat(date, "yyyy-mm-dd"))
		// 		this.initDatepicker()
		// 	}),
		// }
		// const endOptions = {
		// 	autoClose: true,
		// 	format: "d mmmm, yyyy",
		// 	// minDate: new Date(),
		// 	onSelect: ((date) => {
		// 		this.handleChange("endDate", dateformat(date, "yyyy-mm-dd"))
		// 		this.initDatepicker()
		// 	}),
		// }
		if (search.startDate.value) {
			search.endDate.minDate = new Date(search.startDate.value)

		}
		if (search.endDate.value) {
			search.startDate.maxDate = new Date(search.endDate.value)

		}
		this.setState({ search })

		// setTimeout(() => {
		// 	let elems = document.querySelectorAll(".startDate");
		// 	startOptions.i18n = calenderI18n(this.context);
		// 	endOptions.i18n = calenderI18n(this.context);
		// 	// M.Datepicker.init(elems, startOptions);
		// 	elems = document.querySelectorAll(".endDate");
		// 	// M.Datepicker.init(elems, endOptions);
		// }, 500);
	}
	getActivityFullList = async ({ title = "", type = "", zone = "", fieldType = "", startDate = "", endDate = "" }) => {
		try {
			const { config } = this.state;
			const { lang } = this.props;
			let url = ""
			if (lang == "ar")
				url = `${config.origin}${lang}/api/${urlEndPoints.getDashboardActivityFullList}?`
			else
				url = `${config.origin}api/${urlEndPoints.getDashboardActivityFullList}?`

			const body = {}

			if (!_.isEmpty(title)) {
				body.title = title
			}
			if (!_.isEmpty(type)) {
				body.type_1 = type
			}
			if (!_.isEmpty(zone)) {
				body.field_activity_zone_target_id = zone
			}
			if (!_.isEmpty(fieldType)) {
				body.field_type_of_activity_value = fieldType
			}
			if (!_.isEmpty(startDate)) {
				body.field_start_and_end_time_value = `${dateformat(startDate.value, "yyyy-mm-dd")} 00:00:00`
			}
			if (!_.isEmpty(endDate)) {
				body.field_start_and_end_time_end_value_1 = `${dateformat(endDate.value, "yyyy-mm-dd")} 00:00:00`
			}

			this.props.showLoader()
			const data = await this.networkRequest(url, "POST", body)
			const results = data.data.results;
			results.forEach(item => {
				item.startDate = item.startDate.split("*")
				item.endDate = item.endDate.split("*")
			});
			this.setState({ activityFullList: results })
			this.props.hideLoader()
			setTimeout(() => {
				return window.print();
			}, 500);
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}

	}
	getStudentsFullList = async ({ title = "", age = "", nationality = "", gender = "", startDate = "", endDate = "", type = "", zone = "", fieldType = "", }) => {
		try {
			const { config } = this.state;
			const { lang } = this.props;
			let url = `${config.api_url}${urlEndPoints.getDashboardStudentFullList}?`

			const body = {}
			if (!_.isEmpty(title)) {
				body.field_student_name_value = title
			}
			if (!_.isEmpty(age)) {
				var dob = new Date();
				dob.setFullYear(dob.getFullYear() - Number(age));
				body.field_student_dob_value = `${dateformat(dob, "yyyy-mm-dd")}`
			}
			if (!_.isEmpty(nationality)) {
				body.field_nationality_value = nationality
			}
			if (!_.isEmpty(gender)) {
				body.field_gender_value = gender
			}
			if (!_.isEmpty(startDate)) {
				body.field_start_and_end_time_value = dateformat(startDate.value, "yyyy-mm-dd")
			}
			if (!_.isEmpty(endDate)) {
				body.field_start_and_end_time_end_value_1 = dateformat(endDate.value, "yyyy-mm-dd")
			}
			if (!_.isEmpty(type)) {
				body.type_1 = type
			}
			if (!_.isEmpty(zone)) {
				if (zone == "1") {
					body.field_activity_zone_target_id = "Creativity Zone"
				}
				if (zone == "3") {
					body.field_activity_zone_target_id = "Technology Zone"
				}
				if (zone == "2") {
					body.field_activity_zone_target_id = "Software Zone"
				}
			}
			if (!_.isEmpty(fieldType)) {
				body.field_type_of_activity_value = fieldType
			}

			this.props.showLoader()
			const data = await this.networkRequest(url, "POST", body)
			this.setState({ studentFullList: data.data.results })
			this.props.hideLoader()
			setTimeout(() => {
				return window.print();
			}, 500);
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
	}
	getNewStudentsFullList = async ({ title = "", age = "", nationality = "", gender = "", startDate = "", endDate = "" }) => {
		try {
			const { config } = this.state;
			const { lang } = this.props;
			let url = `${config.api_url}${urlEndPoints.getDashboardNewStudentFullList}?`

			const body = {}
			if (!_.isEmpty(title)) {
				body.field_student_name_value = title
			}
			if (!_.isEmpty(age)) {
				var dob = new Date();
				dob.setFullYear(dob.getFullYear() - Number(age));
				body.field_student_dob_value = `${dateformat(dob, "yyyy-mm-dd")}`
			}
			if (!_.isEmpty(nationality)) {
				body.field_nationality_value = nationality
			}
			if (!_.isEmpty(gender)) {
				body.field_gender_value = gender
			}
			if (!_.isEmpty(startDate)) {
				body.field_start_and_end_time_value = dateformat(startDate.value, "yyyy-mm-dd")
			}
			if (!_.isEmpty(endDate)) {
				body.field_start_and_end_time_end_value_1 = dateformat(endDate.value, "yyyy-mm-dd")
			}

			this.props.showLoader()
			const data = await this.networkRequest(url, "POST", body)
			this.setState({ studentFullList: data.data.results })
			this.props.hideLoader()
			setTimeout(() => {
				return window.print();
			}, 500);
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
	}
	handleChange = (field, value) => {
		const { search } = this.state;
		const { match: { params: { type } } } = this.props;
		if (field == "type" || field == "zone" || field == "fieldType" || field == "studentLevel" || field == "studentPoint") {
			search[field] = value;
		} else {
			search[field].value = value;
		}
		this.setState({ search }, () => {
			// this.initDatepicker()
		});
		if (type == "all-student") {
			this.studentReference.click()
			// this.props.history.push("/dashboard/activity")
			// type = "student"
		}
	}
	handleApplyDateFilter = () => {
		const { search } = this.state;
		const { lang } = this.props;
		// if (!_.isEmpty(search.startDate) || !_.isEmpty(search.endDate)) {
		this.props.showLoader();
		// this.props.getStudentsList(search);
		// this.props.getStudentsFullList(search);
		this.getDashboardCount()
		this.props.getActivityList({ lang, ...search });
		this.props.getStudentsList({ ...search });
		this.props.getNewStudentsList({ ...search });
		// this.props.getActivityFullList({ lang, ...search });
		// }
	}
	getDashboardCount = async () => {
		const { config, search } = this.state;
		let url = `${config.origin}${urlEndPoints.getDashboardCount}?`
		let obj = {};
		if (!_.isEmpty(search.startDate))
			obj.startDate = `${dateformat(search.startDate.value, "yyyy-mm-dd")} 00:00:00`
		if (!_.isEmpty(search.endDate))
			obj.endDate = `${dateformat(search.endDate.value, "yyyy-mm-dd")} 00:00:00`
		if (!_.isEmpty(search.type))
			obj.type_1 = search.type
		if (!_.isEmpty(search.zone)) {
			if (search.zone == "1") {
				obj.field_activity_zone_target_id = "Creativity Zone"
			}
			if (search.zone == "3") {
				obj.field_activity_zone_target_id = "Technology Zone"
			}
			if (search.zone == "2") {
				obj.field_activity_zone_target_id = "Software Zone"
			}
		}

		if (!_.isEmpty(search.fieldType))
			obj.field_type_of_activity_value = search.fieldType

		try {
			const data = await this.networkRequest(url, "POST", obj)
			if (data.status == 200) {
				this.setState({ dashboardCount: data.data.results })
			}
		} catch (error) {
			// this.props.hideLoader();
		}

	}
	handlePrint = async () => {
		const { match: { params: { type } } } = this.props;
		const { search } = this.state;
		if (type == "student") {
			this.getStudentsFullList({ ...search })

		} else if (type == "activity") {
			this.getActivityFullList({ ...search })
			// return window.print();
		} else {
			this.getNewStudentsFullList({ ...search })
		}
	}
	handleResetFilter = () => {
		const { search } = this.state;
		const { lang } = this.props;
		// if (!_.isEmpty(search.startDate) || !_.isEmpty(search.endDate)) {

		this.setState({ search: { startDate: {}, endDate: {}, type: "", zone: "", fieldType: "" }, resetFlag: !this.state.resetFlag }, () => {
			this.props.showLoader();
			// this.props.getStudentsList({});
			// this.props.getStudentsFullList({});
			this.getDashboardCount()
			this.props.getActivityList({ lang, });
			// this.props.getActivityFullList({ lang, });
		})

		// }
	}

	getZone = zone => {
		zone = zone.toLowerCase()
		switch (zone) {
			case "creativity zone":
				return this.context.t("_creative_zone")
			case "software zone":
				return this.context.t("_software_zone")
			case "technology zone":
				return this.context.t("_technology_zone")

		}
	}
	getType = type => {
		type = type.toLowerCase()
		switch (type) {
			case "event":
				return this.context.t("_events")
			case "session":
				return this.context.t("_sessions")
			case "competition":
				return this.context.t("_competitions")
			case "challenge":
				return this.context.t("_challenges")
			case "project":
				return this.context.t("_projects")

		}
	}
	getFieldType = field => {
		field = field.toLowerCase()
		switch (field) {
			case "online":
				return this.context.t("_online")
			case "onsite":
				return this.context.t("_onsite")

		}
	}

	calculateDateDiff = (fromDate, toDate) => {
		const date1 = new Date(fromDate);
		const date2 = new Date(toDate);
		const diffTime = Math.abs(date2 - date1);
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		return diffDays;
	}
	getButtonLabel = (type) => {
		switch (type) {
			case "student":
				return this.context.t("_export_student_details")
			case "activity":
				return this.context.t("_export_activity_details")
			case "all-student":
				return this.context.t("_export_all_student_details")

			default:
				break;
		}
	}
	render() {
		const { search, dashboardCount, studentFullList, activityFullList, config, resetFlag, studentLevel, studentPoints } = this.state;
		const { match: { params: { type } } } = this.props;
		console.log("searchsearch", search);
		return (
			<div id="main-content">

				<div className="dashboardTitleBar"></div>
				<div id="skipContent">
					<div id="main-container">
						<div className="container dashboardContainer">

							<div className="dashboardTop">
								<div className="dashboardTopForm">
									<p>{this.context.t("_select_date_range")}</p>
									<div className="dashboardFormCalendar">

										<div className="date-popup">
											<div className="input-field">
												<label htmlFor="startDate">{this.context.t("_from")}</label>
												{/* <input
												name="startDate"
												placeholder={this.context.t("_from")}
												id="startDate"
												type="text"
												className="startDate"
												readOnly="readonly"
												autoComplete="no"
											/> */}
												<DatePicker
													name="startDate"
													placeholderText={this.context.t("_from")}
													id="startDate"
													className="startDate"
													// readOnly="readonly"
													autoComplete="off"
													dateFormat="yyyy-MM-dd"
													locale={calenderLocal(this.context)}
													onChange={date => {
														this.handleChange("startDate", date)

													}}
													// style={dynamicStyles.textAlign(lang)}
													minDate={search.startDate.minDate}
													maxDate={search.startDate.maxDate}
													value={search.startDate.value}
													selected={search.startDate.value}
													customInput={<CustomDateInput ariaLabel={this.context.t("_select_start_date_button")} onFieldChange={(date) => this.handleChange("startDate", new Date(date))} />}
													onCalendarOpen={e => {
														let elems = document.querySelector(".react-datepicker");
														elems.setAttribute("tabindex", "0")
														elems && elems.focus()
														elems = document.querySelector(".react-datepicker__month-select");
														elems.setAttribute("aria-label", this.context.t("_select_month"))
														elems = document.querySelector(".react-datepicker__year-select");
														elems.setAttribute("aria-label", this.context.t("_select_year"))

													}}
													onCalendarClose={e => {
														var elems = document.querySelector("#startDate");
														elems.setAttribute("aria-label", `${this.context.t("_selected_date")} ${dateformat(search.startDate.value, "dd-mm-yyyy")}`)
														elems.setAttribute("value", dateformat(search.startDate.value, "yyyy-mm-dd"))
														setTimeout(() => {
															elems && elems.focus()
														}, 200);
													}}
													peekNextMonth
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
											</div>
										</div>
										<span className="inBetween"> . . . </span>
										<div className="date-popup">
											<div className="input-field">
												<label htmlFor="endDate">{this.context.t("_to")}</label>
												{/* <input
												name="endDate"
												placeholder={this.context.t("_to")}
												id="endDate"
												type="text"
												className="endDate"
												readOnly="readonly"
												autoComplete="no"
											/> */}
												<DatePicker
													name="endDate"
													placeholderText={this.context.t("_to")}
													id="endDate"
													className="endDate"
													// readOnly="readonly"
													autoComplete="off"
													locale={calenderLocal(this.context)}
													dateFormat="yyyy-MM-dd"
													onChange={date => {
														this.handleChange("endDate", date)
														this.initDatepicker()
													}}
													// style={dynamicStyles.textAlign(lang)}
													minDate={search.endDate.minDate}
													maxDate={search.endDate.maxDate}
													value={search.endDate.value}
													selected={search.endDate.value}
													customInput={<CustomDateInput ariaLabel={this.context.t("_select_end_date_button")} onFieldChange={(date) => this.handleChange("endDate", new Date(date))} />}
													onCalendarOpen={e => {
														let elems = document.querySelector(".react-datepicker");
														elems.setAttribute("tabindex", "0")
														elems && elems.focus()
														elems = document.querySelector(".react-datepicker__month-select");
														elems.setAttribute("aria-label", this.context.t("_select_month"))
														elems = document.querySelector(".react-datepicker__year-select");
														elems.setAttribute("aria-label", this.context.t("_select_year"))

													}}
													onCalendarClose={e => {
														var elems = document.querySelector("#endDate");
														elems.setAttribute("aria-label", `${this.context.t("_selected_date")} ${dateformat(search.endDate.value, "dd-mm-yyyy")}`)
														elems.setAttribute("value", dateformat(search.endDate.value, "yyyy-mm-dd"))
														setTimeout(() => {
															elems && elems.focus()
														}, 200);
													}}
													peekNextMonth
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
											</div>
										</div>

									</div>

									{/* <button onClick={() => this.handleApplyDateFilter()} className="btn bgYellow waves-effect waves-light">{this.context.t("_apply")}</button>
									<button onClick={() => this.handleResetFilter()} type="button" className="btn blueBorder">{this.context.t("_reset")}</button> */}
								</div>

								<div className="dashboardTopRight">
									<button onClick={() => this.handlePrint()} className="btn bgYellow waves-effect waves-light">{this.getButtonLabel(type)}</button>
								</div>
								<div className="" style={{ width: "100%" }}>
									<div className="col s12">
										<div className="dashboard_main_filter">
											<label htmlFor="type">{this.context.t("_type")}</label>
											<div className="input-field inline search mobileSelect">
												<select
													id="type"
													name="type"
													className="browser-default"
													onChange={evt => {
														this.handleChange("type", evt.target.value)

													}}
													value={search.type}
												>
													<option value="" disabled>{this.context.t("_select_type")}</option>
													<option value="event">{this.context.t("_events")}</option>
													<option value="session">{this.context.t("_sessions")}</option>
													<option value="competition">{this.context.t("_competitions")}</option>
													<option value="challenge">{this.context.t("_challenges")}</option>
													<option value="project">{this.context.t("_projects")}</option>
												</select>
											</div>
											{/* <label htmlFor="type">{this.context.t("_type")}</label>
											<div className="input-field inline search mobileSelect">
												<select
													id="type"
													name="type"
													className="browser-default"
													onChange={evt => {
														this.handleChange("type", evt.target.value)

													}}
													value={search.type}
												>
													<option value="" disabled>{this.context.t("_select_type")}</option>
													<option value="event">{this.context.t("_events")}</option>
													<option value="session">{this.context.t("_sessions")}</option>
													<option value="competition">{this.context.t("_competitions")}</option>
													<option value="challenge">{this.context.t("_challenges")}</option>
													<option value="project">{this.context.t("_projects")}</option>
												</select>
											</div> */}
											<label htmlFor="zone">{this.context.t("_zone")}</label>
											<div className="input-field inline search mobileSelect">
												<select
													id="zone"
													name="zone"
													className="browser-default"
													onChange={evt => {
														this.handleChange("zone", evt.target.value)

													}}
													value={search.zone}
												>

													<option value="" disabled>{this.context.t("_select_zone")}</option>
													<option value="1">{this.context.t("_creative_zone")}</option>
													<option value="3">{this.context.t("_technology_zone")}</option>
													<option value="2">{this.context.t("_software_zone")}</option>

												</select>
											</div>
											<label htmlFor="fieldType">{`${this.context.t("_online")}/ ${this.context.t("_onsite")}`}</label>
											<div className="input-field inline mobileSelect">
												<select
													id="fieldType"
													name="fieldType"
													className="browser-default"
													onChange={evt => {
														this.handleChange("fieldType", evt.target.value)

													}}
													value={search.fieldType}
												>
													<option value="" disabled>{this.context.t("_select")}</option>
													<option value="online">{this.context.t("_online")}</option>
													<option value="onsite">{this.context.t("_onsite")}</option>
												</select>
											</div>

											<button onClick={() => this.handleApplyDateFilter()} className="btn bgYellow waves-effect waves-light">{this.context.t("_apply")}</button>
											<button onClick={() => this.handleResetFilter()} type="button" className="btn blueBorder">{this.context.t("_reset")}</button>
										</div>

									</div>


								</div>

							</div>

							<div className="dashboardBoxHolder">
								<StudentItem {...search} {...dashboardCount} />
								<GenderList {...search} {...dashboardCount} resetFlag={resetFlag} />
								<CitizenList {...search} {...dashboardCount} resetFlag={resetFlag} />
								<ActivityItem {...search} {...dashboardCount} />
								<TypesItem {...search} {...dashboardCount} />
								<ZoneItem {...search} {...dashboardCount} />
							</div>



							<div className="dashboardWholeWrapper">

								<div className="row">
									<div className="col s12">
										<ul className="tabs">
											<li className="tab"><a ref={input => this.studentReference = input} onClick={() => this.props.history.push("/dashboard/student")} className={`waves-effect waves-light ${type == "student" ? "active" : ""}`} href="#student">{this.context.t("_student_details")}</a></li>
											<li className="tab"><a onClick={() => this.props.history.push("/dashboard/activity")} className={`waves-effect waves-light ${type == "activity" ? "active" : ""}`} href="#activity">{this.context.t("_activity_details")}</a></li>
											{
												_.isEmpty(search.type) && _.isEmpty(search.zone) && _.isEmpty(search.fieldType) && <li className="tab"><a onClick={() => this.props.history.push("/dashboard/all-student")} className={`waves-effect waves-light ${type == "all-student" ? "active" : ""}`} href="#all-student">{this.context.t("_all_students")}</a></li>

											}

										</ul>
									</div>

									<StudentList {...search} />
									<ActivityList {...search} />
									<NewStudentList {...search} />

									{

										type == "student" || type == "all-student" ? <div id="student" className="col s12">
											<div className="tableHolder list-print">
												<form className="fliters">
													{this.context.t("_applied_filters")}: &nbsp;&nbsp;
													{
														!_.isEmpty(search.title) && <>
															<label>{this.context.t("_search")}: <span>{search.title}</span></label>
														</>
													}

													{
														!_.isEmpty(search.age) && <>
															<label>{this.context.t("_age")}: <span>{search.age}</span></label>
														</>
													}
													{
														!_.isEmpty(search.nationality) && <>
															<label>{this.context.t("_nationality")}: <span>{search.nationality}</span></label>
														</>
													}
													{
														!_.isEmpty(search.gender) && <>
															<label>{this.context.t("_gender")}: <span>{search.gender}</span></label>
														</>
													}

												</form>

												<table>
													<thead>
														<tr>
															<th><strong>{this.context.t("_student_name_1")}</strong></th>
															<th><strong>{this.context.t("_qid")}</strong></th>
															<th><strong>{this.context.t("_phone_number")}</strong></th>
															<th><strong>{this.context.t("_gender")}</strong></th>
															<th><strong>{this.context.t("_grade")}</strong></th>
															<th><strong>{this.context.t("_age")}</strong></th>
															<th><strong>{this.context.t("_email")}</strong></th>
															<th><strong>{this.context.t("_nationality")}</strong></th>
															<th><strong>{this.context.t("_enrolled")} </strong></th>
															<th><strong>{this.context.t("_attended")}</strong></th>
															<th><strong>{this.context.t("_waiting")}</strong></th>
															<th><strong>{this.context.t("_student_points")}</strong></th>
															<th><strong>{this.context.t("_student_level")}</strong></th>
														</tr>
													</thead>
													<tbody>
														{
															studentFullList.map(item => {
																return (
																	<tr key={item.uid}>
																		<td>
																			<img className="profile-img" src={item.picture} alt="" />
																			<span>{item.name}</span>
																		</td>
																		<td>{item.qid}</td>
																		<td>{item.phone}</td>
																		<td>{getGender(item.gender, this.context)}</td>
																		<td>{handleGradeName(item.grade, this.props.lang)}</td>
																		<td>{calculateAge(item.dob)}</td>
																		<td>{item.email}</td>
																		<td>{handleCountryName(item.nationality, this.props.lang)}</td>
																		<td className="text-center">{item.enrollment_count}</td>
																		<td className="text-center">{item.attended_count}</td>
																		<td className="text-center">{item.waitinglist_count}</td>
																		<td className="text-center">{item.field_activity_point}</td>
																		<td>{item.level}</td>
																	</tr>
																)
															})
														}
													</tbody>
													<tfoot>
														<tr>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
														</tr>
													</tfoot>
												</table>
											</div>
										</div> :
											<div id="activity" className="col s12">
												<div className="tableHolder list-print">
													<form className="fliters" onSubmit={this.onSubmit} autoComplete="no">
														{this.context.t("_applied_filters")}: &nbsp;&nbsp;
														{
															!_.isEmpty(search.title) && <>
																<label>{this.context.t("_search")}: {search.title}&nbsp;&nbsp;</label>
															</>
														}

														{
															!_.isEmpty(search.type) && <>
																<label>{this.context.t("_type")}: {search.type}&nbsp;&nbsp;</label>
															</>
														}

														{
															!_.isEmpty(search.zone) && <>
																<label>{this.context.t("_zone")}: {search.zone}&nbsp;&nbsp;</label>
															</>
														}

														{
															!_.isEmpty(search.fieldType) && <>
																<label>{`${this.context.t("_online")}/ ${this.context.t("_onsite")}`}: {search.fieldType}</label>
															</>
														}

													</form>
													<table>
														<thead>
															<tr>
																<th><strong>{this.context.t("_activity_name")}</strong></th>
																<th><strong>{this.context.t("_type")}</strong></th>
																<th><strong>{`${this.context.t("_online")}/ ${this.context.t("_onsite")}`}</strong></th>
																<th><strong>{this.context.t("_zone")}</strong></th>
																<th><strong>{this.context.t("_session_hours")}</strong></th>
																<th><strong>{this.context.t("_start_date")}</strong></th>
																<th><strong>{this.context.t("_end_date")}</strong></th>
																<th><strong>{this.context.t("_activity_gender")}</strong></th>
																<th><strong>{this.context.t("_age_group")}</strong></th>
																<th><strong>{this.context.t("_enrolled")}</strong></th>
																<th><strong>{this.context.t("_attended")}</strong></th>
																<th><strong>{this.context.t("_waiting")}</strong></th>
															</tr>
														</thead>
														<tbody>
															{
																activityFullList.map(item => {
																	return (
																		<tr key={item.nid}>
																			<td>
																				<img className="profile-img" src={item.image} alt="" />
																				<span>{item.title}</span>
																			</td>
																			<td>{getType(item.event_type, this.context)}</td>
																			<td>{getFieldType(item.activity_type, this.context)}</td>
																			<td>{getZone(item.activity_zone, this.context)}</td>
																			<td className="text-center">{item.session_hours}</td>
																			<td>{item.startDate[0]}</td>
																			<td>{item.endDate[item.endDate.length - 1]}</td>
																			<td>{getGender(item.activity_gender, this.context)}</td>
																			<td>{item.activity_age_group}</td>
																			<td className="text-center">{item.enrollment_count}</td>
																			<td className="text-center">{item.attended_count}</td>
																			<td className="text-center">{item.waitinglist_count}</td>
																		</tr>
																	)
																})
															}
														</tbody>
														<tfoot>
															<tr>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
															</tr>
														</tfoot>
													</table>

												</div>
											</div>
									}

								</div>
							</div>
						</div>
					</div>
				</div>
			</div >
		)
	}
}

const mapStateToProps = ({ i18nState }) => {
	return {
		lang: i18nState.lang
	}
}
const mapDispatchToProps = {
	showPopup: (data) => PopupAction.showPopup(data),
	hidePopup: (data) => PopupAction.hidePopup(data),
	getActivityList: (data) => ActivityListAction.getActivityList(data),
	// getActivityFullList: (data) => ActivityListAction.getActivityFullList(data),
	getStudentsList: StudentListAction.getStudentsList,
	getNewStudentsList: StudentListAction.getNewStudentsList,
	getNewStudentsList: StudentListAction.getNewStudentsList,
	// getStudentsFullList: StudentListAction.getStudentsFullList,
	getStudentsEnrolled: StudentCountAction.getStudentsEnrolled,
	getStudentsWaitingList: StudentCountAction.getStudentsWaitingList,
	getStudentsAttended: StudentCountAction.getStudentsAttended,
	getTotalActivities: ActivityCountAction.getTotalActivities,
	getCurrentActivities: ActivityCountAction.getCurrentActivities,
	getCompletedActivities: ActivityCountAction.getCompletedActivities,
	getUpcomingActivities: ActivityCountAction.getUpcomingActivities,
	getEventActivity: TypeCountAction.getEventActivity,
	getSessionActivity: TypeCountAction.getSessionActivity,
	getCompetitionActivity: TypeCountAction.getCompetitionActivity,
	getZoneCreative: ZoneCountAction.getZoneCreative,
	getZoneSoftware: ZoneCountAction.getZoneSoftware,
	getZoneTechnology: ZoneCountAction.getZoneTechnology,
	showLoader: LoadingAction.showLoader,
	hideLoader: LoadingAction.hideLoader,
}
Dashboard.contextTypes = {
	t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);