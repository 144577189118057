import React from "react"
import Main from "../main"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import _ from "lodash"
import Pagination from "react-js-pagination";
import dateformat from "dateformat";
import { PopupAction, LoadingAction } from "./../../actions"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { gradeListNew } from "./../../constants/common"
import CountryList from "./../../constants/country.json"
import profileImage from "../../assets/images/profile-image-big.jpg"
const logo = require("./../../assets/images/logo56.png");
const logoMTC = require("./../../assets/images/logoMTC.png");

class DashboardStudentDetails extends Main {
	constructor(props) {
		super(props);
		this.state = {
			config: this.config(),
			studentDetails: {},
			activities: [],
			completeActivities: [],
			pager: { count: 0, pages: 0, items_per_page: 0, current_page: 1 }
		}
	}
	async componentDidMount() {
		document.title = `${this.context.t("_studio5")} | ${this.context.t("_student_details")}`
		this.props.showLoader()
		this.getUserDetails();
		await this.getUserActivities();
		this.getUserCompleteActivities();
	}
	componentDidUpdate() {
		document.title = `${this.context.t("_studio5")} | ${this.context.t("_student_details")}`
	}
	getUserDetails = async () => {
		const { match: { params: { id } } } = this.props;
		// if (authResponse) {
		const { config } = this.state
		const url = `${config.api_url}${urlEndPoints.getDashboardStudentProfile(id)}`
		// const headers = {
		//     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
		// }
		try {
			const data = await this.networkRequest(url)
			this.setState({ studentDetails: data.data })
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
	}
	getUserActivities = async () => {
		const { config, pager } = this.state
		const { lang } = this.props;
		const { match: { params: { id } } } = this.props;
		let url = ""
		if (lang == "ar")
			url = `${config.origin}${lang}/api/${urlEndPoints.studentAttendance}`
		else
			url = `${config.origin}api/${urlEndPoints.studentAttendance}`

		const body = {
			page: pager.pages,
			dashboard: 1
		}

		if (id)
			body.stu_id = id
		try {
			const data = await this.networkRequest(url, "POST", body);
			if (data.data.error) {

			} else {
				if (data.data.results) {
					const results = data.data.results;
					results.forEach(async item => {
						item.end_date = item.end_date.split("*")
						item.start_date = item.start_date.split("*")
					});
					this.setState({
						activities: results,
						pager: data.data.pager,
					})
				}
			}
			this.props.hideLoader()
			// this.props.hideLoader()
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
	}
	getUserCompleteActivities = async () => {
		const { config } = this.state
		const { lang } = this.props;
		const { match: { params: { id } } } = this.props;
		let url = ""
		if (lang == "ar")
			url = `${config.origin}${lang}/api/${urlEndPoints.studentAttendance}`
		else
			url = `${config.origin}api/${urlEndPoints.studentAttendance}`
		const body = {
			dashboard: 1,
			no_pagination: 1
		}

		if (id)
			body.stu_id = id
		try {
			const data = await this.networkRequest(url, "POST", body);
			if (data.data.error) {

			} else {
				if (data.data.results) {
					const results = data.data.results;
					results.forEach(async item => {
						item.end_date = item.end_date.split("*")
						item.start_date = item.start_date.split("*")
					});
					this.setState({
						completeActivities: results,
					})
				}
			}
			// this.props.hideLoader()
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
	}

	handlePrint = () => {
		return window.print();
	}
	getGender = gender => {
		if (!_.isEmpty(gender)) {
			gender = gender.toLowerCase()
			switch (gender) {
				case "male":
					return this.context.t("_male")
				case "female":
					return this.context.t("_female")
			}
		}
		return gender
	}
	getStatus = status => {
		if (!_.isEmpty(status)) {
			status = status.toLowerCase()
			switch (status) {
				case "published":
					return this.context.t("_enrolled")
				case "unpublished":
					return this.context.t("_pending")
				case "waitinglist":
					return this.context.t("_waiting_list")
			}
		}
		return status
	}
	handleGradeName = (grade) => {
		if (!_.isEmpty(grade)) {
			const { lang } = this.props;
			const gradeValue = _.find(gradeListNew[lang], ["value", grade])
			return !_.isEmpty(gradeValue) ? gradeValue.label : ""
		}
		return grade
	}
	handleCountryName = (country) => {
		if (!_.isEmpty(country)) {
			const { lang } = this.props;
			const countryValue = _.find(CountryList, { country_id: Number(country) })
			return !_.isEmpty(countryValue) ? countryValue.country[lang] : ""
		}
		return country
	}
	handleStudentPageChange = (pageNumber) => {
		this.props.showLoader()
		var { pager } = this.state;
		pager.pages = pageNumber - 1;
		this.setState({ pager })
		this.getUserActivities();
	}
	getDateInFormat = date => {
		if (date) {
			date = dateformat(date, "dd mmm, yyyy")
			if (!_.isEmpty(date)) {
				if (date.includes("Jan"))
					date = date.replace(/Jan/g, this.context.t("_jan"))

				if (date.includes("Feb"))
					date = date.replace(/Feb/g, this.context.t("_feb"))

				if (date.includes("Mar"))
					date = date.replace(/Mar/g, this.context.t("_mar"))

				if (date.includes("Apr"))
					date = date.replace(/Apr/g, this.context.t("_apr"))

				if (date.includes("May"))
					date = date.replace(/May/g, this.context.t("_may"))

				if (date.includes("Jun"))
					date = date.replace(/Jun/g, this.context.t("_jun"))

				if (date.includes("Jul"))
					date = date.replace(/Jul/g, this.context.t("_jul"))

				if (date.includes("Aug"))
					date = date.replace(/Aug/g, this.context.t("_aug"))

				if (date.includes("Sep"))
					date = date.replace(/Sep/g, this.context.t("_sep"))

				if (date.includes("Oct"))
					date = date.replace(/Oct/g, this.context.t("_oct"))

				if (date.includes("Nov"))
					date = date.replace(/Nov/g, this.context.t("_nov"))

				if (date.includes("Dec"))
					date = date.replace(/Dec/g, this.context.t("_dec"))
			}
		}
		return date
	}
	render() {
		const { activities, pager, studentDetails, config, completeActivities } = this.state;
		const that = this;
		return (
			<div id="main-content" >
				<div className="page-title en">
					<div className="container">
						<h1>{this.context.t("_student_details")}</h1>
					</div>
				</div>

				<div className="container">
					<nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
						<ul>
							<li className="breadcrumb-item">
								<Link to="/dashboard/student">{this.context.t("_dashboard")}</Link> {" "}
							</li>
							<li className="breadcrumb-item">
								<Link tabIndex="0" to aria-current={this.context.t("_page")}>
									<span>{this.context.t("_student_details")}</span>
								</Link>
							</li>
						</ul>
					</nav>
				</div>

				<div className="container studentNewPrint details-block">
					<div className="row" id="skipContent">

						<div className="col s12 export-button">
							<button onClick={() => this.handlePrint()} className="btn bgYellow waves-effect waves-light">{this.context.t("_export_student_details")}</button>
						</div>
						{
							_.isEmpty(studentDetails) ? <div /> :
								<>
									<div className="col s5 profile">
										<img className="profile__img" src={`${config.origin}${studentDetails.picture}`} alt="type image"></img>
										<div className="profile__heading-block">
											<h2>{studentDetails.name}</h2>
										</div>
									</div>

									<div className="col s7 profileInfo">
										<ul>
											<li><span>{this.context.t("_qid")}: </span>{studentDetails.field_qid}</li>
											<li><span>{this.context.t("_dob")}:</span>{studentDetails.field_student_dob}</li>
											<li><span>{this.context.t("_grade")}:</span>{this.handleGradeName(studentDetails.field_grade)}</li>
											<li><span>{this.context.t("_gender")}:</span>{this.getGender(studentDetails.field_gender)}</li>
											<li><span>{this.context.t("_nationality")}:</span>{this.handleCountryName(studentDetails.field_nationality)}</li>
											<li><span>{this.context.t("_email")}:</span>{studentDetails.field_student_email}</li>
											<li><span>{this.context.t("_phone_number")}:</span>{studentDetails.field_student_mobile}</li>
										</ul>
									</div>
								</>
						}

						{
							activities.length > 0 && <div className="col s12 tableHolder">
								<h3 tabIndex="0" aria-label={`${studentDetails.name} ${this.context.t("_activity_details")}`} className="left">{this.context.t("_activity_details")}</h3>
								<table className="responsive-table highlight activityTable">
									<thead className="blue lighten-5">
										<tr>
											<th><strong>{this.context.t("_activity_name")}</strong></th>
											<th width="160"><strong>{this.context.t("_date")}</strong></th>
											<th><strong>{this.context.t("_status")}</strong></th>
											<th><strong>{this.context.t("_enrolled_by")} </strong></th>
											<th><strong>{this.context.t("_attendance")} </strong></th>
										</tr>
									</thead>
									<tbody>
										{
											activities.map(item => {
												return (
													<tr key={item.nid}>
														<td>
															<img className="profile-img" src={item.image} alt="" />
															<span aria-label={`${item.title} ${this.context.t("_date")} ${this.getDateInFormat(item.start_date[0])} ${this.context.t("_to")} ${this.getDateInFormat(item.end_date[item.end_date.length - 1])} ${this.context.t("_status")} ${this.getStatus(item.field_published_status_1)}`} tabIndex="0">{item.title}</span>
														</td>
														<td>{`${this.getDateInFormat(item.start_date[0])} - ${this.getDateInFormat(item.end_date[item.end_date.length - 1])}`}</td>
														<td>{this.getStatus(item.field_published_status_1)}</td>
														<td>{item.field_enrolled_user}</td>
														<td className="attendance-day-holder">
															{
																Object.keys(item.attendence).map(function (key, index) {
																	return (
																		<div key={index} className={`attendance-day ${item.attendence[key] == 0 ? "absent" : ""}`}>
																			<span>
																				{dateformat(key, "dd")}
																				<small>{dateformat(key, "mmm yyyy")}</small>
																			</span>
																			<em>{item.attendence[key] == 0 ? that.context.t("_absent") : that.context.t("_present")}</em>
																		</div>
																	)
																})
															}
														</td>
													</tr>
												)
											})
										}
									</tbody>
								</table>
								<Pagination
									prevPageText={`< ${this.context.t("_previous")}`}
									nextPageText={`${this.context.t("_next")} >`}
									hideFirstLastPages={true}
									hideDisabled={true}
									linkClassPrev="linkClassPrev"
									linkClassNext="linkClassNext"
									activePage={pager.current_page + 1}
									itemsCountPerPage={pager.items_per_page}
									totalItemsCount={pager.count}
									pageRangeDisplayed={5}
									onChange={this.handleStudentPageChange}
								/>
							</div>
						}


						{
							/**
							 * Print
							 */
						}

						{/* <div className="col s12 tableHolder list-print">
							<h3 className="left">{this.context.t("_activity_details")}</h3>
							<table className="activityTable">
								<thead>
									<tr>
										<th><strong>{this.context.t("_activity_name")}</strong></th>
										<th width="160"><strong>{this.context.t("_date")}</strong></th>
										<th><strong>{this.context.t("_status")}</strong></th>
										<th><strong>{this.context.t("_enrolled_by")} </strong></th>
										<th><strong>{this.context.t("_attendance")} </strong></th>
									</tr>
								</thead>
								<tbody>
									{
										completeActivities.map(item => {
											return (
												<tr key={`print_${item.nid}`}>
													<td>
														<img className="profile-img" src={item.image} alt="" />
														<span>{item.title}</span>
													</td>

													<td>{`${this.getDateInFormat(item.start_date[0])} - ${this.getDateInFormat(item.end_date[item.end_date.length - 1])}`}</td>
													<td>{item.field_published_status_1}</td>
													<td>{item.field_enrolled_user}</td>
													<td className="attendance-day-holder">
														{
															Object.keys(item.attendence).map(function (key, index) {
																return (
																	<div key={index} className={`attendance-day ${item.attendence[key] == 0 ? "absent" : ""}`}>
																		<span>
																			{dateformat(key, "dd")}
																			<small>{dateformat(key, "mmm yyyy")}</small>
																		</span>
																		<em>{item.attendence[key] == 0 ? that.context.t("_absent") : that.context.t("_present")}</em>
																	</div>
																)
															})
														}
													</td>
												</tr>
											)
										})
									}

									<tr>
										<td><span>Robotics competition</span></td>
										<td>12 Jun, 2020  to  12 Jun,2020</td>
										<td>Enrolled</td>
										<td>Mother</td>
									</tr> 
								</tbody>
							</table>
						</div> */}
						<div class="page-wrapper student-transcript-print">
							<div class="table-cover">
								<div className="header">
									<div class="row justify-content-center">
										<div class="col text-right"><a href="#"><img src={logoMTC} /></a></div>
										<div class="col text-left"><a href="#"><img src={logo} /></a></div>
									</div>
								</div>
								<section class="section-name">
									<div class="name-card">
										<div class="media align-items-center">
											<figure>
												<div class="image"><img src={`${config.origin}${studentDetails.picture}`} alt="type image" /></div>
											</figure>
											<div class="content">
												<h4>{studentDetails.name}</h4>
												<div class="row">
													<div class="col">
														<table>
															<tr>
																<td>{this.context.t("_qid")}:</td>
																<td>{studentDetails.field_qid}</td>
															</tr>
															<tr>
																<td>{this.context.t("_dob")}:</td>
																<td>{studentDetails.field_student_dob}</td>
															</tr>
															<tr>
																<td>{this.context.t("_grade")}:</td>
																<td>{this.handleGradeName(studentDetails.field_grade)}</td>
															</tr>
															<tr>
																<td>{this.context.t("_gender")}:</td>
																<td>{this.getGender(studentDetails.field_gender)}</td>
															</tr>
														</table>
													</div>
													<div class="col">
														<table>
															<tr>
																<td>{this.context.t("_nationality")}:</td>
																<td>{this.handleCountryName(studentDetails.field_nationality)}</td>
															</tr>
															<tr>
																<td>{this.context.t("_email")}:</td>
																<td>{studentDetails.field_student_email}</td>
															</tr>
															<tr>
																<td>{this.context.t("_phone_number")}:</td>
																<td>{studentDetails.field_student_mobile}</td>
															</tr>
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
								</section>
								<section class="section">
									<h6 class="text-center text-blue mb-0">{this.context.t("_activity_details")}</h6>
								</section>
								<section>
									<div class="activity-table">
										<table class="table">
											<thead>
												<tr>
													<th>{this.context.t("_activity_name")}</th>
													<th>{this.context.t("_date")}</th>
													<th>{this.context.t("_status")}</th>
													<th>{this.context.t("_enrolled_by")}</th>
													<th>{this.context.t("_attendance")}</th>
												</tr>
											</thead>
											<tbody>
												{
													completeActivities.map(item => {
														return (
															<tr>
																<td>
																	<figure className="activity-image"><img src={item.image} /></figure><span>{item.title}</span>
																</td>
																<td>{this.getDateInFormat(item.start_date[0])} - <br /> {this.getDateInFormat(item.end_date[item.end_date.length - 1])}</td>
																<td>{item.field_published_status_1}</td>
																<td>{item.field_enrolled_user}</td>
																<td>
																	{
																		Object.keys(item.attendence).map(function (key, index) {
																			return (
																				<div key={index} className={`${item.attendence[key] == 0 ? "absent" : "present"}`} ><span>{that.getDateInFormat(key)}</span><strong>{item.attendence[key] == 0 ? that.context.t("_absent") : that.context.t("_present")}</strong></div>
																			)
																		})
																	}
																</td>
															</tr>
														)
													})
												}
											</tbody>
										</table>
									</div>
								</section>
							</div>
						</div>

					</div>
				</div>

			</div>
		)
	}
}

const mapStateToProps = ({ i18nState }) => {
	return {
		lang: i18nState.lang
	}
}
const mapDispatchToProps = {
	showPopup: (data) => PopupAction.showPopup(data),
	hidePopup: (data) => PopupAction.hidePopup(data),
	showLoader: LoadingAction.showLoader,
	hideLoader: LoadingAction.hideLoader,
}
DashboardStudentDetails.contextTypes = {
	t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(DashboardStudentDetails);