import React from "react";
import Main from "../main"
import { connect } from "react-redux"
import PropTypes from "prop-types"

export class SlideToTop extends Main {
  constructor(props) {
    super(props);
    this.state = {
      intervalId: 0,
      scrollStepInPx: 50,
      delayInMs: 16.66
    };
    // this.scrollToTop.bind(this);
    // this.scrollStep.bind(this);
  }
  componentDidMount() {
    // this.scrollToTop();
    window.scroll(0, 0)
  }
  scrollStep() {
    var scrollStepInPx = this.props.scrollStepInPx ? this.props.scrollStepInPx : this.state.scrollStepInPx;
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - scrollStepInPx);
  }
  scrollToTop() {
		window.scroll({ top: 0, left: 0, behavior: "smooth" })
		// var delayInMs = this.props.delayInMs ? this.props.delayInMs : this.state.delayInMs;
		// let intervalId = setInterval(this.scrollStep.bind(this), delayInMs);
		// this.setState({ intervalId: intervalId });
  }
  render() {
    const { lang } = this.props;
    if (this.props.button) {
      return (<button onClick={() => { this.scrollToTop(); }} className={`scroll-top ${lang === "en" ? "scroll-top-en" : "scroll-top-ar"}`}>{this.context.t("_scroll_to_top")}</button>)
    }
    else {
      return null
    }
  }
}
const mapStateToProps = ({ i18nState }) => {
  return {
    lang: i18nState.lang
  }
}
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
SlideToTop.contextTypes = {
  t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(SlideToTop);