import React from "react";
import { Link } from "react-router-dom";
import Main from "./../main"
import PageTitle from "../includes/page_title";
import SlideToTop from "../includes/slide_to_top";
import M from "materialize-css";
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { LoadingAction } from "./../../actions"
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";

class Calendar extends Main {
    constructor(props) {
        super(props);
        this.state = {
            config: this.config(),
            calendarWeekends: true,
            calendarEvents: [
                // initial event data
                { title: "Event Now", start: new Date() },
                { title: "Two Event Now", start: new Date("2020/03/26"), end: new Date("2020/03/28") }
            ]
        }
        // this.props.showLoader()
    }
    componentDidMount() {
        M.AutoInit();
        // this.getFaqList();
    }
    calendarComponentRef = React.createRef();
    getFaqList = async () => {
        const { config } = this.state
        const url = `${config.api_url}${urlEndPoints.getFaqs}`
        try {
            const data = await this.networkRequest(url)
            if (data.data.error) {

            } else {
                this.setState({
                    faqList: data.data.results,
                })

            }
            this.props.hideLoader()
        } catch (error) {
            this.props.hideLoader()
            console.warn("error: ", error)
        }
    }
    toggleWeekends = () => {
        this.setState({
            // update a property
            calendarWeekends: !this.state.calendarWeekends
        });
    };

    gotoPast = () => {
        let calendarApi = this.calendarComponentRef.current.getApi();
        calendarApi.gotoDate("2000-01-01"); // call a method on the Calendar object
    };

    handleDateClick = arg => {
        // if (confirm("Would you like to add an event to " + arg.dateStr + " ?")) {
        // this.setState({
        //     // add new event data
        //     calendarEvents: this.state.calendarEvents.concat({
        //         // creates a new array
        //         title: "New Event",
        //         start: arg.date,
        //         allDay: arg.allDay
        //     })
        // });
        // }
    };
    render() {
        const { } = this.state;
        return (
            <div id="main-container">
                <SlideToTop />
                <PageTitle title={"Calendar"} />
                <div id="breadcrumb-wrap">
                    <div className="container">
                    </div>
                </div>
                <div className="container">
                    <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                        <ul>
                            <li className="breadcrumb-item">
                                <Link to="/">{`Home`}</Link> {" "}
                            </li>
                            <li className="breadcrumb-item">
                                <span> Calendar </span>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="container">
                    <FullCalendar
                        defaultView="dayGridMonth"
                        header={{
                            left: "prev",
                            center: "title",
                            right: "next"
                        }}
                        plugins={[dayGridPlugin, interactionPlugin]}
                        ref={this.calendarComponentRef}
                        weekends={this.state.calendarWeekends}
                        events={this.state.calendarEvents}
                        dateClick={this.handleDateClick}
                    />

                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ i18nState }) => {
    return {
        lang: i18nState.lang
    }
}
const mapDispatchToProps = {
    showLoader: LoadingAction.showLoader,
    hideLoader: LoadingAction.hideLoader,
}
Calendar.contextTypes = {
    t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
