import React from "react"
import Main from "../../main"
import SlideToTop from "../../includes/slide_to_top.js"
import PageTitle from "../../includes/page_title.js"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { dynamicStyles, customModalStyles } from "./../../../constants/dynamicStyles"
import { calenderI18n, CustomDateInput, calenderLocal } from "./../../../constants/common"
import { Link } from "react-router-dom"
import Pagination from "react-js-pagination";
import { urlEndPoints } from "./../../../httpAPI/apiConfig"
import M from "materialize-css"
import { LoadingAction, PopupAction } from "./../../../actions"
import _ from "lodash"
import topLineImage from "../../../assets/images/top-line.png"
import Modal from "react-modal";
import dateformat from "dateformat"
import { getType } from "./../../dashboard/pages/utilFunctions"

class ActivityHistory extends Main {
	constructor(props) {
		super(props);
		this.checkLogin();
		this.state = {
			isLoaded: false,
			config: this.config(),
			currentActivityList: [],
			pager: { count: 0, pages: 0, items_per_page: 0, current_page: 1 },
			search: { type: "All", title: "", start_date: "", end_date: "", page: 0 },
			studentList: [],
			checkedList: [],
			selectedActivityId: null,
			intervalId: 0,
			scrollStepInPx: 50,
			delayInMs: 16.66,
			cancelActivityConfirm: false,
			cancelActivityId: null
		}
		this.handlePageChange = this.handlePageChange.bind(this);
		this.props.showLoader()
	}
	async componentDidMount() {
		document.title = `${this.context.t("_studio5")} | ${this.context.t("_attendance")}`
		await this.initSearchComponents();
		M.AutoInit();
		await this.getActivity();
		window.addEventListener('load', this.handleTabIndex());
		setTimeout(() => {
			let elems = document.querySelectorAll(".modal");
			M.Modal.init(elems, {
				autoClose: true,
				dismissible: true
			});
			setTimeout(() => {
				document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
			}, 500);
		}, 200);
	}
	componentDidUpdate() {
		document.title = `${this.context.t("_studio5")} | ${this.context.t("_attendance")}`
	}
	handleTabIndex = () => {
		setTimeout(() => {
			let elem = document.querySelectorAll('select');
			elem.forEach(element => {
				element.setAttribute("tabindex", "0");
			});
		}, 1000);
	}
	initSearchComponents() {
		var options = { autoClose: true, format: "dd/mm/yyyy" };
		var start_date_options = {
			autoClose: true,
			format: "dd/mm/yyyy",
			showClearBtn: true,
			onSelect: ((date) => {
				this.handleChange("start_date", dateformat(date, "dd/mm/yyyy"))
			}),
		};
		var end_date_options = {
			autoClose: true,
			format: "dd/mm/yyyy",
			showClearBtn: true,
			onSelect: ((date) => {
				this.handleChange("end_date", dateformat(date, "dd/mm/yyyy"))
			}),
		};
		setTimeout(() => {
			let start_date = document.querySelectorAll("#start_date");
			let end_date = document.querySelectorAll("#end_date");
			start_date_options.i18n = calenderI18n(this.context);
			end_date_options.i18n = calenderI18n(this.context);
			// M.Datepicker.init(start_date, start_date_options);
			// M.Datepicker.init(end_date, end_date_options);
			let elems = document.querySelectorAll("select");
			M.FormSelect.init(elems, options);
		}, 1000);
	}
	getChildren = async () => {
		const { config } = this.state
		const url = `${config.api_url}${urlEndPoints.myChildren}`

		try {
			const data = await this.networkRequest(url)
			this.props.hideLoader();
			if (!data.data.message) {
				return data.data.results && data.data.results.length > 0 ? data.data.results : []
			}
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
		// }
	}
	getActivity = async () => {
		const { config, search } = this.state;
		const { lang } = this.props;
		let url = ""
		if (localStorage.getItem("role") == "trainer" || localStorage.getItem("role") == "asistant") {
			if (lang == "ar")
				url = `${config.origin}${lang}/api/${urlEndPoints.trainerActivityHistory}?`
			else
				url = `${config.api_url}${urlEndPoints.trainerActivityHistory}?`
		}
		if (localStorage.getItem("role") == "manager" || localStorage.getItem("role") == "edu_specialist") {
			if (lang == "ar")
				url = `${config.origin}${lang}/api/${urlEndPoints.managerActivityHistory}?`
			else
				url = `${config.api_url}${urlEndPoints.managerActivityHistory}?`
		}

		if (!_.isEmpty(search.title) && search.title != "All")
			url = `${url}&field_event_type_value=${search.title}`
		if (search.start_date)
			url = `${url}&field_start_end_dates_value=${dateformat(search.start_date, "dd-mm-yyyy")}`
		if (search.end_date)
			url = `${url}&field_start_end_dates_end_value=${dateformat(search.end_date, "dd-mm-yyyy")}`
		if (search.page)
			url = `${url}&page=${search.page}`

		try {
			const data = await this.networkRequest(url)
			if (data.status == 200) {
				this.scrollToTop()
				this.props.hideLoader()
				const currentActivityList = data.data.results;
				currentActivityList.forEach(async item => {
					item.field_start_and_end_time_2 = item.field_start_and_end_time_2.split("*")
					item.field_start_and_end_time_2 = Math.min.apply(null, item.field_start_and_end_time_2);
					item.field_start_and_end_time_3 = item.field_start_and_end_time_3.split("*")
					item.field_start_and_end_time_4 = item.field_start_and_end_time_4.split("*")
				});
				this.setState({
					currentActivityList,
					pager: data.data.pager,
					isLoaded: true,
					checkedList: {}
				})
			}
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
		// }
	}
	calculateAge = (dateString) => {
		var today = new Date();
		var birthDate = new Date(dateString);
		var age = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	}
	handleCheck = (event, id, index) => {
		const { checkedList } = this.state
		if (event.target.checked)
			checkedList[index] = id
		else
			delete checkedList[index]
		this.setState({ checkedList })

	}
	handlePageChange(pageNumber) {
		this.props.showLoader()
		var { search } = this.state;
		search.page = pageNumber - 1;
		this.setState({ search: search });
		this.getActivity();
	}
	handleChange = (field, value) => {
		var { search } = this.state;
		search[field] = value;
		this.setState({ search: search });
	}
	handelCancelActivity = async id => {
		this.props.showLoader();
		const { config } = this.state;
		const { lang } = this.props;
		// const authResponse = await this.auth();
		// if (authResponse) {
		const url = `${config.api_url}${urlEndPoints.cancelActivity}`
		const obj = {
			"activity_id": id
		}
		// const headers = {
		// 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
		// }
		try {
			const data = await this.networkRequest(url, "POST", obj);
			if (data.status == 200) {
				if (!_.isEmpty(data.data.error)) {
					this.props.showPopup({
						type: "error",
						title: this.context.t("_error"),
						message: data.data.error.name[lang],
						isOpen: true,
						buttonText: this.context.t("_ok")
					})
				} else {
					this.props.showPopup({
						type: "success",
						title: this.context.t("_success"),
						message: this.context.t("_successfully_cancelled"),
						isOpen: true,
						buttonText: this.context.t("_ok")
					})
				}

				this.getActivity()
			}
		} catch (error) {
			this.props.showPopup({
				type: "error",
				title: this.context.t("_failed"),
				message: this.context.t("_failed_to_cancel"),
				isOpen: true,
				buttonText: this.context.t("_ok")
			})
			this.props.hideLoader()
			console.warn("error: ", error)
		}
		// }
	}
	filterFormSubmit = event => {
		event.preventDefault();
		this.props.showLoader()
		this.getActivity();
	}
	clearSearch = async () => {
		this.props.showLoader()
		this.myFormRef.reset()
		// let elems = document.querySelector("#start_date");
		// let instance = M.Datepicker.getInstance(elems);
		// instance.setInputValue();
		// elems = document.querySelector("#end_date");
		// instance = M.Datepicker.getInstance(elems);
		// instance.setInputValue();
		this.setState({ search: { type: "All", title: "", start_date: "", end_date: "", page: 0 } }, () => { this.getActivity(); })
	}
	handleGetStudentList = async id => {
		this.props.showLoader()
		// const authResponse = await this.auth();
		const { formData } = this.state;
		// if (authResponse) {
		const { config } = this.state
		const url = `${config.api_url}${urlEndPoints.enrolledUsers(id)}`

		// const headers = {
		// 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
		// }
		try {
			const data = await this.networkRequest(url)
			this.props.hideLoader();
			if (!data.data.message) {
				const studentList = _.orderBy(data.data.results, ["field_student_name"], ["asc"])
				this.setState({ studentList, selectedActivityId: id })

				let elems = document.querySelector("#enrollments");
				elems.setAttribute("aria-hidden", "false")
				elems.setAttribute("tabindex", "-1")
				let instance = M.Modal.getInstance(elems);
				instance.open();
			}
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
		// } else {
		// 	this.props.hideLoader()
		// }
	}
	handleCloseModal = () => {
		let elems = document.querySelector("#enrollments");
		elems.removeAttribute("aria-hidden")
		elems.removeAttribute("tabindex")
		let instance = M.Modal.getInstance(elems);
		instance.close();
	}
	handleDetailRedirect = id => {
		this.props.history.push(`/activity/${id}`);
	}
	handleCancelMultipleActivity = async () => {
		this.props.showLoader();
		const { config, checkedList, selectedActivityId } = this.state;
		const { lang } = this.props;
		if (!_.isEmpty(checkedList)) {
			// const authResponse = await this.auth();
			// if (authResponse) {
			const url = `${config.api_url}${urlEndPoints.cancelActivity}`
			const obj = {
				"activity_id": selectedActivityId,
				user_ids: checkedList
			}
			// const headers = {
			// 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
			// }
			try {
				const data = await this.networkRequest(url, "POST", obj);
				if (data.status == 200) {
					if (!_.isEmpty(data.data.error)) {
						this.props.showPopup({
							type: "error",
							title: this.context.t("_error"),
							message: data.data.error.name[lang],
							isOpen: true,
							buttonText: this.context.t("_ok")
						})
					} else {
						this.props.showPopup({
							type: "success",
							title: this.context.t("_success"),
							message: this.context.t("_successfully_cancelled"),
							isOpen: true,
							buttonText: this.context.t("_ok")
						})
					}
					this.handleCloseModal();
					this.getActivity()
				}
			} catch (error) {
				this.props.showPopup({
					type: "error",
					title: this.context.t("_failed"),
					message: this.context.t("_failed_to_cancel"),
					isOpen: true,
					buttonText: this.context.t("_ok")
				})
				this.props.hideLoader()
				console.warn("error: ", error)
			}
			// }
		} else {
			this.handleCloseModal();
			this.getActivity()
		}

	}
	scrollStep() {
		var scrollStepInPx = this.props.scrollStepInPx ? this.props.scrollStepInPx : this.state.scrollStepInPx;
		if (window.pageYOffset === 0) {
			clearInterval(this.state.intervalId);
		}
		window.scroll(0, window.pageYOffset - scrollStepInPx);
	}
	scrollToTop() {
		window.scroll({ top: 0, left: 0, behavior: "smooth" })
		// var delayInMs = this.props.delayInMs ? this.props.delayInMs : this.state.delayInMs;
		// let intervalId = setInterval(this.scrollStep.bind(this), delayInMs);
		// this.setState({ intervalId: intervalId });
	}
	handleCloseConfirmModal = (state) => {
		this.setState({ [state]: false, cancelActivityId: null })
	}
	handleOpenCancelActivityConfirm = id => {
		this.setState({ cancelActivityConfirm: true, cancelActivityId: id })
	}
	handleContinueCancelActivity = () => {
		const { cancelActivityId } = this.state;
		if (!_.isEmpty(cancelActivityId))
			this.handelCancelActivity(cancelActivityId)
		this.handleCloseConfirmModal("cancelActivityConfirm")
	}
	showStatus = status => {
		switch (status) {
			case "Unpublished":
				return this.context.t("_pending");
			case "Waiting List":
				return this.context.t("_waiting_list");

			default:
				return status;
		}
	}
	handleAttendanceBtn = row => {
		// row.field_start_and_end_time_2 >= Math.round(new Date().getTime() / 1000)) ?

		let minDate = new Date();
		const weekBefore = minDate.getDate() - 7
		minDate.setDate(weekBefore);
		minDate = Math.round(minDate.getTime() / 1000)
		return (localStorage.getItem("role") == "manager" || localStorage.getItem("role") == "edu_specialist") || (row.field_start_and_end_time_5 > minDate) ? <Link to={`/attendance/${row.nid}`} className="btn noWidth  blue-btn waves-effect waves-light">{this.context.t("_mark")}</Link> : null
		// return <Link to={`/attendance/${row.nid}`} className="btn noWidth  blue-btn waves-effect waves-light">Mark attendance</Link>

	}
	getDateInFormat = date => {
		// if (!_.isEmpty(date)) {
		if (date.includes("Jan"))
			date = date.replace("Jan", this.context.t("_jan"))

		if (date.includes("Feb"))
			date = date.replace("Feb", this.context.t("_feb"))

		if (date.includes("Mar"))
			date = date.replace("Mar", this.context.t("_mar"))

		if (date.includes("Apr"))
			date = date.replace("Apr", this.context.t("_apr"))

		if (date.includes("May"))
			date = date.replace("May", this.context.t("_may"))

		if (date.includes("Jun"))
			date = date.replace("Jun", this.context.t("_jun"))

		if (date.includes("Jul"))
			date = date.replace("Jul", this.context.t("_jul"))

		if (date.includes("Aug"))
			date = date.replace("Aug", this.context.t("_aug"))

		if (date.includes("Sep"))
			date = date.replace("Sep", this.context.t("_sep"))

		if (date.includes("Oct"))
			date = date.replace("Oct", this.context.t("_oct"))

		if (date.includes("Nov"))
			date = date.replace("Nov", this.context.t("_nov"))

		if (date.includes("Dec"))
			date = date.replace("Dec", this.context.t("_dec"))
		// }
		return date
	}
	render() {
		const { currentActivityList, pager, isLoaded, search, studentList, checkedList, cancelActivityConfirm } = this.state;
		const listActivity = search.type == "All" ? currentActivityList : currentActivityList.filter(data => data.field_event_type.toLowerCase() == search.type)
		const { lang } = this.props;

		return (
			<div id="main-content" className="activiti-list">
				<SlideToTop />
				<PageTitle title={this.context.t("_attendance")} />
				<div className="container">
					<nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
						<ul>
							<li className="breadcrumb-item">
								<Link to="/admin-profile">{this.context.t("_profile")}</Link> {" "}

							</li>
							<li className="breadcrumb-item">
								<Link tabIndex="0" to aria-current={this.context.t("_page")}>
									<span>{this.context.t("_attendance")} </span>
								</Link>

							</li>
						</ul>
					</nav>
				</div>
				<div id="main-container">
					<div id="skipContent" className="container">
						<form onSubmit={this.filterFormSubmit} ref={(el) => this.myFormRef = el}>
							<div className="row topFormHolder">
								<div className="col s12 m6 l3">
									<div className={`input-field mobileSelect item ${lang}`}>
										<label htmlFor="activityType" className="active" style={dynamicStyles.position(lang)}>{this.context.t("_activity_type")}</label>
										<select className="browser-default" id="activityType" value={search.title} onChange={evt => this.handleChange("title", evt.target.value)}>
											<option value="" disabled >{this.context.t("_select_activity_type")}</option>
											<option value="All" >{this.context.t("_all")}</option>
											<option value="competition">{this.context.t("_competitions")}</option>
											<option value="session">{this.context.t("_sessions")}</option>
											<option value="event">{this.context.t("_events")}</option>
											<option value="challenge">{this.context.t("_challenges")}</option>
											<option value="project">{this.context.t("_projects")}</option>
										</select>
										<span className="helper-text"></span>
									</div>
								</div>

								<div className="col s12 m6 l3 date-popup">
									<div className="input-field item">
										<DatePicker
											name="Date"
											placeholderText={this.context.t("_date")}
											// ref={(input) => { this[name] = input; }}
											id="start_date"
											autoComplete="off"
											dateFormat="yyyy-MM-dd"
											onChange={date =>
												this.handleChange("start_date", date)
											}
											value={search.start_date}
											selected={search.start_date}
											locale={calenderLocal(this.context)}
											customInput={<CustomDateInput ariaLabel={this.context.t("_select_start_date_button")} onFieldChange={date => this.handleChange("start_date", new Date(date))} />}
											onCalendarOpen={e => {
												let elems = document.querySelector(".react-datepicker");
												elems.setAttribute("tabindex", "0")
												elems && elems.focus()
												elems = document.querySelector(".react-datepicker__month-select");
												elems.setAttribute("aria-label", this.context.t("_select_month"))
												elems = document.querySelector(".react-datepicker__year-select");
												elems.setAttribute("aria-label", this.context.t("_select_year"))

											}}
											onCalendarClose={e => {
												var elems = document.querySelector("#start_date");
												elems.setAttribute("aria-label", `${this.context.t("_selected_date")} ${dateformat(search.start_date, "dd-mm-yyyy")}`)
												elems.setAttribute("value", dateformat(search.start_date, "yyyy-mm-dd"))
												setTimeout(() => {
													elems && elems.focus()
												}, 200);
											}}
											peekNextMonth
											showMonthDropdown
											showYearDropdown
											dropdownMode="select"
										/>
										<label htmlFor="start_date" style={dynamicStyles.position(lang)}>{this.context.t("_start_date")}</label>
									</div>
								</div>

								<div className="col s12 m6 l3 date-popup">
									<div className="input-field item">
										<DatePicker
											name="Date"
											placeholderText={this.context.t("_date")}
											// ref={(input) => { this[name] = input; }}
											id="end_date"
											autoComplete="off"
											dateFormat="yyyy-MM-dd"
											onChange={date =>
												this.handleChange("end_date", date)
											}
											value={search.end_date}
											selected={search.end_date}
											locale={calenderLocal(this.context)}
											customInput={<CustomDateInput ariaLabel={this.context.t("_select_end_date_button")} onFieldChange={date => this.handleChange("end_date", new Date(date))} />}
											onCalendarOpen={e => {
												let elems = document.querySelector(".react-datepicker");
												elems.setAttribute("tabindex", "0")
												elems && elems.focus()
												elems = document.querySelector(".react-datepicker__month-select");
												elems.setAttribute("aria-label", this.context.t("_select_month"))
												elems = document.querySelector(".react-datepicker__year-select");
												elems.setAttribute("aria-label", this.context.t("_select_year"))

											}}
											onCalendarClose={e => {
												var elems = document.querySelector("#end_date");
												elems.setAttribute("aria-label", `${this.context.t("_selected_date")} ${dateformat(search.end_date, "dd-mm-yyyy")}`)
												elems.setAttribute("value", dateformat(search.end_date, "yyyy-mm-dd"))
												setTimeout(() => {
													elems && elems.focus()
												}, 200);
											}}
											peekNextMonth
											showMonthDropdown
											showYearDropdown
											dropdownMode="select"
										/>
										<label htmlFor="end_date" style={dynamicStyles.position(lang)}>{this.context.t("_end_date")}</label>
									</div>
								</div>

								<div className="col s12 m6 l3 valign-wrapper center-align search-btn-holder">
									<button className="btn noWidth profileBtn yellow waves-effect waves-light">
										{this.context.t("_search")} <i className={`tiny material-icons ${lang}`} >{`${lang == "en" ? "arrow_forward" : "arrow_backward"}`}</i>
									</button>
									<button type="button" onClick={() => this.clearSearch()} className="btn noWidth  blue-btn waves-effect waves-light">
										{this.context.t("_clear")}
									</button>
									<br /><br />
								</div>
							</div>
						</form>

						<div className="row">
							<div className="col s7 result-status">
								{isLoaded ? this.context.t("_search_result") + " ( " + (listActivity.length) + " of " + pager.count + " )" : ""}

							</div>
							<div className="col s5 result-type">
								<div className="input-field mobileSelect col s12 filter-select">
									<label htmlFor="result-type">{this.context.t("_select_activity")}</label>
									<select className="browser-default" id="result-type" value={search.type} onChange={evt => this.handleChange("type", evt.target.value)}>
										<option value="All" >{this.context.t("_all")}</option>
										<option value="competition">{this.context.t("_competitions")}</option>
										<option value="session">{this.context.t("_sessions")}</option>
										<option value="event">{this.context.t("_events")}</option>
										<option value="challenge">{this.context.t("_challenges")}</option>
										<option value="project">{this.context.t("_projects")}</option>
									</select>
								</div>
							</div>
						</div>
						<div className="activities">
							{listActivity.map((row, index) => (
								<div className="row" key={index}>
									<div className="col s7">
										<Link aria-label={`${row.title} ${this.context.t("_start_date")} ${row.field_start_and_end_time_3[0]} ${this.context.t("_end_date")} ${row.field_start_and_end_time_4[row.field_start_and_end_time_4.length - 1]}`} to={`/activity/${row.nid}`}>
											<h2 className="title">{row.title} <span className={row.field_event_type.toLowerCase()}>{getType(row.field_event_type, this.context)}</span></h2>
										</Link>

										<div className="details">
											{row.field_start_and_end_time_3 &&
												<span><i className="calendar-icons"></i> {this.context.t("_start_date")} <span className="date">{this.getDateInFormat(row.field_start_and_end_time_3[0])}</span></span>
											}
											{row.field_start_and_end_time_4 &&
												<span><i className="calendar-icons"></i> {this.context.t("_end_date")} <span className="date">{this.getDateInFormat(row.field_start_and_end_time_4[row.field_start_and_end_time_4.length - 1])}</span></span>
											}
										</div>
									</div>
									<div className="col s5 btn-wrap flex-row justify-end align-center">
										{/* {
											localStorage.getItem("role") == "volenteer" ?
												null :
												localStorage.getItem("role") == "student" ?
													<button onClick={() => this.handleOpenCancelActivityConfirm(row.nid)} className="btn blue-border">Cancel</button>
													:
													<button onClick={() => this.handleGetStudentList(row.nid)} className="btn blue-border">Enrollments</button>
										} */}
										{this.handleAttendanceBtn(row)}
									</div>
								</div>
							))}
							{!currentActivityList.length && isLoaded &&
								<div className="row"><p aria-label={this.context.t("_no_result_found")} className="col s12 no-result">{this.context.t("_no_result_found")}</p></div>
							}
						</div>

						<nav className="pagination-wrapper" aria-label="pagination">
							{(currentActivityList.length && isLoaded) ? (
								<Pagination
									prevPageText={`< ${this.context.t("_previous")}`}
									nextPageText={`${this.context.t("_next")} >`}
									hideFirstLastPages={true}
									hideDisabled={true}
									linkClassPrev="linkClassPrev"
									linkClassNext="linkClassNext"
									activePage={pager.current_page + 1}
									itemsCountPerPage={pager.items_per_page}
									totalItemsCount={pager.count}
									pageRangeDisplayed={5}
									onChange={this.handlePageChange}
								/>) : ""
							}
						</nav>
					</div>
					<div className="sparkles">
						<span className="orange-circle"></span>
						<span className="multi-square two"><b><i></i></b></span>
						<span className="grey-square-rotate red-sq one"></span>
						<span className="grey-square-rotate red-sq two"></span>
					</div>
				</div>
				<div className="top-line ng-scope">
					<img src={topLineImage} alt="" />
				</div>
				<Modal
					isOpen={cancelActivityConfirm}
					style={customModalStyles}
					contentLabel="unlink parent confirm modal"
					ariaHideApp={false}
				>
					<div className="popupAlert warning">
						<br />
						<h2>Confirm</h2>
						<p>Are you sure want to cancel activity</p>
						<div className="row">
							<button className="col btn blue" onClick={() => this.handleCloseConfirmModal("cancelActivityConfirm")}>Cancel</button>
							<button className="col btn profileBtn yellow" onClick={() => this.handleContinueCancelActivity()}>Continue</button>
						</div>
					</div>
				</Modal>
			</div>
		)
	}
}

const mapStateToProps = ({ i18nState }) => {
	return {
		lang: i18nState.lang
	}
}
const mapDispatchToProps = {
	showLoader: LoadingAction.showLoader,
	hideLoader: LoadingAction.hideLoader,
	showPopup: (data) => PopupAction.showPopup(data),
	hidePopup: (data) => PopupAction.hidePopup(data),
}
ActivityHistory.contextTypes = {
	t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(ActivityHistory);
