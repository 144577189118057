import React from "react";
import { Link } from "react-router-dom";
import Main from "../../main";
import PageTitle from "../../includes/page_title.js";
import SlideToTop from "../../includes/slide_to_top.js";
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { dynamicStyles } from "./../../../constants/dynamicStyles"
import M from "materialize-css";
import { emailValidate, nameValidate, qidValidate, phoneValidate, nameWordLengthCheck } from "./../../../constants/common"
import { urlEndPoints } from "./../../../httpAPI/apiConfig"
import _ from "lodash"
import { LoadingAction, PopupAction, LoginAction } from "./../../../actions"
import dateformat from "dateformat";
import ReactCrop from "react-image-crop";
import topLineImage from "../../../assets/images/top-line.png"
import blankImage from "../../../assets/images/blank.png"
import "react-image-crop/dist/ReactCrop.css";

const today = new Date()
class ProfileSettings extends Main {
    constructor(props) {
        super(props);
        this.state = {
            config: this.config(),
            formData: {},
            mainData: {},
            profileImage: "",
            intervalId: 0,
            scrollStepInPx: 50,
            delayInMs: 16.66,
            selectedImage: "",
            croppedImageUrl: "",
            submissionStatus: false,
            submissionStatus_1: false,
            successMessage: null,
            file: "",
            crop: {
                unit: "px",
                width: 200,
                height: 201,
                aspect: 2 / 2.1,
            },
        }
        this.props.showLoader();
    }
    componentDidMount() {
        document.title = `${this.context.t("_studio5")} | ${this.context.t("_settings")}`
        this.initState();
        M.AutoInit();
        let modalOptions = {
            autoClose: true,
            dismissible: true
        };
        var options = {
            autoClose: true,
            onSelect: ((date) => {
                this.handleChange("dob", dateformat(date, "dd/mm/yyyy"))
            }),
            format: "dd/mm/yyyy",
            container: null,
            maxDate: today,
            minDate: new Date("01/01/1950"),
            yearRange: [1950, today.getFullYear()],
        };
        setTimeout(() => {
            // const elems = document.querySelectorAll("#dob");
            const modalelems = document.querySelectorAll(".modal");
            const selectelem = document.querySelectorAll("select");
            // M.Datepicker.init(elems, options);
            M.FormSelect.init(selectelem, options);
            M.Modal.init(modalelems, modalOptions);
        }, 1000);
        this.getProfileSettingsData();
        window.addEventListener('load', this.handleTabIndex());
    }
    componentDidUpdate() {
        document.title = `${this.context.t("_studio5")} | ${this.context.t("_settings")}`
    }
    handleTabIndex = () => {
        setTimeout(() => {
            let elem = document.querySelectorAll('select');
            elem.forEach(element => {
                element.setAttribute("tabindex", "0");
            });
        }, 1000);
    }
    initState = () => {
        this.setState({
            formData: {
                parentName: { value: "", error: "", class: "" },
                gender: { value: "", error: "", class: "" },
                parentId: { value: "", error: "", class: "" },
                dob: { value: "", error: "", class: "" },
                email: { value: "", error: "", class: "" },
                mobileNumber: { value: "", error: "", class: "" },
                channel: { value: "", error: "", class: "" }
            }
        })
    }
    handleChange = (field, value) => {
        const { formData, mainData } = this.state;
        formData[field]["class"] = value ? "" : "invalid";
        formData[field]["value"] = value;
        this.setState({ formData, submissionStatus: false });
    };
    renderInput = ({ name, label, placeholder, type = "text", isMandatory = false, max, disabled }) => {
        const { formData } = this.state;
        const { lang } = this.props;
        return (type === "phone_number" ?
            <div className="row mobile">
                <div className="col s4">
                    <div className="input-field item">
                        <input name={`${name}_country_code`}
                            placeholder="Country Code"
                            value={this.context.t("_ar_country_code")}
                            style={dynamicStyles.textAlign(lang)}
                            id={`${name}_country_code`}
                            type="text"
                            minLength="3"
                            maxLength="3"
                            disabled
                        // className={"validate " + registration_form.mobile.class}
                        // required
                        />
                        <label htmlFor={`${name}_country_code`} style={dynamicStyles.position(lang)}>{this.context.t("_country_code")}</label>
                    </div>
                </div>
                <div className="col s8">
                    <div className="input-field item">
                        <input
                            name={name}
                            placeholder={placeholder}
                            ref={(input) => { this[name] = input; }}
                            aria-label={this.context.t("_phone_aria_label")}
                            id={name}
                            maxLength={max}
                            type="text"
                            style={dynamicStyles.textAlign(lang)}
                            // readOnly={type === "date" ? "readonly" : false}
                            autoComplete="no"
                            onChange={evt =>
                                this.handleChange(name, evt.target.value)
                            }
                            onFocus={evt =>
                                this.handleChange(name, evt.target.value)
                            }
                            value={formData[name].value}
                        // className={"validate " + formData[name].class}
                        // required={isMandatory}
                        />
                        <label htmlFor={name} style={dynamicStyles.position(lang)}>
                            {`${label} `}
                            {isMandatory ? <span className="asterisk">*</span> : null}
                        </label>
                    </div>
                </div>
            </div>
            :
            <div className="input-field item">
                <input
                    name={name}
                    placeholder={placeholder}
                    ref={(input) => { this[name] = input; }}
                    id={name}
                    maxLength={max}
                    readOnly={disabled}
                    type={type === "date" ? "text" : type}
                    style={dynamicStyles.textAlign(lang)}
                    // readOnly={type === "date" ? "readonly" : false}
                    autoComplete="no"
                    onChange={evt =>
                        this.handleChange(name, evt.target.value)
                    }
                    onFocus={evt =>
                        this.handleChange(name, evt.target.value)
                    }
                    value={formData[name].value}
                // className={"validate " + formData[name].class}
                // required={isMandatory}
                />
                <label htmlFor={name} style={dynamicStyles.position(lang)}>
                    {`${label} `}
                    {isMandatory ? <span className="asterisk">*</span> : null}
                </label>
            </div>)
    }

    renderSelect = ({ name, label, placeholder, isMandatory = false, data = [], type = "select" }) => {
        const { formData } = this.state;
        const { lang } = this.props;
        return (
            <div className="input-field mobileSelect" style={{ marginTop: 43 }}>
                <label htmlFor={name} style={dynamicStyles.position(lang)}>
                    {`${label} `}
                    {isMandatory ? <span className="asterisk">*</span> : null}
                </label>
                <select
                    name={name}
                    id={name}
                    style={dynamicStyles.textAlign(lang)}
                    ref={(input) => { this[name] = input; }}
                    onChange={evt =>
                        this.handleChange(name, evt.target.value)
                    }

                    onFocus={evt =>
                        this.handleChange(name, evt.target.value)
                    }
                    value={formData[name].value}
                // className={"validate" + formData[name].class}
                >
                    <option value="" disabled> {placeholder} </option>
                    {
                        data.map((item, index) => {
                            return type === "country" ?
                                <option key={index} value={item.country_id}>{item.country[lang]}</option>
                                :
                                <option key={index} value={item.key}>{item.label}</option>
                        })
                    }
                </select>
            </div>
        )
    }
    renderRadioButton = ({ data = [], name, label, defaultChecked, isMandatory = false }) => {
        const { formData } = this.state;
        const { lang } = this.props;
        return (
            <div className="input-field item">
                <div className="input-radio valign-wrapper" role="radiogroup" aria-labelledby={name}>
                    {
                        data.map((item, index) => {
                            return (
                                <div key={index} className={item.value}>
                                    <input
                                        aria-checked={formData[name].value === item.value}
                                        name={name}
                                        id={item.value}
                                        aria-labelledby={`${name} ${item.label}`}
                                        type="radio"
                                        readOnly
                                        className="radio-btn"
                                        onClick={evt =>
                                            this.handleChange(name, item.value)
                                        }
                                        checked={formData[name].value === item.value}
                                    />
                                    <label id={`_${item.label}`} htmlFor={item.value} className="custom-radio-style">
                                        <span className="custom-radio-style">{item.label}</span>
                                    </label>
                                </div>
                            )
                        })
                    }

                </div>
                <label htmlFor={name} style={dynamicStyles.position(lang)}>
                    {`${label} `}
                    {isMandatory ? <span className="asterisk">*</span> : null}
                </label>

                <span
                    className="helper-text"
                    data-error="Required field."
                ></span>
            </div>
        )
    }
    getProfileSettingsData = async () => {
        // let authResponse = await this.auth();
        // if (authResponse) {
        const { config } = this.state
        let url = `${config.api_url}${urlEndPoints.userSettings}`
        // let headers = {
        //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
        // }
        try {
            const data = await this.networkRequest(url)
            let { formData } = this.state;
            if (!data.data.message) {
                formData = {
                    parentName: { value: data.data.name, error: "", class: "" },
                    // gender: { value: data.data.field_gender, error: "", class: "" },
                    parentId: { value: data.data.field_parent_qid, error: "", class: "" },
                    // dob: { value: data.data.field_student_dob, error: "", class: "" },
                    email: { value: data.data.field_parent_email, error: "", class: "" },
                    mobileNumber: { value: !_.isEmpty(data.data.field_parent_mobile_number) ? data.data.field_parent_mobile_number.replace("+974", "") : "", error: "", class: "" },
                    // channel: { value: data.data.field_channels_of_communication, error: "", class: "" }
                }
            }

            // setTimeout(() => {
            //     const [date, month, year] = data.data.field_student_dob.split("/");
            //     let elems = document.querySelector("#dob");
            //     let instance = M.Datepicker.getInstance(elems);
            //     instance.setDate(new Date(`${month}/${date}/${year}`));
            // }, 1000);
            localStorage.setItem("profileImage", `${config.origin}${data.data.picture}`)
            this.props.userLoggedIn(`${config.origin}${data.data.picture}`)
            this.props.hideLoader()
            this.setState({
                formData,
                mainData: formData,
                profileImage: data.data.picture,
                selectedImage: "",
                croppedImageUrl: "",
            })
        } catch (error) {
            this.props.hideLoader()
            console.warn("error: ", error)
        }
        // }
    }
    scrollStep() {
        var scrollStepInPx = this.state.scrollStepInPx;
        if (window.pageYOffset <= 400) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - scrollStepInPx);
    }
    scrollToTop() {
        window.scroll({ top: 0, left: 0, behavior: "smooth" })
        // var delayInMs = this.props.delayInMs ? this.props.delayInMs : this.state.delayInMs;
        // let intervalId = setInterval(this.scrollStep.bind(this), delayInMs);
        // this.setState({ intervalId: intervalId });
    }
    validate = () => {
        const { formData } = this.state;
        for (var key1 in formData) {
            if (formData[key1].value)
                formData[key1].value = formData[key1].value.trim();
            formData[key1].error = "";
        }

        if (formData.parentName.value === "")
            formData.parentName.error = this.context.t("_parent_name_required");

        if (formData.parentName.value !== "" && !nameValidate.test(formData.parentName.value))
            formData.parentName.error = this.context.t("_invalid_parent_name");

        if (formData.parentName.value !== "" && nameValidate.test(formData.parentName.value) && !nameWordLengthCheck(formData.parentName.value))
            formData.parentName.error = this.context.t("_parent_name_contains_atleast_two_word");

        if (formData.parentId.value === "")
            formData.parentId.error = this.context.t("_parent_qid_required");

        if (formData.parentId.value !== "" && !qidValidate.test(formData.parentId.value))
            formData.parentId.error = this.context.t("_invalid_parent_qid");

        // if (formData.dob.value === "")
        //     formData.dob.error = this.context.t("_date_of_birth_required");

        if (formData.email.value === "")
            formData.email.error = this.context.t("_email_required");

        if (formData.email.value !== "" && !emailValidate.test(formData.email.value))
            formData.email.error = this.context.t("_invalid_email");

        if (formData.mobileNumber.value === "")
            formData.mobileNumber.error = this.context.t("_mobile_number_required");

        if (formData.mobileNumber.value !== "" && !phoneValidate.test(formData.mobileNumber.value.replace(/ /g, "")))
            formData.mobileNumber.error = this.context.t("_invalid_mobile_number");

        for (var key in formData) {
            if (formData[key].error !== "") {
                this.setState({ formData, submissionStatus: false, submissionStatus_1: false })
                this.scrollToTop();
                return false;

            }
        }
        this.setState({ formData })
        return true;
    }
    displayError = error => {
        const { formData } = this.state;
        const { lang } = this.props;
        if (error.mail)
            formData.email.error = error.mail[lang]

        if (error.parent_mobile_number)
            formData.mobileNumber.error = error.parent_mobile_number[lang]

        if (error.qid)
            formData.parentId.error = error.qid[lang]

        this.setState({ formData })
        this.scrollToTop();
    }

    onSubmit = async (event) => {
        event.preventDefault();
        this.props.showLoader()
        this.setState({ submissionStatus_1: false, submissionStatus: false })
        const { formData, config } = this.state;
        if (this.validate()) {
            // let authResponse = await this.auth();
            // if (authResponse) {
            let obj = {
                "name": formData.email.value,
                "mail": formData.email.value,
                "field_parent_name": formData.parentName.value,
                "field_parent_email": formData.email.value,
                // "field_gender": formData.gender.value,
                "field_qid": formData.parentId.value,
                // "field_student_dob": formData.dob.value,
                "roles": {
                    "target_id": "parent"
                },
                "parent_mobile_number": formData.mobileNumber.value.replace(/ /g, ""),
                // "communication_value": formData.channel.value
            }
            // let headers = {
            //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
            // }
            let url = `${config.api_url}${urlEndPoints.registerParent}`
            try {
                const data = await this.networkRequest(url, "PATCH", obj)
                if (data.data.error) {
                    this.props.hideLoader();
                    this.displayError(data.data.error)
                }
                else {
                    this.setState({ submissionStatus: true, submissionStatus_1: true, successMessage: this.context.t("_profile_updated_successfully") })
                    this.getProfileSettingsData();
                    this.scrollToTop();
                }
            } catch (error) {
                this.props.hideLoader();
                console.warn("error: ", error)
            }
            // } else {
            //     this.props.hideLoader();
            // }
        } else {
            this.props.hideLoader();
        }
    }
    onChangeFile(event) {
        event.stopPropagation();
        event.preventDefault();
        const that = this;
        var file = event.target.files[0];
        if (file) {
            if (file.type.includes("image")) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function (e) {
                    var img = new Image;
                    img.onload = function () {
                        // if (img.width < 250) {
                        //     return that.props.showPopup({
                        //         type: "error",
                        //         title: "Invalid",
                        //         message: "Image size should be greater than 250px!",
                        //         isOpen: true,
                        //         buttonText: "Ok"
                        //     })
                        // } else if (img.width >= img.height) {
                        //     return that.props.showPopup({
                        //         type: "error",
                        //         title: "Invalid",
                        //         message: "Image height should be greater than width!",
                        //         isOpen: true,
                        //         buttonText: "Ok"
                        //     })
                        // } else {
                        //     that.saveUserImage(file, reader.result)
                        // }
                        that.setState({ selectedImage: reader.result, file, submissionStatus: false, submissionStatus_1: false })
                        let elems = document.querySelector("#photoCrop");
                        elems.setAttribute("aria-hidden", "false")
                        elems.setAttribute("tabindex", "-1")
                        let instance = M.Modal.getInstance(elems);
                        instance.open();
                        elems = document.querySelector(".ReactCrop");
                        elems.setAttribute("aria-label", that.context.t("_link_crop_image"))
                    };
                    img.src = reader.result;
                };
                reader.onloadend = () => {

                    // this.saveUserImage(file, reader.result)
                };
            } else {
                this.props.showPopup({
                    type: "error",
                    title: this.context.t("_invalid"),
                    message: this.context.t("_invalid_file_type"),
                    isOpen: true,
                    buttonText: this.context.t("_ok")
                })
            }
        }

    }
    saveUserImage = async () => {
        const { config, croppedImageUrl, file } = this.state;
        if (!_.isEmpty(croppedImageUrl)) {
            this.handleCloseModal();
            this.props.showLoader()
            // const authResponse = await this.auth();
            // if (authResponse) {
            let url = `${config.api_url}${urlEndPoints.updateUserPic}`
            // let headers = {
            //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
            // }
            let obj = {
                name: file.name,
                image: croppedImageUrl
            }
            try {
                const data = await this.networkRequest(url, "POST", obj);
                if (data.status === 200) {
                    this.setState({ submissionStatus: true, submissionStatus_1: true, successMessage: this.context.t("_profile_image_updated_successfully") })
                    this.getProfileSettingsData()
                }
            } catch (error) {
                this.props.hideLoader()
                console.warn("error: ", error)
            }

            // } else {
            //     this.props.hideLoader()
            // }
        }

    }
    removeProfilePic = async () => {
        // let authResponse = await this.auth();
        // if (authResponse) {
        this.props.showLoader()
        const { config } = this.state
        let url = `${config.api_url}${urlEndPoints.removeUserPic}`
        // let headers = {
        //     Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
        // }
        let obj = {}
        try {
            const data = await this.networkRequest(url, "POST", obj);
            if (data.status === 200) {
                this.setState({ submissionStatus: true, submissionStatus_1: true, successMessage: this.context.t("_profile_image_removed_successfully") })
                this.getProfileSettingsData()
            }
        } catch (error) {
            this.props.hideLoader()
            console.warn("error: ", error)
        }
        // }
    }
    handleCloseModal = () => {
        this.setState({
            selectedImage: "",
            croppedImageUrl: "",
            file: "",
        })
        this.upload.value = "";
        let elems = document.querySelector("#photoCrop");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        let instance = M.Modal.getInstance(elems);
        instance.close();
        this.refs.changeProfileImageBtn.focus()
    }
    onImageLoaded = image => {
        this.imageRef = image;
    };
    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
            this.setState({ croppedImageUrl });
        }
    }
    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            resolve(canvas.toDataURL());
        });
    }
    handleChangePassword = () => {
        this.props.history.push("/change-password")
    }
    render() {
        const { formData, submissionStatus, submissionStatus_1, profileImage, config, crop, selectedImage, successMessage } = this.state
        const { lang } = this.props;
        let hasError = false;
        Object.keys(formData).map((item, index) => {
            if (formData[item].error !== "")
                hasError = true
        })
        return (
            <div id="main-container">
                <SlideToTop />
                <PageTitle title={this.context.t("_settings")} />
                <div id="skipContent" className="container">
                    <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                        <ul>
                            <li className="breadcrumb-item">
                                <Link to="/profile">{this.context.t("_profile")}</Link> {" "}

                            </li>
                            <li className="breadcrumb-item">
                                <Link tabIndex="0" to aria-current={this.context.t("_page")}>
                                    <span>
                                        {this.context.t("_settings")}
                                    </span>
                                </Link>

                            </li>
                        </ul>
                    </nav>
                </div>
                <div>
                    <div className="container">
                        <div className="row">
                            <div className="col s3 text-center">
                                <figure className="profilePic">
                                    <img src={blankImage} alt="" />
                                    <span>
                                        <img src={`${config.origin}${profileImage}`} alt="" />
                                    </span>
                                    <button aria-label={this.context.t("_change_profile_image")}
                                        type="button" ref="changeProfileImageBtn" onClick={() => { this.upload.click() }} className="btn changePic">
                                        <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                    </button>
                                    <input id="myInput"
                                        type="file"
                                        ref={(ref) => this.upload = ref}
                                        style={{ display: "none" }}
                                        onChange={this.onChangeFile.bind(this)}
                                    />
                                    <button aria-label={this.context.t("_remove_profile_image")} onClick={() => this.removeProfilePic()} type="button" className="btn removePic">
                                        <i className="fa fa-trash" aria-hidden="true"></i>
                                    </button>
                                </figure>
                            </div>
                            <div className="col s9">
                                {/* <div className="btn-wrap type-selection profileBtnHolder">
                                    <button className="btn profileBtn yellow" onClick={() => { this.upload.click() }}>{this.context.t("_upload_new_photo")}</button>
                                    <input id="myInput"
                                        type="file"
                                        ref={(ref) => this.upload = ref}
                                        style={{ display: "none" }}
                                        onChange={this.onChangeFile.bind(this)}
                                    />
                                    <button onClick={() => this.removeProfilePic()} className="btn profileBtn">{this.context.t("_remove")}</button>
                                </div> */}
                            </div>
                        </div>

                        <div className="row reverse-row">
                            {
                                hasError ? <div className="error-container fail">
                                    <h5>{this.context.t("_error_title")}</h5>
                                    <ul>
                                        {
                                            Object.keys(formData).map((item, index) => {
                                                return formData[item].error !== "" ? <li key={index} ref={(refItem) => { this[item + "error"] = refItem; }}><label className="error" htmlFor={item}>{formData[item].error}</label></li>
                                                    :
                                                    null
                                            })}
                                    </ul>
                                </div> : null

                            }

                            {
                                (submissionStatus || submissionStatus_1) ? <div className="error-container success">
                                    <h5>{successMessage}</h5>
                                </div> : null
                            }
                            {
                                _.isEmpty(formData) ?
                                    <div /> :
                                    <form className="full-width" onSubmit={this.onSubmit} >

                                        <div className="col s12 m6">
                                            {this.renderInput({ name: "parentName", label: this.context.t("_name"), placeholder: this.context.t("_name_placeholder"), isMandatory: true })}
                                            {this.renderInput({ name: "parentId", label: this.context.t("_qid"), placeholder: this.context.t("_qid_placeholder"), isMandatory: true, max: 11, disabled: true })}
                                            {/* {
                                                this.renderRadioButton({ label: this.context.t("_gender"), isMandatory: true, name: "gender", defaultChecked: "male", data: [{ label: this.context.t("_male"), value: "Male" }, { label: this.context.t("_female"), value: "Female" }] })
                                            } */}
                                        </div>
                                        <div className="col s12 m6">
                                            {/* <div className=" date-popup">
                                                {this.renderInput({ name: "dob", label: this.context.t("_dob"), placeholder: this.context.t("_dob_placeholder"), isMandatory: true, type: "date" })}
                                            </div> */}
                                            {this.renderInput({ name: "mobileNumber", label: this.context.t("_mobile_number"), placeholder: this.context.t("_mobile_number_placeholder"), isMandatory: true, type: "phone_number", max: 8 })}
                                            {this.renderInput({ name: "email", label: this.context.t("_email"), placeholder: this.context.t("_email_placeholder"), isMandatory: true, type: "email", disabled: true })}
                                            {/* {
                                                this.renderRadioButton({ label: this.context.t("_channel_of_communication"), name: "channel", defaultChecked: "sms", data: [{ label: this.context.t("_email"), value: "Email Address" }, { label: this.context.t("_sms"), value: "Mobile Number" }, { label: this.context.t("_phone_call"), value: "Phone Call" }] })
                                            } */}
                                        </div>
                                        <div className="col s12 flex-row">
                                            <button className="btn blue" style={{ verticalAlign: "top" }}>{this.context.t("_save_changes")}</button>
                                            &nbsp;&nbsp;&nbsp;
                                            <button type="button" onClick={() => { this.handleChangePassword() }} className="btn profileBtn margin0">{this.context.t("_change_password")}</button>
                                        </div>
                                        <div className="col s6">
                                        </div>
                                    </form>
                            }
                        </div>
                        <div id="photoCrop" className="modal sm" role="dialog">
                            <div className="modal-content scroll-x-hidden">
                                <span className="badge modalClose"><button type="button" aria-label="close" tabindex="0" className="close" onClick={() => this.handleCloseModal()}><i className="material-icons">close</i></button></span>
                                <p>{this.context.t("_crop_your_image")}</p>
                                <div className="image-crop-container">
                                    <ReactCrop
                                        // crossorigin="anonymous"
                                        src={selectedImage}
                                        crop={crop}
                                        ruleOfThirds
                                        minWidth={200}
                                        minHeight={200}
                                        keepSelection={true}
                                        imageStyle={{ maxHeight: 250 }}
                                        onImageLoaded={this.onImageLoaded}
                                        onComplete={this.onCropComplete}
                                        onChange={this.onCropChange}
                                    />
                                </div>

                                <div className="clearfix" />
                                <button onClick={() => this.saveUserImage()} className="btn profileBtn yellow">{this.context.t("_upload")}</button>
                                <button onClick={() => this.handleCloseModal()} className="btn profileBtn blue-border">{this.context.t("_cancel")}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="top-line ng-scope">
                    <img src={topLineImage} alt="" />
                </div>
            </div>
        )
    }

}

const mapStateToProps = ({ i18nState }) => {
    return {
        lang: i18nState.lang
    }
}
const mapDispatchToProps = {
    showPopup: (data) => PopupAction.showPopup(data),
    hidePopup: (data) => PopupAction.hidePopup(data),
    showLoader: LoadingAction.showLoader,
    hideLoader: LoadingAction.hideLoader,
    userLoggedIn: LoginAction.userLoggedIn,
}
ProfileSettings.contextTypes = {
    t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(ProfileSettings);
