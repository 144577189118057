import { HIDE_POPUP, SHOW_POPUP } from "./../constants/constants"

const showPopup = ({ type, title, message, buttonText }) => {
    console.warn("message :", message)
    const obj = { type, title, message, isOpen: true, buttonText }
    return async dispatch => {
        dispatch({
            type: SHOW_POPUP, payload: obj
        })
    }

}
const hidePopup = ({ type, title, message, buttonText }) => {
    const obj = { type, title, message, isOpen: false, buttonText }
    return async dispatch => {
        dispatch({
            type: HIDE_POPUP, payload: obj
        })
    }
}

export default {
    showPopup,
    hidePopup
}