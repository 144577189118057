import React from "react"
import Main from "../../main"
import SlideToTop from "../../includes/slide_to_top.js"
import PageTitle from "../../includes/page_title.js"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { dynamicStyles, customModalStyles } from "./../../../constants/dynamicStyles"
import { Link } from "react-router-dom"
import { urlEndPoints } from "./../../../httpAPI/apiConfig"
import M from "materialize-css"
import { LoadingAction, PopupAction } from "./../../../actions"
import _ from "lodash"
import topLineImage from "../../../assets/images/top-line.png"
import { qidValidate, emailValidate } from "./../../../constants/common"
import Modal from "react-modal";

class EnrolmentStudentList extends Main {
    constructor(props) {
        super(props);
        this.checkLogin();
        this.state = {
            isLoaded: false,
            config: this.config(),
            currentActivityList: [],
            pager: { count: 0, pages: 0, items_per_page: 0, current_page: 1 },
            search: { type: "All", title: "", start_date: "", end_date: "", page: 0 },
            studentList: [],
            checkedList: [],
            intervalId: 0,
            scrollStepInPx: 50,
            delayInMs: 16.66,
            cancelActivityConfirm: false,
            cancelActivityId: null,
            formData: {}
        }
        this.props.showLoader()
    }
    async componentDidMount() {
        document.title = `${this.context.t("_studio5")} | ${this.context.t("_enrollments")}`
        this.initState();
        M.AutoInit();
        this.handleGetStudentList()
        // await this.getActivity();
        setTimeout(() => {
            let elems = document.querySelectorAll(".modal");
            M.Modal.init(elems, {
                autoClose: true,
                dismissible: true
            });

        }, 200);
    }
    componentDidUpdate() {
        document.title = `${this.context.t("_studio5")} | ${this.context.t("_enrollments")}`
    }
    initState = () => {
        this.setState({
            formData: {
                studentId: { value: "", error: "", class: "" },
                email: { value: "", error: "", class: "" },
            }
        })
    }
    calculateAge = (dateString) => {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
    handleCheck = (event, id, index) => {
        const { checkedList } = this.state
        if (event.target.checked)
            checkedList[index] = id
        else
            delete checkedList[index]
        this.setState({ checkedList })

    }
    handelCancelActivity = async id => {
        this.props.showLoader();
        const { config } = this.state;
        const { lang } = this.props;
        // const authResponse = await this.auth();
        // if (authResponse) {
        let url = ""
        if (localStorage.getItem("role") == "volenteer")
            url = `${config.api_url}${urlEndPoints.volunteerRemoveActivity}`
        else
            url = `${config.api_url}${urlEndPoints.cancelActivity}`
        const obj = {
            "activity_id": id
        }
        // const headers = {
        // 	Authorization: `${authResponse.data.token_type} ${authResponse.data.access_token}`
        // }
        try {
            const data = await this.networkRequest(url, "POST", obj);
            if (data.status == 200) {
                if (!_.isEmpty(data.data.error)) {
                    this.props.showPopup({
                        type: "error",
                        title: this.context.t("_error"),
                        message: data.data.error.name[lang],
                        isOpen: true,
                        buttonText: this.context.t("_ok")
                    })
                } else {
                    this.props.showPopup({
                        type: "success",
                        title: this.context.t("_success"),
                        message: this.context.t("_successfully_cancelled"),
                        isOpen: true,
                        buttonText: this.context.t("_ok")
                    })
                }

                this.getActivity()
            }
        } catch (error) {
            this.props.showPopup({
                type: "error",
                title: this.context.t("_failed"),
                message: this.context.t("_failed_to_cancel"),
                isOpen: true,
                buttonText: this.context.t("_ok")
            })
            this.props.hideLoader()
            console.warn("error: ", error)
        }
        // }
    }
    handleGetStudentList = async () => {
        this.props.showLoader()
        // const authResponse = await this.auth();
        const { match: { params } } = this.props
        const { config } = this.state
        const url = `${config.api_url}${urlEndPoints.getEnrolledStudents(params.activityId)}`
        try {
            const data = await this.networkRequest(url)
            this.props.hideLoader();
            if (!data.data.message) {
                const studentList = _.orderBy(data.data.results, ["field_student_name"], ["asc"])
                this.setState({ studentList })
            }
            setTimeout(() => {
                document.getElementById("pageHeading") && document.getElementById("pageHeading").focus();
            }, 500);
        } catch (error) {
            this.props.hideLoader()
            console.warn("error: ", error)
        }
        // } else {
        // 	this.props.hideLoader()
        // }
    }
    handleCloseModal = () => {
        this.initState();
        let elems = document.querySelector("#checkQid");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        let instance = M.Modal.getInstance(elems);
        instance.close();
        elems = document.querySelector("#addStudent");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        instance = M.Modal.getInstance(elems);
        instance.close();
    }
    handleDetailRedirect = id => {
        this.props.history.push(`/activity/${id}`);
    }
    scrollStep() {
        var scrollStepInPx = this.props.scrollStepInPx ? this.props.scrollStepInPx : this.state.scrollStepInPx;
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - scrollStepInPx);
    }
    scrollToTop() {
        window.scroll({ top: 0, left: 0, behavior: "smooth" })
    }
    handleCloseConfirmModal = (state) => {
        this.setState({ [state]: false, cancelActivityId: null })
    }
    handleOpenCancelActivityConfirm = id => {
        this.setState({ cancelActivityConfirm: true, cancelActivityId: id })
    }
    handleContinueCancelActivity = () => {
        const { cancelActivityId } = this.state;
        if (!_.isEmpty(cancelActivityId))
            this.handelCancelActivity(cancelActivityId)
        this.handleCloseConfirmModal("cancelActivityConfirm")
    }
    showStatus = status => {
        switch (status) {
            case "Unpublished":
                return this.context.t("_pending");
            case "Waiting List":
                return this.context.t("_waiting_list");

            default:
                return status;
        }
    }
    handleCancelEnrolment = async (studentId) => {
        this.props.showLoader();
        const { match: { params } } = this.props
        const { config } = this.state;
        const { lang } = this.props;
        if (!_.isEmpty(studentId)) {
            const url = `${config.api_url}${urlEndPoints.cancelActivity}`
            const obj = {
                "activity_id": params.activityId,
                "user_ids": {
                    0: studentId
                }
            }
            try {
                const data = await this.networkRequest(url, "POST", obj);
                if (data.status == 200) {
                    if (!_.isEmpty(data.data.error)) {
                        this.props.showPopup({
                            type: "error",
                            title: this.context.t("_error"),
                            message: data.data.error.name[lang],
                            isOpen: true,
                            buttonText: this.context.t("_ok")
                        })
                    } else {
                        this.props.showPopup({
                            type: "success",
                            title: this.context.t("_success"),
                            message: this.context.t("_successfully_cancelled"),
                            isOpen: true,
                            buttonText: this.context.t("_ok")
                        })
                    }
                    // this.handleCloseModal();
                    this.handleGetStudentList()
                }
            } catch (error) {
                this.props.showPopup({
                    type: "error",
                    title: this.context.t("_failed"),
                    message: this.context.t("_failed_to_cancel"),
                    isOpen: true,
                    buttonText: this.context.t("_ok")
                })
                this.props.hideLoader()
                console.warn("error: ", error)
            }
        }

    }
    handleOpenQidCheck = () => {
        const { formData } = this.state;
        for (var key in formData) {
            formData[key].error = "";
        }
        this.setState({ formData })
        let elems = document.querySelector("#checkQid");
        elems.setAttribute("aria-hidden", "false")
        elems.setAttribute("tabindex", "-1")
        let instance = M.Modal.getInstance(elems);
        instance.open();
    }

    validate = (type) => {
        const { formData } = this.state;
        for (var key in formData) {
            formData[key].value = formData[key].value.trim();
            formData[key].error = "";
        }
        if (type == "qid") {
            if (formData.studentId.value == "")
                formData.studentId.error = this.context.t("_qid_required");

            if (formData.studentId.value != "" && !qidValidate.test(formData.studentId.value))
                formData.studentId.error = this.context.t("_invalid_qid");

        } else {
            // if (formData.parentName.value == "")
            //     formData.parentName.error = this.context.t("_parent_name_required");

            // if (formData.parentName.value != "" && !nameValidate.test(formData.parentName.value))
            //     formData.parentName.error = this.context.t("_invalid_parent_name");

            // if (formData.parentName.value != "" && nameValidate.test(formData.parentName.value) && !nameWordLengthCheck(formData.parentName.value))
            //     formData.parentName.error = this.context.t("_parent_name_contains_atleast_two_word");

            // if (formData.dob.value == "")
            //     formData.dob.error = this.context.t("_date_of_birth_required");

            if (_.isEmpty(formData.email.value))
                formData.email.error = this.context.t("_email_required");

            if (formData.email.value != "" && !emailValidate.test(formData.email.value))
                formData.email.error = this.context.t("_invalid_email");

            // if (formData.mobileNumber.value == "")
            //     formData.mobileNumber.error = this.context.t("_mobile_number_required");

            // if (formData.mobileNumber.value != "" && !phoneValidate.test(formData.mobileNumber.value))
            //     formData.mobileNumber.error = this.context.t("_invalid_mobile_number");
        }
        for (var key in formData) {
            if (formData[key].error != "") {
                this.setState({ formData, submissionStatus: false })
                // this.scrollToTop();
                setTimeout(() => {
                    this.refs[key].focus()
                }, 1000);
                return false;

            }
        }
        return true;
    }
    displayError = error => {
        const { formData } = this.state;
        const { lang } = this.props;
        if (error.qid)
            formData.studentId.error = error.qid[lang]

        if (error.name)
            formData.name.error = error.name[lang]

        if (error.mail)
            formData.email.error = error.mail[lang]

        if (error.DOB)
            formData.dob.error = error.DOB[lang]

        for (var key in formData) {
            if (formData[key].error != "") {
                this.setState({ formData })
                setTimeout(() => {
                    // this.refs.errorListRef.focus()
                    this.refs[key].focus()
                }, 1000);
                return false;

            }
        }

    }
    handleCheckQid = async (event) => {
        event.preventDefault();
        const { formData, config } = this.state;

        if (this.validate("qid")) {
            this.props.showLoader()
            const url = `${config.api_url}${urlEndPoints.checkStudentQid(formData.studentId.value)}`
            try {
                const data = await this.networkRequest(url)
                if (data.status == 200) {
                    if (data.data.error) {
                        this.props.hideLoader()
                        this.displayError(data.data.error)
                    } else {
                        if (data.data && data.data.length == 0) {
                            this.props.hideLoader()
                            this.handleOpenAddStudentModal();
                        } else {
                            // this.enrollActivity(data.data[0].uid)
                            this.handleCheckStudentEnrolment(data.data[0].uid)
                            // const parentList = await this.getMyParents();
                            // this.setState({ parentList })
                            // this.props.hideLoader()
                            this.handleCloseModal()
                        }
                    }
                }
            } catch (error) {
                this.props.hideLoader()
                console.warn("error: ", error)
            }
        }
    }
    handleCheckStudentEnrolment = async id => {
        const { match: { params } } = this.props
        const { config } = this.state
        const { lang } = this.props
        const url = `${config.api_url}${urlEndPoints.checkEnrolment(params.activityId, id)}`
        try {
            const data = await this.networkRequest(url)
            this.props.hideLoader();
            if (!data.data.message && data.data.results && data.data.results.length > 0) {
                // const studentList = _.orderBy(data.data.results, ["field_student_name"], ["asc"])
                // this.setState({ studentList, selectedActivityId: id })

                // let elems = document.querySelector("#enrollments");
                // let instance = M.Modal.getInstance(elems);
                // instance.open();
                this.props.showPopup({
                    type: "error",
                    title: this.context.t("_error"),
                    message: this.context.t("_student_already_enrolled"),
                    isOpen: true,
                    buttonText: this.context.t("_ok")
                })
            } else {
                this.enrollActivity(id)
            }
        } catch (error) {
            this.props.hideLoader()
            console.warn("error: ", error)
        }
    }
    enrollActivity = async (id) => {
        const { lang } = this.props;
        const { match: { params } } = this.props
        if (params.activityId) {
            this.props.showLoader();
            const { config } = this.state
            const url = `${config.api_url}${urlEndPoints.activityEnrolment}`
            const obj = {
                "activity_id": params.activityId,
                "user_ids": { "0": id }
            }
            try {
                const data = await this.networkRequest(url, "POST", obj)
                if (data.data.error) {
                    this.props.showPopup({
                        type: "error",
                        title: this.context.t("_error"),
                        message: `${data.data.error.name[lang]}`,
                        isOpen: true,
                        buttonText: this.context.t("_ok")
                    })
                } else {
                    const message = this.context.t("_enroll_success");
                    this.props.showPopup({
                        type: "success",
                        title: this.context.t("_success"),
                        message,
                        isOpen: true,
                        buttonText: this.context.t("_ok")
                    })
                }
                this.handleGetStudentList()
            } catch (error) {
                this.props.hideLoader()
                console.warn("error: ", error)
            }
        }
    }

    handleInviteStudent = async (event) => {
        event.preventDefault();
        const { formData, config } = this.state;
        const { lang } = this.props
        if (this.validate()) {
            this.props.showLoader()
            let obj = {
                email: formData.email.value
            }
            const url = `${config.api_url}${urlEndPoints.sendInvitation}`
            try {
                const data = await this.networkRequest(url, "POST", obj)
                if (data.data.error) {
                    this.displayError(data.data.error)
                    this.props.hideLoader()
                }
                else {
                    this.props.showPopup({
                        type: "success",
                        title: this.context.t("_success"),
                        message: data.data.message[lang],
                        isOpen: true,
                        buttonText: this.context.t("_ok")
                    })
                    this.handleCloseModal();
                    this.handleGetStudentList()
                    // this.getStudentList()
                }
            } catch (error) {
                this.props.hideLoader()
                console.warn("error: ", error)
            }
        }
    }
    handleOpenAddStudentModal = () => {
        const { formData } = this.state;
        for (var key in formData) {
            formData[key].error = "";
        }
        this.setState({ formData })
        let elems = document.querySelector("#checkQid");
        elems.removeAttribute("aria-hidden")
        elems.removeAttribute("tabindex")
        let instance = M.Modal.getInstance(elems);
        instance.close();
        elems = document.querySelector("#addStudent");
        elems.setAttribute("aria-hidden", "false")
        elems.setAttribute("tabindex", "-1")
        instance = M.Modal.getInstance(elems);
        instance.open();
    }
    handleFormChange = (field, value, state) => {
        if (field == "parentQid" || field == "studentId")
            if (isNaN(value))
                return false
        const tempState = this.state[state];
        tempState[field]["class"] = value ? "" : "invalid";
        tempState[field]["value"] = value;
        this.setState({ [state]: tempState });
    };
    render() {
        const { currentActivityList, pager, isLoaded, search, studentList, formData, cancelActivityConfirm } = this.state;
        const listActivity = search.type == "All" ? currentActivityList : currentActivityList.filter(data => data.field_event_type.toLowerCase() == search.type)
        const { lang } = this.props;
        let hasError = false;
        Object.keys(formData).map(item => {
            if (formData[item].error != "")
                hasError = true
        })
        return (
            <div id="main-content" className="activiti-list">
                <SlideToTop />
                <PageTitle title={this.context.t("_enrollments")} />
                <div className="container">
                    <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
                        <ul>
                            <li className="breadcrumb-item">
                                <Link to="/admin-profile">{this.context.t("_profile")}</Link> {" "}

                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/admin-enrolment">{this.context.t("_enrolment_listing")}</Link> {" "}

                            </li>

                            <li className="breadcrumb-item">
                                <Link tabIndex="0" to aria-current={this.context.t("_page")}>
                                    <span>{this.context.t("_enrollments")} </span>
                                </Link>

                            </li>
                        </ul>
                    </nav>
                </div>
                <div id="main-container">
                    <div id="skipContent" className="container">
                        <div>
                            {
                                _.isEmpty(studentList) ?
                                    <div className="page-not-found center">
                                        <br />
                                        <h4>{this.context.t("_no_student_to_list")}</h4>
                                    </div>
                                    :
                                    <div className="tableHolder">

                                        <table className="responsive-table highlight">
                                            <thead className="blue lighten-5">
                                                <tr>
                                                    <th><strong>{this.context.t("_name")}</strong></th>
                                                    <th><strong>{this.context.t("_qid")}</strong></th>
                                                    <th><strong>{this.context.t("_phone_number")}</strong></th>
                                                    <th><strong>{this.context.t("_grade")}</strong></th>
                                                    <th><strong>{this.context.t("_gender")}</strong></th>
                                                    <th><strong>{this.context.t("_age")}</strong></th>
                                                    <th width="50"><span style={{ display: "none" }}>delete</span></th>
                                                    {/* <th width="50"><strong></strong></th> */}
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {
                                                    studentList.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{item.field_student_name}</td>
                                                                <td>{item.field_qid}</td>
                                                                <td>{item.field_student_mobile}</td>
                                                                <td>{item.field_grade}</td>
                                                                <td>{item.field_gender}</td>
                                                                <td>{!_.isEmpty(item.field_student_dob) ? this.calculateAge(item.field_student_dob) : null}</td>
                                                                <td>
                                                                    <i aria-label={`delete ${item.field_student_name}`} onClick={() => this.handleCancelEnrolment(item.field_student)} className="material-icons attendanceClose tooltipped" data-position="right" data-tooltip="Remove enrolment">delete_forever</i>
                                                                </td>
                                                                {/* <td>
                                                                {
                                                                    item.field_published_status == "Published" ? <p className="checkboxHolder"><label><input onChange={(e) => this.handleCheck(e, item.uid, index)} checked={checkedList[index] ? true : false} type="checkbox" /><span></span></label></p> : this.showStatus(item.field_published_status)
                                                                }
                                                            </td> */}
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        <br />
                                    </div>
                            }
                            <button type="button" onClick={() => { this.handleOpenQidCheck() }} className="btn noWidth  blue-btn waves-effect waves-light pull-right">{this.context.t("_add_student")}</button>
                            <br />
                            <div id="checkQid" className="modal sm" role="dialog">
                                <div className="modal-content modal-no-close">
                                    {
                                        _.isEmpty(formData) ?
                                            <div /> :
                                            <div>
                                                <span className="badge modalClose"><button className="close" aria-label="close" onClick={() => this.handleCloseModal()}><i className="material-icons">close</i></button></span>
                                                <h2 className="line">{this.context.t("_add_student")}</h2>
                                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit.</p> */}
                                                {hasError ? <div className="error-container fail">
                                                    <h5>{this.context.t("_error_title")}</h5>
                                                    <ul>
                                                        {formData.studentId.error != "" ? <li ><a ref="studentId" tabIndex="0" href="#studentId" className="error" htmlFor="studentId">{formData.studentId.error}</a></li> : null}
                                                    </ul>
                                                </div> : null
                                                }
                                                <form onSubmit={this.handleCheckQid} className="row full-width">
                                                    <div className="col s12">
                                                        <div className="input-field item">
                                                            <input
                                                                placeholder={this.context.t("_parent_id_placeholder")}
                                                                type="text"
                                                                name="studentId"
                                                                id="studentId"
                                                                maxLength={11}
                                                                onChange={(evt) => this.handleFormChange("studentId", evt.target.value, "formData")}
                                                                onFocus={(evt) => this.handleFormChange("studentId", evt.target.value, "formData")}
                                                                value={formData.studentId.value}
                                                            />
                                                            <label htmlFor="studentId" style={dynamicStyles.position(lang)}>{this.context.t("_student_qid")} <span className="asterisk">*</span></label>
                                                            <span
                                                                style={dynamicStyles.textAlign(lang)}
                                                                className="helper-text"
                                                                data-error="Required field."
                                                            ></span>
                                                        </div>
                                                    </div>

                                                    <div className="col s12">
                                                        <button type="button" onClick={this.handleCloseModal} className="btn profileBtn none waves-effect waves-dark">{this.context.t("_cancel")}</button>
                                                        <button className="btn profileBtn blue noWidth waves-effect waves-light">{this.context.t("_add")}</button>
                                                    </div>
                                                </form>
                                            </div>
                                    }
                                </div>
                            </div>
                            <div id="addStudent" className="modal sm user-add-modal" role="dialog">
                                <div className="modal-content modal-no-close">
                                    {
                                        _.isEmpty(formData) ?
                                            <div /> :
                                            <form onSubmit={this.handleInviteStudent} className="col full-width">
                                                <span className="badge modalClose"><a onClick={() => this.handleCloseModal()}><i className="material-icons">close</i></a></span>
                                                <h2 className="line">{this.context.t("_add_student")}</h2>

                                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit, tellus arcu maximus ipsum, ut pulvinar erat lacus at libero.</p> */}
                                                {hasError ? <div className="error-container fail">
                                                    <h5>{this.context.t("_error_title")}</h5>
                                                    <ul>
                                                        {formData.email.error != "" ? <li ><a ref="email" tabIndex="0" href="#email" className="error" htmlFor="email">{formData.email.error}</a></li> : null}
                                                    </ul>
                                                </div> : null
                                                }
                                                <p>{this.context.t("_required_field")}{" "} <span className="asterisk">*</span></p>

                                                <div className="row">
                                                    <div className="col s12">
                                                        <div className="input-field item">
                                                            <input
                                                                placeholder={this.context.t("_parent_email_address_placeholder")}
                                                                type="text"
                                                                name="email"
                                                                id="email"
                                                                onChange={(evt) => this.handleFormChange("email", evt.target.value, "formData")}
                                                                onFocus={(evt) => this.handleFormChange("email", evt.target.value, "formData")}
                                                                value={formData.email.value}
                                                            />
                                                            <label htmlFor="email" style={dynamicStyles.position(lang)}>{this.context.t("_parent_email_address")} <span className="asterisk">*</span></label>
                                                            <span
                                                                style={dynamicStyles.textAlign(lang)}
                                                                className="helper-text"
                                                                data-error="Required field."
                                                            ></span>
                                                        </div>
                                                    </div>
                                                    <div className="col s12" >
                                                        <button className="btn profileBtn blue waves-effect waves-light">{this.context.t("_send_invitation")}</button>
                                                        <button type="button" onClick={() => this.handleCloseModal()} className="btn profileBtn waves-effect waves-light  blue-border">{this.context.t("_close")}</button>
                                                    </div>
                                                    <div className="clearfix" />
                                                </div>
                                            </form>
                                    }
                                </div>
                            </div>
                            {/* <button onClick={() => { this.handleCancelMultipleActivity() }} className="btn blue-border">Cancel</button> */}
                            {/* </form> */}
                            {/* {
									_.isEmpty(studentFormData) ?
										<div /> :
										<form onSubmit={this.handleAddNewStudent} className="col full-width">
											<span className="badge modalClose"><a onClick={() => this.handleCloseModal()}><i className="material-icons">close</i></a></span>
											<h2 className="line">{this.context.t("_add_student")}</h2>

											<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus, felis vel imperdiet blandit, tellus arcu maximus ipsum, ut pulvinar erat lacus at libero.</p>
											{hasError ? <div className="error-container fail">
												<h5>{this.context.t("_error_title")}</h5>
												<ul>
													{
														Object.keys(studentFormData).map((item, index) => {
															return studentFormData[item].error != "" ? <li key={index} ref={(refItem) => { this[item + "error"] = refItem; }}><label className="error" htmlFor={item}>{studentFormData[item].error}</label></li>
																:
																null
														})}
												</ul>
											</div> : null
											}
											<p>{this.context.t("_required_field")}{" "} <span className="asterisk">*</span></p>

											<div className="row">
												<div className="col s12 m6">
													{this.renderInput({ name: "name", label: this.context.t("_student_name"), placeholder: this.context.t("_student_name_placeholder"), isMandatory: true })}
													{this.renderInput({ name: "email", label: this.context.t("_email"), placeholder: this.context.t("_email_placeholder"), type: "email" })}

												</div>
												<div className="col s12 m6">
													<div className="date-popup">
														{this.renderInput({ name: "dob", label: this.context.t("_dob"), placeholder: this.context.t("_dob_placeholder"), isMandatory: true, type: "date" })}
													</div>

													{this.renderInput({ name: "mobile", label: this.context.t("_mobile_number"), placeholder: this.context.t("_mobile_number_placeholder"), isMandatory: true, type: "phone_number" })}
												</div>
												<div className="col s12">
													<button className="btn profileBtn blue waves-effect waves-light">{this.context.t("_save_changes")}</button>
												</div>
												<div className="clearfix" />
											</div>
										</form>
								} */}
                        </div>
                    </div>
                    <div className="sparkles">
                        <span className="orange-circle"></span>
                        <span className="multi-square two"><b><i></i></b></span>
                        <span className="grey-square-rotate red-sq one"></span>
                        <span className="grey-square-rotate red-sq two"></span>
                    </div>
                </div>
                <div className="top-line ng-scope">
                    <img src={topLineImage} alt="" />
                </div>
                <Modal
                    isOpen={cancelActivityConfirm}
                    style={customModalStyles}
                    contentLabel="unlink parent confirm modal"
                    ariaHideApp={false}
                >
                    <div className="popupAlert warning">
                        <br />
                        <h3>{this.context.t("_confirm")}</h3>
                        <p>{this.context.t("_cancel_activity_confirm")}</p>
                        <div className="row">
                            <button className="col btn blue" onClick={() => this.handleCloseConfirmModal("cancelActivityConfirm")}>{this.context.t("_cancel")}</button>
                            <button className="col btn profileBtn yellow" onClick={() => this.handleContinueCancelActivity()}>{this.context.t("_continue")}</button>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = ({ i18nState }) => {
    return {
        lang: i18nState.lang
    }
}
const mapDispatchToProps = {
    showLoader: LoadingAction.showLoader,
    hideLoader: LoadingAction.hideLoader,
    showPopup: (data) => PopupAction.showPopup(data),
    hidePopup: (data) => PopupAction.hidePopup(data),
}
EnrolmentStudentList.contextTypes = {
    t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(EnrolmentStudentList);
