import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Main from "../main";
import PageTitle from "../includes/page_title.js";
import SlideToTop from "../includes/slide_to_top.js";
import PropTypes from "prop-types"
import Pagination from "react-js-pagination";
import { LoadingAction } from "./../../actions"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import topLineImage from "../../assets/images/top-line.png"
const mailIcon = require("../../assets/images/mail.svg");
class MediaCenter extends Main {
  constructor(props) {
    super(props);
    this.checkLogin();
    this.state = {
      isLoaded: false,
      pager: { count: 0, pages: 0, items_per_page: 0, current_page: 1 },
      config: this.config(),
      search: { page: 0 },
      error: "",
      filter: [],
      media: []
    };
    this.handlePageChange = this.handlePageChange.bind(this);
    this.props.showLoader()
  }
  async componentDidMount() {
    document.title = `${this.context.t("_studio5")} | ${this.context.t("_navbar_media_center")}`
    this.filterMenu();
    await this.getMediaList();
    setTimeout(() => {
      document.getElementById("pageHeading") && document.getElementById("pageHeading").focus()
    }, 500);
  }
  componentDidUpdate() {
    document.title = `${this.context.t("_studio5")} | ${this.context.t("_navbar_media_center")}`
  }
  getMediaList = async (isPagination) => {
    const { config, search } = this.state
    const { lang } = this.props;
    let url = "";
    if (lang == "ar")
      url = `${config.origin}${lang}/api/${urlEndPoints.mediaCenter}`
    else
      url = `${config.origin}api/${urlEndPoints.mediaCenter}`

    if (search.page)
      url = `${url}&page=${search.page}`
    try {
      const data = await this.networkRequest(url)
      this.setState({
        media: data.data.results,
        pager: data.data.pager,
        isLoaded: true
      })
      this.props.hideLoader()
      if (isPagination)
        this.refs.mediaCenterRef0 && this.refs.mediaCenterRef0.focus()
    } catch (error) {
      this.setState({ isLoaded: true })
      this.props.hideLoader()
      console.warn("error: ", error)
    }
  }
  filterMenu = async () => {
    const { config } = this.state
    const { lang } = this.props;
    let url = "";
    if (lang == "ar")
      url = `${config.api_url}${urlEndPoints.filter(lang)}`
    else
      url = `${config.api_url}${urlEndPoints.filter(lang)}`
    try {
      const data = await this.networkRequest(url)
      this.setState({ filter: data.data })
    } catch (error) {
      this.props.hideLoader()
      console.warn("error: ", error)
    }
  }
  handlePageChange(pageNumber) {
    this.props.showLoader()
    var { search } = this.state;
    search.page = pageNumber - 1;
    this.setState({ search: search });
    this.getMediaList(true);
  }
  render() {
    const { filter, media, config, pager, isLoaded } = this.state;
    const { lang } = this.props;
    return (
      <>
        <div id="main-content" className="media-center-page">
          <SlideToTop />
          <PageTitle title={this.context.t("_navbar_media_center")} isDashboardItem={true} />
          <div className="container">
            <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
              <ul>
                <li className="breadcrumb-item">
                  <Link to="/">{this.context.t("_home")}</Link> {" "}
                </li>
                <li className="breadcrumb-item">
                  <Link tabIndex="0" to aria-current={this.context.t("_page")}>
                    <span>{this.context.t("_navbar_media_center")}</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div id="skipContent">
            <div className="container">
              <div className="row">
                <div className="col s3 left-side">
                  <div className="filter-wrap">
                    <h3 id="filter-title">{this.context.t("_filter_media")}</h3>
                    <ul aria-label="aside navigation">
                      {filter.map((row, index) => (
                        <li className={`media-item ${lang}`} key={index}>
                          <Link aria-label={row.filter + " " + this.context.t("_section") + " " + this.context.t("_section {number}", { number: row.count })} to={"/filter-media/" + row.tid}>
                            {row.filter}
                            <span>{row.count}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                    <span className="grey-square-rotate"></span>
                    <span className="multi-square">
                      <b>
                        <i></i>
                      </b>
                    </span>
                  </div>
                </div>
                <div className="col s9 media-content">
                  <span className="orange-circle"></span>
                  <div className="flex-row col-3">
                    {isLoaded && media.length == 0 ? <div className="row"><p aria-label={this.context.t("_no_result_found")} tabIndex="0" className="col s12 no-result">{this.context.t("_no_result_found")}</p></div> : <ul aria-label="media center">
                      {media.map((row, index) => (
                        <li ref={`mediaCenterRef${index}`} className="media-item" key={index}>
                          <div
                            dangerouslySetInnerHTML={{ __html: row.category }}
                            className={"category-wrap " + row.field_key.toLowerCase()}
                          ></div>
                          <h3>
                            <Link aria-label={`${row.category} ${row.title} ${row.date}`} to={"/news/" + row.nid}>{row.title}</Link>
                          </h3>
                          <div className="mediaList">
                            <div className="medialistImg">
                              {row.field_key === "Videos" && (
                                <div className="videowrapper">
                                  <object data={row.video}>
                                    <param name="movie" value={row.video}></param>
                                    <param
                                      name="allowFullScreen"
                                      mozallowfullscreen="mozallowfullscreen"
                                      msallowfullscreen="msallowfullscreen"
                                      oallowfullscreen="oallowfullscreen"
                                      webkitallowfullscreen="webkitallowfullscreen"
                                      value="true"
                                    ></param>
                                    <span>video</span>
                                  </object>
                                </div>
                              )}
                              {(row.field_key === "Gallery" ||
                                row.field_key === "News") && (
                                  <div
                                    // dangerouslySetInnerHTML={{ __html: row.media }}
                                    className="img-wrap imageFixedSize"
                                  >
                                    <img alt="" src={config.origin + row.field_newsimage} />
                                  </div>
                                )}

                            </div>
                            <div className="medialistText">
                              <div
                                className="desc"
                                dangerouslySetInnerHTML={{ __html: row.description }}
                              ></div>
                              <div
                                className="date-wrap"
                                dangerouslySetInnerHTML={{ __html: row.date }}
                              ></div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>}
                  </div>
                  <nav className="pagination-wrapper" aria-label="pagination">
                    {(media.length) ? (
                      <Pagination
                        prevPageText={`< ${this.context.t("_previous")}`}
                        nextPageText={`${this.context.t("_next")} >`}
                        hideFirstLastPages={true}
                        hideDisabled={true}
                        linkClassPrev="linkClassPrev"
                        linkClassNext="linkClassNext"
                        activePage={pager.current_page + 1}
                        itemsCountPerPage={pager.items_per_page}
                        totalItemsCount={pager.count}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange}
                      />) : ""
                    }
                  </nav>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="top-line ng-scope">
          <img src={topLineImage} alt="" />
        </div>
        <div id="register-wrap" className="ng-scope">
          <div className="container">
            <div><img src={mailIcon} className="mail-icon" alt="" /></div>
            <p>{this.context.t("_latest_insight")}</p>
            <p><Link to="/subscribe" className="red-btn btn">{this.context.t("_subscribe")} <i className={`material-icons ${lang}`}>{`${lang == "en" ? "arrow_forward" : "arrow_backward"}`}</i></Link></p>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ i18nState }) => {
  return {
    lang: i18nState.lang
  }
}
const mapDispatchToProps = {
  showLoader: LoadingAction.showLoader,
  hideLoader: LoadingAction.hideLoader,
}
MediaCenter.contextTypes = {
  t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(MediaCenter);
