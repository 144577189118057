import React from "react";
import Main from "../main";
import { Link } from "react-router-dom";
import RegisterTypes from "./registerTypes"
import PageTitle from "../includes/page_title.js";
import SlideToTop from "../includes/slide_to_top.js";
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { dynamicStyles } from "./../../constants/dynamicStyles"
import M from "materialize-css";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { emailValidate, nameValidate, qidValidate, phoneValidate, nameWordLengthCheck, calenderI18n, CustomDateInput, calenderLocal } from "./../../constants/common"
import _ from "lodash"
import { LoadingAction } from "./../../actions"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import dateformat from "dateformat";
import topLineImage from "../../assets/images/top-line.png"

class VolunteerRegistration extends Main {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      config: this.config(),
      intervalId: 0,
      scrollStepInPx: 50,
      delayInMs: 16.66,
      submissionStatus: false,
      termsAndCondition: {}
    }
  }
  componentDidMount() {
    document.title = `${this.context.t("_studio5")} | ${this.context.t("_registration")} | ${this.context.t("_volunteer")}`
    // this.refs.volunteerHeading.focus()
    this.isLoggedInUsers();
    this.initState();
    this.getTermsAndCondition();
    M.AutoInit();
    let today = new Date()
    today.setFullYear(today.getFullYear() - 18);
    var options = {
      autoClose: true,
      onSelect: ((date) => {
        this.handleChange("dob", dateformat(date, "dd/mm/yyyy"))
      }),
      defaultDate: new Date("01/01/2001"),
      format: "dd-mm-yyyy",
      container: null,
      minDate: new Date("01/01/1950"),
      yearRange: 20,
      maxDate: today,
      maxYear: 2002
    };
    setTimeout(() => {
      options.i18n = calenderI18n(this.context);
      var elems = document.querySelectorAll("#dob");
      options.i18n = calenderI18n(this.context);
      // M.Datepicker.init(elems, options);
    }, 1000);
  }
  componentDidUpdate() {
    document.title = `${this.context.t("_studio5")} | ${this.context.t("_registration")} | ${this.context.t("_volunteer")}`
  }

  getTermsAndCondition = async () => {
    const { config } = this.state
    const { lang } = this.props
    let url = "";
    if (lang == "ar")
      url = `${config.origin}${lang}/${urlEndPoints.termsAndCondition}`
    else
      url = `${config.origin}${urlEndPoints.termsAndCondition}`
    try {
      const data = await this.networkRequest(url)
      if (data.status == 200 && data.data && data.data.length > 0) {
        this.setState({ termsAndCondition: data.data[0] })
      }
    } catch (error) {
      this.props.hideLoader()
      console.warn("error: ", error)
    }
  }
  initState = () => {
    this.setState({
      formData: {
        name: { value: "", error: "", class: "" },
        nameArabic: { value: "", error: "", class: "" },
        qid: { value: "", error: "", class: "" },
        gender: { value: "", error: "", class: "" },
        dob: { value: "", error: "", class: "" },
        email: { value: "", error: "", class: "" },
        mobile: { value: "", error: "", class: "" },
        channel: { value: "", error: "", class: "" },
        agree: { value: false, error: "", class: "" },
      }
    })
  }
  handleChange = (field, value) => {
    if (field == "qid")
      if (isNaN(value))
        return false
    const { formData } = this.state;
    formData[field]["class"] = value ? "" : "invalid";
    formData[field]["value"] = value;
    this.setState({ formData, submissionStatus: false });
  };
  renderInput = ({ name, label, placeholder, type = "text", isMandatory = false, max, styleClass = "" }) => {
    const { formData } = this.state;
    const { lang } = this.props;
    return (type == "phone_number" ?
      <div className="row mobile">
        <div className="col s4">
          <div className="input-field item">
            <input name={`${name}_country_code`}
              placeholder="Country Code"
              value={this.context.t("_ar_country_code")}
              id={`${name}_country_code`}
              type="text"
              className={styleClass}
              minLength="3"
              maxLength="3"
              disabled
            // className={"validate " + registration_form.mobile.class}
            // required
            />
            <label htmlFor={`${name}_country_code`} style={dynamicStyles.position(lang)}>{this.context.t("_country_code")}</label>
            <span className="helper-text" data-error="Enter a valid code."></span>
          </div>
        </div>
        <div className="col s8">
          <div className="input-field item">
            <input
              name={name}
              placeholder={placeholder}
              ref={(input) => { this[name] = input; }}
              id={name}
              className={styleClass}
              maxLength={max}
              type="text"
              aria-label={this.context.t("_phone_aria_label")}
              // readOnly={type === "date" ? "readonly" : false}
              autoComplete="no"
              onChange={evt =>
                this.handleChange(name, evt.target.value)
              }
              onFocus={evt =>
                this.handleChange(name, evt.target.value)
              }
              value={formData[name].value}
            // className={"validate " + formData[name].class}
            // required={isMandatory}
            />
            <label htmlFor={name} style={dynamicStyles.position(lang)}>
              {`${label} `}
              {isMandatory ? <span className="asterisk">*</span> : null}
            </label>
            <span
              className="helper-text"
              data-error={formData[name].error ? formData[name].error : "Required field."}
            ></span>
          </div>
        </div>
      </div>
      : type == "date" ? <div className="input-field item">
        <DatePicker
          name={name}
          placeholderText={placeholder}
          ref={(input) => { this[name] = input; }}
          id={name}
          autoComplete="off"
          className={styleClass}
          // maxDate={new Date("12/31/2014")}
          minDate={new Date("01/01/1950")}
          dateFormat="yyyy-MM-dd"
          onChange={date =>
            this.handleChange(name, date)
          }
          style={dynamicStyles.textAlign(lang)}
          value={formData[name].value}
          selected={formData[name].value}
          locale={calenderLocal(this.context)}
          customInput={<CustomDateInput ariaLabel={this.context.t("_select_dob_button")} onFieldChange={date => this.handleChange(name, new Date(date))} />}
          onCalendarOpen={e => {
            let elems = document.querySelector(".react-datepicker");
            elems.setAttribute("tabindex", "0")
            elems.focus()
            elems = document.querySelector(".react-datepicker__month-select");
            elems.setAttribute("aria-label", this.context.t("_select_month"))
            elems = document.querySelector(".react-datepicker__year-select");
            elems.setAttribute("aria-label", this.context.t("_select_year"))

          }}
          onCalendarClose={e => {
            var elems = document.querySelector(".hiddenDob");
            elems.setAttribute("aria-label", `${this.context.t("_selected_date")} ${dateformat(formData[name].value, "dd-mm-yyyy")}`)
            elems.setAttribute("value", dateformat(formData[name].value, "yyyy-mm-dd"))
            setTimeout(() => {
              elems.focus()
            }, 200);
          }}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
        <label htmlFor={name} style={dynamicStyles.position(lang)}>
          {`${label} `}
          {isMandatory ? <span className="asterisk">*</span> : null}
        </label>
        <span
          style={dynamicStyles.textAlign(lang)}
          className="helper-text"
          data-error={formData[name].error ? formData[name].error : "Required field."}
        ></span>
      </div> :
        <div className="input-field item">
          <input
            name={name}
            placeholder={placeholder}
            ref={(input) => { this[name] = input; }}
            id={name}
            className={styleClass}
            maxLength={max}
            type={type === "date" ? "text" : type}
            // readOnly={type === "date" ? "readonly" : false}
            autoComplete="no"
            onChange={evt =>
              this.handleChange(name, evt.target.value)
            }
            onFocus={evt =>
              this.handleChange(name, evt.target.value)
            }
            value={formData[name].value}
          // className={"validate " + formData[name].class}
          // required={isMandatory}
          />
          <label htmlFor={name} style={dynamicStyles.position(lang)}>
            {`${label} `}
            {isMandatory ? <span className="asterisk">*</span> : null}
          </label>
          <span
            className="helper-text"
            data-error={formData[name].error ? formData[name].error : "Required field."}
          ></span>
        </div>)
  }
  renderRadioButton = ({ data = [], name, label, defaultChecked, isMandatory = false }) => {
    const { formData } = this.state;
    const { lang } = this.props;
    return (
      <div className="input-field item">
        <div className="input-radio valign-wrapper" role="radiogroup" aria-labelledby={name}>
          {
            data.map((item, index) => {
              return (
                <div key={index} className={item.value}>
                  <input
                    aria-checked={formData[name].value === item.value}
                    name={name}
                    id={item.value}
                    aria-labelledby={`${name} ${item.label}`}
                    type="radio"
                    readOnly
                    className="radio-btn"
                    onClick={evt =>
                      this.handleChange(name, item.value)
                    }
                    checked={formData[name].value === item.value}
                  />
                  <label id={`_${item.label}`} htmlFor={item.value} className="custom-radio-style">
                    <span className="custom-radio-style">{item.label}</span>
                  </label>
                </div>
              )
            })
          }

        </div>
        <label htmlFor={name} id={name} style={dynamicStyles.position(lang)}>
          {`${label} `}
          {isMandatory ? <span className="asterisk">*</span> : null}
        </label>
        <span
          className="helper-text"
          data-error="Required field."
        ></span>
      </div>
    )
  }
  renderCheckbox = ({ label, name }) => {
    const { lang } = this.props;
    const { formData } = this.state;
    return (
      <div className="item subscribe-items">
        <p>
          <input type="checkbox"
            name={name}
            id={name}
            style={dynamicStyles.textAlign(lang)}
            ref={(input) => { this[name] = input; }}
            onChange={(event) => this.handleChange(name, event.target.checked)}
            checked={formData[name].value}
          />
          <label for={name}>
            <span className={lang}>{this.context.t("_i_agree")}
              <button type="button" style={{ border: 0, background: "none" }} aria-label={this.context.t("_read_terms_condition")} tabindex="0" className="modal-trigger underline-text" onClick={() => this.handleOpenModal()}>{label}</button>
            </span>
          </label>
        </p>
      </div>

    )
  }
  scrollStep() {
    var scrollStepInPx = this.state.scrollStepInPx;
    if (window.pageYOffset <= 400) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - scrollStepInPx);
  }
  scrollToTop() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" })
    // var delayInMs = this.props.delayInMs ? this.props.delayInMs : this.state.delayInMs;
    // let intervalId = setInterval(this.scrollStep.bind(this), delayInMs);
    // this.setState({ intervalId: intervalId });
  }
  validate = () => {
    const { formData } = this.state;
    for (var key in formData) {
      formData[key].value = typeof (formData[key].value) == "boolean" || key == "dob" ? formData[key].value : formData[key].value.trim();
      formData[key].error = "";
    }
    if (formData.name.value == "")
      formData.name.error = this.context.t("_volunteer_name_required");

    if (formData.name.value != "" && !nameValidate.test(formData.name.value))
      formData.name.error = this.context.t("_invalid_volunteer_name");

    if (formData.name.value != "" && nameValidate.test(formData.name.value) && !nameWordLengthCheck(formData.name.value))
      formData.name.error = this.context.t("_volunteer_name_contains_atleast_two_word");

    if (formData.nameArabic.value != "" && nameValidate.test(formData.nameArabic.value) && !nameWordLengthCheck(formData.nameArabic.value))
      formData.nameArabic.error = this.context.t("_volunteer_name_contains_atleast_two_word");

    if (formData.qid.value == "")
      formData.qid.error = this.context.t("_qid_required");

    if (formData.qid.value != "" && !qidValidate.test(formData.qid.value))
      formData.qid.error = this.context.t("_invalid_qid");

    if (formData.gender.value == "")
      formData.gender.error = this.context.t("_gender_required");

    if (formData.dob.value == "")
      formData.dob.error = this.context.t("_date_of_birth_required");

    if (formData.email.value == "")
      formData.email.error = this.context.t("_email_required");

    if (formData.email.value != "" && !emailValidate.test(formData.email.value))
      formData.email.error = this.context.t("_invalid_email");

    if (formData.mobile.value == "")
      formData.mobile.error = this.context.t("_mobile_number_required");

    if (formData.mobile.value != "" && !phoneValidate.test(formData.mobile.value.replace(/ /g, "")))
      formData.mobile.error = this.context.t("_invalid_mobile_number");

    // if (formData.channel.value == "")
    //   formData.channel.error = this.context.t("_channel_of_communication_required");

    if (!(formData.agree.value))
      formData.agree.error = this.context.t("_terms_and_condition_agree");

    for (var key in formData) {
      if (formData[key].error != "") {
        this.setState({ formData, submissionStatus: false })
        this.scrollToTop();
        setTimeout(() => {
          this[key + "error"].focus()
        }, 1000);
        return false;

      }
    }
    this.setState({ formData })
    return true;
  }
  displayError = error => {
    const { formData } = this.state;
    const { lang } = this.props;
    if (error.mail)
      formData.email.error = error.mail[lang]

    if (error.field_volenteer_mobile)
      formData.mobile.error = error.field_volenteer_mobile[lang]

    if (error.qid)
      formData.qid.error = error.qid[lang]

    if (error.dob)
      formData.dob.error = error.dob[lang]

    for (var key in formData) {
      if (formData[key].error != "") {
        this.setState({ formData })
        this.scrollToTop();
        setTimeout(() => {
          this[key + "error"].focus()
        }, 1000);
        return false;

      }
    }
    this.setState({ formData })
    this.scrollToTop();
  }
  onSubmit = async (event) => {
    event.preventDefault();
    const { formData, config } = this.state;
    if (this.validate()) {
      this.props.showLoader()
      let obj = {
        "name": formData.email.value,
        "mail": formData.email.value,
        "field_volenteer_name": formData.name.value,
        "field_volenteer_name_arabic": formData.nameArabic.value,
        "field_volenteer_email": formData.email.value,
        "field_gender": formData.gender.value,
        "field_qid": formData.qid.value,
        "field_student_dob": dateformat(formData.dob.value, "dd/mm/yyyy"),
        "roles": {
          "target_id": "volenteer"
        },
        "field_volenteer_mobile": `+974${formData.mobile.value.replace(/ /g, "")}`,
        // "field_channels_of_communication": formData.channel.value
      }
      let url = `${config.api_url}${urlEndPoints.registerVolunteer}`
      try {
        const data = await this.networkRequest(url, "POST", obj, null, true)
        if (data.data.error) {
          this.displayError(data.data.error)
        } else {
          this.setState({ submissionStatus: true })
          this.initState();
          M.AutoInit();
          // this.scrollToTop();
          this.refs.successMessageRef.focus()
        }
        this.props.hideLoader()
      } catch (error) {
        this.props.hideLoader()
        console.warn("error: ", error)
      }
    }
  }
  handleCloseModal = () => {
    let elems = document.querySelector("#termsAndConditionModal");
    elems.removeAttribute("aria-hidden")
    elems.removeAttribute("tabindex")
    let instance = M.Modal.getInstance(elems);
    instance.close();
  }
  handleOpenModal = () => {
    let elems = document.querySelector("#termsAndConditionModal");
    elems.setAttribute("aria-hidden", "false")
    elems.setAttribute("tabindex", "-1")
    let instance = M.Modal.getInstance(elems);
    instance.open();
  }
  render() {
    const { formData, submissionStatus, termsAndCondition } = this.state;
    const { lang } = this.props;
    let hasError = false;
    Object.keys(formData).map((item, index) => {
      if (formData[item].error != "")
        hasError = true
    })
    return (
      <div id="main-container">
        <SlideToTop />
        <PageTitle title={this.context.t("_registration")} />
        <div className="container">
          <nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
            <ul>
              <li className="breadcrumb-item">
                <Link to="/registration">{this.context.t("_registration")}</Link> {" "}

              </li>
              <li className="breadcrumb-item">
                <Link tabIndex="0" to aria-current={this.context.t("_page")}>
                  <span>
                    {this.context.t("_volunteer")}
                  </span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <ColouredItems />
        <div id="skipContent" className="container registration-form">
          <RegisterTypes {...this.props} />
          <h2 tabIndex="0" ref="volunteerHeading" aria-label={this.context.t("_for_volunteer_form")} className="primary-heading">{this.context.t("_for_volunteer")}</h2>
          <div className="row">
            <div className="col s6 form-container">
              <p>
                {this.context.t("_required_field")}{" "}
                <span className="asterisk">*</span>
              </p>
              {
                (hasError && !submissionStatus) ? <div className="error-container fail">
                  <h5>{this.context.t("_error_title")}</h5>
                  <ul aria-label="error" >
                    {
                      Object.keys(formData).map((item, index) => {
                        return formData[item].error != "" ? <li key={index} ><a href={`#${item}`} ref={(refItem) => { this[item + "error"] = refItem; }} tabIndex="0" className="error" htmlFor={item}>{formData[item].error}</a></li>
                          :
                          null
                      })}
                  </ul>
                </div> : null

              }
              {
                submissionStatus ? <div className="error-container success" tabIndex="0" ref="successMessageRef">
                  <h5>{this.context.t("_success_title_student_1")}</h5>
                  <p>{this.context.t("_success_title_student_2")}</p>
                  <p>{this.context.t("_success_title_student_3")}</p>
                </div> : null
              }
              {
                _.isEmpty(formData) ?
                  <div /> :
                  <form onSubmit={this.onSubmit} autoComplete="off">
                    {this.renderInput({ name: "name", label: this.context.t("_name_english"), placeholder: this.context.t("_name_english_placeholder"), isMandatory: true })}
                    {this.renderInput({ name: "nameArabic", label: this.context.t("_name_arabic"), placeholder: this.context.t("_name_arabic_placeholder"), isMandatory: false })}
                    {this.renderInput({ name: "qid", label: this.context.t("_qid"), placeholder: this.context.t("_qid_placeholder"), isMandatory: true, max: 11 })}
                    {
                      this.renderRadioButton({ label: this.context.t("_gender"), isMandatory: true, name: "gender", defaultChecked: "male", data: [{ label: this.context.t("_male"), value: "Male" }, { label: this.context.t("_female"), value: "Female" }] })
                    }
                    <div className=" date-popup">
                      {this.renderInput({ name: "dob", label: this.context.t("_dob"), placeholder: this.context.t("_dob_placeholder"), isMandatory: true, type: "date" })}
                    </div>
                    {this.renderInput({ name: "email", label: this.context.t("_email"), placeholder: this.context.t("_email_placeholder"), isMandatory: true, type: "email", styleClass: "fontEnglish" })}
                    {this.renderInput({ name: "mobile", label: this.context.t("_mobile_number"), placeholder: this.context.t("_mobile_number_placeholder"), isMandatory: true, type: "phone_number", max: 8 })}
                    {/* {
                      this.renderRadioButton({ label: this.context.t("_channel_of_communication"), name: "channel", defaultChecked: "sms", data: [{ label: this.context.t("_email"), value: "Email Address" }, { label: this.context.t("_sms"), value: "Mobile Number" }, { label: this.context.t("_phone_call"), value: "Phone Call" }] })
                    } */}
                    {this.renderCheckbox({ label: this.context.t("_terms_and_condition"), name: "agree" })}
                    <div className="btn-wrap">
                      <button className="btn blue">{this.context.t("_registration")}</button>
                    </div>
                  </form>
              }

            </div>
            <div className="col s6" />
          </div>
        </div>
        <div id="termsAndConditionModal" className="modal" role="dialog">
          <div className="modal-content">
            <span className="badge modalClose"><button type="button" aria-label="close" tabindex="0" className="close" onClick={() => this.handleCloseModal()}><i className="material-icons">close</i></button></span>
            <h4>{termsAndCondition.title}</h4>
            <div className="description" dangerouslySetInnerHTML={{ __html: termsAndCondition.body }}></div>

            <button className={`modal-close waves-effect waves-green btn blue ${lang == "ar" ? "pull-left" : "pull-right"}`}>{this.context.t("_agree")}</button>
          </div>
        </div>
        <div className="top-line ng-scope">
          <img src={topLineImage} alt="" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ i18nState }) => {
  return {
    lang: i18nState.lang
  }
}

const mapDispatchToProps = {
  showLoader: LoadingAction.showLoader,
  hideLoader: LoadingAction.hideLoader,
}
VolunteerRegistration.contextTypes = {
  t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(VolunteerRegistration);

const ColouredItems = () => {
  return (
    <div className="volunteer-color-container">
      <span className="grey-square-rotate red-sq one"></span>
      <span className="grey-square-rotate red-sq two"></span>
      <span className="grey-square-rotate red-sq three"></span>
      <span className="orange-circle one"></span>
      <span className="orange-circle two"></span>
      <span className="orange-circle three"></span>
      <span className="orange-circle four"></span>
      <span className="multi-square one"><b><i></i></b></span>
      <span className="multi-square two"><b><i></i></b></span>
      <span className="multi-square three"><b><i></i></b></span>
    </div>
  )
}