import { Component } from "react"
import _ from "lodash"
import { authRequest } from "./../httpAPI/api"
import { urlEndPoints } from "./../httpAPI/apiConfig";
import axios from "axios"
import querystring from "querystring"

export const env = "studio"
export const client_secret = "B>s?[7NLWW32%\y";
export const grant_type = "password";
export const client_id = "7dc0e907-f4cf-4138-89f3-e736c143aaba";
export const envConfig = {
	"dev": {
		url: "http://52.157.102.50/drupal-app/"
	},
	"studio": {
		url: "https://www.studio5.qa/drupal-app/"
	}
}
let apiRequestID = 1;

export class Main extends Component {
	constructor(props) {
		super(props);
		this.timer = null
	}

	componentDidMount() {
		// window.scroll({ top: 0, left: 0, behavior: "smooth" })
	}
	checkLogin = () => {
		// console.log("login check function");
	};
	accessPermission = async () => {
		return new Promise((resolve) => {
			const response = {
				permission: (localStorage.getItem("refreshToken") && localStorage.getItem("accessToken") && localStorage.getItem("role")) ? true : false,
				redirect: "/login",
				loaded: true
			};
			if (response.permission) {
				resolve(response);
			} else {
				resolve(response);
			}
		});
	}
	isLoggedInUsers = async () => {
		if (!_.isEmpty(localStorage.getItem("accessToken")) && !_.isEmpty(localStorage.getItem("refreshToken")) && !_.isEmpty(localStorage.getItem("role"))) {
			return this.props.history.push((
				localStorage.getItem("role") == "trainer" ||
				localStorage.getItem("role") == "manager" ||
				localStorage.getItem("role") == "asistant" ||
				localStorage.getItem("role") == "edu_specialist" ||
				localStorage.getItem("role") == "administrator"
			)
				? "/admin-profile"
				:
				"/profile"
			)
		}
	}
	config = () => {
		const fbUrl = "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href + "&amp;src=sdkpreparse";
		const twitterurl = "http://twitter.com/share?text=" + document.title.replace("|", " ") + "&amp;url=" + window.location.href;
		const config = {
			"environment": "development", //development | production
			"api_url": `${envConfig[env].url}api/`,
			"origin": `${envConfig[env].url}`,
			"map_api_key": "AIzaSyCzsI__SEELUMsAQYloJrf4d61TPFctVu4",
			"social_media_links": {
				"facebook": fbUrl,
				"twitter": twitterurl,
			},
			client_secret,
			grant_type,
			client_id
		};
		return config;
	};
	showAlert = () => {
		this.timer = setTimeout(() => {
			alert("Failed")
		}, 8000);
	}
	auth = async () => {
		const config = this.config()
		const refreshToken = await localStorage.getItem("refreshToken");
		const obj = {
			"client_secret": config.client_secret,
			"grant_type": "refresh_token",
			"client_id": config.client_id,
			"refresh_token": `${refreshToken}`
		}
		const authUrl = `${config.origin}${urlEndPoints.auth}`
		const authResponse = await authRequest(authUrl, "POST", obj, null, null, this)
		if (!_.has(authResponse, "error")) {
			localStorage.setItem("accessToken", authResponse.data.access_token);
			localStorage.setItem("refreshToken", authResponse.data.refresh_token);
			return authResponse
		} else {
			return false
		}

	}
	getCsrf = async () => {
		const xcsrfURL = envConfig[env].url + urlEndPoints.xcsrf
		const xcsrfToken = await axios(xcsrfURL)
		if (xcsrfToken.status == 200) {
			return xcsrfToken.data
		}
		else
			return null
	}
	networkRequest = async (url, method = "GET", param = {}, header = {}, isRegistration = false, apiID = apiRequestID++) => {
		// this.showAlert();
		const requestBodyParam = param

		const headerParam = {
			// ...header,
			// "X-CSRF-Token": xcsrfToken,
			Authorization: `${localStorage.getItem("tokenType")} ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		}
		if (isRegistration) {
			const xcsrfToken = await this.getCsrf();
			headerParam["X-CSRF-Token"] = xcsrfToken;
		}
		const promise = new Promise(async function (resolve, reject) {
			try {
				const response = await axios({
					method: method.toUpperCase(),
					timeout: 20000,
					url,
					headers: headerParam,
					data: requestBodyParam
				});
				if (response && response.status === 200) {
					// clearTimeout(that.timer)
					resolve(response)
				} else {
					// clearTimeout(that.timer)
					if (response && response.message) {
						reject(response)
					} else if (response) {
						reject(response)
					}
					reject(response)
					// const lang = await localStorage.getItem("lang");
					// alert(lang == "ar" ? "هناك خطأ ما. الرجاء معاودة المحاولة في وقت لاحق." : "Something went wrong. Please try again later.")
				}
			} catch (error) {
				console.warn(`APIRequestID : ${apiID} CONNECTION FAILED`)
				console.warn(`error : ${error})
			} `)
				reject(error)
			}
		});

		return promise
	}
	authRequest = async (url, method = "GET", param = {}, header = {}, apiID = apiRequestID++) => {
		const requestBodyParam = querystring.stringify(param)
		const headerParam = {
			"Content-Type": "application/x-www-form-urlencoded"
		}
		const promise = new Promise(async function (resolve, reject) {
			try {
				const response = await axios({
					method: method.toUpperCase(),
					url,
					headers: headerParam,
					data: requestBodyParam
				});
				if (response.status === 200) {
					resolve(response)
				} else {

					if (response && response.message) {
						reject(response)
					} else if (response) {
						reject(response)
					}
				}

			} catch (error) {
				console.warn(`APIRequestID : ${apiID} CONNECTION FAILED`)
				console.warn(`error : ${error})
			} `)
				reject(error)
				// console.log("self : ", self)
				// localStorage.removeItem("accessToken");
				localStorage.removeItem("refreshToken");
				localStorage.removeItem("tokenType");
				localStorage.removeItem("role");
				// self.props.history.push("/login");
			}
		});

		return promise
	}
}
export default Main