import React from "react"
import Main from "../main"
import SlideToTop from "../includes/slide_to_top.js"
import PageTitle from "../includes/page_title.js"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker"
import M from "materialize-css"
import { urlEndPoints } from "./../../httpAPI/apiConfig"
import { dynamicStyles } from "./../../constants/dynamicStyles"
import { calenderI18n, CustomDateInput, calenderLocal } from "./../../constants/common"
import _ from "lodash"
import { PopupAction, LoadingAction } from "./../../actions"
import topLineImage from "../../assets/images/top-line.png"
import dateformat from "dateformat";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loading } from "./../../components/common"


class ActivitiesList extends Main {
	constructor(props) {
		super(props);
		this.checkLogin();
		this.state = {
			isLoaded: false,
			config: this.config(),
			results: [],
			userActivityList: [],
			studentList: [],
			pager: { count: 0, pages: 0, items_per_page: 0, current_page: 1 },
			search: { _format: "json", type: "All", title: "", field_start_end_dates_value: "", page: 0 },
			modalContent: {
				type: "",
				title: "",
				message: "",
				isOpen: false,
				buttonText: ""
			},
			intervalId: 0,
			scrollStepInPx: 50,
			delayInMs: 16.66,
			searchActive: false
		}
		this.props.showLoader();
		this.handleChange = this.handleChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);


	}
	async componentDidMount() {
		document.title = `${this.context.t("_studio5")} | ${this.context.t("_activities")}`
		M.AutoInit();
		var options = {
			autoClose: true,
			format: "d mmmm, yyyy",
			onSelect: ((date) => {
				this.handleChange("field_start_end_dates_value", dateformat(date, "d mmmm, yyyy"))
				// this.handleChange("field_start_end_dates_value", dateformat(date, "yyyy-mm-dd"))
			}),
			showClearBtn: true,
			i18n: {
				cancel: this.context.t("_cancel"),
				done: this.context.t("_ok"),
				clear: this.context.t("_clear")
			},
		}
		setTimeout(() => {
			var elems = document.querySelectorAll("select");
			M.FormSelect.init(elems, options);
			var clearBtn = document.querySelectorAll(".datepicker-clear");
			clearBtn.forEach(data => {
				data.setAttribute("aria-label", this.context.t("_clear"));
			});
		}, 1000);
		if (localStorage.getItem("role") == "parent")
			await this.getChildren();
		if (localStorage.getItem("role") == "student")
			await this.checkStudentEnrolled();
		if (localStorage.getItem("role") == "volenteer")
			await this.checkVolunteerEnrolled();
		await this.getActivity();
		window.addEventListener('load', this.handleTabIndex());
	}
	componentDidUpdate() {
		document.title = `${this.context.t("_studio5")} | ${this.context.t("_activities")}`
	}
	handleTabIndex = () => {
		setTimeout(() => {
			let elem = document.querySelectorAll('select');
			elem.forEach(element => {
				element.setAttribute("tabindex", "0");
			});
		}, 1000);
	}
	getChildren = async () => {
		const { formData } = this.state;
		const { config } = this.state
		let url = `${config.api_url}${urlEndPoints.myChildren}`
		try {
			const data = await this.networkRequest(url)
			if (!data.data.message) {
				this.setState({ studentList: data.data.results })
			}
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
	}
	getActivity = async (isPagination = false) => {
		const { config, search } = this.state;
		const { lang } = this.props;
		let url = "";
		if (lang == "ar")
			url = `${config.origin}${lang}/api/${urlEndPoints.getActivitiesList}`
		else
			url = `${config.origin}api/${urlEndPoints.getActivitiesList}`
		// title: "", field_start_end_dates_value: "",
		const body = {
			lang
		}
		if (!_.isEmpty(search.type) && search.type != "All")
			body.field_event_type_value = search.type
		if (!_.isEmpty(search.title))
			body.title = search.title
		if (!_.isEmpty(search.category) && search.category != "All")
			body.field_activity_category_target_id = search.category
		if (search.field_start_end_dates_value)
			body.field_start_and_end_time_value = dateformat(search.field_start_end_dates_value, "yyyy-mm-dd")
		if (search.page)
			body.page = search.page

		try {
			const data = await this.networkRequest(url, "POST", body)
			if (data.status == 200) {
				// this.scrollToTop()
				// this.props.hideLoader()
				const results = data.data.results;
				results.forEach(async item => {
					item.field_start_and_end_time_2 = item.field_start_and_end_time_2.split("*")
					item.field_start_and_end_time_2 = item.field_start_and_end_time_2.map(date => new Date(date))
					item.field_start_and_end_time_2 = new Date(Math.min.apply(null, item.field_start_and_end_time_2));
					item.field_start_and_end_time_3 = item.field_start_and_end_time_3.split("*")
					item.field_start_and_end_time_4 = item.field_start_and_end_time_4.split("*")
					item.field_start_and_end_time = item.field_start_and_end_time.split("*")
					item.field_start_and_end_time_1 = item.field_start_and_end_time_1.split("*")
				});
				this.setState({
					// results,
					pager: data.data.pager,
				})
				this.setEnrolCount(results, isPagination)
			}
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
	}
	setEnrolCount = async (activities, isPagination) => {
		const { config, searchActive, search } = this.state
		// for (const activity of activities) {
		// 	let url = `${config.api_url}${urlEndPoints.getEnrolmentCount(activity.nid)}`
		// 	try {
		// 		const data = await this.networkRequest(url)
		// 		activity.enrolmentCount = !_.isEmpty(data.data) ? data.data.length : 0
		// 	} catch (error) {
		// 		this.props.hideLoader()
		// 		console.warn("error: ", error)
		// 	}
		// }
		this.setState({
			// results: search.page == 0 ? activities : [...this.state.results, ...activities],
			results: activities,
			isLoaded: true
		})
		setTimeout(() => {
			if (searchActive) {
				if (activities.length == 0)
					document.getElementById("noResultFoundText") && document.getElementById("noResultFoundText").focus()
				else
					this.refs.searchResult && this.refs.searchResult.focus()
			} else if (isPagination && activities.length > 0) {
				// this.refs.activityListItemRef0.focus()
			} else {
				document.getElementById("pageHeading") && document.getElementById("pageHeading").focus()
			}
		}, 500);

		this.props.hideLoader()
	}
	checkStudentEnrolled = async () => {
		const { config } = this.state
		let url = `${config.api_url}${urlEndPoints.getUserActivityById}`
		try {
			const data = await this.networkRequest(url)
			this.setState({ userActivityList: data.data })
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
	}
	checkVolunteerEnrolled = async () => {
		const { config } = this.state
		let url = `${config.api_url}${urlEndPoints.get_volunteer_activities}`
		try {
			const data = await this.networkRequest(url)
			this.setState({ userActivityList: data.data })
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
	}
	// handlePageChange() {
	// 	var { search, pager } = this.state;
	// 	if (pager.current_page + 1 < pager.pages) {
	// 		this.props.showLoader()
	// 		search.page = search.page + 1;
	// 		this.setState({ search: search }, () => {
	// 			this.getActivity(true);
	// 		});
	// 	}
	// }
	handlePageChange(pageNumber) {
		this.props.showLoader()
		var { search } = this.state;
		search.page = pageNumber - 1;
		this.setState({ search: search });
		this.getActivity(true);
	}
	handleChange = (field, value) => {
		var { search } = this.state;
		search[field] = value;
		this.setState({ search: search });
		// if (field == "type") {
		// 	this.props.showLoader()
		// 	this.getActivity();
		// }
	}
	onSubmit = async (event) => {
		this.props.showLoader()
		event.preventDefault();
		this.getActivity(true);
		this.setState({ searchActive: true })
	}
	handleEnroll = async (id, category) => {
		const role = await localStorage.getItem("role");
		switch (role) {
			case "student":
				if (category == "77") {
					this.props.showLoader();
					this.handleEnrollStudent(id)
				} else {
					this.props.showPopup({
						type: "error",
						title: this.context.t("_error"),
						message: this.context.t("_school_activity_error"),
						isOpen: true,
						buttonText: this.context.t("_close")
					})
				}
				break;
			case "volenteer":
				this.props.showLoader();
				this.handleEnrollVolunteer(id)
				break;
			case "school":
				if (category == "78") {
					this.props.showLoader();
					this.handleEnrollParent(id);
				} else {
					this.props.showPopup({
						type: "error",
						title: this.context.t("_error"),
						message: this.context.t("_public_activity_error"),
						isOpen: true,
						buttonText: this.context.t("_close")
					})
				}
				break;
			case "parent":
				if (category == "77") {
					this.props.showLoader();
					this.handleEnrollParent(id)
				} else {
					this.props.showPopup({
						type: "error",
						title: this.context.t("_error"),
						message: this.context.t("_school_activity_error"),
						isOpen: true,
						buttonText: this.context.t("_close")
					})
				}
				break;

			default:
				this.props.showPopup({
					type: "error",
					title: "",
					// message: this.context.t("_login_required"),
					message: <>
						<Link aria-label={this.context.t("_login_link")} onClick={() => { this.props.hidePopup({}) }} to="/login"><b>{this.context.t("_login_link")}</b></Link> {this.context.t("_is_required_for_enrolment")}
					</>,
					isOpen: true,
					buttonText: this.context.t("_ok")
				})
				break;
		}
	}
	handleEnrollStudent = async (id) => {
		const { config } = this.state
		const url = `${config.api_url}${urlEndPoints.activityEnrolment}`
		const obj = {
			"activity_id": id
		}
		try {
			const data = await this.networkRequest(url, "POST", obj)
			this.props.hideLoader();
			if (data.data.error) {
				this.displayError(data.data.error)
			} else {
				await this.checkStudentEnrolled();
				this.getActivity()
				this.props.showPopup({
					type: "success",
					title: this.context.t("_success"),
					message: this.context.t("_enroll_success"),
					isOpen: true,
					buttonText: this.context.t("_ok")
				})
			}
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
		// }

	}
	handleEnrollVolunteer = async (id) => {
		const { config } = this.state
		const url = `${config.api_url}${urlEndPoints.volunteerEnrolment}`
		const obj = {
			"activity_id": id
		}
		try {
			const data = await this.networkRequest(url, "POST", obj)
			this.props.hideLoader();
			if (data.data.error) {
				this.displayError(data.data.error)
			} else {
				await this.checkVolunteerEnrolled();
				this.getActivity()
				this.props.showPopup({
					type: "success",
					title: this.context.t("_success"),
					message: this.context.t("_request_send"),
					isOpen: true,
					buttonText: this.context.t("_ok")
				})
			}
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
		// }

	}
	handleEnrollParent = (id) => {
		const { studentList } = this.state;
		if (localStorage.getItem("role") == "parent" && studentList.length == 1) {
			this.checkStudentEnrolled1(studentList[0].uid, id);
		} else {
			this.props.history.push(`/subscribe-activities/${id}`);
		}

	}
	checkStudentEnrolled1 = async (userId, activityId) => {
		const { config } = this.state
		let url = `${config.api_url}${urlEndPoints.getActivityById(userId)}`
		try {
			const data = await this.networkRequest(url)
			if (_.has(data, "data") && data.data.length > 0) {
				const fieldActivity = _.find(data.data, ["field_activity_id", activityId]);
				if (!_.isEmpty(fieldActivity)) {

					this.props.hideLoader()
					return this.props.showPopup({
						type: fieldActivity.field_published_status == "Waiting List" ? "success" : "error",
						title: this.context.t(fieldActivity.field_published_status == "Waiting List" ? "_success" : "_error"),
						message: this.context.t(fieldActivity.field_published_status == "Waiting List" ? "already_waiting_list" : "_already_enrolled"),
						isOpen: true,
						buttonText: this.context.t("_ok")
					})
				}
				else
					this.handleInitialEnroll(activityId);
			} else {
				this.handleInitialEnroll(activityId);
			}
			this.props.hideLoader();
		} catch (error) {
			this.props.hideLoader()
			console.warn("error: ", error)
		}
	}
	handleInitialEnroll = async (id) => {
		const { lang } = this.props;
		const { studentList, config } = this.state;
		if (studentList && studentList[0] && studentList[0].uid) {
			const checkedList = {
				0: studentList[0].uid
			}
			this.props.showLoader();
			let url = `${config.api_url}${urlEndPoints.activityEnrolment}`
			let obj = {
				"activity_id": id,
				user_ids: checkedList
			}
			try {
				const data = await this.networkRequest(url, "POST", obj)
				if (data.data.error) {
					this.props.showPopup({
						type: "error",
						title: this.context.t("_error"),
						message: `${data.data.error.name[lang]}`,
						isOpen: true,
						buttonText: this.context.t("_ok")
					})
				} else {
					let message = this.context.t("_enroll_success");
					if (localStorage.getItem("role") == "school") {
						message = `${message}. ${this.context.t("_admin_contact_you")}`
					}
					this.props.showPopup({
						type: "success",
						title: this.context.t("_success"),
						message,
						isOpen: true,
						buttonText: this.context.t("_ok")
					})
				}
				this.getActivity();
			} catch (error) {
				this.props.hideLoader()
				console.warn("error: ", error)
			}
			// } else {
			//     this.props.hideLoader();
			// }

		}
	}
	displayError = error => {
		const { lang } = this.props;
		if (error.name) {
			this.props.showPopup({
				type: error.type == "waiting_list" ? "success" : "error",
				title: error.type == "waiting_list" ? this.context.t("_success") : this.context.t("_error"),
				message: error.name[lang],
				isOpen: true,
				buttonText: this.context.t("_ok")
			})
		}
	}
	clearSearch = async () => {
		this.props.showLoader()
		setTimeout(() => {
			let elems = document.querySelector("select");
			M.FormSelect.init(elems)
		}, 1000);
		this.setState({ search: { _format: "json", type: "All", title: "", category: "All", field_start_end_dates_value: "", page: 0 }, searchActive: false }, () => { this.getActivity(); })
	}
	handleDetailRedirect = id => {
		this.props.history.push(`/activity/${id}`);
	}
	scrollStep() {
		var scrollStepInPx = this.props.scrollStepInPx ? this.props.scrollStepInPx : this.state.scrollStepInPx;
		if (window.pageYOffset === 0) {
			clearInterval(this.state.intervalId);
		}
		window.scroll(0, window.pageYOffset - scrollStepInPx);
	}
	scrollToTop() {
		window.scroll({ top: 0, left: 0, behavior: "smooth" })
	}
	handleEnrolledClick = () => {
		this.props.showPopup({
			type: "error",
			title: this.context.t("_warning"),
			message: this.context.t("_already_enrolled"),
			isOpen: true,
			buttonText: this.context.t("_ok")
		})
	}
	handleVolunteeredClick = () => {
		this.props.showPopup({
			type: "error",
			title: this.context.t("_warning"),
			message: this.context.t("_volunteer_not_needed"),
			isOpen: true,
			buttonText: this.context.t("_ok")
		})
	}
	handleRegisterVolunteerRegister = () => {
		this.props.showPopup({
			type: "error",
			title: "",
			// message: this.context.t("_login_required"),
			message: <>
				<Link aria-label={this.context.t("_login_link")} onClick={() => { this.props.hidePopup({}) }} to="/login"><b>{this.context.t("_login_link")}</b></Link> {this.context.t("_is_required_for_volunteer")}
			</>,
			isOpen: true,
			buttonText: this.context.t("_ok")
		})
		// this.props.showPopup({
		// 	type: "error",
		// 	title: this.context.t("_warning"),
		// 	message: this.context.t("_volunteer_not_registered"),
		// 	isOpen: true,
		// 	buttonText: this.context.t("_ok")
		// })
		// this.props.history.push("/registration/volunteer")
	}
	getGender = gender => {
		gender = gender.toLowerCase()
		switch (gender) {
			case "male":
				return this.context.t("_boys")
			case "female":
				return this.context.t("_girls")
			case "mixed":
				return this.context.t("_mixed")

		}
	}
	getDateInFormat = date => {
		// if (!_.isEmpty(date)) {
		if (date.includes("Jan"))
			date = date.replace("Jan", this.context.t("_jan"))

		if (date.includes("Feb"))
			date = date.replace("Feb", this.context.t("_feb"))

		if (date.includes("Mar"))
			date = date.replace("Mar", this.context.t("_mar"))

		if (date.includes("Apr"))
			date = date.replace("Apr", this.context.t("_apr"))

		if (date.includes("May"))
			date = date.replace("May", this.context.t("_may"))

		if (date.includes("Jun"))
			date = date.replace("Jun", this.context.t("_jun"))

		if (date.includes("Jul"))
			date = date.replace("Jul", this.context.t("_jul"))

		if (date.includes("Aug"))
			date = date.replace("Aug", this.context.t("_aug"))

		if (date.includes("Sep"))
			date = date.replace("Sep", this.context.t("_sep"))

		if (date.includes("Oct"))
			date = date.replace("Oct", this.context.t("_oct"))

		if (date.includes("Nov"))
			date = date.replace("Nov", this.context.t("_nov"))

		if (date.includes("Dec"))
			date = date.replace("Dec", this.context.t("_dec"))
		// }

		date = date.replace(",", this.context.t("_coma"))
		return date
	}

	getTimeInFormat = time => {
		// if (!_.isEmpty(time)) {
		if (time.includes("am"))
			time = time.replace("am", this.context.t("_am"))

		if (time.includes("pm"))
			time = time.replace("pm", this.context.t("_pm"))
		// }
		return time;
	}
	handleEnrolButton = item => {
		if (Number(item.field_number_of_attendees) > Number(item.enrollment_count))
			return <button onClick={() => { this.handleEnroll(item.nid, item.field_activity_category_1) }} className="btn blueColor ">
				{this.context.t("_enroll")}
			</button>
		else if (Number(item.enrollment_count) < (Number(item.field_number_of_attendees) + Number(item.field_waiting_list_count)))
			return <button onClick={() => { this.handleEnroll(item.nid, item.field_activity_category_1) }} className="btn blueColor ">
				{this.context.t("_waiting_list")}
			</button>
		else
			return <button onClick={() => { this.handleEnrollClosed() }} className="btn blueColor btn-disabled">
				{this.context.t("_closed")}
			</button>
	}
	handleEnrollClosed = () => {
		this.props.showPopup({
			type: "error",
			title: this.context.t("_warning"),
			message: this.context.t("_enrolment_closed"),
			isOpen: true,
			buttonText: this.context.t("_ok")
		})
	}
	render() {
		const { results, pager, isLoaded, search, userActivityList, config, searchActive } = this.state;
		const { lang } = this.props;
		const listActivity = search.type == "All" ? results : results.filter(data => data.field_event_type.toLowerCase() == search.type)
		return (
			<div id="main-content" className="activiti-list">
				<SlideToTop />
				<PageTitle title={this.context.t("_activities")} isDashboardItem={true} />
				<div className="container">
					<nav className="breadcrumb" id="breadcrumb-wrap" aria-label="breadcrumb">
						<ul>
							<li className="breadcrumb-item">
								<Link to="/">{this.context.t("_home")}</Link>{" "}
							</li>
							<li className="breadcrumb-item">
								<Link tabIndex="0" to aria-current={this.context.t("_page")}>
									<span>{this.context.t("_activities")}</span>
								</Link>
							</li>
						</ul>
					</nav>
				</div>
				<span className="multi-square one"><b><i></i></b></span>
				<div id="skipContent">
					<div id="main-container">
						<div className="container">
							<form onSubmit={this.onSubmit} method="post" name="search" className="search">
								<div className="row topFormHolder">
									<div className="col s12 m6 l3">
										<div className="input-field item">
											<input name="Title"
												placeholder={this.context.t("_title")}
												onChange={evt => this.handleChange("title", evt.target.value)}
												onFocus={evt => this.handleChange("title", evt.target.value)}
												value={search.title}
												id="title"
												type="text"
												aria-label="Activity search title"
											/>
											<label style={dynamicStyles.position(lang)} htmlFor="title">{this.context.t("_title")}</label>
											<span className="helper-text" ></span>
										</div>
									</div>
									<div className="col s12 m6 l3 date-popup">
										<div className={`input-field mobileSelect item ${lang}`}>
											<label htmlFor="activityType" className="active" style={dynamicStyles.position(lang)}>{this.context.t("_activity_type")}</label>
											<select
												className="browser-default" id="activityType" tabIndex="0" value={search.category} onChange={evt => this.handleChange("category", evt.target.value)}>
												<option value="" disabled >{this.context.t("_select_activity_type")}</option>
												<option value="All" >{this.context.t("_all")}</option>
												<option value="77">{this.context.t("_public_activity")}</option>
												<option value="78">{this.context.t("_school_activity")}</option>
											</select>
											<span className="helper-text"></span>
										</div>
									</div>
									<div className="col s12 m6 l3 date-popup">

										<div className="input-field item">
											<DatePicker
												name="date"
												placeholderText={this.context.t("_date")}
												id="date"
												autoComplete="off"
												dateFormat="yyyy-MM-dd"
												locale={calenderLocal(this.context)}
												customInput={<CustomDateInput ariaLabel={this.context.t("_select_date_button")} onFieldChange={date => this.handleChange("field_start_end_dates_value", new Date(date))} />}
												ariaLabelledBy="select date"
												onChange={date =>
													this.handleChange("field_start_end_dates_value", date)
												}
												onCalendarOpen={e => {
													let elems = document.querySelector(".react-datepicker");
													elems.setAttribute("tabindex", "0")
													elems && elems.focus()
													elems = document.querySelector(".react-datepicker__month-select");
													elems.setAttribute("aria-label", this.context.t("_select_month"))
													elems = document.querySelector(".react-datepicker__year-select");
													elems.setAttribute("aria-label", this.context.t("_select_year"))

												}}
												onCalendarClose={e => {
													var elems = document.querySelector(".hiddenDob");
													elems.setAttribute("aria-label", `${this.context.t("_selected_date")} ${dateformat(search.field_start_end_dates_value, "dd-mm-yyyy")}`)
													elems.setAttribute("value", dateformat(search.field_start_end_dates_value, "yyyy-mm-dd"))
													setTimeout(() => {
														elems && elems.focus()
													}, 200);
												}}
												style={dynamicStyles.textAlign(lang)}
												value={search.field_start_end_dates_value}
												selected={search.field_start_end_dates_value}
												peekNextMonth
												showMonthDropdown
												showYearDropdown
												dropdownMode="select"
											/>
											<label style={dynamicStyles.position(lang)} htmlFor="date">{this.context.t("_date")}</label>
											<span className="helper-text" ></span>
										</div>
									</div>
									<div className="col s12 m6 l3 valign-wrapper center-align search-btn-holder">
										<button type="submit" className="btn noWidth profileBtn yellow waves-effect waves-light" aria-label="Activity search">{this.context.t("_search")} <i className={`material-icons ${lang}`}>{`${lang === "en" ? "arrow_forward" : "arrow_backward"}`}</i></button>
										<button type="button" aria-label="Activity search clear" onClick={() => this.clearSearch()} className="btn noWidth blue-btn waves-effect waves-light">
											{this.context.t("_clear")}
										</button>
									</div>
								</div>
							</form>
							<div className="row">
								<div className="col s7 result-status">
									{isLoaded && searchActive ? <h2 tabIndex="0" ref="searchResult" className="search-result">{this.context.t("_search_result") + " ( " + (listActivity.length) + ` ${this.context.t("_of")} ` + pager.count + " )"}</h2> : ""}

								</div>
								<div className="col s5 result-type">
									<div className={`input-field col s12 mobileSelect filter-select ${lang}`}>
										<label htmlFor="result-type">{this.context.t("_select_activity")}</label>
										<select id="result-type" className="browser-default" value={search.type} onChange={evt => this.handleChange("type", evt.target.value)}>
											<option value="All" >{this.context.t("_all")}</option>
											<option value="competition">{this.context.t("_competitions")}</option>
											<option value="session">{this.context.t("_sessions")}</option>
											<option value="event">{this.context.t("_events")}</option>
											<option value="challenge">{this.context.t("_challenges")}</option>
											<option value="project">{this.context.t("_projects")}</option>
										</select>
									</div>
								</div>
							</div>
							<ul className="activities-list" aria-label="activities">
								{/* <InfiniteScroll
									dataLength={listActivity.length}
									next={this.handlePageChange}
									hasMore={true}
									// loader={<Loading />}
									style={{ overflow: 'visible', }}
								> */}
								{
									listActivity.map((row, index) => {
										return (


											<li className="newactivities test3" key={row.nid}>

												<div className="figHolderNA"><img src={row.field_activity_thumbnail} alt={`${row.title} image`} alt="" /></div>

												<div className="textHolderNA">
													<div className={`topTextHolderNA ${row.field_activity_zone}`}>
														<Link aria-label={`${row.title} ${this.context.t("_activity")} ${row.field_gender} ${row.field_age_group} ${this.context.t("_year_old")} ${this.context.t("_start_date")} ${this.getDateInFormat(row.field_start_and_end_time_3[0])} ${this.getTimeInFormat(row.field_start_and_end_time[0])} ${this.context.t("_and")} ${this.context.t("_end_date")} ${this.getDateInFormat(row.field_start_and_end_time_4[row.field_start_and_end_time_4.length - 1])} ${this.getTimeInFormat(row.field_start_and_end_time_1[row.field_start_and_end_time_1.length - 1])}`} ref={`activityListItemRef${index}`} to={`/activity/${row.nid}`}>
															<h4><i className="topHeadNA"></i>{row.title}</h4>
														</Link>
														<div className="topTextInsideNA">
															<span className="blackText"><i className="topBoyIconNA"></i> {this.getGender(row.field_gender)}</span>
															<span>{`${row.field_age_group} ${this.context.t("_year_old")}`}</span>
															{/* <span className="schoolSessionNA">{`${row.nothing.trim()} - ${row.field_activity_category}`}</span> */}
														</div>
													</div>

													<div className="bottomTextHolderNA">
														<div className="bottomTextLeftNA" dangerouslySetInnerHTML={{ __html: row.body }}></div>
														<div className="bottomTextRightNA">
															<span className="calendarNA">
																<i className="calendar-icons"></i> {this.context.t("_start_date")}  <span className="date">{this.getDateInFormat(row.field_start_and_end_time_3[0])} <br /><small>{this.getTimeInFormat(row.field_start_and_end_time[0])}</small></span>
															</span>
															<span className="calendarNA">
																<i className="calendar-icons"></i> {this.context.t("_end_date")} <span className="date">{this.getDateInFormat(row.field_start_and_end_time_4[row.field_start_and_end_time_4.length - 1])}<br /><small>{this.getTimeInFormat(row.field_start_and_end_time_1[row.field_start_and_end_time_1.length - 1])}</small></span>
															</span>
														</div>
													</div>
												</div>
												<div className="btnHolderNA">
													{
														(_.isEmpty(localStorage.getItem("role")) && row.field_need_volenteer != "0") ?
															<button className="btn" onClick={() => { this.handleRegisterVolunteerRegister() }}>{this.context.t("_volunteer_now")}</button> : null
													}
													{
														localStorage.getItem("role") == "volenteer" ?
															row.field_need_volenteer == "0" ?
																<button className="btn btn-disabled" onClick={() => { this.handleVolunteeredClick() }} >{this.context.t("_volunteer_now")}</button>
																:
																<button className="btn" onClick={() => { this.handleEnroll(row.nid, row.field_activity_category_1) }} >{this.context.t("_volunteer_now")}</button>
															: null
													}
													{
														(
															localStorage.getItem("role") == "trainer" ||
															localStorage.getItem("role") == "manager" ||
															localStorage.getItem("role") == "asistant" ||
															localStorage.getItem("role") == "edu_specialist" ||
															localStorage.getItem("role") == "administrator"
														) ? null :
															(
																row.field_need_to_show_enroll_button == "1" &&
																!_.isEmpty(row.field_start_and_end_time_3) &&
																!_.isEmpty(row.field_start_and_end_time_4)) ?
																(new Date(row.field_start_and_end_time_2) >= new Date()) ?
																	(localStorage.getItem("role") != "volenteer" ||
																		_.isEmpty(localStorage.getItem("role"))) &&
																	(!_.isEmpty(_.find(userActivityList, ["field_activity_id", row.nid])) ?
																		(_.find(userActivityList, ["field_activity_id", row.nid]).field_published_status == "Unpublished" ||
																			_.find(userActivityList, ["field_activity_id", row.nid]).field_published_status == "Published") ?
																			<button onClick={() => { this.handleEnrolledClick() }} className="btn blueColor btn-disabled">
																				{_.find(userActivityList, ["field_activity_id", row.nid]).field_published_status == "Published" ? this.context.t("_enrolled") : this.context.t("_pending")}
																			</button> :
																			this.handleEnrolButton(row)
																		:
																		this.handleEnrolButton(row)
																	)
																	: <button onClick={() => { this.handleEnrollClosed() }} className="btn blueColor btn-disabled">
																		{this.context.t("_closed")}
																	</button>
																: null

													}
												</div>
											</li>

										)
									})
								}
								{/* </InfiniteScroll> */}

							</ul>
							{
								isLoaded && listActivity.length == 0 && <div className="row"><p id="noResultFoundText" aria-label={this.context.t("_no_activities_found")} tabIndex="0" className="col s12 no-result">{this.context.t("_no_activities_found")}</p></div>
							}

							<nav className="pagination-wrapper" aria-label="pagination">
								{(listActivity.length > 0 && isLoaded) ? (
									<Pagination
										prevPageText={`< ${this.context.t("_previous")}`}
										nextPageText={`${this.context.t("_next")} >`}
										hideFirstLastPages={true}
										hideDisabled={true}
										linkClassPrev="linkClassPrev"
										linkClassNext="linkClassNext"
										activePage={pager.current_page + 1}
										itemsCountPerPage={pager.items_per_page}
										totalItemsCount={pager.count}
										pageRangeDisplayed={5}
										onChange={this.handlePageChange}
									/>) : ""
								}
							</nav>

						</div>
						<div className="sparkles">
							<span className="orange-circle"></span>
							<span className="multi-square two"><b><i></i></b></span>
							<span className="grey-square-rotate red-sq one"></span>
							<span className="grey-square-rotate red-sq two"></span>
						</div>
					</div>
					<div className="top-line ng-scope">
						<img src={topLineImage} alt="" />
					</div>
				</div>
			</div>
		)
	}
}
const mapStateToProps = ({ i18nState }) => {
	return {
		lang: i18nState.lang
	}
}
const mapDispatchToProps = {
	showPopup: (data) => PopupAction.showPopup(data),
	hidePopup: (data) => PopupAction.hidePopup(data),
	showLoader: LoadingAction.showLoader,
	hideLoader: LoadingAction.hideLoader,
}
ActivitiesList.contextTypes = {
	t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesList);
