import { GET_ACTIVITY_LIST, GET_ACTIVITY_FULL_LIST } from "./../../constants/constants";

const initialState = {
    activityList: {
        results: [],
        pager: { count: 0, pages: 0, items_per_page: 0, current_page: 1 }
    },
    activityFullList: []
};

const activityListReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ACTIVITY_LIST:
            return {
                ...state,
                activityList: action.payload
            };
        case GET_ACTIVITY_FULL_LIST:
            return {
                ...state,
                activityFullList: action.payload
            };
        default:
            return { ...state };
    }
};

export default activityListReducer;
