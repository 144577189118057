import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { dynamicStyles } from "./../../constants/dynamicStyles"

class RegisterTypes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedRole: "",
        }
    }
    componentDidMount() {
        setTimeout(() => {
            document.getElementById("pageHeading") && document.getElementById("pageHeading").focus()
        }, 500);
    }
    changeRole = selectedRole => {
        this.props.history.push(`/registration/${selectedRole}`);
        // this.setState({ selectedRole });
    };
    onNextPress = () => {
        const { selectedRole } = this.state;
        this.props.history.push(`/registration/${selectedRole}`);
    };
    render() {
        const { path } = this.props.match
        const { lang } = this.props;
        const selectedRole = path.split("/").pop()
        return (
            <div className="registration-container">
                <h2 id="registration-title" className="primary-heading" style={dynamicStyles.textAlign(lang)}>{this.context.t("_are_you")}</h2>
                <div className="btn-wrap type-selection" id="skipContent">
                    <button aria-label={`${this.context.t("_are_you_1")} ${this.context.t("_parent")}`} role="button" className={selectedRole === "parent" ? "btn active" : "btn"} onClick={() => this.changeRole("parent")} > {this.context.t("_parent")} </button>
                    <button aria-label={`${this.context.t("_are_you_1")} ${this.context.t("_student")}`}
                        className={
                            selectedRole === "student" ? "btn active" : "btn"
                        }
                        onClick={() => { this.changeRole("student") }}
                    >
                        {this.context.t("_student")}
                    </button>
                    <button aria-label={`${this.context.t("_are_you_1")} ${this.context.t("_school")}`}
                        className={
                            selectedRole === "school" ? "btn active" : "btn"
                        }
                        onClick={() => this.changeRole("school")}
                    >
                        {this.context.t("_school")}
                    </button>
                    <button aria-label={`${this.context.t("_are_you_1")} ${this.context.t("_volunteer")}`}
                        className={
                            selectedRole === "volunteer" ? "btn active" : "btn"
                        }
                        onClick={() => this.changeRole("volunteer")}
                    >
                        {this.context.t("_volunteer")}
                    </button>
                </div>
                {/* <div className="btn-wrap">
              <button className="btn" onClick={() => this.onNextPress()}>
                Next <i className="material-icons">arrow_forward</i>
              </button>
            </div> */}
            </div>
        )
    }
}

const mapStateToProps = ({ i18nState }) => {
    return {
        lang: i18nState.lang
    }
}
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
RegisterTypes.contextTypes = {
    t: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(RegisterTypes);